import React from 'react'
import { Box, SelectChangeEvent, TextField, Typography } from '@mui/material'

import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import DatePicker from '../../elements/DatePicker'
import { NewPartnerEntity } from '../../interfaces/Partners/NewPartnerEntity'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { ACCEPTED_FILE_TYPES } from '../../../constants/constants'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { PartnerGenerateAgreement } from './PartnerGenerateAgreement'
import { FormRow } from '../../styled/FormRow'
import { FormLabel } from '../../styled/FormLabel'
import ContractDiscontinueDropDownList from '../../elements/partners/ContractDiscontinueDropDownList'
import { PartnerGenerateContractDiscontinueAgreement } from './PartnerGenerateContractDiscontinueAgreement'
import { FileEntry } from '../../interfaces/File/FileEntry'

const sx = {
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
    fontSize: 18,
  },
}

interface Props {
  partner: NewPartnerEntity
  partnerId: number
  partnerStatusList: ClassifierStatus[]
  contractDiscontinueCodes: ClassifierStatus[]
  filesContract: FileListItem[]
  formErrors: { [key: string]: string }
  setPartner: React.Dispatch<React.SetStateAction<NewPartnerEntity>>
  handleDropDownChange: (event: SelectChangeEvent) => void
  handleContractChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFileChange: (
    files: FileListItem[],
    setState: React.Dispatch<React.SetStateAction<FileListItem[]>>,
    setUploadedFileState: React.Dispatch<React.SetStateAction<FileEntry[]>>
  ) => void
  setFilesContract: React.Dispatch<React.SetStateAction<FileListItem[]>>
  setUploadedFilesContract: React.Dispatch<React.SetStateAction<FileEntry[]>>
}

export default function NewPartnerContractData({
  partner,
  partnerId,
  partnerStatusList,
  contractDiscontinueCodes,
  filesContract,
  formErrors,
  onFileChange,
  handleDropDownChange,
  handleContractChange,
  setPartner,
  setFilesContract,
  setUploadedFilesContract,
}: Props) {
  const { komentārs, nosaukums, numurs, termiņš } = partner.līgums

  const handleDateChange = (date: Date | null) => {
    setPartner({
      ...partner,
      līgums: {
        ...partner.līgums,
        termiņš: date,
      },
    })
  }

  return (
    <Box>
      <Typography margin="20px 0" fontWeight="700">
        Līguma dati
      </Typography>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Statuss
        </Box>
        <PartnerDropDownList
          name="statuss_kods"
          value={partner.statuss_kods || ''}
          onChange={handleDropDownChange}
          data={partnerStatusList}
        />
      </FormRow>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Numurs
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="numurs"
          value={numurs}
          onChange={handleContractChange}
        />
      </FormRow>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Termiņš
        </Box>
        <DatePicker
          value={termiņš}
          onChange={handleDateChange}
          minDate={new Date()}
          helperText={formErrors.termiņš}
          error={Boolean(formErrors.termiņš)}
          sx={{ flexGrow: 1 }}
        />
      </FormRow>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Nosaukums
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="nosaukums"
          onChange={handleContractChange}
          value={nosaukums}
        />
      </FormRow>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Komentārs
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="komentārs"
          onChange={handleContractChange}
          value={komentārs}
        />
      </FormRow>
      <FormRow>
        <FormLabel sx={sx.labelRow} />
        <PartnerGenerateAgreement partnerId={partnerId} />
      </FormRow>
      <Typography margin="20px 0" fontWeight="700">
        Līguma pārtraukšana
      </Typography>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Pārtraukšanas iemesls:
        </Box>
        <ContractDiscontinueDropDownList
          data={contractDiscontinueCodes}
          name="pārtraukšanas_iemesls_kods"
          value={partner.pārtraukšanas_iemesls_kods || ''}
          onChange={handleDropDownChange}
        />
      </FormRow>
      <FormRow>
        <FormLabel sx={sx.labelRow} />
        <PartnerGenerateContractDiscontinueAgreement partnerId={partnerId} />
      </FormRow>
      <Typography margin="20px 0" fontWeight="700">
        Līguma faili
      </Typography>
      <FormRow>
        <Box component="label" sx={sx.labelRow}>
          Faili
        </Box>
        <Box>
          <FileUploadButton
            useLink={true}
            onFileChange={(e) => onFileChange(e, setFilesContract, setUploadedFilesContract)}
            files={filesContract}
            acceptedUploadFileTypes={ACCEPTED_FILE_TYPES}
          />
        </Box>
      </FormRow>
    </Box>
  )
}
