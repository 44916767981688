import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { changeApplicationChildren } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
// import { ApplicationStatusCodes } from '../../enums/ApplicationStatusCodes'
import { ApplicationOwnerTypes } from '../../enums/ApplicationOwnerTypes'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { convertToChildrenArray } from '../../utils/convertToChildrenArray'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { SelectChildrenForParentCardContent } from './SelectChildrenForParentCardContent'
import * as helpers from './helpers'
import {
  DISPLAYABLE_CHILDREN_COUNT,
  REQUIRED_CHILDREN_ERROR_MESSAGE,
} from '../../../constants/constants'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
}

interface Props {
  card: ApplicationCard | null
  applicationCards: ApplicationCard[]
  applicationEntry: ApplicationEntry | null
  onClose: () => void
}

export default function SelectChildrenForParentCardModal({
  card,
  applicationCards,
  applicationEntry,
  onClose,
}: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    selectedChildren: '',
  })

  const remapApplicationEntry =
    applicationEntry?.aizbilstamie.map((aizbilstamais) => {
      return {
        ...aizbilstamais,
        cardData: applicationCards.find((card) => card.persona_id === aizbilstamais.persona_id),
      }
    }) || []

  const childrenNames = remapApplicationEntry
    .filter((application) =>
      application.pārbaudes.some((r) => r.atbilst) && application.cardData
        ? helpers.isApplicationCardValid(application.cardData) &&
          application?.cardData?.dzimšanas_datums &&
          application?.cardData?.tips_nosaukums === ApplicationOwnerTypes.Child
        : true
    )
    .sort((a, b) => a.vārds.localeCompare(b.vārds))

  const [childrenCheck, setChildrenCheck] = React.useState(
    Object.assign(
      {},
      ...childrenNames.map((child) => ({
        [`${child.persona_id}_${child.vārds}_${child.uzvārds}`.replace(/ /g, '')]: false,
      }))
    )
  )

  const onChildCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      Object.values(childrenCheck).filter((child) => child).length >= DISPLAYABLE_CHILDREN_COUNT &&
      event.target.checked
    ) {
      showSnackBar({
        severity: 'info',
        text: `Nedrīkst norādīt vairāk par ${DISPLAYABLE_CHILDREN_COUNT} bērniem`,
      })

      return
    }

    setChildrenCheck({
      ...childrenCheck,
      [event.target.name]: event.target.checked,
    })
  }

  const updateApplicationChildrenCall = () => {
    return card && card.id && applicationEntry
      ? changeApplicationChildren(
          {
            id: card.id,
            bērni: convertToChildrenArray(childrenCheck),
          },
          card.id
        )
      : Promise.resolve()
  }

  const isFormInvalid = () => {
    const _errors: { [key: string]: string } = {}

    if (!Object.values(childrenCheck).includes(true))
      _errors['selectedChildren'] = REQUIRED_CHILDREN_ERROR_MESSAGE

    setFormErrors(_errors)

    return !!Object.keys(_errors).length
  }

  const onSubmit = () => {
    if (isFormInvalid()) {
      return
    }

    setIsLoadingSubmit(true)

    updateApplicationChildrenCall()
      .then(() => {
        showSnackBar()
        onClose()
      })
      .catch((error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      })
      .finally(() => setIsLoadingSubmit(false))
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Bērnu atzīmēšana drukāšanai uz plastikāta apliecības
            </Box>
          </DialogTitle>
          <DialogContent>
            <SelectChildrenForParentCardContent
              childrenNames={childrenNames}
              childrenCheck={childrenCheck}
              formErrors={formErrors}
              onChildCheckChange={onChildCheckChange}
            />
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
