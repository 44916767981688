import React from 'react'
import { FormControl, MenuItem, Select, Box, SelectChangeEvent } from '@mui/material'
import { UserRoleSelect } from '../../interfaces/UserRole'

const sx = {
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '160px',
  },
}

interface Props {
  data: UserRoleSelect[]
  name: string
  value: string[]
  onChange: (event: SelectChangeEvent<string[]>) => void
  label?: string
  fullWidth?: boolean
}

export default function UserRolesDropDownList({
  label = 'Lomas',
  fullWidth = true,
  data = [],
  ...props
}: Props) {
  return (
    <FormControl sx={sx.inputWrapper} variant="outlined">
      <Box component="span" sx={sx.inputLabel}>
        {label}
      </Box>
      <Select multiple fullWidth style={{ overflow: 'hidden' }} {...props}>
        {data.map(({ text }, index) => (
          <MenuItem key={index} value={text}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
