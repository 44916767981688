import React, { useMemo } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
} from '@mui/material'
import { HistoryAction } from '../../enums/HistoryAction'
import lvLocale from 'date-fns/locale/lv'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATETIME_MASK } from '../../../constants/constants'
import { HistoryListFilterState } from '../../interfaces/HistoryListFilterState'

const sx = {
  filterControl: {
    width: '240px',
    marginRight: '12px',
  },
}

interface Props {
  actions: { [key in HistoryAction]: string }
  filter: HistoryListFilterState
  onTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (event: SelectChangeEvent<string>) => void
  onTimeChange: (name: string, date?: Date | null) => void
  resetFilters: () => void
}

export const HistoryFilter = ({
  actions,
  filter,
  onTextFieldChange,
  onSelectChange,
  onTimeChange,
  resetFilters,
}: Props) => {
  const actionKeys = useMemo(() => Object.keys(actions), [actions]) as HistoryAction[]

  return (
    <Box component="form">
      <Box>
        <TextField
          size="small"
          label="Personas kods"
          name="personas_kods"
          sx={sx.filterControl}
          value={filter.personas_kods}
          onChange={onTextFieldChange}
        />
        <TextField
          size="small"
          label="Klienta personas kods"
          name="klienta_personas_kods"
          sx={sx.filterControl}
          value={filter.klienta_personas_kods}
          onChange={onTextFieldChange}
        />
        <FormControl variant="outlined" size="small">
          <InputLabel id="list-action-select">Darbība</InputLabel>
          <Select
            labelId="list-action-select"
            label="Darbība"
            name="darbība"
            sx={sx.filterControl}
            value={filter.darbība}
            onChange={onSelectChange}
          >
            <MenuItem key="-1" value="">
              Visas
            </MenuItem>
            {actionKeys.map((key: HistoryAction) => {
              return (
                <MenuItem key={key} value={key}>
                  {actions[key]}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lvLocale}>
          <DateTimePicker
            label="Laiks no"
            value={filter.laiks_no || null}
            disableFuture={true}
            onChange={(date) => onTimeChange('laiks_no', date)}
            renderInput={(params) => <TextField size="small" sx={sx.filterControl} {...params} />}
            inputFormat={DEFAULT_DATETIME_FORMAT}
            mask={DEFAULT_DATETIME_MASK}
          />
          <DateTimePicker
            label="Laiks līdz"
            value={filter.laiks_līdz || null}
            minDateTime={filter.laiks_no}
            disableFuture={true}
            onChange={(date) => onTimeChange('laiks_līdz', date)}
            renderInput={(params) => <TextField size="small" sx={sx.filterControl} {...params} />}
            inputFormat={DEFAULT_DATETIME_FORMAT}
            mask={DEFAULT_DATETIME_MASK}
          />
        </LocalizationProvider>
        <Button
          sx={{ textDecoration: 'underline', minWidth: '125px' }}
          variant="text"
          color="primary"
          onClick={resetFilters}
        >
          Notīrīt filtrus
        </Button>
      </Box>
    </Box>
  )
}
