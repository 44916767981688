import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import TaskDropDownList from '../../elements/partners/TaskDropDownList'
import DatePicker from '../../elements/DatePicker'
import RequiredLabel from '../../elements/RequiredLabel'
import { addTask, editTask } from '../../../api/API'
import { useMutation } from 'react-query'
import { Loader } from '../../elements/Loader'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { NewTaskForm } from '../../interfaces/NewTaskForm'
import {
  DEFAULT_API_DATE_FORMAT,
  INVALID_DATE_FORMAT,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { format, isValid } from 'date-fns'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    minWidth: '400px',
  },
}

interface NewTaskModalProps {
  partnerId: number
  onClose: () => void
  task?: NewTaskForm
}

export default function NewTaskModal({ partnerId, onClose, task }: NewTaskModalProps) {
  const [tasks, setTasks] = useState([])
  const [formState, setFormState] = useState<NewTaskForm>({
    sadarbības_partneris_id: partnerId,
    izpildīts: task?.izpildīts,
    apraksts: task?.apraksts ?? null,
    brīdinājums: task ? new Date(task.brīdinājums!) : null,
    uzdevums_veids_kods: task?.uzdevums_veids_kods,
    termiņš: task ? new Date(task.termiņš!) : null,
  })
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const { showSnackBar } = useSnackBar()

  const applicationStatusesQueryParams = {
    klasifikatora_kods: 'UZDEVUMA_VEIDS',
  }

  const { isLoading: isLoadingTasks } = useFetchClassifiers({
    queryKey: 'classifier_records',
    queryParameters: applicationStatusesQueryParams,
    querySuccess: (resp) => {
      setTasks(resp)
    },
    queryError: (error) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const [addTaskRecordMutation] = useMutation(
    ({
      sadarbības_partneris_id,
      izpildīts,
      apraksts,
      brīdinājums,
      uzdevums_veids_kods,
      termiņš,
    }: NewTaskForm) =>
      addTask({
        sadarbības_partneris_id,
        uzdevums_veids_kods,
        apraksts,
        izpildīts: !!izpildīts,
        brīdinājums: format(brīdinājums!, DEFAULT_API_DATE_FORMAT),
        termiņš: format(termiņš!, DEFAULT_API_DATE_FORMAT),
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const [editTaskRecordMutation] = useMutation(
    ({
      sadarbības_partneris_id,
      izpildīts,
      apraksts,
      brīdinājums,
      uzdevums_veids_kods,
      termiņš,
    }: NewTaskForm) =>
      editTask({
        id: task?.id,
        sadarbības_partneris_id,
        apraksts,
        uzdevums_veids_kods,
        izpildīts: !!izpildīts,
        brīdinājums: format(brīdinājums!, DEFAULT_API_DATE_FORMAT),
        termiņš: format(termiņš!, DEFAULT_API_DATE_FORMAT),
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const isValidDate = (date: null | Date) => {
    if (date === null) return false
    if (isValid(date)) return true
    return false
  }

  const isFormValid = () => {
    const { apraksts, brīdinājums, uzdevums_veids_kods, termiņš } = formState
    const _errors: { [key: string]: string } = {}

    if (!apraksts) _errors['apraksts'] = REQUIRED_ERROR_MESSAGE
    if (!uzdevums_veids_kods) _errors['uzdevums_veids_kods'] = REQUIRED_ERROR_MESSAGE
    if (!isValidDate(termiņš)) _errors['termiņš'] = INVALID_DATE_FORMAT
    if (!isValidDate(brīdinājums)) _errors['brīdinājums'] = INVALID_DATE_FORMAT
    if (
      termiņš &&
      format(termiņš!, DEFAULT_API_DATE_FORMAT) < format(new Date(), DEFAULT_API_DATE_FORMAT)
    )
      _errors['termiņš'] = INVALID_DATE_FORMAT
    if (
      brīdinājums &&
      format(brīdinājums!, DEFAULT_API_DATE_FORMAT) < format(new Date(), DEFAULT_API_DATE_FORMAT)
    )
      _errors['brīdinājums'] = INVALID_DATE_FORMAT

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsLoadingSubmit(true)
    if (!task) {
      addTaskRecordMutation(formState)
    } else {
      editTaskRecordMutation(formState)
    }
  }

  const onTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {task ? 'Labot' : 'Pievienot'} uzdevumu
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Uzdevuma veids" />
              <TaskDropDownList
                value={formState.uzdevums_veids_kods ?? ''}
                data={tasks}
                name="uzdevums_veids_kods"
                onChange={onTextFieldChange}
                error={Boolean(formErrors.uzdevums_veids_kods)}
                helperText={formErrors.uzdevums_veids_kods}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Uzdevuma termiņš" />
              <DatePicker
                minDate={new Date()}
                value={formState.termiņš}
                onChange={(e) => setFormState({ ...formState, termiņš: e })}
                sx={sx.datePicker}
                error={Boolean(formErrors.termiņš)}
                helperText={formErrors.termiņš}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Brīdinājuma termiņš" />
              <DatePicker
                minDate={new Date()}
                value={formState.brīdinājums}
                onChange={(e) => setFormState({ ...formState, brīdinājums: e })}
                sx={sx.datePicker}
                error={Boolean(formErrors.brīdinājums)}
                helperText={formErrors.brīdinājums}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Apraksts" />
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={5}
                fullWidth
                name="apraksts"
                autoComplete="off"
                value={formState.apraksts ?? ''}
                onChange={onTextFieldChange}
                error={Boolean(formErrors.apraksts)}
                helperText={formErrors.apraksts}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
      <Loader open={isLoadingTasks} />
    </Dialog>
  )
}
