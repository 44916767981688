import React from 'react'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { Button, Grid } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useQuery, useMutation } from 'react-query'
import AddEditUserModal from './AddEditUserModal'
import {
  fetchUserRoles,
  editUserData,
  USER_DATA_ENDPOINT,
  USER_DATA_COUNT_ENDPOINT,
} from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { paramFetcher } from '../../elements/ParamFetcher'
import UsersFilter from './UsersFilter'
import { UserStateFilter } from '../../interfaces/UserFilterState'
import { UserQueryParams } from '../../interfaces/UserQueryParams'
import { User } from '../../interfaces/User'
import { UserRole, UserRoleSelect } from '../../interfaces/UserRole'
import { useCurrentUser } from '../../../store/user'
import { hasUserRole, remapRolesToRolesRequest } from '../../utils/role'
import { UserRoles } from '../../enums/UserRoles'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { convertPersonCode } from '../../utils/convertPersonCode'

const columns = [
  {
    field: 'vārds',
    title: 'Vārds',
  },
  {
    field: 'uzvārds',
    title: 'Uzvārds',
  },
  {
    field: 'personas_kods',
    title: 'Personas kods',
    render: ({ personas_kods }: User) => convertPersonCode(personas_kods),
  },
  {
    field: 'active_directory_lietotājvārds',
    title: 'AD lietotājvārds',
  },
  {
    field: 'ir_aktivs',
    title: 'Lietotāja statuss',
    render: ({ ir_aktivs }: User) => (ir_aktivs ? 'Aktīvs' : 'Neaktīvs'),
  },
  {
    field: 'lomas',
    title: 'Lomas',
    render: ({ lomas }: User) =>
      lomas.map((role: UserRole) => <div key={role.loma}>{role.loma}</div>),
  },
]

export default function Users() {
  const { showSnackBar } = useSnackBar()
  const { currentUser } = useCurrentUser()

  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null)
  const [rows, setRows] = React.useState<User[]>([])
  const [count, setCount] = React.useState(0)
  const [dropdownRoles, setDropdownRoles] = React.useState<UserRoleSelect[]>([])

  const [queryParams, setQueryParams] = React.useState<UserQueryParams>({
    search: '',
    ir_aktivs: false,
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
    sort: null,
  })

  const { Superadmin, Admin } = UserRoles

  const { isLoading, refetch } = useQuery(
    ['users', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
          ir_aktivs: !queryParams.ir_aktivs,
        },
        USER_DATA_ENDPOINT,
        USER_DATA_COUNT_ENDPOINT
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        setRows(list)
        setCount(count)
      },
    }
  )

  const { isLoading: isLoadingRoles } = useQuery('user_roles', fetchUserRoles, {
    refetchOnWindowFocus: false,
    onSuccess: (data: UserRoleSelect[]) => {
      if (data) {
        if (hasUserRole(currentUser?.lomas, [Superadmin])) {
          setDropdownRoles(data)
        } else {
          setDropdownRoles(data.filter((role) => role.text !== Superadmin && role.text !== Admin))
        }
      } else {
        setDropdownRoles([])
      }
    },
  })

  const [editUserStatusMutation] = useMutation(
    (user: User) =>
      editUserData(
        {
          ...user,
          lomas: remapRolesToRolesRequest(user.lomas),
          ir_aktivs: !user.ir_aktivs,
        },
        user.id
      ),
    {
      onSuccess: () => {
        showSnackBar()
        refetch()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onUserEdit = (event: React.MouseEvent, user: User) => {
    setSelectedUser(user)
    setOpenModalAddEdit(true)
  }

  const closeModal = () => {
    setSelectedUser(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const onUserStatusChange = (event: React.MouseEvent, user: User) => {
    let { tableData, ...rest } = user

    editUserStatusMutation(rest)
  }

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const onFilterChange = (filters: UserStateFilter) => {
    setQueryParams({
      ...queryParams,
      ...filters,
    })
  }

  return (
    <>
      {openModalAddEdit && (
        <AddEditUserModal user={selectedUser} dropdownRoles={dropdownRoles} onClose={closeModal} />
      )}
      <Grid container sx={{ pb: '20px' }}>
        <Grid item xs={9}>
          <UsersFilter onFilterChange={onFilterChange} />
        </Grid>
        <Grid item xs={3} justifyContent="flex-end" alignItems="flex-end" container>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedUser(null)
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot lietotāju
          </Button>
        </Grid>
      </Grid>
      <CustomizedMaterialTable
        columns={columns}
        data={rows}
        isLoading={isLoading || isLoadingRoles}
        page={queryParams.offset / queryParams.limit}
        totalCount={count}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        rowStyle={{
          height: 50,
        }}
        actions={[
          {
            icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
            onClick: onUserEdit,
            tooltip: 'Rediģēt',
          },
          (rowData: User) => {
            const isActive = Boolean(rowData.ir_aktivs)
            return {
              hidden: isActive,
              icon: () => <CheckCircleOutlineIcon fontSize="small" htmlColor="#00DC25" />,
              onClick: onUserStatusChange,
              tooltip: 'Aktivizēt',
            }
          },
          (rowData: User) => {
            const isActive = Boolean(rowData.ir_aktivs)
            return {
              hidden: !isActive,
              icon: () => <RemoveCircleOutlineIcon fontSize="small" htmlColor="#FF0000" />,
              onClick: onUserStatusChange,
              tooltip: 'Bloķēt',
            }
          },
        ]}
      />
    </>
  )
}
