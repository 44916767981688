import React from 'react'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { useQuery } from 'react-query'
import { SETTINGS_DATA_ENDPOINT, SETTINGS_DATA_COUNT_ENDPOINT } from '../../../api/API'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { paramFetcher } from '../../elements/ParamFetcher'
import { User } from '../../interfaces/User'
import { SettingsQueryParams } from '../../interfaces/SettingsQueryParams'
import EditIcon from '@mui/icons-material/Edit'
import { PartnerSetting } from '../../interfaces/Partners/PartnerSetting'
import EditSettingModal from '../Partner/EditSettingModal'

const columns = [
  {
    field: 'vērtība',
    title: 'Vērtība',
    width: '20%',
  },
  {
    field: 'apraksts',
    title: 'Apraksts',
  },
]

export default function Settings() {
  const [rows, setRows] = React.useState<User[]>([])
  const [count, setCount] = React.useState(0)
  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [selectedSetting, setSelectedSetting] = React.useState<PartnerSetting | null>(null)
  const [queryParams, setQueryParams] = React.useState<SettingsQueryParams>({
    search: '',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const { isLoading, refetch } = useQuery(
    ['settings', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        SETTINGS_DATA_ENDPOINT,
        SETTINGS_DATA_COUNT_ENDPOINT
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        setRows(list)
        setCount(count)
      },
    }
  )

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const onSettingsEdit = (event: React.MouseEvent, setting: PartnerSetting) => {
    setSelectedSetting(setting)
    setOpenModalAddEdit(true)
  }

  const closeModal = () => {
    setSelectedSetting(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onSettingsEdit,
      tooltip: 'Rediģēt',
    },
  ]

  return (
    <>
      {openModalAddEdit && <EditSettingModal setting={selectedSetting} onClose={closeModal} />}
      <CustomizedMaterialTable
        columns={columns}
        data={rows}
        isLoading={isLoading}
        page={queryParams.offset / queryParams.limit}
        actions={actions}
        totalCount={count}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
