import {
  DiscountStatusCode,
  NewPartnerDiscountResponse,
} from '../interfaces/Partners/NewPartnerDiscount'

export const getRowColor = (data: NewPartnerDiscountResponse) => {
  switch (data.statuss_kods) {
    case DiscountStatusCode.Aizstāta:
      return '#bababa'
    case DiscountStatusCode.Noraidīta:
      return '#FF0000'
    case DiscountStatusCode.Piedāvāta:
      return '#FF7F50'
    case DiscountStatusCode.Aktīva:
    default:
      return 'inherit'
  }
}
