import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Grid,
  Chip,
  Link,
} from '@mui/material'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import { useMutation } from 'react-query'
import * as helpers from './helpers'
import { createNewCheckApplication, updateExistingApplicationAgreement } from './ApplicationUtils'
import { ApplicationCheck } from '../../interfaces/Application/ApplicationCheck'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { ApplicationAgreement } from '../../interfaces/Application/ApplicationAgreement'
import DebtorStatus from '../../enums/DebtorStatus'
import { Person } from '../../interfaces/Person'
import { DelayedLoader } from '../../elements/DelayedLoader'

const sx = {
  formRow: {
    mt: 1,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    mr: 2,
    width: '260px',
    display: 'inline-block',
  },
}

interface Props {
  applicationEntry: ApplicationEntry
  userInfo: Person | null
  reload: () => void
}

export function ExistingApplicationTab({ applicationEntry, userInfo, reload }: Props) {
  const { showSnackBar } = useSnackBar()
  const { id, piekrīt_veselības_datu_apstrādei } = applicationEntry
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasAcceptedVda, setHasAcceptedVda] = React.useState(piekrīt_veselības_datu_apstrādei)
  const [isVdaCheckboxShown, toggleIsVdaCheckboxShown] = React.useReducer((state) => !state, false)
  const debtorStatus = React.useMemo(
    () => helpers.getDebtorStatus(applicationEntry),
    [applicationEntry]
  )

  const [checkApplication] = useMutation<ApplicationCheck>(
    () => createNewCheckApplication({ id }),
    {
      onSuccess: () => {
        reload()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoading(false)
      },
    }
  )

  const [updateApplicationAgreement] = useMutation<void | ApplicationAgreement>(
    () =>
      updateExistingApplicationAgreement(
        {
          id: id,
          piekrīt_veselības_datu_apstrādei: hasAcceptedVda,
        },
        id
      ),
    {
      onSuccess: () => {
        checkApplication()
      },
      onError: (error: any) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasAcceptedVda(event.target.checked)
  }

  const handleVdaChange = () => {
    setIsLoading(true)
    updateApplicationAgreement()
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>Iesnieguma reģistrācijas datums:</Typography>
          <Typography>
            {format(parseISO(applicationEntry.iesniegšanas_datums), DEFAULT_DATE_FORMAT)}
          </Typography>
        </Box>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>Statuss:</Typography>
          <Typography>{applicationEntry.statuss_nosaukums}</Typography>
        </Box>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>Pēdējās aktualizācijas datums:</Typography>
          <Typography>
            {format(parseISO(applicationEntry.aktualizacijas_datums), DEFAULT_DATE_FORMAT)}
          </Typography>
        </Box>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>Iesnieguma ID:</Typography>
          <Typography>{applicationEntry.id}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>PMLP aktualizācijas datums:</Typography>
          <Typography>
            {userInfo?.pmlp_aktualizācijas_laiks
              ? format(parseISO(userInfo.pmlp_aktualizācijas_laiks), DEFAULT_DATETIME_FORMAT)
              : ''}
          </Typography>
        </Box>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>UGFA:</Typography>
          <DebtorStatusRenderer debtorStatus={debtorStatus} />
        </Box>
        <Box sx={sx.formRow}>
          <Typography sx={sx.label}>Veselības datu apstrāde:</Typography>
          <MedicalDataProcessingStatusRenderer isAllowed={piekrīt_veselības_datu_apstrādei} />
          <Box ml={1}>
            <Link
              color="#000"
              component="button"
              variant="body2"
              onClick={toggleIsVdaCheckboxShown}
            >
              Mainīt
            </Link>
          </Box>
        </Box>
        {isVdaCheckboxShown && (
          <Box sx={{ ...sx.formRow, mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasAcceptedVda}
                  size="small"
                  onChange={onCheckChange}
                  disabled={isLoading}
                />
              }
              label={
                <Typography lineHeight={1.1} component="span">
                  Apliecinājums, ka persona piekrīt Veselības datu apstrādes noteikumiem īpašos
                  gadījumos
                </Typography>
              }
            />
          </Box>
        )}
      </Grid>
      <Box width={1} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button color="primary" variant="outlined" onClick={handleVdaChange} disabled={isLoading}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <DelayedLoader />
            </Box>
          ) : (
            'Saglabāt un veikt atkārtotu pārbaudi'
          )}
        </Button>
      </Box>
    </Grid>
  )
}

function MedicalDataProcessingStatusRenderer({ isAllowed }: { isAllowed: boolean }) {
  const statusText = React.useMemo(() => (isAllowed ? 'Atļauta' : 'Nav atļauta'), [isAllowed])
  const statusColor = React.useMemo(() => (isAllowed ? 'success' : 'default'), [isAllowed])

  return <Chip sx={{ fontWeight: 'bold' }} label={statusText} color={statusColor} />
}

function DebtorStatusRenderer({ debtorStatus }: { debtorStatus: DebtorStatus }) {
  const statusText = React.useMemo(() => {
    switch (debtorStatus) {
      case DebtorStatus.Debtor:
        return 'Parādnieks'
      case DebtorStatus.NotDebtor:
        return 'Nav parādnieks'
      default:
        return 'Nav pārbaudīts'
    }
  }, [debtorStatus])

  const statusColor = React.useMemo(() => {
    switch (debtorStatus) {
      case DebtorStatus.Debtor:
        return 'error'
      case DebtorStatus.NotDebtor:
        return 'success'
      default:
        return 'default'
    }
  }, [debtorStatus])

  return <Chip sx={{ fontWeight: 'bold' }} label={statusText} color={statusColor} />
}
