import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATETIME_FORMAT } from '../../../constants/constants'
import { ApplicationHistoryData } from '../../interfaces/Application/ApplicationHistoryData'
import { useQuery } from 'react-query'
import { getApplicationHistory } from './ApplicationUtils'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { Box } from '@mui/material'

const columns = [
  {
    field: 'laiks',
    title: 'Datums, laiks',
    minWidth: 100,
    render: ({ laiks }: ApplicationHistoryData) => format(parseISO(laiks), DEFAULT_DATETIME_FORMAT),
  },
  {
    field: 'tips_nosaukums',
    title: 'Notikuma nosaukums',
  },
  {
    field: 'komentārs',
    title: 'Komentārs',
  },
]

export const ApplicationHistory = () => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const { showSnackBar } = useSnackBar()

  const [applicationHistory, setApplicationHistory] = useState<ApplicationHistoryData[]>()

  const { isFetching: isLoading } = useQuery(
    ['applicationHistory', applicationId],
    () => getApplicationHistory(applicationId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: ApplicationHistoryData[]) => {
        setApplicationHistory(data)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
          <DelayedLoader />
        </Box>
      ) : (
        <>
          <CustomizedMaterialTable
            columns={columns}
            data={applicationHistory}
            options={{
              paging: false,
            }}
            rowStyle={() => ({
              height: 50,
            })}
          />
        </>
      )}
    </>
  )
}
