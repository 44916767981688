import React from 'react'
import { Grid, Typography } from '@mui/material'
import OutlinedContainer from '../../elements/OutlinedContainer'
import { FormRow } from '../../styled/FormRow'
import { FormLabel } from '../../styled/FormLabel'
import { NewPartnerResponse } from '../../interfaces/Partners/NewPartnerEntity'
import { NewPartnerStatus } from '../../interfaces/Partners/NewPartnerStatus'
import { PartnerGenerateAgreement } from './PartnerGenerateAgreement'
import { PartnerUploadAgreement } from './PartnerUploadAgreement'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT, FILE_SIZE_LIMIT_MB_MESSAGE } from '../../../constants/constants'
import { PartnerGenerateContractDiscontinueAgreement } from './PartnerGenerateContractDiscontinueAgreement'

interface Props {
  partnerEntry: NewPartnerResponse
  partnerStatus: NewPartnerStatus | null
  onRefetch: () => void
}

export const ExistingPartnerAgreement = ({ partnerEntry, partnerStatus, onRefetch }: Props) => {
  return (
    <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
      <OutlinedContainer id="contractInfo" label={<b>Līgums</b>}>
        <Typography margin="20px 0" fontWeight="700">
          Līguma dati
        </Typography>
        <FormRow>
          <FormLabel>Statuss:</FormLabel>
          <Typography>{partnerStatus?.statuss_nosaukums}</Typography>
        </FormRow>

        {partnerEntry.līgums && (
          <>
            <FormRow>
              <FormLabel>Nosaukums:</FormLabel>
              <Typography>{partnerEntry.līgums.nosaukums}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Numurs:</FormLabel>
              <Typography>{partnerEntry.līgums.numurs}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Komentārs:</FormLabel>
              <Typography>{partnerEntry.līgums.komentārs}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Izveidošanas datums:</FormLabel>
              <Typography>
                {partnerEntry.līgums.izveidošanas_datums
                  ? format(parseISO(partnerEntry.līgums.izveidošanas_datums), DEFAULT_DATE_FORMAT)
                  : ''}
              </Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Termiņš:</FormLabel>
              <Typography>
                {partnerEntry.līgums.termiņš
                  ? format(parseISO(partnerEntry.līgums.termiņš), DEFAULT_DATE_FORMAT)
                  : ''}
              </Typography>
            </FormRow>
            <FormRow>
              <FormLabel />
              <PartnerGenerateAgreement partnerId={partnerEntry.id} />
            </FormRow>
            <Typography margin="20px 0" fontWeight="700">
              Līguma pārtraukšana
            </Typography>
            <FormRow>
              <FormLabel>Pārtraukšanas iemesls:</FormLabel>
              <Typography>{partnerEntry.pārtraukšanas_iemesls_nosaukums}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel />
              <PartnerGenerateContractDiscontinueAgreement partnerId={partnerEntry.id} />
            </FormRow>
            <Typography margin="20px 0" fontWeight="700">
              Līguma faili
            </Typography>
            <FormRow>
              <FormLabel sx={{ alignSelf: 'baseline', marginTop: '7px' }}>Faili:</FormLabel>
              <PartnerUploadAgreement partnerEntry={partnerEntry} onRefetch={onRefetch} />
            </FormRow>
            <FormRow>
              <FormLabel sx={{ alignSelf: 'baseline' }} />
              <Typography sx={{ ml: 0.5, color: '#999', mt: '-10px !important' }}>
                {FILE_SIZE_LIMIT_MB_MESSAGE}
              </Typography>
            </FormRow>
          </>
        )}
      </OutlinedContainer>
    </Grid>
  )
}
