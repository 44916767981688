import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { UserStateFilter } from '../../interfaces/UserFilterState'

let timeoutId = 0

interface Props {
  onFilterChange: (filter: UserStateFilter) => void
}

export default function UsersFilter({ onFilterChange }: Props) {
  const firstUpdate = useRef(true)

  const [filterState, setFilterState] = useState<UserStateFilter>({
    search: '',
    ir_aktivs: false,
  })

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = window.setTimeout(() => {
      onFilterChange(filterState)
    }, 300)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  const onChange = (event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) => {
    setFilterState({
      ...filterState,
      [event.target.name as string]: event.target.value,
    })
  }

  const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState({
      ...filterState,
      [event.target.name as string]: event.target.checked,
    })
  }

  return (
    <form>
      <TextField
        size="small"
        sx={{
          width: '240px',
          marginRight: '12px',
        }}
        label="Atslēgvārdi"
        name="search"
        value={filterState.search}
        onChange={onChange}
        variant="outlined"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="ir_aktivs"
            checked={filterState.ir_aktivs}
            onChange={onCheckChange}
            color="primary"
          />
        }
        label="Rādīt neaktīvos lietotājus"
      />
    </form>
  )
}
