import React from 'react'
import OutlinedContainer from '../elements/OutlinedContainer'
import { Box, Grid } from '@mui/material'
import { useQuery } from 'react-query'
import {
  infopanelCertificatesToBeRevoked,
  infopanelPendingComplaints,
  infopanelPendingInquiries,
  infopanelRawDocuments,
  infopanelRawSubmissions,
  infopanelReevaluationSubmissions,
  infopanelUncompletedTasks,
  infopanelUnpublishedDiscounts,
  infopanelUnreviewedResponses,
} from '../../api/API'
import { hasUserRole } from '../utils/role'
import { useCurrentUser } from '../../store/user'
import { UserRoles } from '../enums/UserRoles'
import routes from '../routes/routes'
import { useHistory } from 'react-router-dom'
import {
  DEFAULT_APPLICATION_LIST_FILTER,
  useApplicationListQueryParams,
} from '../../store/applicationsList'
import { DEFAULT_PARTNER_LIST_FILTER, usePartnersListQueryParams } from '../../store/partnersList'
import { ApplicationListFilterState } from '../interfaces/Application/ApplicationListFilterState'
import { PartnerListFilterState } from '../interfaces/Partners/PartnerListFilterState'
import InfoLink from '../elements/info/InfoLink'

export default function Info() {
  const history = useHistory()
  const { setFilter } = useApplicationListQueryParams()
  const { setFilter: setFilterPartner } = usePartnersListQueryParams()

  const { currentUser } = useCurrentUser()
  const { Program_coordinator, Partner_coordinator } = UserRoles
  const isProgramCoordinator = hasUserRole(currentUser?.lomas, [Program_coordinator])
  const isPartnerCoordinator = hasUserRole(currentUser?.lomas, [Partner_coordinator])

  const [rawSubmissionsCount, setRawSubmissionsCount] = React.useState(null)
  const [reevaluationSubmissionsCount, setReevaluationSubmissionsCount] = React.useState(null)
  const [rawDocumentsCount, setRawDocumentsCount] = React.useState(null)
  const [certificatesToBeRevokedCount, setCertificatesToBeRevokedCount] = React.useState(null)

  const [pendingInquiriesCount, setPendingInquiriesCount] = React.useState(null)
  const [pendingComplaintsCount, setPendingComplaintsCount] = React.useState(null)
  const [unreviewedResponsesCount, setUnreviewedResponsesCount] = React.useState(null)
  const [unpublishedDiscounts, setUnpublishedDiscounts] = React.useState(null)
  const [uncompletedTasks, setUncompletedTasks] = React.useState(null)

  // Aktuālās lietas

  useQuery('infopanel_raw_submissions', infopanelRawSubmissions, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setRawSubmissionsCount(response)
    },
  })

  useQuery('infopanel_reevaluation_submissions', infopanelReevaluationSubmissions, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setReevaluationSubmissionsCount(response)
    },
  })

  useQuery('infopanel_raw_documents', infopanelRawDocuments, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setRawDocumentsCount(response)
    },
  })

  useQuery('infopanel_certificates_to_be_revoked', infopanelCertificatesToBeRevoked, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setCertificatesToBeRevokedCount(response)
    },
  })

  // Partneru lietas

  useQuery('infopanel_pending_inquiries', infopanelPendingInquiries, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setPendingInquiriesCount(response)
    },
  })

  useQuery('infopanel_pending_complaints', infopanelPendingComplaints, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setPendingComplaintsCount(response)
    },
  })

  useQuery('infopanel_unreviewed_responses', infopanelUnreviewedResponses, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setUnreviewedResponsesCount(response)
    },
  })

  useQuery('infopanel_unpublished_discounts', infopanelUnpublishedDiscounts, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setUnpublishedDiscounts(response)
    },
  })

  useQuery('infopanel_uncompleted_tasks', infopanelUncompletedTasks, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setUncompletedTasks(response)
    },
  })

  const handleLinkClickApplication = (filter: ApplicationListFilterState, route: string) => {
    setFilter(filter)
    history.push(route, {
      keepFilters: true,
    })
  }

  const handleLinkClickPartner = (filter: PartnerListFilterState, route: string) => {
    setFilterPartner(filter)
    history.push(route, {
      keepFilters: true,
    })
  }

  const applicationGrid = [
    {
      label: `Neapstrādātie iesniegumi - ${rawSubmissionsCount}`,
      route: routes.applications.list,
      filter: {
        ...DEFAULT_APPLICATION_LIST_FILTER,
        statuss: [{ nosaukums: 'Izskatīšanā', kods: 'IZSKATĪŠANĀ' }],
      },
    },
    {
      label: `Pārvērtēšanā esošie iesniegumi - ${reevaluationSubmissionsCount}`,
      route: routes.applications.list,
      filter: {
        ...DEFAULT_APPLICATION_LIST_FILTER,
        statuss: [{ nosaukums: 'Pārvērtēšanā', kods: 'PĀRVĒRTĒŠANĀ' }],
      },
    },
    {
      label: `Neapstrādātie dokumenti - ${rawDocumentsCount}`,
      route: routes.applications.list,
      filter: {
        ...DEFAULT_APPLICATION_LIST_FILTER,
        statuss: [],
        ir_neapstrādāti_dokumenti: true,
        secondaryFilters: true,
      },
    },
    {
      label: `Anulējamās apliecības - ${certificatesToBeRevokedCount}`,
      route: routes.applications.list,
      filter: {
        ...DEFAULT_APPLICATION_LIST_FILTER,
        satur_anulējamas_apliecības: true,
        secondaryFilters: true,
      },
    },
  ]

  const partnerGrid = [
    {
      label: `Neizskatītie pieteikumi - ${pendingInquiriesCount}`,
      route: routes.partners.list,
      filter: {
        ...DEFAULT_PARTNER_LIST_FILTER,
        statuss: [{ nosaukums: 'Jauns', kods: 'SJAU' }],
      },
    },
    {
      label: `Neizskatītās sūdzības - ${pendingComplaintsCount}`,
      route: routes.partners.list,
      filter: {
        ...DEFAULT_PARTNER_LIST_FILTER,
        ir_sūdzības: true,
        secondaryFilters: true,
      },
    },
    {
      label: `Neizskatītās atbildes uz informācijas pieprasījumiem - ${unreviewedResponsesCount}`,
      route: routes.partners.list,
      filter: {
        ...DEFAULT_PARTNER_LIST_FILTER,
        ir_pieprasījumi: true,
        secondaryFilters: true,
      },
    },
    {
      label: `Nepublicētās akcijas - ${unpublishedDiscounts}`,
      route: routes.partners.list,
      filter: {
        ...DEFAULT_PARTNER_LIST_FILTER,
        ir_akcijas: true,
        secondaryFilters: true,
      },
    },
    {
      label: `Neizpildītie uzdevumi - ${uncompletedTasks}`,
      route: routes.partners.list,
      filter: {
        ...DEFAULT_PARTNER_LIST_FILTER,
        ir_uzdevumi: true,
        secondaryFilters: true,
      },
    },
  ]

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        {isProgramCoordinator && (
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', flexDirection: 'column', lineHeight: 2 }}
          >
            <OutlinedContainer id="propInfo" label={<b>Aktuālās lietas</b>}>
              {applicationGrid.map((element) => (
                <Box>
                  <InfoLink
                    label={element.label}
                    handleClick={() => handleLinkClickApplication(element.filter, element.route)}
                  />
                </Box>
              ))}
            </OutlinedContainer>
          </Grid>
        )}

        {isPartnerCoordinator && (
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', flexDirection: 'column', lineHeight: 2 }}
          >
            <OutlinedContainer id="propInfo" label={<b>Partneru lietas</b>}>
              {partnerGrid.map((element) => (
                <Box>
                  <InfoLink
                    label={element.label}
                    handleClick={() => handleLinkClickPartner(element.filter, element.route)}
                  />
                </Box>
              ))}
            </OutlinedContainer>
          </Grid>
        )}
      </Grid>
    </>
  )
}
