import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'

export function DelayedLoader() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true)
    }, 300)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return <>{isVisible && <CircularProgress />}</>
}
