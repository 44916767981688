import React, { useState, useEffect, useRef } from 'react'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { useQuery } from 'react-query'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATETIME_FORMAT } from '../../../constants/constants'
import { HISTORY_DATA_ENDPOINT, HISTORY_DATA_COUNT_ENDPOINT } from '../../../api/API'
import { paramFetcher } from '../../elements/ParamFetcher'
import { HistoryEntry, HistoryListEntry } from '../../interfaces/History'
import { HistoryAction } from '../../enums/HistoryAction'
import { HistoryStatus } from '../../enums/HistoryStatus'
import { HistoryFilter } from './HistoryFilter'
import { reverse } from 'named-urls'
import { Box, SelectChangeEvent } from '@mui/material'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { convertPersonCode } from '../../utils/convertPersonCode'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../routes/routes'
import { DEFAULT_HISTORY_LIST_FILTER, useHistoryListQueryParams } from '../../../store/historyList'
import { HistoryListFilterState } from '../../interfaces/HistoryListFilterState'

const actions: { [key in HistoryAction]: string } = {
  create: 'Datu pievienošana',
  save: 'Datu labošana',
  remove: 'Datu dzēšana',
  view: 'Datu apskatīšana',
  login: 'Autentifikācija',
  list: 'Saraksts',
  api: 'API',
}

const actionStatus: { [key in HistoryStatus]: string } = {
  success: 'Veiksmīgs',
  error: 'Kļūdains',
}

const columns = [
  {
    field: 'laiks',
    title: 'Datums un laiks',
  },
  {
    field: 'vards',
    title: 'Lietotājs',
    render: ({ vards, uzvards }: HistoryListEntry) => (
      <>
        {vards} {uzvards}
      </>
    ),
  },
  {
    field: 'personas_kods',
    title: 'Personas kods',
    render: ({ personas_kods }: HistoryListEntry) => convertPersonCode(personas_kods),
  },
  {
    field: 'darbība',
    title: 'Darbības veids',
  },
  {
    field: 'statuss',
    title: 'Darbības statuss',
  },
]

export default function History() {
  const { showSnackBar } = useSnackBar()
  const history = useHistory()
  const { state: { prevPathName } = {} } = useLocation<{
    prevPathName?: string
  }>()
  const firstUpdate = useRef(true)
  const [rows, setRows] = useState<HistoryListEntry[]>([])
  const [count, setCount] = useState(0)
  const { filter, setFilter } = useHistoryListQueryParams()
  const [queryParams, setQueryParams] = React.useState<HistoryListFilterState>(filter)

  useEffect(() => {
    if (prevPathName && !prevPathName.includes(routes.history.list)) {
      setFilter(DEFAULT_HISTORY_LIST_FILTER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    const timeout = setTimeout(() => {
      setQueryParams(filter)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const { isLoading } = useQuery(
    ['history', queryParams],
    () => paramFetcher(queryParams, HISTORY_DATA_ENDPOINT, HISTORY_DATA_COUNT_ENDPOINT, true),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        const historyList: HistoryListEntry[] = list.map((entry: HistoryEntry) => {
          return {
            id: entry.id,
            vards: entry.vards || '',
            uzvards: entry.uzvards || '',
            laiks: format(parseISO(entry.laiks), DEFAULT_DATETIME_FORMAT),
            statuss: actionStatus[entry.statuss],
            darbība: actions[entry.darbība],
            personas_kods: entry.personas_kods || '',
          }
        })

        setRows(historyList)
        setCount(count)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * filter.limit !== filter.offset) {
      setFilter({
        ...filter,
        offset: pageNumber * filter.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== filter.limit) {
      setFilter({
        ...filter,
        limit: rowsPerPage,
      })
    }
  }

  const onSelectChange = (event: SelectChangeEvent<string>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    })
  }

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    })
  }

  const onTimeChange = (name: string, date?: Date | null) => {
    if (date) {
      setFilter({
        ...filter,
        [name]: date,
      })
    }
  }

  const resetFilters = () => {
    setFilter(DEFAULT_HISTORY_LIST_FILTER)
  }

  return (
    <>
      <Box sx={{ mb: '20px' }}>
        <HistoryFilter
          actions={actions}
          filter={filter}
          onSelectChange={onSelectChange}
          onTextFieldChange={onTextFieldChange}
          onTimeChange={onTimeChange}
          resetFilters={resetFilters}
        />
      </Box>
      <CustomizedMaterialTable
        columns={columns}
        data={rows}
        isLoading={isLoading}
        rowStyle={{
          height: 50,
        }}
        page={filter.offset / filter.limit}
        totalCount={count}
        pageSize={filter.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={(e: React.MouseEvent, rowData: HistoryListEntry) => {
          window.history.replaceState({}, '')
          history.push(reverse(routes.history.details, { itemId: rowData.id }))
        }}
      />
    </>
  )
}
