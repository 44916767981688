import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { deletePromotion, PROMOTIONS, PROMOTIONS_COUNT } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { paramFetcher } from '../../elements/ParamFetcher'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import NewPartnerPromotion from './NewPartnerPromotions'
import { Button, Grid } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { confirmDialog } from '../../elements/ConfirmDialog'
import { NewPromotionReq } from '../../interfaces/NewPromotionForm'
import { DateClassifier } from '../../interfaces/DateClassifier'
import { format, parseISO } from 'date-fns'

interface PartnerPromotionsProps {
  partnerId: number
}

export const PartnerPromotionsTabContent = ({ partnerId }: PartnerPromotionsProps) => {
  const [openModalAddEdit, setOpenModalAddEdit] = useState(false)
  const [count, setCount] = useState(0)
  const [selectedPromotion, setSelectedPromotion] = useState<NewPromotionReq | undefined>(undefined)

  const closeModal = () => {
    setOpenModalAddEdit(false)
    setSelectedPromotion(undefined)
    refetch()
  }

  const [queryParams, setQueryParams] = React.useState<TaskQueryParams>({
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const [data, setData] = useState<NewPromotionReq[] | null>(null)

  const { showSnackBar } = useSnackBar()

  const { isLoading, refetch } = useQuery(
    ['partnerPromotion', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        PROMOTIONS,
        PROMOTIONS_COUNT
      ),
    {
      onSuccess: ([data, count]) => {
        setData(data)
        setCount(count)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )
  const columns = [
    {
      field: 'nosaukums',
      title: 'Nosaukums',
    },
    {
      field: 'aktīva_no',
      title: 'Aktīva no',
      render: (rowData: DateClassifier) => {
        if (rowData.aktīva_no) {
          return <>{format(parseISO(rowData.aktīva_no), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'aktīva_līdz',
      title: 'Aktīva līdz',
      render: (rowData: DateClassifier) => {
        if (rowData.aktīva_līdz) {
          return <>{format(parseISO(rowData.aktīva_līdz), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'statuss_nosaukums',
      title: 'Statuss',
    },
  ]

  const [deletePartnerPromotionMutation] = useMutation((id: number) => deletePromotion(id), {
    onSuccess: () => {
      showSnackBar()
      refetch()
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const onPromotionDelete = async (
    event: React.MouseEvent,
    { nosaukums, id }: { [key: string]: any }
  ) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst akciju "${nosaukums}"?`,
    })

    if (confirm.ok) {
      deletePartnerPromotionMutation(id)
    }
  }

  const onPromotionEdit = (event: React.MouseEvent, promotion: NewPromotionReq) => {
    setSelectedPromotion(promotion)
    setOpenModalAddEdit(true)
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onPromotionEdit,
      tooltip: 'Rediģēt',
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onPromotionDelete,
      tooltip: 'Dzēst',
    },
  ]

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  return (
    <>
      {openModalAddEdit && (
        <NewPartnerPromotion
          partnerId={partnerId}
          promotion={selectedPromotion}
          onClose={closeModal}
        />
      )}
      <Grid container sx={{ pb: '20px' }}>
        <Grid item xs={12} justifyContent="end" alignItems="flex-end" container>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot Akciju
          </Button>
        </Grid>
      </Grid>
      <CustomizedMaterialTable
        columns={columns}
        data={data ? data : []}
        isLoading={isLoading}
        page={queryParams.offset! / queryParams.limit!}
        totalCount={count}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        actions={actions}
        onRowsPerPageChange={onRowsPerPageChange}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
