/*
 * Copyright 1997-1998 by Charles L. Taylor
 * adjusted modification from http://neogeo.lv/ekartes/koord2/
 */

const pi = 3.14159265358979

// ellipsoid model constants (actual values here are for GRS80)
const sm_a = 6378137.0
const sm_b = 6356752.31414

const UTMScaleFactor = 0.9996

const lks_DegToRad = (deg: number) => {
  return (deg / 180.0) * pi
}

const lks_RadToDeg = (rad: number) => {
  return (rad / pi) * 180.0
}

/*
 * lks_FootpointLatitude
 *
 * Computes the footpoint latitude for use in converting transverse
 * Mercator coordinates to ellipsoidal coordinates.
 *
 * Reference: Hoffmann-Wellenhof, B., Lichtenegger, H., and Collins, J.,
 *   GPS: Theory and Practice, 3rd ed.  New York: Springer-Verlag Wien, 1994.
 *
 * Inputs:
 *   y - The UTM northing coordinate, in meters.
 *
 * Returns:
 *   The footpoint latitude, in radians.
 *
 */
const lks_FootpointLatitude = (y: number) => {
  let y_, alpha_, beta_, gamma_, delta_, epsilon_, n
  let result

  // precalculate n (Eq. 10.18)
  n = (sm_a - sm_b) / (sm_a + sm_b)

  // precalculate alpha_ (Eq. 10.22) (Same as alpha in Eq. 10.17)
  alpha_ = ((sm_a + sm_b) / 2.0) * (1 + Math.pow(n, 2.0) / 4 + Math.pow(n, 4.0) / 64)

  // precalculate y_ (Eq. 10.23)
  y_ = y / alpha_

  // precalculate beta_ (Eq. 10.22)
  beta_ = (3.0 * n) / 2.0 + (-27.0 * Math.pow(n, 3.0)) / 32.0 + (269.0 * Math.pow(n, 5.0)) / 512.0

  // precalculate gamma_ (Eq. 10.22)
  gamma_ = (21.0 * Math.pow(n, 2.0)) / 16.0 + (-55.0 * Math.pow(n, 4.0)) / 32.0

  // precalculate delta_ (Eq. 10.22)
  delta_ = (151.0 * Math.pow(n, 3.0)) / 96.0 + (-417.0 * Math.pow(n, 5.0)) / 128.0

  // precalculate epsilon_ (Eq. 10.22)
  epsilon_ = (1097.0 * Math.pow(n, 4.0)) / 512.0

  // now calculate the sum of the series (Eq. 10.21)
  result =
    y_ +
    beta_ * Math.sin(2.0 * y_) +
    gamma_ * Math.sin(4.0 * y_) +
    delta_ * Math.sin(6.0 * y_) +
    epsilon_ * Math.sin(8.0 * y_)

  return result
}

/*
 * lks_MapXYToLatLon
 *
 * Converts x and y coordinates in the Transverse Mercator projection to
 * a latitude/longitude pair.  Note that Transverse Mercator is not
 * the same as UTM; a scale factor is required to convert between them.
 *
 * Reference: Hoffmann-Wellenhof, B., Lichtenegger, H., and Collins, J.,
 *   GPS: Theory and Practice, 3rd ed.  New York: Springer-Verlag Wien, 1994.
 *
 * Inputs:
 *   x - The easting of the point, in meters.
 *   y - The northing of the point, in meters.
 *   lambda0 - Longitude of the central meridian to be used, in radians.
 *
 * Outputs:
 *   philambda - A 2-element containing the latitude and longitude
 *               in radians.
 *
 * Returns:
 *   The function does not return a value.
 *
 * Remarks:
 *   The local variables Nf, nuf2, tf, and tf2 serve the same purpose as
 *   N, nu2, t, and t2 in lks_MapLatLonToXY, but they are computed with respect
 *   to the footpoint latitude phif.
 *
 *   x1frac, x2frac, x2poly, x3poly, etc. are to enhance readability and
 *   to optimize computations.
 *
 */
const lks_MapXYToLatLon = (x: number, y: number, lambda0: number) => {
  let phif, Nf, Nfpow, nuf2, ep2, tf, tf2, tf4, cf
  let x1frac, x2frac, x3frac, x4frac, x5frac, x6frac, x7frac, x8frac
  let x2poly, x3poly, x4poly, x5poly, x6poly, x7poly, x8poly

  // get the value of phif, the footpoint latitude
  phif = lks_FootpointLatitude(y)

  // Precalculate ep2
  ep2 = (Math.pow(sm_a, 2.0) - Math.pow(sm_b, 2.0)) / Math.pow(sm_b, 2.0)

  // precalculate cos (phif)
  cf = Math.cos(phif)

  // precalculate nuf2
  nuf2 = ep2 * Math.pow(cf, 2.0)

  // precalculate Nf and initialize Nfpow
  Nf = Math.pow(sm_a, 2.0) / (sm_b * Math.sqrt(1 + nuf2))
  Nfpow = Nf

  // precalculate tf
  tf = Math.tan(phif)
  tf2 = tf * tf
  tf4 = tf2 * tf2

  // precalculate fractional coefficients for x**n in the equations below to simplify the expressions for latitude and longitude
  x1frac = 1.0 / (Nfpow * cf)

  Nfpow *= Nf // now equals Nf**2)
  x2frac = tf / (2.0 * Nfpow)

  Nfpow *= Nf // now equals Nf**3)
  x3frac = 1.0 / (6.0 * Nfpow * cf)

  Nfpow *= Nf // now equals Nf**4)
  x4frac = tf / (24.0 * Nfpow)

  Nfpow *= Nf // now equals Nf**5)
  x5frac = 1.0 / (120.0 * Nfpow * cf)

  Nfpow *= Nf // now equals Nf**6)
  x6frac = tf / (720.0 * Nfpow)

  Nfpow *= Nf // now equals Nf**7)
  x7frac = 1.0 / (5040.0 * Nfpow * cf)

  Nfpow *= Nf // now equals Nf**8)
  x8frac = tf / (40320.0 * Nfpow)

  // precalculate polynomial coefficients for x**n. -- x**1 does not have a polynomial coefficient
  x2poly = -1.0 - nuf2

  x3poly = -1.0 - 2 * tf2 - nuf2

  x4poly =
    5.0 +
    3.0 * tf2 +
    6.0 * nuf2 -
    6.0 * tf2 * nuf2 -
    3.0 * (nuf2 * nuf2) -
    9.0 * tf2 * (nuf2 * nuf2)

  x5poly = 5.0 + 28.0 * tf2 + 24.0 * tf4 + 6.0 * nuf2 + 8.0 * tf2 * nuf2

  x6poly = -61.0 - 90.0 * tf2 - 45.0 * tf4 - 107.0 * nuf2 + 162.0 * tf2 * nuf2

  x7poly = -61.0 - 662.0 * tf2 - 1320.0 * tf4 - 720.0 * (tf4 * tf2)

  x8poly = 1385.0 + 3633.0 * tf2 + 4095.0 * tf4 + 1575 * (tf4 * tf2)

  const lat =
    phif +
    x2frac * x2poly * (x * x) +
    x4frac * x4poly * Math.pow(x, 4.0) +
    x6frac * x6poly * Math.pow(x, 6.0) +
    x8frac * x8poly * Math.pow(x, 8.0)

  const long =
    lambda0 +
    x1frac * x +
    x3frac * x3poly * Math.pow(x, 3.0) +
    x5frac * x5poly * Math.pow(x, 5.0) +
    x7frac * x7poly * Math.pow(x, 7.0)

  return {
    lat: lks_RadToDeg(lat),
    long: lks_RadToDeg(long),
  }
}

/*
 * lks_ArcLengthOfMeridian
 *
 * Computes the ellipsoidal distance from the equator to a point at a
 * given latitude.
 *
 * Reference: Hoffmann-Wellenhof, B., Lichtenegger, H., and Collins, J.,
 * GPS: Theory and Practice, 3rd ed.  New York: Springer-Verlag Wien, 1994.
 *
 * Inputs:
 *     phi - Latitude of the point, in radians.
 *
 * Globals:
 *     sm_a - Ellipsoid model major axis.
 *     sm_b - Ellipsoid model minor axis.
 *
 * Returns:
 *     The ellipsoidal distance of the point from the equator, in meters.
 *
 */
const lks_ArcLengthOfMeridian = (phi: number) => {
  let alpha, beta, gamma, delta, epsilon, n
  let result

  // precalculate n
  n = (sm_a - sm_b) / (sm_a + sm_b)

  // precalculate alpha
  alpha = ((sm_a + sm_b) / 2.0) * (1.0 + Math.pow(n, 2.0) / 4.0 + Math.pow(n, 4.0) / 64.0)

  // precalculate beta
  beta = (-3.0 * n) / 2.0 + (9.0 * Math.pow(n, 3.0)) / 16.0 + (-3.0 * Math.pow(n, 5.0)) / 32.0

  // precalculate gamma
  gamma = (15.0 * Math.pow(n, 2.0)) / 16.0 + (-15.0 * Math.pow(n, 4.0)) / 32.0

  // precalculate delta
  delta = (-35.0 * Math.pow(n, 3.0)) / 48.0 + (105.0 * Math.pow(n, 5.0)) / 256.0

  // precalculate epsilon
  epsilon = (315.0 * Math.pow(n, 4.0)) / 512.0

  // now calculate the sum of the series and return
  result =
    alpha *
    (phi +
      beta * Math.sin(2.0 * phi) +
      gamma * Math.sin(4.0 * phi) +
      delta * Math.sin(6.0 * phi) +
      epsilon * Math.sin(8.0 * phi))

  return result
}

/*
 * lks_MapLatLonToXY
 *
 * Converts a latitude/longitude pair to x and y coordinates in the
 * Transverse Mercator projection.  Note that Transverse Mercator is not
 * the same as UTM; a scale factor is required to convert between them.
 *
 * Reference: Hoffmann-Wellenhof, B., Lichtenegger, H., and Collins, J.,
 * GPS: Theory and Practice, 3rd ed.  New York: Springer-Verlag Wien, 1994.
 *
 * Inputs:
 *    phi - Latitude of the point, in radians.
 *    lambda - Longitude of the point, in radians.
 *    lambda0 - Longitude of the central meridian to be used, in radians.
 *
 * Outputs:
 *    xy - A 2-element array containing the x and y coordinates
 *         of the computed point.
 *
 * Returns:
 *    The function does not return a value.
 *
 */
const lks_MapLatLonToXY = (phi: number, lambda: number, lambda0: number, xy: number[]) => {
  let N, nu2, ep2, t, t2, l
  let l3coef, l4coef, l5coef, l6coef, l7coef, l8coef

  // precalculate ep2
  ep2 = (Math.pow(sm_a, 2.0) - Math.pow(sm_b, 2.0)) / Math.pow(sm_b, 2.0)

  // precalculate nu2
  nu2 = ep2 * Math.pow(Math.cos(phi), 2.0)

  // precalculate N
  N = Math.pow(sm_a, 2.0) / (sm_b * Math.sqrt(1 + nu2))

  // precalculate t
  t = Math.tan(phi)
  t2 = t * t

  // precalculate l
  l = lambda - lambda0

  /* precalculate coefficients for l**n in the equations below
   * so a normal human being can read the expressions for easting
   * and northing
   * -- l**1 and l**2 have coefficients of 1.0
   */
  l3coef = 1.0 - t2 + nu2

  l4coef = 5.0 - t2 + 9 * nu2 + 4.0 * (nu2 * nu2)

  l5coef = 5.0 - 18.0 * t2 + t2 * t2 + 14.0 * nu2 - 58.0 * t2 * nu2

  l6coef = 61.0 - 58.0 * t2 + t2 * t2 + 270.0 * nu2 - 330.0 * t2 * nu2

  l7coef = 61.0 - 479.0 * t2 + 179.0 * (t2 * t2) - t2 * t2 * t2

  l8coef = 1385.0 - 3111.0 * t2 + 543.0 * (t2 * t2) - t2 * t2 * t2

  // calculate easting (x)
  xy[0] =
    N * Math.cos(phi) * l +
    (N / 6.0) * Math.pow(Math.cos(phi), 3.0) * l3coef * Math.pow(l, 3.0) +
    (N / 120.0) * Math.pow(Math.cos(phi), 5.0) * l5coef * Math.pow(l, 5.0) +
    (N / 5040.0) * Math.pow(Math.cos(phi), 7.0) * l7coef * Math.pow(l, 7.0)

  // calculate northing (y)
  xy[1] =
    lks_ArcLengthOfMeridian(phi) +
    (t / 2.0) * N * Math.pow(Math.cos(phi), 2.0) * Math.pow(l, 2.0) +
    (t / 24.0) * N * Math.pow(Math.cos(phi), 4.0) * l4coef * Math.pow(l, 4.0) +
    (t / 720.0) * N * Math.pow(Math.cos(phi), 6.0) * l6coef * Math.pow(l, 6.0) +
    (t / 40320.0) * N * Math.pow(Math.cos(phi), 8.0) * l8coef * Math.pow(l, 8.0)

  return
}

/*
 * lks_LatLonToUTMXY
 *
 * Converts a latitude/longitude pair to x and y coordinates in the
 * Universal Transverse Mercator projection.
 *
 * Inputs:
 *   lat - Latitude of the point, in radians.
 *   lon - Longitude of the point, in radians.
 *   zone - UTM zone to be used for calculating values for x and y.
 *          If zone is less than 1 or greater than 60, the routine
 *          will determine the appropriate zone from the value of lon.
 *
 * Outputs:
 *   xy - A 2-element array where the UTM x and y values will be stored.
 *
 * Returns:
 *   The UTM zone used for calculating the values of x and y.
 *
 */
const lks_LatLonToUTMXY = (lat: number, long: number) => {
  const xy = new Array(2)

  lks_MapLatLonToXY(lat, long, lks_DegToRad(24.0), xy)

  // adjust easting and northing for UTM system

  xy[0] = xy[0] * UTMScaleFactor + 500000.0
  xy[1] = xy[1] * UTMScaleFactor - 6000000.0

  if (xy[1] < 0.0) {
    xy[1] = xy[1] + 10000000.0
  }

  return xy
}

export const lks92ToWgs84 = (x: number, y: number) => {
  x -= 500000.0
  x /= UTMScaleFactor

  y -= -6000000.0

  // y is not adjusted for southern hemisphere

  y /= UTMScaleFactor

  return lks_MapXYToLatLon(x, y, lks_DegToRad(24.0))
}

export const wgs84ToLks92 = (lat: number, long: number) => {
  return lks_LatLonToUTMXY(lks_DegToRad(lat), lks_DegToRad(long))
}
