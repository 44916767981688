import React from 'react'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import { ApplicationListEntry } from '../../interfaces/Application/ApplicationListEntry'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import ConfirmationStatusRenderer from '../../elements/ConfirmationStatusRenderer'
import { EAddressStatus } from '../../enums/EAddressStatus'
import { convertPersonCode } from '../../utils/convertPersonCode'

export const getApplicationTableColumns = () => {
  return [
    {
      field: 'pieteicējs',
      title: 'Pieteicējs',
      render: (rowData: ApplicationListEntry) => {
        return <Box>{`${rowData.personas_vārds} ${rowData.personas_uzvārds}`}</Box>
      },
    },
    {
      field: 'personas_kods',
      title: 'Personas kods',
      render: (rowData: ApplicationListEntry) => convertPersonCode(rowData.personas_kods),
    },
    {
      field: 'kontaktinformācija',
      title: 'Kontaktinformācija',
      render: (rowData: ApplicationListEntry) => {
        return (
          <div>
            <List dense>
              {rowData.telefoni
                .filter((entry) => entry.telefons)
                .map((entry, index) => {
                  return (
                    <ListItem disableGutters disablePadding key={'phone_' + index}>
                      <ListItemText primary={entry.telefons} />
                      <ConfirmationStatusRenderer
                        sx={{ ml: 1 }}
                        size="small"
                        isEnabled={entry.ir_verificēts}
                      />
                    </ListItem>
                  )
                })}
              {rowData.epasti
                .filter((entry) => entry.epasts)
                .map((entry, index) => {
                  return (
                    <ListItem disableGutters disablePadding key={'email_' + index}>
                      <ListItemText primary={entry.epasts} />
                      <ConfirmationStatusRenderer
                        sx={{ ml: 1 }}
                        size="small"
                        isEnabled={entry.ir_verificēts}
                      />
                    </ListItem>
                  )
                })}
              {rowData.personas_eadrese === EAddressStatus.verificēts && (
                <ListItem disableGutters disablePadding key={rowData.id}>
                  <ListItemText primary="E-adrese" />
                  <ConfirmationStatusRenderer sx={{ ml: 1 }} size="small" isEnabled={true} />
                </ListItem>
              )}
              {rowData.saziņa_neelektroniskā_formā && (
                <ListItem disableGutters disablePadding key={rowData.id}>
                  <ListItemText primary="Neelektroniski" />
                  <ConfirmationStatusRenderer sx={{ ml: 1 }} size="small" isEnabled={true} />
                </ListItem>
              )}
            </List>
          </div>
        )
      },
    },
    {
      field: 'statuss_nosaukums',
      title: 'Statuss',
    },
    {
      field: 'informācijas_termiņš',
      title: 'Termiņš',
      render: (rowData: ApplicationListEntry) => {
        if (rowData.informācijas_termiņš) {
          return <>{format(parseISO(rowData.informācijas_termiņš), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'pēdējā_aktivitāte_laiks',
      title: 'Pēdējā veiktā darbība',
      render: (rowData: ApplicationListEntry) => {
        if (rowData.pēdējā_aktivitāte_laiks) {
          return (
            <>
              <div>
                {format(parseISO(rowData.pēdējā_aktivitāte_laiks), DEFAULT_DATETIME_FORMAT)}
              </div>
              <div>{rowData.pēdējā_aktivitāte_nosaukums}</div>
            </>
          )
        }
        return <></>
      },
    },
  ]
}
