import { useMemo } from 'react'
import { Typography, Grid, List, Box, ListItem } from '@mui/material'
import OutlinedContainer from '../../elements/OutlinedContainer'
import { NewPartnerResponse } from '../../interfaces/Partners/NewPartnerEntity'
import { FileLink } from '../../elements/FileLink'
import { NewPartnerStatus } from '../../interfaces/Partners/NewPartnerStatus'
import { ExistingPartnerAgreement } from './ExistingPartnerAgreement'
import { FormRow } from '../../styled/FormRow'
import { FormLabel } from '../../styled/FormLabel'
import { sanitize as sanitizeHtml } from '../../utils/sanitizeHtml'

interface Props {
  partnerEntry: NewPartnerResponse
  partnerStatus: NewPartnerStatus | null
  onRefetch: () => void
}

export function ExistingPartner({ partnerEntry, partnerStatus, onRefetch }: Props) {
  const sanitizedHTMLDescription = useMemo(
    () => sanitizeHtml(partnerEntry.apraksts || ''),
    [partnerEntry.apraksts]
  )

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="basicInfo" label={<b>Pamatdati</b>}>
            <FormRow>
              <FormLabel>Reģistrācijas numurs:</FormLabel>
              <Typography>{partnerEntry.reģistrācijas_numurs}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Partnera nosaukums:</FormLabel>
              <Typography>{partnerEntry.nosaukums}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Juridiskais nosaukums:</FormLabel>
              <Typography>{partnerEntry.juridiskais_nosaukums}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Partnera veids:</FormLabel>
              <Typography>{partnerEntry.veids_nosaukums}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Oficiālā e-pasta adrese:</FormLabel>
              <Typography>{partnerEntry.epasts}</Typography>
            </FormRow>
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="propInfo" label={<b>Rekvizīti</b>}>
            <FormRow>
              <FormLabel>PVN reģistrācijas nr.:</FormLabel>
              <Typography>{partnerEntry.pvn_reģistrācijas_numurs}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Juridiskā adrese:</FormLabel>
              <Typography>{partnerEntry.juridiskā_adrese?.adrese}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Banka:</FormLabel>
              <Typography>{partnerEntry.banka}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>Konta nr:</FormLabel>
              <Typography>{partnerEntry.konta_numurs}</Typography>
            </FormRow>
            <FormRow>
              <FormLabel>SWIFT:</FormLabel>
              <Typography>{partnerEntry.swift}</Typography>
            </FormRow>
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="extraInfo" label={<b>Papildus informācija</b>}>
            <FormRow sx={{ alignItems: 'baseline' }}>
              <FormLabel>Preču un pakalpojumu grupas:</FormLabel>
              {partnerEntry.pakalpojumu_grupa && (
                <List>
                  {partnerEntry.pakalpojumu_grupa.map((group, index) => (
                    <Typography key={index}>{group.grupa_nosaukums}</Typography>
                  ))}
                </List>
              )}
            </FormRow>
            <FormRow sx={{ alignItems: 'baseline' }}>
              <FormLabel>Nozares:</FormLabel>
              {partnerEntry.pakalpojumu_birkas && (
                <List>
                  {partnerEntry.pakalpojumu_birkas.map((tag, index) => (
                    <Typography key={index}>{tag.birka_nosaukums}</Typography>
                  ))}
                </List>
              )}
            </FormRow>
            <FormRow>
              <FormLabel>Mājas lapa:</FormLabel>
              <Typography>{partnerEntry.mājas_lapa}</Typography>
            </FormRow>
            <FormRow style={{ alignItems: 'baseline' }}>
              <FormLabel>Interneta veikals:</FormLabel>
              <Box>
                {partnerEntry?.interneta_veikals?.map((storeEntry, index) => (
                  <List key={index} dense>
                    <ListItem disableGutters>{storeEntry.url}</ListItem>
                    <ListItem disableGutters>{storeEntry.komentārs}</ListItem>
                  </List>
                ))}
              </Box>
            </FormRow>
            <FormRow>
              <FormLabel>Publicitātes foto:</FormLabel>
              {partnerEntry.foto && (
                <FileLink
                  file={{
                    ...partnerEntry.foto,
                    faila_id: partnerEntry.foto.id,
                  }}
                />
              )}
            </FormRow>
            <FormRow>
              <FormLabel>Logotips:</FormLabel>
              {partnerEntry.logotips && (
                <FileLink
                  file={{
                    ...partnerEntry.logotips,
                    faila_id: partnerEntry.logotips.id,
                  }}
                />
              )}
            </FormRow>
            <FormRow>
              <FormLabel>Svarīga informācija:</FormLabel>
              {sanitizedHTMLDescription.__html.length > 0 && (
                <Box
                  className="ProseMirror"
                  sx={{ borderStyle: 'dashed', width: '100%', wordBreak: 'break-all' }}
                >
                  <Typography component="span" dangerouslySetInnerHTML={sanitizedHTMLDescription} />
                </Box>
              )}
            </FormRow>
          </OutlinedContainer>
        </Grid>

        <ExistingPartnerAgreement
          partnerEntry={partnerEntry}
          partnerStatus={partnerStatus}
          onRefetch={onRefetch}
        />
      </Grid>
    </>
  )
}
