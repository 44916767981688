import React from 'react'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import { ApplicationRegistryCheck } from '../../interfaces/Application/ApplicationRegistryCheck'

const columns = [
  {
    field: 'tips_kods',
    title: 'Veids',
    width: '10%',
  },
  {
    field: 'komentārs_nosaukums',
    title: 'Komentārs',
    width: '100%',
  },
  {
    field: 'beigu_datums',
    title: 'Beigu datums',
    render: ({ beigu_datums }: ApplicationRegistryCheck) => {
      if (!beigu_datums) {
        return undefined
      }

      return format(parseISO(beigu_datums), DEFAULT_DATE_FORMAT)
    },
  },
  {
    field: 'aktualizācijas_laiks',
    title: 'Aktualizācijas datums',
    render: ({ aktualizācijas_laiks }: ApplicationRegistryCheck) => {
      if (!aktualizācijas_laiks) {
        return undefined
      }

      return format(parseISO(aktualizācijas_laiks), DEFAULT_DATETIME_FORMAT)
    },
  },
]

interface Props {
  listItem: ApplicationRegistryCheck
}

export const ApplicationRegistersTestResults = ({ listItem }: Props) => {
  return (
    <>
      <CustomizedMaterialTable
        columns={columns}
        wrapperBoxStyles={{ width: '100%' }}
        data={[listItem]}
        options={{
          paging: false,
        }}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
