import { Link } from '@mui/material'

interface Props {
  handleClick: () => void
  label: string
}

export default function InfoLink({ handleClick, label }: Props) {
  return (
    <Link color="#000" component="button" variant="body1" underline="none" onClick={handleClick}>
      {label}
    </Link>
  )
}
