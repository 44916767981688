import { Chip, SxProps } from '@mui/material'
import React from 'react'

export default function ConfirmationStatusRenderer({
  isEnabled,
  size = 'medium',
  sx,
}: {
  isEnabled: boolean
  size?: 'small' | 'medium'
  sx?: SxProps
}) {
  const statusText = React.useMemo(
    () => (isEnabled ? 'Apstiprināts' : 'Nav apstiprināts'),
    [isEnabled]
  )
  const statusColor = React.useMemo(() => (isEnabled ? 'success' : 'default'), [isEnabled])

  return (
    <Chip size={size} sx={{ fontWeight: 'bold', ...sx }} label={statusText} color={statusColor} />
  )
}
