import CancelIcon from '@mui/icons-material/Cancel'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useMutation } from 'react-query'

import { addInfoRequest } from '../../../api/API'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import RequiredLabel from '../../elements/RequiredLabel'
import { ComplaintAddInfoRequest } from '../../interfaces/ComplaintInfoRequest'
import { Complaint } from '../../interfaces/Complaints'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

interface Props {
  complaint?: Partial<Complaint>
  onClose: () => void
  onSuccess: () => void
}

export default function AskInfoRequestModal({ complaint, onClose, onSuccess }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isQuestionInvalid, setIsQuestionInvalid] = useState<boolean>(false)
  const [question, setQuestion] = useState<string>('')

  const [complaintInfoRequestMutation] = useMutation(
    (data: ComplaintAddInfoRequest) => addInfoRequest(data),
    {
      onSuccess: () => {
        showSnackBar()
        setIsLoadingSubmit(false)
        onSuccess()
        onClose()
      },
      onError: (error: any) => {
        setIsLoadingSubmit(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onSubmit = () => {
    if (!question) {
      setIsQuestionInvalid(true)
      return
    }

    setIsLoadingSubmit(true)

    complaintInfoRequestMutation({
      sadarbības_partnera_sūdzības_id: complaint?.id,
      sadarbības_partneris_id: Number(complaint?.sadarbības_partneris_id),
      jautājums: question,
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Informācijas pieprasījums partnerim
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Jautājums" />
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={5}
                fullWidth
                autoComplete="off"
                value={question}
                sx={{ minWidth: '400px' }}
                onChange={(e) => {
                  setQuestion(e.target.value)
                  setIsQuestionInvalid(false)
                }}
                error={isQuestionInvalid}
                helperText={isQuestionInvalid && REQUIRED_ERROR_MESSAGE}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
