import { Box, Button, Divider, InputAdornment, SelectChangeEvent, TextField } from '@mui/material'
import React from 'react'
import { PHONE_PLACEHOLDER } from '../../../constants/constants'
import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import { RequiredMarker } from '../../elements/RequiredMarker'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { NewPartnerContact } from '../../interfaces/Partners/NewPartnerContact'

const sx = {
  button: {
    minWidth: 130,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
}

const emptyContact = {
  vārds: '',
  uzvārds: '',
  personas_kods: '',
  telefons: '',
  epasts: '',
  loma_kods: '',
}

interface Props {
  contacts: NewPartnerContact[]
  partnerRoleList: ClassifierStatus[]
  formErrors: { [key: string]: string }
  setContacts: React.Dispatch<React.SetStateAction<NewPartnerContact[]>>
  handleArrayValueChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerContact[]>>,
    index: number
  ) => void
  removeAdditionalEntry: (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerContact[]>>,
    index: number
  ) => void
  addAdditionalEntry: (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerContact[]>>,
    emptyObject: object
  ) => void
}

export default function NewPartnerContactData({
  handleArrayValueChange,
  contacts,
  setContacts,
  removeAdditionalEntry,
  addAdditionalEntry,
  formErrors,
  partnerRoleList,
}: Props) {
  return (
    <>
      {contacts.map((_contactPerson, index) => (
        <Box key={index}>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Vārds
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="vārds"
              onChange={(e) => handleArrayValueChange(e, contacts, setContacts, index)}
              value={contacts[index].vārds}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Uzvārds
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="uzvārds"
              onChange={(e) => handleArrayValueChange(e, contacts, setContacts, index)}
              value={contacts[index].uzvārds}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Personas kods
              <RequiredMarker />
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="personas_kods"
              onChange={(e) => handleArrayValueChange(e, contacts, setContacts, index)}
              value={contacts[index].personas_kods}
              helperText={formErrors['personas_kods' + index]}
              error={Boolean(formErrors['personas_kods' + index])}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Telefona nr.
              <RequiredMarker />
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="telefons"
              placeholder={PHONE_PLACEHOLDER}
              onChange={(e) => handleArrayValueChange(e, contacts, setContacts, index)}
              value={contacts[index].telefons}
              helperText={formErrors['telefons' + index]}
              error={Boolean(formErrors['telefons' + index])}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              E-pasta adrese
              <RequiredMarker />
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="epasts"
              onChange={(e) => handleArrayValueChange(e, contacts, setContacts, index)}
              value={contacts[index].epasts}
              helperText={formErrors['epasts' + index]}
              error={Boolean(formErrors['epasts' + index])}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Loma
              <RequiredMarker />
            </Box>
            <PartnerDropDownList
              name="loma_kods"
              value={contacts[index].loma_kods}
              onChange={(e) => handleArrayValueChange(e, contacts, setContacts, index)}
              data={partnerRoleList}
              helperText={formErrors['loma_kods' + index]}
              error={Boolean(formErrors['loma_kods' + index])}
            />
          </Box>

          {contacts.length > 0 && (
            <>
              <Box
                mb={2}
                mt={2}
                style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  sx={sx.button}
                  variant="contained"
                  onClick={() => removeAdditionalEntry(contacts, setContacts, index)}
                  color="primary"
                >
                  Noņemt kontaktpersonu
                </Button>
              </Box>

              <Divider sx={sx.divider} />
            </>
          )}
        </Box>
      ))}
      <Box mb={2} mt={6} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={sx.button}
          variant="outlined"
          color="primary"
          onClick={() => addAdditionalEntry(contacts, setContacts, emptyContact)}
        >
          Pievienot kontaktpersonu
        </Button>
      </Box>
    </>
  )
}
