import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import GenerateContractModal from './GenerateContractModal'
import { PartnerContractSignatory } from '../../interfaces/Partners/PartnerContractSignatory'
import { useMutation } from 'react-query'
import { generateContract } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

interface Props {
  partnerId: number
}

export const PartnerGenerateAgreement = ({ partnerId }: Props) => {
  const { showSnackBar } = useSnackBar()

  const [openGenerateModal, setOpenGenerateModal] = useState(false)

  const onOpenGenerateModal = () => {
    setOpenGenerateModal(true)
  }

  const onCloseGenerateModal = () => {
    setOpenGenerateModal(false)
  }

  const [generateContractMutation, { isLoading }] = useMutation(
    (signatory: PartnerContractSignatory) => generateContract(signatory, partnerId as number),
    {
      onSuccess: (data) => {
        displayPdf(data)
        onCloseGenerateModal()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const displayPdf = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  const onGenerate = (signatory: PartnerContractSignatory) => {
    generateContractMutation(signatory)
  }

  return (
    <Box>
      {openGenerateModal && (
        <GenerateContractModal
          isLoading={isLoading}
          onGenerate={onGenerate}
          onClose={onCloseGenerateModal}
        />
      )}
      <Button variant="outlined" onClick={onOpenGenerateModal}>
        Ģenerēt parakstāmo līgumu
      </Button>
    </Box>
  )
}
