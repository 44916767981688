import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Rating as MUIRating } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'

import {
  PARTNER_RATING_DATA_ENDPOINT,
  PARTNER_RATING_DATA_COUNT_ENDPOINT,
  editPersonRatingRights,
} from '../../../api/API'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { paramFetcher } from '../../elements/ParamFetcher'
import { Rating, RatingEditingRightsPayload } from '../../interfaces/Raitings'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const columns = [
  {
    field: 'vārds_uzvārds',
    title: 'Apliecības turētājs',
    render: (rowData: Rating) => `${rowData.personas_vārds} ${rowData.personas_vārds}`,
  },
  {
    field: 'vērtējums',
    title: 'Novērtējums',
    render: (rowData: Rating) => (
      <MUIRating sx={{ color: '#A30A32' }} value={rowData.vērtējums} readOnly />
    ),
  },
  {
    field: 'personas_vērtējumi_bloķēti',
    title: 'Atsauksmju sniegšana',
    render: (rowData: Rating) => (rowData.personas_vērtējumi_bloķēti ? 'Bloķēta' : 'Aktīva'),
  },
]

const actions = (handleRatingRights: (rowData: Rating) => void) => [
  (rowData: Rating) => ({
    icon: () =>
      rowData.personas_vērtējumi_bloķēti ? (
        <CheckIcon color="success" />
      ) : (
        <BlockIcon color="error" />
      ),
    onClick: () => handleRatingRights(rowData),
    tooltip: rowData.personas_vērtējumi_bloķēti
      ? 'Atļaut vērtējuma iesniegšanu'
      : 'Bloķēt vērtējuma iesniegšanu',
  }),
]

interface PartnerTasksProps {
  partnerId: number
}

export const PartnerRatingTabContent: React.FC<PartnerTasksProps> = ({ partnerId }) => {
  const { showSnackBar } = useSnackBar()
  const [ratingsData, setRatingsData] = useState<Rating[]>([])
  const [ratingsCount, setRatingsCount] = useState<number>(0)
  const [queryParams, setQueryParams] = useState<TaskQueryParams>({
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const { isLoading, refetch: refetchPartnerRatings } = useQuery(
    ['partnerComplaints', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        PARTNER_RATING_DATA_ENDPOINT,
        PARTNER_RATING_DATA_COUNT_ENDPOINT
      ),
    {
      onSuccess: ([data, count]) => {
        setRatingsData(data)
        setRatingsCount(count)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const [editPersonRatingRightsMutation] = useMutation(
    (payload: RatingEditingRightsPayload) => editPersonRatingRights(payload),
    {
      onSuccess: (data: RatingEditingRightsPayload) => {
        showSnackBar({
          severity: 'success',
          text: `Vērtējumu sniegšana apliecības turētājam ${
            data.vērtējumi_bloķēti ? 'bloķēta' : 'atļauta'
          } `,
        })
        refetchPartnerRatings()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const handleRatingRights = (rowData: Rating) => {
    editPersonRatingRightsMutation({
      id: rowData.persona_id,
      vērtējumi_bloķēti: !rowData.personas_vērtējumi_bloķēti,
    })
  }

  return (
    <>
      <CustomizedMaterialTable
        columns={columns}
        data={ratingsData}
        isLoading={isLoading}
        page={queryParams.offset! / queryParams.limit!}
        totalCount={ratingsCount}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        actions={actions(handleRatingRights)}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
