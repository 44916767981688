import React from 'react'
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material'

interface Props {
  isLoading?: boolean
  children: React.ReactNode
}

export default function StyledIconButton({
  isLoading = false,
  disabled,
  children,
  ...props
}: Props & IconButtonProps) {
  return (
    <IconButton disabled={disabled || isLoading} {...props}>
      {isLoading ? <CircularProgress size={20} color="primary" /> : children}
    </IconButton>
  )
}
