import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { fetchCurrentUser } from '../../api/API'
import { CurrentUser } from '../interfaces/CurrentUser'

export function PrivateRoute({ children }: { children: JSX.Element; path: string }) {
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(null)

  React.useEffect(() => {
    fetchCurrentUser()
      .then((data) => {
        setCurrentUser(data)
      })
      .catch(() => {
        setCurrentUser(null)
      })
  }, [])

  return currentUser && <Child children={children} currentUser={currentUser} />
}

function Child({ children, currentUser }: { children: JSX.Element; currentUser: CurrentUser }) {
  return (
    <Route
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
