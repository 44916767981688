import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  SelectChangeEvent,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { addPartnerContact, editPartnerContactData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import {
  NewPartnerContact,
  NewPartnerContactResponse,
} from '../../interfaces/Partners/NewPartnerContact'
import {
  EMAIL_LENGTH,
  INVALID_EMAIL_FORMAT,
  EXISTING_PERSON_CODE,
  INVALID_EMAIL_LENGTH,
  INVALID_PERSON_CODE_FORMAT,
  PHONE_FORMAT_ERROR_MESSAGE,
  PHONE_PLACEHOLDER,
  REGEX_EMAIL_FORMAT,
  REGEX_PERSON_CODE,
  REGEX_PHONE_CODE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import RequiredLabel from '../../elements/RequiredLabel'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { convertPersonCode } from '../../utils/convertPersonCode'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

const defaultFormErrors = {
  personas_kods: '',
  epasts: '',
  telefons: '',
  loma_kods: '',
}

interface Props {
  person: NewPartnerContact | null
  partnerId: number
  partnerRoleList: ClassifierStatus[]
  partnerContactData?: NewPartnerContactResponse[]
  onClose: () => void
}

export default function EditPersonModal({
  partnerId,
  person,
  partnerRoleList,
  partnerContactData,
  onClose,
}: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
  const [formState, setFormState] = React.useState<NewPartnerContact>({
    id: person?.id,
    telefons: person?.telefons || '',
    loma_kods: person?.loma_kods || '',
    vārds: person?.vārds || '',
    uzvārds: person?.uzvārds || '',
    personas_kods: person?.personas_kods || '',
    epasts: person?.epasts || '',
    ir_bloķēts: person?.ir_bloķēts || false,
  })

  const validateForm = () => {
    const errors: { [key: string]: string } = {}
    const existingUsersPersonCodes = partnerContactData?.map(({ personas_kods }) => personas_kods)

    if (formState.epasts && !formState.epasts.match(REGEX_EMAIL_FORMAT)) {
      errors['epasts'] = INVALID_EMAIL_FORMAT
    }

    if (formState.epasts && formState.epasts.length > EMAIL_LENGTH) {
      errors['epasts'] = INVALID_EMAIL_LENGTH
    }

    if (formState.epasts && !formState.epasts.match(REGEX_EMAIL_FORMAT)) {
      errors['epasts'] = INVALID_EMAIL_FORMAT
    }

    if (!formState.epasts) {
      errors['epasts'] = REQUIRED_ERROR_MESSAGE
    }

    if (!formState.telefons.match(REGEX_PHONE_CODE)) {
      errors['telefons'] = PHONE_FORMAT_ERROR_MESSAGE
    }

    if (!formState.telefons) {
      errors['telefons'] = REQUIRED_ERROR_MESSAGE
    }

    if (!formState.personas_kods) {
      errors['personas_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (formState.personas_kods && !formState.personas_kods.match(REGEX_PERSON_CODE)) {
      errors['personas_kods'] = INVALID_PERSON_CODE_FORMAT
    }

    if (
      formState.personas_kods &&
      person?.personas_kods !== formState.personas_kods &&
      existingUsersPersonCodes?.includes(formState.personas_kods)
    ) {
      errors['personas_kods'] = EXISTING_PERSON_CODE
    }

    if (!formState.loma_kods) {
      errors['loma_kods'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const [addPartnerContactMutation] = useMutation(
    ({
      vārds,
      uzvārds,
      personas_kods,
      epasts,
      telefons,
      loma_kods,
      ir_bloķēts,
    }: NewPartnerContact) =>
      addPartnerContact({
        sadarbības_partneris_id: partnerId,
        vārds: vārds,
        uzvārds: uzvārds,
        personas_kods: convertPersonCode(personas_kods),
        epasts: epasts,
        telefons: telefons,
        loma_kods: loma_kods,
        ir_bloķēts: ir_bloķēts,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const [editPartnerContactMutation] = useMutation(
    ({
      id,
      vārds,
      uzvārds,
      personas_kods,
      epasts,
      telefons,
      loma_kods,
      ir_bloķēts,
    }: NewPartnerContact) =>
      editPartnerContactData(
        {
          id: id,
          sadarbības_partneris_id: partnerId,
          vārds: vārds,
          uzvārds: uzvārds,
          personas_kods: convertPersonCode(personas_kods),
          epasts: epasts,
          telefons: telefons,
          loma_kods: loma_kods,
          ir_bloķēts: ir_bloķēts,
        },
        id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = target.value

    setFormState({
      ...formState,
      [target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const handleDropDownChange = (event: SelectChangeEvent) => {
    let value = event.target.value

    setFormState({
      ...formState,
      [event.target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onSubmit = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }
    setIsLoadingSubmit(true)

    if (person) {
      editPartnerContactMutation(formState)
      setIsLoadingSubmit(false)
    } else {
      addPartnerContactMutation(formState)
      setIsLoadingSubmit(false)
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {person ? 'Labot' : 'Pievienot'} lietotāju
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Vārds
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="vārds"
                autoComplete="off"
                value={formState.vārds}
                onChange={onFieldChange}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Uzvārds
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="uzvārds"
                autoComplete="off"
                value={formState.uzvārds}
                onChange={onFieldChange}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Personas kods" />
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="personas_kods"
                autoComplete="off"
                value={formState.personas_kods}
                onChange={onFieldChange}
                helperText={formErrors.personas_kods}
                error={Boolean(formErrors.personas_kods)}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Telefona nr." />
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="telefons"
                autoComplete="off"
                placeholder={PHONE_PLACEHOLDER}
                value={formState.telefons}
                onChange={onFieldChange}
                helperText={formErrors.telefons}
                error={Boolean(formErrors.telefons)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+</InputAdornment>,
                }}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="E-pasta adrese" />
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="epasts"
                autoComplete="off"
                value={formState.epasts}
                onChange={onFieldChange}
                helperText={formErrors.epasts}
                error={Boolean(formErrors.epasts)}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Loma" />
              <PartnerDropDownList
                name="loma_kods"
                value={formState.loma_kods}
                onChange={handleDropDownChange}
                data={partnerRoleList}
                helperText={formErrors.loma_kods}
                error={Boolean(formErrors.loma_kods)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
