import { CSSProperties } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import { NewPartnerDiscountResponse } from '../../interfaces/Partners/NewPartnerDiscount'

interface Props {
  onClose: () => void
  discountData: NewPartnerDiscountResponse | null
}

const sx: { [key: string]: CSSProperties } = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
}

const ViewDiscountModal: React.FC<Props> = ({ onClose, discountData }) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <DialogTitle sx={sx.dialogTitle}>
          <Box display="flex" justifyContent="center" px={12}>
            Atlaides dati
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Typography>
              <b>Skaits: </b>
              {discountData?.skaits}
            </Typography>
            <Typography>
              <b>Mērvienība: </b>
              {discountData?.mērvienība_nosaukums}
            </Typography>
            <Typography>
              <b>Apraksts: </b>
              {discountData?.apraksts}
            </Typography>
            <Typography>
              <b>Statuss: </b>
              {discountData?.statuss_nosaukums}
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default ViewDiscountModal
