import { EditorContent, useEditor } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Image from '@tiptap/extension-image'
import StarterKit from '@tiptap/starter-kit'
import { EditorMenu } from './EditorMenu'
import { Box, Typography } from '@mui/material'
import CharacterCount from '@tiptap/extension-character-count'
import { DEFAULT_MAX_LENGTH } from '../../../constants/constants'

interface EditorProps {
  value?: string
  name?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  maxLength?: number
  showMaxLength?: boolean
  error?: boolean
  helperText?: string
  tableStyle?: string
  tableClass?: string
  cellStyle?: string
  cellClass?: string
}

// TODO: update border color on error
export const Editor = ({
  value = '',
  onChange,
  name,
  maxLength = DEFAULT_MAX_LENGTH,
  showMaxLength,
  helperText,
  error,
  tableStyle,
  tableClass,
  cellStyle,
  cellClass,
}: EditorProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CharacterCount.configure({
        limit: maxLength,
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      Link.configure({
        protocols: ['mailto'],
      }),
      Table.configure({
        HTMLAttributes: {
          style: tableStyle,
          class: tableClass,
        },
      }),
      TableRow,
      TableHeader,
      TableCell.configure({
        HTMLAttributes: {
          style: cellStyle,
          class: cellClass,
        },
      }),
    ],
    // For normalising the editor (like a standard html input)
    content: value,
    onUpdate: ({ editor }) =>
      onChange({
        target: { value: editor.getHTML(), name },
      } as React.ChangeEvent<HTMLInputElement>),
  })

  return (
    <Box sx={{ width: '100%' }}>
      <EditorMenu editor={editor} />
      <EditorContent name={name} editor={editor} />
      {helperText && (
        <Typography fontSize="0.75rem" mt={1} ml={2} color={error ? 'error' : 'default'}>
          {helperText}
        </Typography>
      )}
      {editor && showMaxLength && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ mt: 1, ml: 'auto' }}>
            <Typography component="span">
              {editor.storage.characterCount.characters()} / {maxLength}
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  )
}
