import { useState } from 'react'
import { Box, SelectChangeEvent, SxProps, TextField, Button, CircularProgress } from '@mui/material'
import React, { CSSProperties } from 'react'
import { REGEX_ONLY_NUMBERS } from '../../../constants/constants'
import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import { RequiredMarker } from '../../elements/RequiredMarker'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { NewPartnerEntity } from '../../interfaces/Partners/NewPartnerEntity'

const sx: { [key: string]: CSSProperties | SxProps } = {
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    minWidth: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
    fontSize: 18,
  },
  registryFieldBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  button: {
    marginTop: '12px',
    width: '50%',
  },
}

interface Props {
  handlePartnerChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDropDownChange: (event: SelectChangeEvent) => void
  handleRegistryData: () => void
  partner: NewPartnerEntity
  formErrors: { [key: string]: string }
  partnerTypeList: ClassifierStatus[]
  isRegistryDataLoading: boolean
}

export default function NewPartnerBaseData({
  handlePartnerChange,
  handleDropDownChange,
  handleRegistryData,
  isRegistryDataLoading,
  partner,
  formErrors,
  partnerTypeList,
}: Props) {
  const { reģistrācijas_numurs, nosaukums, juridiskais_nosaukums, veids_kods, epasts } = partner
  const [invalidRegistryNumber, setInvalidRegistryNumber] = useState<string>('')

  const handleFetchFromRegistry = () => {
    if (!reģistrācijas_numurs.match(REGEX_ONLY_NUMBERS)) {
      setInvalidRegistryNumber('Reģistrācijas numuru norādīt tikai ar cipariem')
    } else {
      handleRegistryData()
      setInvalidRegistryNumber('')
    }
  }

  return (
    <>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Reģistrācijas numurs
          <RequiredMarker />
        </Box>
        <Box sx={sx.registryFieldBox}>
          <TextField
            sx={{ flexGrow: 1, width: '100%' }}
            size="small"
            name="reģistrācijas_numurs"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInvalidRegistryNumber('')
              handlePartnerChange(e)
            }}
            value={reģistrācijas_numurs}
            helperText={formErrors.reģistrācijas_numurs || invalidRegistryNumber}
            error={Boolean(formErrors.reģistrācijas_numurs) || !!invalidRegistryNumber}
          />
          <Button
            sx={sx.button}
            disabled={!reģistrācijas_numurs || Boolean(formErrors.reģistrācijas_numurs)}
            color="primary"
            variant="contained"
            onClick={handleFetchFromRegistry}
          >
            {isRegistryDataLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Ielādēt datus no uzņēmumu reģistra'
            )}
          </Button>
        </Box>
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Partnera nosaukums
          <RequiredMarker />
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="nosaukums"
          onChange={handlePartnerChange}
          value={nosaukums}
          helperText={formErrors.nosaukums}
          error={Boolean(formErrors.nosaukums)}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Juridiskais nosaukums
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="juridiskais_nosaukums"
          onChange={handlePartnerChange}
          value={juridiskais_nosaukums}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Partnera veids
          <RequiredMarker />
        </Box>
        <PartnerDropDownList
          name="veids_kods"
          value={veids_kods}
          onChange={handleDropDownChange}
          data={partnerTypeList}
          helperText={formErrors.veids_kods}
          error={Boolean(formErrors.veids_kods)}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Oficiālā e-pasta adrese
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="epasts"
          onChange={handlePartnerChange}
          value={epasts}
          helperText={formErrors.epasts}
          error={Boolean(formErrors.epasts)}
        />
      </Box>
    </>
  )
}
