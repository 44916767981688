import { Box } from '@mui/material'

export default function RequiredLabel({ ...props }) {
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '150px',
      }}
      {...props}
    >
      <span>{props.label}</span>
      <Box
        component="span"
        sx={{
          display: 'flex',
          marginRight: '10px',
          color: props.color || 'red',
        }}
      >
        *
      </Box>
    </Box>
  )
}
