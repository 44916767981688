import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  SelectChangeEvent,
  CircularProgress,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import DatePicker from '../../elements/DatePicker'
import RequiredLabel from '../../elements/RequiredLabel'
import { addPromotion, editPromotion } from '../../../api/API'
import { useMutation } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { NewPromotionForm, NewPromotionReq } from '../../interfaces/NewPromotionForm'
import {
  ACCEPTED_PARTNER_FILE_TYPES_LOGOTYPE,
  DEFAULT_API_DATE_FORMAT,
  FILE_SIZE_LIMIT_MB_MESSAGE,
  INVALID_DATE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { format, isValid } from 'date-fns'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { uploadFiles } from '../../utils/uploadFiles'
import StatusCodeDropDownList from '../../elements/partners/StatusCodeDropDownList'
import { StatusCodes, statusCodes } from './constants'
import { Editor } from '../../elements/Editor/Editor'
import { removeHtmlTags } from '../../utils/removeHtmlTags'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    minWidth: '400px',
  },
}

interface NewPartnerPromotionProps {
  partnerId: number
  onClose: () => void
  promotion?: NewPromotionReq
}

export default function NewPartnerPromotion({
  partnerId,
  onClose,
  promotion,
}: NewPartnerPromotionProps) {
  const [formState, setFormState] = useState<NewPromotionForm>({
    sadarbības_partneris_id: partnerId,
    nosaukums: promotion?.nosaukums ?? null,
    aktīva_līdz: promotion?.aktīva_līdz ? new Date(promotion.aktīva_līdz) : null,
    aktīva_no: promotion?.aktīva_no ? new Date(promotion.aktīva_no) : null,
    apraksts: promotion?.apraksts ?? null,
    saite: promotion?.saite ?? null,
    pielikums_id: promotion?.pielikums_id ?? null,
    statuss_kods: promotion?.statuss_kods ?? StatusCodes.PBL,
    pielikums: promotion?.pielikums ?? null,
  })
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [files, setFiles] = useState<FileListItem[]>(
    promotion?.pielikums
      ? [{ file: { ...promotion?.pielikums, faila_id: promotion.pielikums.id }, state: 'keep' }]
      : []
  )

  const { showSnackBar } = useSnackBar()

  const [addPromotionMutation] = useMutation(
    ({
      sadarbības_partneris_id,
      aktīva_līdz,
      aktīva_no,
      nosaukums,
      saite,
      apraksts,
      pielikums_id,
      statuss_kods,
      pielikums,
    }: NewPromotionForm) =>
      addPromotion({
        sadarbības_partneris_id,
        nosaukums,
        apraksts,
        saite,
        statuss_kods,
        pielikums_id,
        pielikums,
        aktīva_līdz: format(aktīva_līdz!, DEFAULT_API_DATE_FORMAT),
        aktīva_no: format(aktīva_no!, DEFAULT_API_DATE_FORMAT),
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const [editPromotionMutation] = useMutation(
    ({
      sadarbības_partneris_id,
      aktīva_līdz,
      aktīva_no,
      nosaukums,
      saite,
      apraksts,
      pielikums_id,
      statuss_kods,
      pielikums,
    }: NewPromotionForm) =>
      editPromotion(
        {
          id: promotion?.id,
          sadarbības_partneris_id,
          nosaukums,
          apraksts,
          saite,
          pielikums_id,
          pielikums,
          statuss_kods,
          aktīva_līdz: format(aktīva_līdz!, DEFAULT_API_DATE_FORMAT),
          aktīva_no: format(aktīva_no!, DEFAULT_API_DATE_FORMAT),
        },
        promotion!.id!
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const isValidDate = (date: null | Date) => {
    if (date === null) return false
    if (isValid(date)) return true
    return false
  }

  const isFormValid = () => {
    const { nosaukums, aktīva_līdz, aktīva_no, statuss_kods, apraksts } = formState
    const _errors: { [key: string]: string } = {}

    if (!nosaukums) _errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    if (!statuss_kods) _errors['statuss_kods'] = REQUIRED_ERROR_MESSAGE
    if (!isValidDate(aktīva_no)) _errors['aktīva_no'] = INVALID_DATE
    if (!isValidDate(aktīva_līdz)) _errors['aktīva_līdz'] = INVALID_DATE
    if (
      aktīva_no &&
      format(aktīva_no!, DEFAULT_API_DATE_FORMAT) < format(new Date(), DEFAULT_API_DATE_FORMAT)
    )
      _errors['aktīva_no'] = INVALID_DATE
    if (
      aktīva_līdz &&
      format(aktīva_līdz!, DEFAULT_API_DATE_FORMAT) < format(new Date(), DEFAULT_API_DATE_FORMAT)
    )
      _errors['aktīva_līdz'] = INVALID_DATE

    if (!removeHtmlTags(apraksts)) {
      _errors['apraksts'] = REQUIRED_ERROR_MESSAGE
    }
    if (files.length < 1) {
      _errors['pielikums'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsLoadingSubmit(true)

    const requestData = { ...formState }

    uploadFiles(files || [])
      .then(async (uploadedFiles: Response[]) => {
        if (uploadedFiles.length) {
          const json = await uploadedFiles[0].json()

          requestData.pielikums_id = json.id
          requestData.pielikums = {
            id: json.id,
            nosaukums: json.filename,
            sha256: json.sha_256,
            content_type: json.content_type,
          }
        } else {
          const removedFiles = files.filter((file) => file.state === 'delete')

          if (removedFiles.length) {
            requestData.pielikums_id = null
            requestData.pielikums = null
          }
        }

        if (!promotion) {
          addPromotionMutation({ ...formState, ...requestData })
        } else {
          editPromotionMutation({ ...formState, ...requestData })
        }
      })
      .catch((error) => {
        setFiles([])
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      })
      .finally(() => {
        setIsLoadingSubmit(false)
      })
  }

  const onTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleFileChange = (file: FileListItem[]) => {
    setFiles(file)
  }

  const handlePartnerPromotionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    setFormState((prevPartnerPromotion) => ({
      ...prevPartnerPromotion,
      [event.target.name]: value,
    }))

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {promotion ? 'Labot' : 'Pievienot'} akciju
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Nosaukums" />
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={1}
                fullWidth
                name="nosaukums"
                autoComplete="off"
                value={formState.nosaukums ?? ''}
                onChange={onTextFieldChange}
                error={Boolean(formErrors.nosaukums)}
                helperText={formErrors.nosaukums}
              />
            </Box>
            <Box sx={[sx.inputWrapper, { alignItems: 'flex-start' }]}>
              <RequiredLabel sx={[sx.inputLabel, { marginTop: '10px' }]} label="Apraksts" />
              <Editor
                showMaxLength
                name="apraksts"
                onChange={handlePartnerPromotionChange}
                value={formState.apraksts || ''}
                error={Boolean(formErrors.apraksts)}
                helperText={formErrors.apraksts}
              />
            </Box>
            <Box sx={[sx.inputWrapper, { alignItems: 'flex-start' }]}>
              <RequiredLabel sx={[sx.inputLabel, { marginTop: '10px' }]} label="Pielikums" />
              <Box>
                <FileUploadButton
                  useLink={true}
                  multiple={false}
                  onFileChange={handleFileChange}
                  files={files}
                  acceptedUploadFileTypes={ACCEPTED_PARTNER_FILE_TYPES_LOGOTYPE}
                  error={formErrors.pielikums}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    color: '#999',
                    mt: '-10px !important',
                    maxWidth: '390px !important',
                  }}
                >
                  {FILE_SIZE_LIMIT_MB_MESSAGE}
                </Typography>
              </Box>
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Aktīva no" />
              <DatePicker
                minDate={new Date()}
                value={formState.aktīva_no}
                onChange={(e) => setFormState({ ...formState, aktīva_no: e })}
                sx={sx.datePicker}
                error={Boolean(formErrors.aktīva_no)}
                helperText={formErrors.aktīva_no}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Aktīva līdz" />
              <DatePicker
                minDate={new Date()}
                value={formState.aktīva_līdz}
                onChange={(e) => setFormState({ ...formState, aktīva_līdz: e })}
                sx={sx.datePicker}
                error={Boolean(formErrors.aktīva_līdz)}
                helperText={formErrors.aktīva_līdz}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Statuss" />
              <StatusCodeDropDownList
                value={formState.statuss_kods ?? ''}
                data={statusCodes}
                name="statuss_kods"
                onChange={onTextFieldChange}
                error={Boolean(formErrors.statuss_kods)}
                helperText={formErrors.statuss_kods}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
