import create from 'zustand'
import { CurrentUser } from '../components/interfaces/CurrentUser'

interface UserStore {
  currentUser: CurrentUser | null
  setCurrentUser: (nextUser: CurrentUser | null) => void
}

export const useCurrentUser = create<UserStore>((set) => ({
  currentUser: null,
  setCurrentUser: (nextUser: CurrentUser | null) => {
    set(() => ({ currentUser: nextUser }))
  },
}))
