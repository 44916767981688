import React from 'react'
import { Box, TextField } from '@mui/material'
import { NewPartnerEntity } from '../../interfaces/Partners/NewPartnerEntity'

const sx = {
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
    fontSize: 18,
  },
}

interface Props {
  handlePartnerChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  partner: NewPartnerEntity
  formErrors: { [key: string]: string }
}

export default function NewPartnerAdditionalPropertyData({
  handlePartnerChange,
  handleAddressChange,
  partner,
  formErrors,
}: Props) {
  const { juridiskā_adrese, pvn_reģistrācijas_numurs, banka, swift, konta_numurs } = partner

  return (
    <>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          PVN reģistrācijas nr.
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="pvn_reģistrācijas_numurs"
          onChange={handlePartnerChange}
          value={pvn_reģistrācijas_numurs}
          helperText={formErrors.pvn_reģistrācijas_numurs}
          error={Boolean(formErrors.pvn_reģistrācijas_numurs)}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Juridiskā adrese
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="juridiskā_adrese"
          onChange={handleAddressChange}
          value={juridiskā_adrese?.address}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Banka
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="banka"
          onChange={handlePartnerChange}
          value={banka}
          helperText={formErrors.banka}
          error={Boolean(formErrors.banka)}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Konta nr.
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="konta_numurs"
          onChange={handlePartnerChange}
          value={konta_numurs}
          helperText={formErrors.konta_numurs}
          error={Boolean(formErrors.konta_numurs)}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          SWIFT
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="swift"
          onChange={handlePartnerChange}
          value={swift}
          helperText={formErrors.swift}
          error={Boolean(formErrors.swift)}
        />
      </Box>
    </>
  )
}
