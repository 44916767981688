import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Dialog, DialogContent, Divider, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useQuery } from 'react-query'

import { getComplaint } from '../../../api/API'
import { DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import { ComplaintInfoRequest } from '../../interfaces/ComplaintInfoRequest'
import { DelayedLoader } from '../../elements/DelayedLoader'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
}

interface Props {
  complaintInfoRequest: ComplaintInfoRequest
  onClose: () => void
}

export default function InfoRequestModal({ complaintInfoRequest, onClose }: Props) {
  const { data: complaint, isLoading } = useQuery(
    ['complaint'],
    () => getComplaint(complaintInfoRequest.id),
    {
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading) {
    return <DelayedLoader />
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogContent>
            {complaint && (
              <>
                <Box display="flex" justifyContent="center">
                  <Typography fontSize="20px" fontWeight={700} paddingBottom="16px">
                    Sūdzības pārskats
                  </Typography>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(2, max-content)"
                  columnGap="30px"
                  rowGap="12px"
                >
                  <Typography>Pievienošanas datums</Typography>
                  <Typography fontWeight={500}>
                    {complaint?.pievienošanas_datums
                      ? format(new Date(complaint?.pievienošanas_datums), DEFAULT_DATE_FORMAT)
                      : null}
                  </Typography>
                  <Typography>Apraksts</Typography>
                  <Typography fontWeight={500}>{complaint?.apraksts}</Typography>
                  <Typography>Atbilde lietotājam</Typography>
                  <Typography fontWeight={500}>{complaint?.atbilde}</Typography>
                  <Typography>Statuss</Typography>
                  <Typography fontWeight={500}>{complaint?.statuss_nosaukums}</Typography>
                </Box>
                <Divider sx={{ margin: '28px 0' }} />
              </>
            )}
            <Box display="flex" justifyContent="center">
              <Typography fontSize="20px" fontWeight={700} paddingBottom="16px">
                Informācijas pieprasījums
              </Typography>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(2, max-content)"
              columnGap="30px"
              rowGap="12px"
            >
              <Typography>Pievienošanas datums</Typography>

              <Typography fontWeight={500}>
                {complaintInfoRequest.pievienošanas_laiks
                  ? format(new Date(complaintInfoRequest.pievienošanas_laiks), DEFAULT_DATE_FORMAT)
                  : null}
              </Typography>
              <Typography>Jautājums</Typography>
              <Typography fontWeight={500}>{complaintInfoRequest.jautājums}</Typography>
              <Typography>Atbildes laiks</Typography>
              <Typography fontWeight={500}>
                {complaintInfoRequest.atbildes_laiks
                  ? format(new Date(complaintInfoRequest.atbildes_laiks), DEFAULT_DATE_FORMAT)
                  : null}
              </Typography>
              <Typography>Atbilde no partnera</Typography>
              <Typography fontWeight={500}>{complaintInfoRequest.atbilde}</Typography>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  )
}
