enum ChildEducationStatus {
  // Atbilst
  Studying = 'Studying',
  // Neatbilst
  NotStudying = 'NotStudying',
  // Nav pārbaudīts
  NotChecked = 'NotChecked',
}

export default ChildEducationStatus
