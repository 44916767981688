import { FormControl, MenuItem, Select, SelectChangeEvent, FormHelperText } from '@mui/material'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'

interface Props {
  data: ClassifierStatus[]
  name: string
  value: string
  onChange: (event: SelectChangeEvent<string>) => void
  helperText?: string
  disabled?: boolean
  error?: boolean
}

export default function ContractDiscontinueDropDownList({
  data,
  helperText,
  error,
  disabled,
  ...props
}: Props) {
  return (
    <FormControl
      variant="outlined"
      error={error}
      sx={{ flexGrow: 1, minWidth: 400 }}
      disabled={disabled}
    >
      <Select size="small" {...props}>
        {data?.map((data, index) => (
          <MenuItem key={index} value={data.kods}>
            {data.nosaukums}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
