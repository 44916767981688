import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { useMutation } from 'react-query'
import { REQUIRED_ERROR_MESSAGE } from '../../constants/constants'
import RequiredLabel from '../elements/RequiredLabel'
import { MassMailType } from '../enums/MassMailType'
import { AddMassMail } from '../interfaces/AddMassMail'
import { MassMailInquiryType } from '../enums/MassMailInquiryType'
import { addMassMail } from '../../api/API'
import { useSnackBar } from '../providers/SnackBarProvider'
import { handleErrorMessages } from '../utils/handleErrorMessages'

const sx = {
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
  },
  button: {
    minWidth: 130,
  },
}

const defaultFormState = {
  saturs_html: '',
  darbinieks: 'Darbinieks',
  veids: MassMailType.Aptauja,
  iesnieguma_veids: MassMailInquiryType.Visi,
  virsraksts: '',
}

const defaultFormErrors = {
  virsraksts: '',
  saturs_html: '',
}

export default function MassMail() {
  const { showSnackBar } = useSnackBar()

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState(defaultFormErrors)
  const [formState, setFormState] = useState<AddMassMail>(defaultFormState)

  const [addMassMailMutation] = useMutation(() => addMassMail(formState), {
    onSuccess: () => {
      showSnackBar()
      setIsLoadingSubmit(false)
      setFormState(defaultFormState)
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
      setIsLoadingSubmit(false)
    },
    onSettled: () => {
      setIsLoadingSubmit(false)
    },
  })

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [target.name]: target.value,
    }))

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const onTextFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [target.name]: target.value,
    })

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsLoadingSubmit(true)

    addMassMailMutation()
  }

  const isFormValid = () => {
    const errors: { [key: string]: string } = {}

    if (!formState.virsraksts) {
      errors['virsraksts'] = REQUIRED_ERROR_MESSAGE
    }

    if (!formState.saturs_html) {
      errors['saturs_html'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length === 0
  }

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Typography variant="h1" color="black" sx={{ mr: 4 }}>
          Jauns paziņojums
        </Typography>
      </Box>

      <Box sx={sx.inputWrapper}>
        <RequiredLabel label="Saziņas kanāls" />
        <FormControl sx={{ mt: -1 }}>
          <RadioGroup
            onChange={onFieldChange}
            value={formState.veids}
            defaultValue={MassMailType.Aptauja}
            name="veids"
          >
            <Box display="flex" alignItems="center">
              <FormControlLabel value={MassMailType.Aptauja} control={<Radio />} label="Aptaujas" />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                value={MassMailType.Marketing}
                control={<Radio />}
                label="Mārketinga materiāli"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={sx.inputWrapper}>
        <RequiredLabel label="Saziņas grupas" />
        <FormControl sx={{ mt: -1 }}>
          <RadioGroup
            onChange={onFieldChange}
            value={formState.iesnieguma_veids}
            defaultValue={MassMailInquiryType.Visi}
            name="iesnieguma_veids"
          >
            <Box display="flex" alignItems="center">
              <FormControlLabel value={MassMailInquiryType.Visi} control={<Radio />} label="Visi" />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                value={MassMailInquiryType.Daudzbērnu}
                control={<Radio />}
                label="Daudzbērnu ģimenes"
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                value={MassMailInquiryType.Invaliditāte}
                control={<Radio />}
                label="Ģimenes ar bērniem ar invaliditāti"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={sx.inputWrapper}>
        <RequiredLabel label="Nosaukums" />
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          name="virsraksts"
          autoComplete="off"
          value={formState.virsraksts}
          onChange={onTextFieldChange}
          helperText={formErrors.virsraksts}
          error={Boolean(formErrors.virsraksts)}
        />
      </Box>

      <Box sx={sx.inputWrapper}>
        <RequiredLabel label="Paziņojuma saturs" />
        <TextField
          variant="outlined"
          size="small"
          multiline
          rows={5}
          fullWidth
          name="saturs_html"
          autoComplete="off"
          value={formState.saturs_html}
          onChange={onTextFieldChange}
          helperText={formErrors.saturs_html}
          error={Boolean(formErrors.saturs_html)}
        />
      </Box>

      <Box mt={5} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={sx.button}
          variant="contained"
          onClick={onSubmit}
          color="primary"
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'Nosūtīt'}
        </Button>
      </Box>
    </>
  )
}
