import React, { useState } from 'react'
import { differenceInDays, format, isValid, parseISO } from 'date-fns'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material'
import DatePicker from '../../elements/DatePicker'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { editClassifierRecordData } from '../../../api/API'
import { addClassifierRecordData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import RequiredLabel from '../../elements/RequiredLabel'
import {
  DEFAULT_API_DATE_FORMAT,
  REQUIRED_ERROR_MESSAGE,
  INVALID_DATE,
  MIN_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE,
  END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE,
} from '../../../constants/constants'
import { Classifier } from '../../interfaces/Classifier'
import { AddEditClassifierState } from '../../interfaces/AddEditClassifier'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { selectedDateExceedsMinDate } from '../../utils/minDateRestriction'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

interface Props {
  classifier: Classifier | null
  code: string
  onClose: () => void
}

export default function AddEditClassifierModal({ classifier, code, onClose }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  //form data
  const isEditMode = Boolean(classifier)
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>({
    nosaukums: '',
    kods: '',
  })
  const [formState, setFormState] = React.useState<AddEditClassifierState>({
    id: classifier?.id,
    datums_no: classifier?.datums_no ? parseISO(classifier.datums_no) : null,
    datums_līdz: classifier?.datums_līdz ? parseISO(classifier.datums_līdz) : null,
    klasifikatora_kods: code || '',
    kods: classifier?.kods || '',
    kods_sort: classifier?.kods_sort || '',
    nosaukums: classifier?.nosaukums || '',
    piezīmes: classifier?.piezīmes || '',
    virskods: classifier?.virskods || '',
  })

  const isFormValid = () => {
    const { nosaukums, kods, datums_no, datums_līdz } = formState
    const _errors: { [key: string]: string } = {}

    if (!nosaukums) _errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    if (!kods) _errors['kods'] = REQUIRED_ERROR_MESSAGE
    if (datums_no && !isValid(datums_no)) _errors['datums_no'] = INVALID_DATE
    if (datums_no && !selectedDateExceedsMinDate(datums_no, new Date()))
      _errors['datums_no'] = MIN_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE
    if (datums_līdz && !isValid(datums_līdz)) _errors['datums_līdz'] = INVALID_DATE
    if (datums_no && datums_līdz && differenceInDays(datums_no, datums_līdz) > 0)
      _errors['datums_līdz'] = END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const [addClassifierRecordMutation] = useMutation(
    ({
      datums_no,
      datums_līdz,
      klasifikatora_kods,
      kods,
      kods_sort,
      nosaukums,
      piezīmes,
      virskods,
    }: AddEditClassifierState) =>
      addClassifierRecordData({
        datums_no: datums_no ? format(datums_no, DEFAULT_API_DATE_FORMAT) : null,
        datums_līdz: datums_līdz ? format(datums_līdz, DEFAULT_API_DATE_FORMAT) : null,
        klasifikatora_kods: klasifikatora_kods,
        kods: kods,
        kods_sort: kods_sort,
        nosaukums: nosaukums,
        piezīmes: piezīmes,
        virskods: virskods,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const [editClassifierRecordMutation] = useMutation(
    ({
      id,
      datums_no,
      datums_līdz,
      klasifikatora_kods,
      kods,
      kods_sort,
      nosaukums,
      piezīmes,
      virskods,
    }: AddEditClassifierState) =>
      editClassifierRecordData(
        {
          id: id,
          datums_no: datums_no ? format(datums_no, DEFAULT_API_DATE_FORMAT) : null,
          datums_līdz: datums_līdz ? format(datums_līdz, DEFAULT_API_DATE_FORMAT) : null,
          klasifikatora_kods: klasifikatora_kods,
          kods: kods,
          kods_sort: kods_sort,
          nosaukums: nosaukums,
          piezīmes: piezīmes,
          virskods: virskods,
        },
        id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState({ ...formState, [target.name]: target.value })

  const onSubmit = () => {
    if (!isFormValid()) return

    setIsLoadingSubmit(true)
    if (isEditMode) {
      editClassifierRecordMutation(formState)
      setIsLoadingSubmit(false)
    } else {
      addClassifierRecordMutation(formState)
      setIsLoadingSubmit(false)
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Klasifikatora ieraksts
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Nosaukums" />
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="nosaukums"
                autoComplete="off"
                value={formState.nosaukums}
                onChange={onFieldChange}
                helperText={formErrors.nosaukums}
                error={Boolean(formErrors.nosaukums)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Kods" />
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="kods"
                autoComplete="off"
                value={formState.kods}
                onChange={onFieldChange}
                helperText={formErrors.kods}
                error={Boolean(formErrors.kods)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Meklēšanas frāze
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="kods_sort"
                autoComplete="off"
                value={formState.kods_sort}
                onChange={onFieldChange}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <Box className="ProseMirror" sx={{ borderStyle: 'dashed' }}>
                Meklēšanas frāzes ir jānorāda atdalot vienu vārdu no otra ar atstarpi, piemēram,
                bērnu apģērbs ziemas apavi.
              </Box>
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Virskods
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="virskods"
                autoComplete="off"
                value={formState.virskods}
                onChange={onFieldChange}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Piezīmes
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="piezīmes"
                autoComplete="off"
                value={formState.piezīmes}
                onChange={onFieldChange}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Datums no
              </Box>
              <DatePicker
                minDate={new Date()}
                value={formState.datums_no}
                helperText={formErrors.datums_no}
                error={Boolean(formErrors.datums_no)}
                onChange={(date: Date | null) =>
                  setFormState({
                    ...formState,
                    datums_no: date,
                  })
                }
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Datums līdz
              </Box>
              <DatePicker
                value={formState.datums_līdz}
                helperText={formErrors.datums_līdz}
                error={Boolean(formErrors.datums_līdz)}
                onChange={(date: Date | null) => setFormState({ ...formState, datums_līdz: date })}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
