import React from 'react'
import { Snackbar, Alert, SnackbarProps, AlertColor, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  severity?: AlertColor
  text?: string
}

interface CustomSnackbarProps extends SnackbarProps, Props {}

const SnackBarContext = React.createContext({
  showSnackBar: (props?: Props) => {},
})

export const useSnackBar = () => {
  const context = React.useContext(SnackBarContext)
  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider')
  }
  return context
}

const SnackBarProvider = ({ children }: { children: JSX.Element }) => {
  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState<AlertColor>('success')
  const [text, setText] = React.useState('')
  const openSnackBar = ({ severity = 'success', text = '' }: Props = {}) => {
    setSeverity(severity)
    setText(text)
    setOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <SnackBarContext.Provider
      value={{
        showSnackBar: openSnackBar,
      }}
    >
      <SnackBar
        text={text}
        severity={severity}
        open={open}
        action={action}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
      />
      {children}
    </SnackBarContext.Provider>
  )
}

export default SnackBarProvider

const SnackBar = ({ severity = 'success', text, action, ...props }: CustomSnackbarProps) => {
  const _text = text || (severity === 'success' ? 'Saglabāts!' : 'kļūda!')
  return (
    <Snackbar {...props}>
      <Alert
        severity={severity}
        elevation={6}
        variant="filled"
        sx={{
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 16,
            alignItems: 'center',
          }}
        >
          {_text} {action}
        </div>
      </Alert>
    </Snackbar>
  )
}
