import { format } from 'date-fns'
import { DEFAULT_API_DATE_FORMAT } from '../../constants/constants'
import { UserRole, UserRoleRequest } from '../interfaces/UserRole'

export function hasUserRole(userRoles: string[] | undefined, desiredRoles: string[]) {
  if (userRoles) {
    return userRoles.some((currentRole) => desiredRoles.includes(currentRole))
  } else return false
}

export const remapRolesToRolesRequest = (roles: UserRole[]): UserRoleRequest[] => {
  if (!Array.isArray(roles)) {
    return []
  }

  return roles.map((role) => ({
    ...role,
    datums_no: role.datums_no
      ? role.datums_no instanceof Date
        ? format(role.datums_no, DEFAULT_API_DATE_FORMAT)
        : role.datums_no
      : null,
    datums_līdz: role.datums_līdz
      ? role.datums_līdz instanceof Date
        ? format(role.datums_līdz, DEFAULT_API_DATE_FORMAT)
        : role.datums_līdz
      : null,
  }))
}
