enum DebtorStatus {
  // Parādnieks
  Debtor = 'Debtor',
  // Nav parādnieks
  NotDebtor = 'notDebtor',
  // Nav pārbaudīts
  NotChecked = 'notChecked',
}

export default DebtorStatus
