import React from 'react'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import EditLocationModal from './EditLocationModal'
import { FileLink } from '../../elements/FileLink'
import { useMutation, useQuery } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { Alert, Box, Button, Grid } from '@mui/material'
import { deletePartnerLocationData, fetchPartnerLocationData } from '../../../api/API'
import { NewPartnerLocationResponse } from '../../interfaces/Partners/NewPartnerLocation'
import { confirmDialog } from '../../elements/ConfirmDialog'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const columns = [
  {
    field: 'adrese',
    title: 'Adrese',
    render: ({ adrese }: NewPartnerLocationResponse) => <>{adrese?.adrese}</>,
  },
  {
    field: 'faila_id',
    title: 'Attēls',
    render: ({ faila_id, sha256, nosaukums, content_type }: NewPartnerLocationResponse) => (
      <>
        {faila_id && sha256 && (
          <FileLink
            file={{
              nosaukums: nosaukums,
              content_type: content_type,
              sha256: sha256,
              faila_id: faila_id,
            }}
          />
        )}
      </>
    ),
  },
  {
    field: 'komentārs',
    title: 'Komentārs',
  },
]

const rows: NewPartnerLocationResponse[] = []

interface PartnerLocationProps {
  partnerId: string
}

export const PartnerLocationTabContent = ({ partnerId }: PartnerLocationProps) => {
  const { showSnackBar } = useSnackBar()
  const partnerQueryParams = {
    sadarbības_partneris_id: partnerId,
  }

  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [partnerLocationData, setPartnerLocationData] =
    React.useState<NewPartnerLocationResponse[]>()
  const [selectedLocation, setSelectedLocation] = React.useState<NewPartnerLocationResponse | null>(
    null
  )

  const { isLoading, refetch } = useQuery(
    ['partnerLocations', partnerQueryParams],
    () => fetchPartnerLocationData(partnerQueryParams),
    {
      refetchOnWindowFocus: false,
      onSuccess: (locations: NewPartnerLocationResponse[]) =>
        setPartnerLocationData(locations && locations.length ? locations : rows),
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const [deletePartnerLocationMutation] = useMutation(
    (id: number) => deletePartnerLocationData(id),
    {
      onSuccess: () => {
        showSnackBar()
        refetch()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onLocationEdit = (event: React.MouseEvent, location: NewPartnerLocationResponse) => {
    setSelectedLocation(location)
    setOpenModalAddEdit(true)
  }

  const onLocationDelete = async (
    event: React.MouseEvent,
    { adrese, id }: NewPartnerLocationResponse
  ) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst atrašanās vietu "${adrese.adrese}" no datubāzes?`,
    })

    if (confirm.ok) {
      deletePartnerLocationMutation(id)
    }
  }

  const closeModal = () => {
    setSelectedLocation(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onLocationEdit,
      tooltip: 'Rediģēt',
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onLocationDelete,
      tooltip: 'Dzēst',
    },
  ]

  return (
    <>
      {openModalAddEdit && (
        <EditLocationModal
          location={selectedLocation}
          partnerId={parseInt(partnerId)}
          onClose={closeModal}
        />
      )}
      <Grid container sx={{ pb: '20px' }}>
        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end" container>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot atrašanās vietu
          </Button>
        </Grid>
      </Grid>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
          <DelayedLoader />
        </Box>
      ) : (
        <>
          {partnerLocationData == null ? (
            <></>
          ) : partnerLocationData.length > 0 ? (
            <CustomizedMaterialTable
              columns={columns}
              data={partnerLocationData}
              options={{
                paging: false,
              }}
              actions={actions}
              rowStyle={{
                height: 50,
              }}
            />
          ) : (
            <Alert severity="info">Sadarbības partnerim nav norādītas atrašanās vietas.</Alert>
          )}
        </>
      )}
    </>
  )
}
