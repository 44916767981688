import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Collapse } from '@mui/material'
import React, { CSSProperties } from 'react'

const sx = {
  collapseContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
    backgroundColor: '#A8A9AD',
    padding: '5px',
    borderRadius: '8px',
    border: '1px solid #000000',
    color: 'white',
    fontSize: '25px',
  },
}

export function CollapsableSection({
  children,
  title,
  isClosed = false,
  style = {},
}: {
  children: JSX.Element
  title: string
  isClosed?: boolean
  style?: CSSProperties
}) {
  const [openCollapsibleSection, setOpenCollapsibleSection] = React.useState<boolean>(!isClosed)

  return (
    <>
      <Box
        sx={[sx.collapseContainer, style]}
        onClick={() => setOpenCollapsibleSection(!openCollapsibleSection)}
      >
        <div>{title}</div>
        {openCollapsibleSection ? <ExpandLess /> : <ExpandMore />}
      </Box>

      <Collapse in={openCollapsibleSection} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}
