import { FileListItem } from '../interfaces/File/FileListItem'

export async function uploadFiles(files: FileListItem[]) {
  const newFiles = files
    .filter((entry) => entry.state === 'new')
    .map((entry) => {
      const formData = new FormData()

      formData.append('file', entry.file as File)

      return fetch('/api/v1/upload', {
        method: 'POST',
        body: formData,
      }).then(async (response) => {
        if (response.status === 400 || response.status === 413) {
          const responseJson = await response.json()

          const errorObject = {
            response: {
              data: responseJson,
              status: response.status,
            },
          }

          throw Error(JSON.stringify(errorObject))
        } else {
          return response
        }
      })
    })

  return await Promise.all(newFiles)
    .then((resp) => {
      return resp
    })
    .catch((error) => {
      const errorMessage = JSON.parse(error.message)
      return Promise.reject(errorMessage)
    })
}
