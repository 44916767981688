import { InternetStore } from '../components/interfaces/Partners/InternetStore'

export const DEFAULT_PAGE_SIZE = 10
export const FILE_SIZE_LIMIT_MB = 10
export const FILE_SIZE_LIMIT_MB_MESSAGE =
  'Faila izmērs nedrīkst pārsniegt 10Mb, attēlam jābūt "portrait" vai "landscape" novietojumā.'

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy.'
export const DEFAULT_DATE_FORMAT2 = 'dd.MM.yyyy'
export const DEFAULT_DATE_MASK = '__.__.____.'
export const DEFAULT_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const DEFAULT_DATETIME_MASK = '__.__.____. __:__'
export const DEFAULT_API_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_API_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm"

export const DEFAULT_DOCUMENT_STATUS = 'JAUNS'

export const BEST_REGARDS_SIGNATURE_TEXT = 'Ar cieņu,'

export const PASSWORD_UPPERCASE_CHARS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
export const PASSWORD_LOWERCASE_CHARS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]
export const PASSWORD_SPECIAL_CHARS = [
  '~',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '+',
  '-',
  '=',
  '{',
  '}',
  '[',
  ']',
  ':',
  ';',
  '?',
  ', ',
  '.',
  '|',
  '\\',
]
export const PASSWORD_NUMBER_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

export const EMAIL_LENGTH = 254
export const REGEX_PHONE_CODE = /^\d{6,14}$/
export const PHONE_PLACEHOLDER = '37100000000'
export const PERSON_CODE_LENGTH = 11
export const REGEX_PERSON_CODE = /^([0-3]\d{5})-?\d{5}$/
export const REGEX_NUMERIC_DASH = /^[0-9-]+$/
export const REGEX_EMAIL_FORMAT =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_HOME_PAGE_FORMAT =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
export const REGEX_ONLY_NUMBERS = /^[0-9]+$/

export const DISPLAYABLE_CHILDREN_COUNT = 8

export const PHONE_FORMAT_ERROR_MESSAGE = 'Nepareizs telefona formāts'
export const REQUIRED_ERROR_MESSAGE = 'Šis lauks ir obligāts'
export const INVALID_PASSWORD_LENGTH = 'Parolei jābūt vismaz 16 simbolu garai'
export const REQUIRED_ADRESS = 'Jāizvēlas vismaz viena saņemšanas vieta'
export const INVALID_EMAIL_LENGTH = `E-pasts pārsniedz maksimālo pieļaujamo garumu ${EMAIL_LENGTH}`
export const INVALID_EMAIL_FORMAT = 'Nekorekts e-pasta formāts'
export const INVALID_PERSON_CODE_FORMAT =
  'Personas kods ir jānorādā formātā 111111-11111 vai 11111111111'
export const EXISTING_PERSON_CODE =
  'Personas kods ir piesaistīts jau esošam sadarbības partnera lietotājam'
export const EXISTING_PERSON_CODE_IN_REGISTER =
  'Šis personas kods ir norādīts vairāk nekā vienai kontaktpersonai'
export const INVALID_DATE = 'Norādītais datums nav derīgs'
export const INVALID_DATE_FORMAT = 'Datums jānorāda formātā ' + DEFAULT_DATE_FORMAT
export const MIN_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE =
  'Datumam jābūt lielākam par vakardienas datumu'
export const END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE =
  'Beigu datumam ir jābūt lielākam par sākuma datumu'
export const INVALID_HOME_PAGE_URL =
  'Lūdzu norādīt mājas lapu pareizā formātā (piemērs: http://www.exammple.com, https://www.example.com)'
export const REQUIRED_CHILDREN_ERROR_MESSAGE =
  'Lūdzu, norādīt vismaz vienu bērnu, kura datus attēlot uz vecāka apliecības'

export const DEFAULT_PARTNER_FILTER_STATUS = 'SIA'

export const PAGE_NOT_FOUND = 'Atvainojiet! Šāda lapa netika atrasta'

export const INFO_REQUESTS_PAGE_TAB = 7

export const ACCEPTED_FILE_TYPES: { [key: string]: string | string[] } = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  tiff: 'image/tiff',
  edoc: ['', 'application/vnd.etsi.asic-e+zip'],
}

export const ACCEPTED_PARTNER_FILE_TYPES: { [key: string]: string } = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
}

export const ACCEPTED_PARTNER_FILE_TYPES_LOGOTYPE: { [key: string]: string } = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
}

export const DEFAULT_MAX_LENGTH = 1000
export const TAG_LIMIT = 6
export const GOODS_SERVICES_LIMIT = 3

export const NEW_ONLINE_STORE: InternetStore = {
  url: '',
  komentārs: '',
}

export const EMAIL_SIGNATURE_STYLES = {
  tableStyle: 'border-collapse:collapse;border: 1px solid #b8d1e5',
  cellStyle:
    'padding: 0 6px; border: 1px solid #b8d1e5; vertical-align: top; background: #f7f9fa; font-size: 13px;',
}
