import { include } from 'named-urls'

const routes = {
  login: '/login',
  info: '/info',
  profile: '/profile',
  users: '/users',
  report: '/report',
  classifiers: include('/classifiers', {
    root: '',
    view: ':id',
  }),
  mail: '/mass-mail',
  history: include('/history', {
    list: '',
    details: ':itemId',
  }),
  settings: '/settings',
  applications: include('/applications', {
    list: '',
    details: ':applicationId',
  }),
  partners: include('/partners', {
    list: '',
    details: ':partnerId',
  }),
  certificates: '/certificates',
}

export default routes
