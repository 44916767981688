import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { Box, Grid } from '@mui/material'
import OutlinedContainer from '../../elements/OutlinedContainer'
import StyledIconButton from '../../elements/StyledIconButton'
import useReportsExporter from './useReportsExporter'

// TODO: DRY and rework this component to render the items programmatically from an array
export default function Report() {
  const {
    exportHonoraryFamilyCards,
    isExportingHonoraryFamilyCards,
    exportCurrentHonoraryFamilyCards,
    isExportingCurrentHonoraryFamilyCards,
    exportCooperationPartnersByGroupsNumber,
    isExportingCooperationPartnersByGroupsNumber,
    exportCooperationPartnersActions,
    isExportingCooperationPartnersActions,
    exportCooperationPartnersDiscounts,
    isExportCooperationPartnersDiscounts,
    exportCooperationPartners,
    isExportingCooperationPartners,
    exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod,
    isExportingDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod,
    exportIssuedHonoraryCertificatesOfFamiliesInAPeriod,
    isExportingIssuedHonoraryCertificatesOfFamiliesInAPeriod,
    isExportingNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod,
    exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod,
    isExportingAuthorizationCount,
    exportAuthorizationCountMutation,
    isExportingDeniedCountOfHonoraryFamilyCertificates,
    exportDeniedCountOfHonoraryFamilyCertificates,
  } = useReportsExporter()

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'column', lineHeight: 3 }}
        >
          <OutlinedContainer id="propInfo" label={<b>Aktuālās lietas</b>}>
            <Box>
              GĢA saraksts
              <StyledIconButton
                isLoading={isExportingHonoraryFamilyCards}
                onClick={() => exportHonoraryFamilyCards()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Aktuālās GĢA
              <StyledIconButton
                isLoading={isExportingCurrentHonoraryFamilyCards}
                onClick={() => exportCurrentHonoraryFamilyCards()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Aktuālo GA skaita dinamikas laika periodā
              <StyledIconButton
                isLoading={isExportingDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod}
                onClick={() => exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Izsniegtās GĢA
              <StyledIconButton
                isLoading={isExportingIssuedHonoraryCertificatesOfFamiliesInAPeriod}
                onClick={() => exportIssuedHonoraryCertificatesOfFamiliesInAPeriod()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Atteikto GĢA iesniegumu skaits
              <StyledIconButton
                isLoading={isExportingNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod}
                onClick={() => exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Anulēto GĢA skaits
              <StyledIconButton
                isLoading={isExportingDeniedCountOfHonoraryFamilyCertificates}
                onClick={() => exportDeniedCountOfHonoraryFamilyCertificates()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Sadarbības partneri pa preču un pakalpojumu grupām
              <StyledIconButton
                isLoading={isExportingCooperationPartnersByGroupsNumber}
                onClick={() => exportCooperationPartnersByGroupsNumber()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              GĢA autorizācijas izsaukumi
              <StyledIconButton
                isLoading={isExportingAuthorizationCount}
                onClick={() => exportAuthorizationCountMutation()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
          </OutlinedContainer>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'column', lineHeight: 3 }}
        >
          <OutlinedContainer id="propInfo" label={<b>Partneru lietas</b>}>
            <Box>
              Sadarbības partneri
              <StyledIconButton
                isLoading={isExportingCooperationPartners}
                onClick={() => exportCooperationPartners()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Sadarbības partneru akcijas
              <StyledIconButton
                isLoading={isExportingCooperationPartnersActions}
                onClick={() => exportCooperationPartnersActions()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
            <Box>
              Sadarbības partneru atlaides
              <StyledIconButton
                isLoading={isExportCooperationPartnersDiscounts}
                onClick={() => exportCooperationPartnersDiscounts()}
              >
                <DownloadOutlinedIcon />
              </StyledIconButton>
            </Box>
          </OutlinedContainer>
        </Grid>
      </Grid>
    </>
  )
}
