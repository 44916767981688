import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  deleteTask,
  editTask,
  TASK_LIST_COUNT_ENDPOINT,
  TASK_LIST_ENDPOINT,
} from '../../../api/API'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { NewTaskForm, NewTaskReq } from '../../interfaces/NewTaskForm'
import { useSnackBar } from '../../providers/SnackBarProvider'
import NewTaskModal from './NewTaskModal'
import { Button, Checkbox, FormControlLabel, Grid, Switch } from '@mui/material'
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { paramFetcher } from '../../elements/ParamFetcher'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { DateClassifier } from '../../interfaces/DateClassifier'
import { format, parseISO } from 'date-fns'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmDialog } from '../../elements/ConfirmDialog'

interface PartnerTasksProps {
  partnerId: number
}

export const PartnerTasksTabContent = ({ partnerId }: PartnerTasksProps) => {
  const [openModalAddEdit, setOpenModalAddEdit] = useState(false)
  const [isToggleDisabled, setToggleDisabled] = useState(false)
  const closeModal = () => {
    setOpenModalAddEdit(false)
    setSelectedTask(undefined)
    refetch()
  }
  const [count, setCount] = useState(0)
  const [selectedTask, setSelectedTask] = useState<NewTaskForm | undefined>(undefined)

  const [queryParams, setQueryParams] = React.useState<TaskQueryParams>({
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const [data, setData] = useState<NewTaskReq | null>(null)

  const { showSnackBar } = useSnackBar()

  const { isLoading, refetch } = useQuery(
    ['partnerTasks', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        TASK_LIST_ENDPOINT,
        TASK_LIST_COUNT_ENDPOINT
      ),
    {
      onSuccess: ([data, count]) => {
        setData(data)
        setCount(count)
        setToggleDisabled(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const [editTaskRecordMutation] = useMutation((task: NewTaskReq) => editTask(task), {
    onSuccess: (e) => {
      showSnackBar()
      refetch()
    },
    onError: (error) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
      setToggleDisabled(false)
    },
  })

  const [deletePartnerTaskMutation] = useMutation((id: number) => deleteTask(id), {
    onSuccess: () => {
      showSnackBar()
      refetch()
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const onToggle = (e: NewTaskReq) => {
    setToggleDisabled(true)
    editTaskRecordMutation(e)
  }

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const columns = [
    {
      field: 'uzdevums_veids_nosaukums',
      title: 'Veids',
      width: '20%',
    },
    {
      field: 'termiņš',
      title: 'Termiņš',
      width: '10%',
      render: (rowData: DateClassifier) => {
        if (rowData.termiņš) {
          return <>{format(parseISO(rowData.termiņš), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'brīdinājums',
      title: 'Brīdinājums',
      width: '10%',
      render: (rowData: DateClassifier) => {
        if (rowData.brīdinājums) {
          return <>{format(parseISO(rowData.brīdinājums), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'apraksts',
      title: 'Apraksts',
      width: '40%',
    },
    {
      title: 'Izpildīts',
      render: (row: NewTaskReq) => {
        return (
          <Switch
            key={row.id}
            disabled={isToggleDisabled}
            checked={!!row.izpildīts}
            onChange={() =>
              onToggle({
                ...row,
                izpildīts: !row.izpildīts,
              })
            }
          />
        )
      },
      width: '20%',
    },
  ]

  const onTaskEdit = (event: React.MouseEvent, Task: NewTaskForm) => {
    setSelectedTask(Task)
    setOpenModalAddEdit(true)
  }

  const onTaskDelete = async (event: React.MouseEvent, rowData: NewTaskReq) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst uzdevumu "${rowData.uzdevums_veids_nosaukums}"?`,
    })

    if (confirm.ok) {
      deletePartnerTaskMutation(rowData.id)
    }
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onTaskEdit,
      tooltip: 'Rediģēt',
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onTaskDelete,
      tooltip: 'Dzēst',
    },
  ]

  return (
    <>
      {openModalAddEdit && (
        <NewTaskModal partnerId={partnerId} task={selectedTask} onClose={closeModal} />
      )}
      <Grid container sx={{ pb: '20px' }}>
        <Grid item xs={12} justifyContent="space-between" alignItems="flex-end" container>
          <FormControlLabel
            // didn't found info about it
            control={<Checkbox disabled />}
            label="Rādīt visus"
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot uzdevumu
          </Button>
        </Grid>
      </Grid>
      <CustomizedMaterialTable
        columns={columns}
        data={data ? data : []}
        isLoading={isLoading}
        page={queryParams.offset! / queryParams.limit!}
        totalCount={count}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        actions={actions}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
