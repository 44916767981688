import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import EditIcon from '@mui/icons-material/Edit'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SendIcon from '@mui/icons-material/Send'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { COMPLAINTS_LIST_COUNT_ENDPOINTS, COMPLAINTS_LIST_ENDPOINTS } from '../../../api/API'
import { DEFAULT_PAGE_SIZE, INFO_REQUESTS_PAGE_TAB } from '../../../constants/constants'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { paramFetcher } from '../../elements/ParamFetcher'
import { Complaint } from '../../interfaces/Complaints'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import AskInfoRequestModal from './AskInfoRequestModal'
import EditComplaintStatusModal from './EditComplaintStatusModal'
import ReplyComplaintModal from './ReplyComplaintModal'

const columns = [
  {
    field: 'apraksts',
    title: 'Sūdzības apraksts',
    width: '45%',
  },
  {
    field: 'atbilde',
    title: 'Atbilde',
    width: '45%',
  },
  {
    field: 'statuss_nosaukums',
    title: 'Statuss',
    width: '10%',
  },
]

interface PartnerTasksProps {
  partnerId: number
  setActiveTab: (tabNumber: number) => void
}

export const PartnerComplaintsTabContent: React.FC<PartnerTasksProps> = ({
  partnerId,
  setActiveTab,
}) => {
  const { showSnackBar } = useSnackBar()
  const history = useHistory()
  const [complaintsData, setComplaintsData] = useState<Complaint[]>([])
  const [isEditStatusModalOpen, setIsEditStatusModalOpen] = useState<boolean>(false)
  const [isReplyModalOpen, setIsReplyModalOpen] = useState<boolean>(false)
  const [isComplaintInfoRequestModalOpen, setIsComplaintInfoRequestModalOpen] =
    useState<boolean>(false)
  const [selectedComplaint, setSelectedComplaint] = useState<Complaint | null>(null)
  const [complaintsCount, setComplaintsCount] = useState<number>(0)
  const [queryParams, setQueryParams] = useState<TaskQueryParams>({
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const { isLoading, refetch: refetchPartnerComplaints } = useQuery(
    ['partnerComplaints', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        COMPLAINTS_LIST_ENDPOINTS,
        COMPLAINTS_LIST_COUNT_ENDPOINTS
      ),
    {
      onSuccess: ([data, count]) => {
        setComplaintsData(data)
        setComplaintsCount(count)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const handleStatusChange = (_: React.MouseEvent, complaint: Complaint) => {
    setSelectedComplaint(complaint)
    setIsEditStatusModalOpen(true)
  }

  const handleComplaintReply = (_: React.MouseEvent, complaint: Complaint) => {
    setSelectedComplaint(complaint)
    setIsReplyModalOpen(true)
  }

  const handleRequestInfo = (_: React.MouseEvent, complaint: Complaint) => {
    setSelectedComplaint(complaint)
    setIsComplaintInfoRequestModalOpen(true)
  }

  const handleNavigateToInfoRequests = (_: React.MouseEvent, complaint: Complaint) => {
    setActiveTab(INFO_REQUESTS_PAGE_TAB)
    history.push(`/partners/${partnerId}?tab=${INFO_REQUESTS_PAGE_TAB}&complaintId=${complaint.id}`)
  }

  const handleCloseStatusChangeModal = () => {
    setSelectedComplaint(null)
    setIsEditStatusModalOpen(false)
  }

  const handleCloseReplyChangeModal = () => {
    setSelectedComplaint(null)
    setIsReplyModalOpen(false)
  }

  const handleCloseRequestInfoModal = () => {
    setSelectedComplaint(null)
    setIsComplaintInfoRequestModalOpen(false)
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: handleStatusChange,
      tooltip: 'Mainīt statusu',
    },
    {
      icon: () => <SendIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: handleComplaintReply,
      tooltip: 'Atbildēt sūdzības iesniedzējam',
    },
    {
      icon: () => <QuestionAnswerIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: handleRequestInfo,
      tooltip: 'Nosūtīt informācijas pieprasījumu partnerim',
    },
    {
      icon: () => <ContentPasteSearchIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: handleNavigateToInfoRequests,
      tooltip: 'Apskatīt saistītos sūdzību informācijas pieprasījumus',
    },
  ]

  return (
    <>
      {isEditStatusModalOpen && selectedComplaint && (
        <EditComplaintStatusModal
          complaint={selectedComplaint}
          onClose={handleCloseStatusChangeModal}
          onSuccess={refetchPartnerComplaints}
        />
      )}
      {isReplyModalOpen && selectedComplaint && (
        <ReplyComplaintModal
          complaint={selectedComplaint}
          onClose={handleCloseReplyChangeModal}
          onSuccess={refetchPartnerComplaints}
        />
      )}
      {isComplaintInfoRequestModalOpen && selectedComplaint && (
        <AskInfoRequestModal
          complaint={selectedComplaint}
          onClose={handleCloseRequestInfoModal}
          onSuccess={refetchPartnerComplaints}
        />
      )}
      <CustomizedMaterialTable
        columns={columns}
        data={complaintsData}
        isLoading={isLoading}
        page={queryParams.offset! / queryParams.limit!}
        totalCount={complaintsCount}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        actions={actions}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
