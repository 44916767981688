import create from 'zustand'
import { PartnerListFilterState } from '../components/interfaces/Partners/PartnerListFilterState'
import { DEFAULT_PAGE_SIZE } from '../constants/constants'

interface PartnerFilterStore {
  filter: PartnerListFilterState
  setFilter: (nextFilter: PartnerListFilterState) => void
}

export const DEFAULT_PARTNER_LIST_FILTER: PartnerListFilterState = {
  atslēgvārdi: '',
  veids: [],
  grupa: [],
  statuss: [],
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  sort: null,
}

export const usePartnersListQueryParams = create<PartnerFilterStore>((set) => ({
  filter: DEFAULT_PARTNER_LIST_FILTER,
  setFilter: (nextFilter: PartnerListFilterState) => {
    set(() => ({ filter: nextFilter }))
  },
}))
