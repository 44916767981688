import { useQuery } from 'react-query'
import { fetchClassifierRecordData } from './API'

interface FetchClassifiersProps {
  queryKey: string
  queryParameters: { [key: string]: string }
  enabled?: boolean
  querySuccess?: (response: any) => void
  queryError?: (response: any) => void
}

export default function useFetchClassifierRecordData({
  queryKey,
  queryParameters,
  enabled = true,
  querySuccess = () => {},
  queryError = () => {},
}: FetchClassifiersProps) {
  const { data, isLoading, isFetching, error } = useQuery(
    [queryKey, queryParameters],
    () => fetchClassifierRecordData(queryParameters),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
      onSuccess: (resp) => querySuccess(resp),
      onError: (error) => queryError(error),
    }
  )

  return { data, isLoading, isFetching, error }
}
