import { useState } from 'react'
import { ApplicationRegistryCheck } from '../../interfaces/Application/ApplicationRegistryCheck'
import { List, ListItem, Grid, Button, Divider, Box } from '@mui/material'
import { ApplicationRegistersDocumentList } from './ApplicationRegistersDocumentList'
import { ApplicationDocumentUpload } from './ApplicationDocumentUpload'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { ApplicationRegistersTestResults } from './ApplicationRegistersTestResults'

interface Props {
  applicationEntryId: number
  list: ApplicationRegistryCheck[]
  documentStatusList: ClassifierStatus[]
  reload: () => void
}

export const ApplicationRegistersCheckList = ({
  applicationEntryId,
  list,
  documentStatusList,
  reload,
}: Props) => {
  const [activeRow, setActiveRow] = useState<{ tips_kods: string; personas_pārbaude_id: number }>()

  const openDocumentDialog = (index: number) => {
    setActiveRow({
      tips_kods: list[index].tips_kods,
      personas_pārbaude_id: list[index].id,
    })
  }

  const closeDocumentDialog = () => {
    setActiveRow(undefined)
  }

  return (
    <>
      <List sx={{ width: 1 }}>
        {list.map((listItem, index) => (
          <ListItem key={index} disableGutters sx={{ flexDirection: 'column' }}>
            <Grid container spacing={2}>
              <Box
                sx={{
                  mt: 2,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '16px',
                }}
              >
                <ApplicationRegistersTestResults listItem={listItem} />
                <Button
                  onClick={() => openDocumentDialog(index)}
                  color="primary"
                  variant="outlined"
                  sx={{ ml: 'auto', marginLeft: '16px' }}
                >
                  Pievienot
                </Button>
              </Box>

              {listItem.dokumenti && listItem.dokumenti.length > 0 && (
                <Grid xs={12} sx={{ mt: 2, paddingLeft: '16px' }}>
                  <ApplicationRegistersDocumentList
                    documents={listItem.dokumenti}
                    documentStatusList={documentStatusList}
                    applicationEntryId={applicationEntryId}
                    type={listItem.tips_kods}
                    register_check_id={listItem.id}
                    reload={reload}
                  />
                </Grid>
              )}
            </Grid>
            {index !== list.length - 1 && <Divider sx={{ mt: 4, mb: 2, width: '100%' }} />}
          </ListItem>
        ))}
      </List>
      {activeRow && (
        <ApplicationDocumentUpload
          open={!!activeRow}
          onClose={closeDocumentDialog}
          tips_kods={activeRow.tips_kods}
          iesniegums_id={applicationEntryId}
          personas_pārbaude_id={activeRow.personas_pārbaude_id}
          documentStatusList={documentStatusList}
          onNewDocument={reload}
        />
      )}
    </>
  )
}
