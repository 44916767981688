import create from 'zustand'
import { HistoryListFilterState } from '../components/interfaces/HistoryListFilterState'
import { DEFAULT_PAGE_SIZE } from '../constants/constants'

interface PartnerFilterStore {
  filter: HistoryListFilterState
  setFilter: (nextFilter: HistoryListFilterState) => void
}

export const DEFAULT_HISTORY_LIST_FILTER: HistoryListFilterState = {
  personas_kods: '',
  klienta_personas_kods: '',
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  sort: null,
}

export const useHistoryListQueryParams = create<PartnerFilterStore>((set) => ({
  filter: DEFAULT_HISTORY_LIST_FILTER,
  setFilter: (nextFilter: HistoryListFilterState) => {
    set(() => ({ filter: nextFilter }))
  },
}))
