import { useState } from 'react'
import { useQuery } from 'react-query'
import { PARTNER_LAST_ACTIONS, PARTNER_LAST_ACTIONS_COUNT } from '../../../api/API'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { format, parseISO } from 'date-fns'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { paramFetcher } from '../../elements/ParamFetcher'
import { LastActions } from '../../interfaces/LastActions'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const columns = [
  {
    field: 'laiks',
    title: 'Datums, laiks',
    render: ({ laiks }: LastActions) => format(parseISO(laiks), DEFAULT_DATETIME_FORMAT),
    width: '15%',
  },
  {
    field: 'tips_nosaukums',
    title: 'Notikuma nosaukums',
  },
]

interface PartnerLastActionsProps {
  partnerId: number
}

export const PartnerLastActionsTabContent: React.FC<PartnerLastActionsProps> = ({ partnerId }) => {
  const { showSnackBar } = useSnackBar()
  const [lastActionsData, setLastActionsData] = useState<LastActions[]>([])
  const [lastActionsCount, setLastActionsCount] = useState<number>(0)
  const [queryParams, setQueryParams] = useState<TaskQueryParams>({
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const { isLoading } = useQuery(
    ['partnerLastActions', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        PARTNER_LAST_ACTIONS,
        PARTNER_LAST_ACTIONS_COUNT
      ),
    {
      onSuccess: ([data, count]) => {
        setLastActionsData(data)
        setLastActionsCount(count)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  return (
    <>
      <CustomizedMaterialTable
        columns={columns}
        data={lastActionsData}
        isLoading={isLoading}
        page={queryParams.offset! / queryParams.limit!}
        totalCount={lastActionsCount}
        pageSize={queryParams.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
