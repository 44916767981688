import React from 'react'
import { Box } from '@mui/material'

interface ApplicationTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export function applicationTabA11yProps(index: number) {
  return {
    id: `application-tab-${index}`,
    'aria-controls': `application-tabpanel-${index}`,
  }
}

export function ApplicationTabPanel(props: ApplicationTabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`application-tabpanel-${index}`}
      aria-labelledby={`application-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
    </div>
  )
}
