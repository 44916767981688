import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { editPartnerData } from '../../../api/API'
import { ACCEPTED_FILE_TYPES, FILE_SIZE_LIMIT_MB_MESSAGE } from '../../../constants/constants'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { Loader } from '../../elements/Loader'
import { FileEntry } from '../../interfaces/File/FileEntry'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { NewPartnerResponse } from '../../interfaces/Partners/NewPartnerEntity'
import { PartnerContractFiles } from '../../interfaces/Partners/PartnerContract'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { mapPartnerAgreementFilesToFileListItems } from '../../utils/partner'
import { uploadFiles } from '../../utils/uploadFiles'

interface Props {
  partnerEntry: NewPartnerResponse
  onRefetch: () => void
}

export const PartnerUploadAgreement = ({ partnerEntry, onRefetch }: Props) => {
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState(false)
  const [contractFiles, setContractFiles] = useState<FileListItem[]>(
    mapPartnerAgreementFilesToFileListItems(partnerEntry)
  )

  useEffect(() => {
    setContractFiles(mapPartnerAgreementFilesToFileListItems(partnerEntry))

    setIsLoading(false)
  }, [partnerEntry])

  const [editPartnerMutation] = useMutation(
    (partner: NewPartnerResponse) => editPartnerData(partner, partner.id),
    {
      onSuccess: () => {
        setIsLoading(false)
        onRefetch()
        showSnackBar()
      },
      onError: (error: any) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onFileChange = (files: FileListItem[]) => {
    setContractFiles(files)
    setIsLoading(true)

    const keepFiles: PartnerContractFiles[] = files
      .filter((fileEntry) => fileEntry.state === 'keep')
      .map((file) => {
        const fileEntry = file.file as FileEntry

        return {
          faila_id: fileEntry.faila_id,
        }
      })

    uploadFiles(files)
      .then(async (response: Response[]) => {
        const faili: PartnerContractFiles[] = []

        for (const resp of response) {
          const fileMeta = await resp.json()

          faili.push({
            faila_id: fileMeta.id,
          })
        }

        const līgums = partnerEntry.līgums
          ? {
              ...partnerEntry.līgums,
              faili: [...faili, ...keepFiles],
            }
          : {
              izveidošanas_datums: '',
              komentārs: '',
              nosaukums: '',
              numurs: '',
              termiņš: null,
              faili: [...faili, ...keepFiles],
            }

        editPartnerMutation({
          ...partnerEntry,
          līgums: līgums,
        }).catch((e) => {
          setIsLoading(false)
        })
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <FileUploadButton
        onFileChange={onFileChange}
        useLink={true}
        files={contractFiles}
        acceptedUploadFileTypes={ACCEPTED_FILE_TYPES}
      />
      <Loader open={isLoading} />
    </>
  )
}
