import { Box } from '@mui/material'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { fetchFullHistoryData } from '../../../api/API'
import OutlinedContainer from '../../elements/OutlinedContainer'
import { DetailHistoryEntry } from '../../interfaces/History'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const HistoryItem: React.FC = () => {
  const { itemId } = useParams<{ itemId: string }>()
  const { showSnackBar } = useSnackBar()
  const [historyItemData, setHistoryItemData] = useState<DetailHistoryEntry>(
    {} as DetailHistoryEntry
  )

  useQuery('fetch-history-item', () => fetchFullHistoryData(itemId), {
    onSuccess: (data: DetailHistoryEntry) => {
      setHistoryItemData(data)
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  return (
    <OutlinedContainer id="histroy-item-list" label={<b>Lietotāja audita pārskats</b>}>
      {Object.keys(historyItemData).map((key) => {
        const historyItem = historyItemData[key as keyof DetailHistoryEntry]

        if (historyItem && typeof historyItem === 'string' && isJsonString(historyItem)) {
          const parsedJSON = JSON.parse(historyItem)

          return (
            <Box>
              <pre>{`${key}: ${JSON.stringify(parsedJSON, null, 2)}`}</pre>
            </Box>
          )
        } else {
          return <Box>{`${key}: ${historyItem}`}</Box>
        }
      })}
    </OutlinedContainer>
  )
}

export default HistoryItem
