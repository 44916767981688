import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'
import { blockPlasticCard } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import CancelIcon from '@mui/icons-material/Cancel'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
}

interface Props {
  card: ApplicationCard | null
  onClose: () => void
  refetch: () => void
}

export default function BlockCardModal({ card, onClose, refetch }: Props) {
  const { showSnackBar } = useSnackBar()

  const onSubmit = () => {
    if (card && card.id) {
      blockPlasticCard(
        {
          id: card.id,
        },
        card.id
      )
        .then(() => {
          showSnackBar()
          refetch()
          onClose()
        })
        .catch((error) => {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        })
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon sx={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Goda Ģimenes apliecības anulēšana
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box textAlign="center">
              Goda Ģimenes apliecības anulēšanas process ir neatgriezenisks un vienlaicīgi tiks
              anulēta gan digitālā, gan plastikāta apliecība. Vai vēlaties turpināt un anulēt
              apliecību Nr. {card?.numurs} ?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box width={1} display="flex" justifyContent="space-between">
              <Button sx={sx.button} variant="contained" onClick={onClose} color="primary">
                ATCELT
              </Button>
              <Button sx={sx.button} variant="contained" onClick={onSubmit} color="primary">
                TURPINĀT
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
