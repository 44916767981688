import React, { useEffect, useState } from 'react'
import { Button, Grid, SelectChangeEvent } from '@mui/material'
import { paramFetcher } from '../../elements/ParamFetcher'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { getApplicationTableColumns } from './ApplicationsTableUtils'
import { ApplicationListEntry } from '../../interfaces/Application/ApplicationListEntry'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../routes/routes'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import ApplicationsFilter from './ApplicationsFilter'
import { INQUIRY_DATA_ENDPOINT, INQUIRY_DATA_COUNT_ENDPOINT } from '../../../api/API'
import EditIcon from '@mui/icons-material/Edit'
import { reverse } from 'named-urls'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import {
  DEFAULT_APPLICATION_LIST_FILTER,
  useApplicationListQueryParams,
} from '../../../store/applicationsList'
import { ApplicationListFilterState } from '../../interfaces/Application/ApplicationListFilterState'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { ApplicationsListQueryParams } from '../../interfaces/Application/ApplicationsListQueryParams'
import { getApplicationEntry } from '../Application/ApplicationUtils'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'

const equal = require('fast-deep-equal')

const applicationStatusesQueryParams = {
  klasifikatora_kods: 'IESNIEGUMA_STATUSS',
}

const mapFilterToQueryParams = (
  filter: ApplicationListFilterState
): ApplicationsListQueryParams => {
  return {
    personas_kods: filter.personas_kods,
    atslēgvārdi: filter.atslēgvārdi,
    statuss: filter.statuss.map((item) => item.kods),
    iesniegšanas_datums_no: filter.iesniegšanas_datums_no,
    iesniegšanas_datums_līdz: filter.iesniegšanas_datums_līdz,
    aktualizacijas_datums_no: filter.aktualizacijas_datums_no,
    aktualizacijas_datums_līdz: filter.aktualizacijas_datums_līdz,
    secondaryFilters: filter.secondaryFilters,
    ir_neapstrādāti_dokumenti: filter.ir_neapstrādāti_dokumenti,
    satur_anulējamas_apliecības: filter.satur_anulējamas_apliecības,
    sort: filter.sort,
    offset: filter.offset,
    limit: filter.limit,
  }
}

interface KeepFiltersProps {
  keepFilters: boolean
}

export default function Applications() {
  const history = useHistory()
  const { state } = useLocation<KeepFiltersProps>()
  const { keepFilters } = state || {}

  const { filter, setFilter } = useApplicationListQueryParams()

  const filterValues = typeof keepFilters == 'boolean' ? filter : DEFAULT_APPLICATION_LIST_FILTER

  const [applications, setApplications] = useState<ApplicationEntry[]>([])
  const [applicationsCount, setApplicationsCount] = useState(0)
  const [statusList, setStatusList] = useState<ClassifierStatus[]>([])
  const [queryParams, setQueryParams] = React.useState<ApplicationsListQueryParams>(
    mapFilterToQueryParams(filterValues)
  )

  const firstUpdate = React.useRef(true)

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    const timeoutId = setTimeout(() => {
      const nextQueryParams = mapFilterToQueryParams(filter)

      const isRemovingKeywords = nextQueryParams.atslēgvārdi.length < queryParams.atslēgvārdi.length

      const keywords = isRemovingKeywords
        ? nextQueryParams.atslēgvārdi
        : nextQueryParams.atslēgvārdi.length < 3
        ? queryParams.atslēgvārdi
        : nextQueryParams.atslēgvārdi

      nextQueryParams.atslēgvārdi = keywords

      if (equal(nextQueryParams, queryParams)) {
        return
      }

      setQueryParams(nextQueryParams)
    }, 300)

    return () => {
      clearTimeout(timeoutId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const { isLoading: isLoadingList } = useQuery(
    ['applications_list', queryParams],
    () => paramFetcher(queryParams, INQUIRY_DATA_ENDPOINT, INQUIRY_DATA_COUNT_ENDPOINT),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        Promise.all<ApplicationEntry>(
          list.map((inquiry: ApplicationEntry) => getApplicationEntry(inquiry.id))
        ).then((inquiryList) => {
          setApplications(inquiryList)
          setApplicationsCount(count)
        })
      },
    }
  )

  const { isLoading: isLoadingStatuses } = useFetchClassifiers({
    queryKey: 'classifier_records',
    queryParameters: applicationStatusesQueryParams,
    querySuccess: (resp) => {
      setStatusList(convertToClassifierStatus(resp))
    },
  })

  useEffect(() => {
    if (history.action === 'PUSH' && typeof keepFilters !== 'boolean') {
      resetFilterValues()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepFilters])

  const onRowClick = (event: React.MouseEvent, rowData: ApplicationListEntry) => {
    onApplicationOpen(rowData.id)
  }

  const onApplicationOpen = (applicationId: number) => {
    history.push(reverse(routes.applications.details, { applicationId }))
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onRowClick,
      tooltip: 'Rediģēt',
    },
  ]

  const columns = getApplicationTableColumns()

  const onPageChange = (pageNumber: number) => {
    if (
      filter.limit != null &&
      filter.offset != null &&
      pageNumber * filter.limit !== filter.offset
    ) {
      setFilter({
        ...filter,
        offset: pageNumber * filter.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== filter.limit) {
      setFilter({
        ...filter,
        limit: rowsPerPage,
      })
    }
  }

  const onFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    })
  }

  const onFilterSelectChange = (event: SelectChangeEvent<string[]>) => {
    onChange(event.target.name, event.target.value)
  }

  const onChange = (name: string, value: string | string[]) => {
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const onApplicationDateFromChange = (date: Date | null) => {
    onDateChange('iesniegšanas_datums_no', date)
  }

  const onApplicationDateToChange = (date: Date | null) => {
    onDateChange('iesniegšanas_datums_līdz', date)
  }

  const onModifiedDateFromChange = (date: Date | null) => {
    onDateChange('aktualizacijas_datums_no', date)
  }

  const onModifiedDateToChange = (date: Date | null) => {
    onDateChange('aktualizacijas_datums_līdz', date)
  }

  const onDateChange = (name: string, date: Date | null) => {
    setFilter({
      ...filter,
      [name]: date,
    })
  }

  const onStatusChange = (status: ClassifierStatus[]) => {
    setFilter({
      ...filter,
      statuss: status,
    })
  }

  const onSecondaryFilterToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilter({
        ...filter,
        [event.target.name]: event.target.checked,
      })
    } else {
      setFilter({
        ...filter,
        [event.target.name]: event.target.checked,
        iesniegšanas_datums_no: null,
        iesniegšanas_datums_līdz: null,
        aktualizacijas_datums_no: null,
        aktualizacijas_datums_līdz: null,
        satur_anulējamas_apliecības: false,
        ir_neapstrādāti_dokumenti: false,
      })
    }
  }

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter({
      ...filter,
      [event.target.name]: checked,
    })
  }

  const resetFilterValues = () => {
    setFilter(DEFAULT_APPLICATION_LIST_FILTER)
  }

  const newApplication = () => {
    history.push(reverse(routes.applications.details, { applicationId: 'new' }))
  }

  return (
    <>
      <Grid container sx={{ paddingBottom: '20px' }}>
        <Grid item xs={9}>
          <ApplicationsFilter
            filter={filter}
            statusList={statusList}
            isLoading={isLoadingStatuses}
            onSelectChange={onFilterSelectChange}
            onTextFieldChange={onFilterTextChange}
            onSecondaryFilterToggle={onSecondaryFilterToggle}
            resetFilterValues={resetFilterValues}
            onApplicationDateFromChange={onApplicationDateFromChange}
            onApplicationDateToChange={onApplicationDateToChange}
            onModifiedDateFromChange={onModifiedDateFromChange}
            onModifiedDateToChange={onModifiedDateToChange}
            onStatusChange={onStatusChange}
            onCheckboxChange={onCheckboxChange}
          />
        </Grid>
        <Grid item xs={3} justifyContent="flex-end" alignItems="flex-end" container>
          <Button onClick={newApplication} variant="outlined" color="primary">
            Jauns iesniegums +
          </Button>
        </Grid>
      </Grid>
      <CustomizedMaterialTable
        columns={columns}
        data={applications}
        rowStyle={{
          height: 50,
        }}
        actions={actions}
        isLoading={isLoadingList}
        page={filter.offset && filter.limit ? filter?.offset / filter?.limit : 0}
        totalCount={applicationsCount}
        pageSize={filter.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={onRowClick}
      />
    </>
  )
}
