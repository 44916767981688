import { RequestErrors } from '../enums/RequestErrors'

function firstLetterUpperCase(string: string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export function handleErrorMessages(error: any) {
  let processedErrorMessage = 'Kļūda'

  if (error) {
    const errorMessage = error.response.data[0] ? error.response.data[0].kļūdas[0] : undefined
    const errorCode = error.response.status

    if (errorMessage) {
      const normalizedErrorMessage = firstLetterUpperCase(errorMessage)

      if (normalizedErrorMessage && normalizedErrorMessage in RequestErrors) {
        processedErrorMessage =
          'Kļūda: ' + RequestErrors[normalizedErrorMessage as keyof typeof RequestErrors]
      } else {
        processedErrorMessage = 'Kļūda: ' + normalizedErrorMessage
      }
    } else {
      processedErrorMessage = 'Kļūda: ' + errorCode
    }
  }

  return processedErrorMessage
}
