import {
  PASSWORD_LOWERCASE_CHARS,
  PASSWORD_NUMBER_CHARS,
  PASSWORD_SPECIAL_CHARS,
  PASSWORD_UPPERCASE_CHARS,
} from '../../constants/constants'

const getRandomElement = (arr: Array<any>) => {
  const rand = Math.floor(Math.random() * arr.length)
  return arr[rand]
}

export const generateRandomPassword = (length: number) => {
  const nonSpecialChars = [
    ...PASSWORD_UPPERCASE_CHARS,
    ...PASSWORD_LOWERCASE_CHARS,
    ...PASSWORD_NUMBER_CHARS,
  ]
  const specialChars = PASSWORD_SPECIAL_CHARS

  let password = ''

  for (let i = 0; i < length; i++) {
    if (i !== 0 && specialChars.includes(password[i - 1])) {
      password += getRandomElement(nonSpecialChars)
    } else password += getRandomElement([...nonSpecialChars, ...specialChars])
  }

  return password
}
