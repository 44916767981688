import React from 'react'
import { Box, Card, Chip, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { DEFAULT_DATE_FORMAT2 } from '../../../constants/constants'
import { Children } from '../../interfaces/Children'
import * as helpers from './helpers'
import ChildEducationStatus from '../../enums/ChildEducationStatus'
import ChildDisabilityStatus from '../../enums/ChildDisabilityStatus'
import { useQuery } from 'react-query'
import { getActiveApplicationCardByPersonId } from './ApplicationUtils'

const sx = {
  row: {
    mt: 1,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    mr: 1,
    fontWeight: 'bold',
  },
}

interface Props {
  childrenData: Children[]
  hasAgreedToHealthDataProcessing: boolean
}

// TODO: Check if children are sorted by birthdate (from youngest to oldest)
export default function ChildItems({ childrenData, hasAgreedToHealthDataProcessing }: Props) {
  const sortedChildren = helpers.sortChildrenByBirthdate(childrenData)
  return (
    <Grid container rowSpacing={4} columnSpacing={6}>
      {sortedChildren.map((child) => (
        <Grid item xs={6} key={child.persona_id}>
          <ChildItem
            child={child}
            hasAgreedToHealthDataProcessing={hasAgreedToHealthDataProcessing}
          />
        </Grid>
      ))}
    </Grid>
  )
}

function ChildItem({
  child,
  hasAgreedToHealthDataProcessing,
}: {
  child: Children
  hasAgreedToHealthDataProcessing: boolean
}) {
  const { data: certificateData } = useQuery(['active-certificate-data', child.persona_id], () =>
    getActiveApplicationCardByPersonId(child.persona_id)
  )

  return (
    <Card variant="outlined" sx={{ minHeight: 250, p: 2, boxShadow: '0px 2px 3px 0px #CCC' }}>
      <Typography fontSize={22} fontWeight="bold" color="#000">
        {child.vārds} {child.uzvārds} {child.personas_kods}
      </Typography>
      <Box sx={{ ...sx.row, mt: 3 }}>
        <Typography fontSize={18} sx={sx.label}>
          Dzimšanas datums: {format(new Date(child.dzimšanas_datums), DEFAULT_DATE_FORMAT2)} (
          {helpers.getAgeByDate(child.dzimšanas_datums)} gadi)
        </Typography>
      </Box>
      <Box sx={sx.row}>
        <Typography sx={sx.label}>Izglītība:</Typography>
        <EducationStatusRenderer childEducationStatus={helpers.getChildEducationStatus(child)} />
      </Box>
      <Box sx={sx.row}>
        <Typography sx={sx.label}>Invaliditāte:</Typography>
        <DisabledPersonStatusRenderer
          childDisabilityStatus={helpers.getChildDisabilityStatus(
            child,
            hasAgreedToHealthDataProcessing
          )}
        />
      </Box>
      <Box mt={2}>
        {certificateData && (
          <>
            <Box sx={{ ...sx.row, mt: 0 }}>
              <Typography sx={sx.label}>Digitālā apliecība:</Typography> {certificateData.numurs}
            </Box>
            <Box sx={{ ...sx.row, mt: 0 }}>
              <Typography sx={sx.label}>Plastikāta karte:</Typography>{' '}
              {certificateData.statuss_nosaukums}
            </Box>
            {certificateData.derīguma_termiņš && (
              <Box sx={{ ...sx.row, mt: 0 }}>
                <Typography sx={sx.label}>Derīguma termiņš:</Typography>{' '}
                {format(new Date(certificateData.derīguma_termiņš), DEFAULT_DATE_FORMAT2)}
              </Box>
            )}
          </>
        )}
      </Box>
    </Card>
  )
}

function DisabledPersonStatusRenderer({
  childDisabilityStatus,
}: {
  childDisabilityStatus: ChildDisabilityStatus
}) {
  const statusText = React.useMemo(() => {
    switch (childDisabilityStatus) {
      case ChildDisabilityStatus.Disabled:
        return 'Atbilst'
      case ChildDisabilityStatus.NotDisabled:
        return 'Neatbilst'
      case ChildDisabilityStatus.NotAllowed:
        return 'Nav atļauta'
      default:
        return 'Nav pārbaudīts'
    }
  }, [childDisabilityStatus])

  const statusColor = React.useMemo(() => {
    switch (childDisabilityStatus) {
      case ChildDisabilityStatus.Disabled:
        return 'success'
      default:
        return 'default'
    }
  }, [childDisabilityStatus])

  return <Chip sx={{ fontWeight: 'bold' }} label={statusText} color={statusColor} />
}

function EducationStatusRenderer({
  childEducationStatus,
}: {
  childEducationStatus: ChildEducationStatus
}) {
  const statusText = React.useMemo(() => {
    switch (childEducationStatus) {
      case ChildEducationStatus.Studying:
        return 'Atbilst'
      case ChildEducationStatus.NotStudying:
        return 'Neatbilst'
      default:
        return 'Nav pārbaudīts'
    }
  }, [childEducationStatus])

  const statusColor = React.useMemo(() => {
    switch (childEducationStatus) {
      case ChildEducationStatus.Studying:
        return 'success'
      case ChildEducationStatus.NotStudying:
        return 'error'
      default:
        return 'default'
    }
  }, [childEducationStatus])

  return <Chip sx={{ fontWeight: 'bold' }} label={statusText} color={statusColor} />
}
