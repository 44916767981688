import { useMediaQuery } from '@mui/material'
import { theme } from '../providers/ThemeProvider'

const breakPoints = {
  tablet: 860,
  smallMonitor: 1300
}

export const useViewport = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))
  const isSmallMonitor = useMediaQuery(theme.breakpoints.down(breakPoints.smallMonitor))

  return {
    isTablet,
    isSmallMonitor
  }
}
