import { FileListItem } from '../interfaces/File/FileListItem'
import { NewPartnerResponse } from '../interfaces/Partners/NewPartnerEntity'

export const mapPartnerAgreementFilesToFileListItems = (
  partnerEntry: NewPartnerResponse
): FileListItem[] => {
  return partnerEntry.līgums && partnerEntry.līgums.faili
    ? partnerEntry.līgums.faili.map((el) => {
        return {
          file: {
            ...el,
          },
          state: 'keep',
        }
      })
    : []
}
