export interface NewPartnerDiscount {
  id?: number
  skaits: number
  mērvienība_kods: string
  mērvienība_nosaukums?: string
  statuss_nosaukums?: string
  apraksts: string
  sadarbības_partneris_id?: number
  statuss_kods?: DiscountStatusCode
  komentārs?: string
  aizstājamā_atlaide_id?: number | null
}

export interface NewPartnerDiscountRequest extends NewPartnerDiscount {
  sadarbības_partneris_id: number
}

export type AggregatedNewPartnerDiscountRequest = Omit<
  NewPartnerDiscountRequest,
  'sadarbības_partneris_id'
>

export interface NewPartnerDiscountResponse extends NewPartnerDiscount {
  id: number
}

export enum DiscountStatusCode {
  Aizstāta = 'AAIZ',
  Aktīva = 'AAKT',
  Noraidīta = 'ANOR',
  Piedāvāta = 'APIE',
}
