import React, { SyntheticEvent, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { addPartnerLocation, editPartnerLocationData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import {
  NewPartnerLocation,
  NewPartnerLocationResponse,
} from '../../interfaces/Partners/NewPartnerLocation'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { FileListItem } from '../../interfaces/File/FileListItem'
import {
  ACCEPTED_PARTNER_FILE_TYPES,
  FILE_SIZE_LIMIT_MB_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { uploadFiles } from '../../utils/uploadFiles'
import AddressDropDownList from '../../elements/partners/AddressDropDownList'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import RequiredLabel from '../../elements/RequiredLabel'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

interface Props {
  location: NewPartnerLocationResponse | null
  partnerId: number
  onClose: () => void
}

export default function EditLocationModal({ partnerId, location, onClose }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const {
    adrese,
    code,
    geom,
    novads_atvk,
    novads_nosaukums,
    pagasts_atvk,
    pagasts_nosaukums,
    pilseta_atvk,
    pilseta_nosaukums,
    zip,
  } = location?.adrese || {}

  const defaultFormErrors = {
    adrese: '',
  }

  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
  const [formState, setFormState] = React.useState<NewPartnerLocation>({
    komentārs: location?.komentārs || '',
    adrese: {
      address: adrese || '',
      code: code,
      geom: geom,
      novAtvk: novads_atvk,
      novName: novads_nosaukums,
      pilAtvk: pilseta_atvk,
      pilName: pilseta_nosaukums,
      pagAtvk: pagasts_atvk,
      pagName: pagasts_nosaukums,
      zipCode: zip,
    },
    fails:
      location && location.faila_id
        ? [
            {
              file: {
                faila_id: location.faila_id,
                nosaukums: location.nosaukums,
                content_type: location.content_type,
                sha256: location.sha256,
              },
              state: 'keep',
            },
          ]
        : [],
    id: location?.id,
  })

  const [addPartnerLocationMutation] = useMutation(
    ({ adrese, faila_id }: NewPartnerLocation) =>
      addPartnerLocation({
        sadarbības_partneris_id: partnerId,
        komentārs: formState.komentārs,
        adrese: {
          geom: adrese.geom || null,
          zip: adrese.zipCode || '',
          novads_nosaukums: adrese.novName || '',
          novads_atvk: adrese.novAtvk || '',
          pagasts_nosaukums: adrese.pagName || '',
          pilseta_atvk: adrese.pilAtvk || '',
          code: adrese.code || 0,
          adrese: adrese.address || '',
          pilseta_nosaukums: adrese.pilName || '',
          pagasts_atvk: adrese.pagAtvk || '',
          vzd_adrese: '',
        },
        faila_id: faila_id,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const [editPartnerLocationMutation] = useMutation(
    ({ id, adrese, komentārs, faila_id }: NewPartnerLocation) =>
      editPartnerLocationData(
        {
          id: id,
          sadarbības_partneris_id: partnerId,
          komentārs: komentārs,
          adrese: {
            geom: adrese.geom || null,
            zip: adrese.zipCode || '',
            novads_nosaukums: adrese.novName || '',
            novads_atvk: adrese.novAtvk || '',
            pagasts_nosaukums: adrese.pagName || '',
            pilseta_atvk: adrese.pilAtvk || '',
            code: adrese.code || 0,
            adrese: adrese.address || '',
            pilseta_nosaukums: adrese.pilName || '',
            pagasts_atvk: adrese.pagAtvk || '',
            vzd_adrese: '',
          },
          faila_id: faila_id,
        },
        id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const handleLocationChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AddressResponse
  ) => {
    if (newValue) {
      setFormState({
        ...formState,
        adrese: newValue,
      })
    }
  }

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = target.value

    setFormState({
      ...formState,
      [target.name]: value,
    })
  }

  const handleFileChange = (files: FileListItem[]) => {
    setFormState({
      ...formState,
      fails: files,
    })
  }

  const onSubmit = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoadingSubmit(true)

    uploadFiles(formState.fails || [])
      .then(async (uploadResponse: Response[]) => {
        const faili = []

        for (const resp of uploadResponse) {
          const json = await resp.json()

          faili.push({
            faila_id: json.id,
          })
        }

        if (location) {
          editPartnerLocationMutation({
            ...formState,
            faila_id: faili[0]
              ? faili[0].faila_id
              : formState.fails && formState.fails[0]?.state !== 'delete'
              ? location?.faila_id
              : null,
          })
        } else {
          addPartnerLocationMutation({
            ...formState,
            faila_id: faili[0] ? faili[0].faila_id : null,
          })
        }
      })
      .catch((error) => {
        setIsLoadingSubmit(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      })
      .finally(() => setIsLoadingSubmit(false))
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!formState.adrese.address) {
      errors['adrese'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {location ? 'Labot' : 'Pievienot'} atrašanās vietu
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Adrese" />
              <AddressDropDownList
                value={formState.adrese}
                onValueChange={(event, newValue) => handleLocationChange(event, newValue)}
                helperText={formErrors.adrese}
                error={Boolean(formErrors.adrese)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Komentārs
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="komentārs"
                autoComplete="off"
                value={formState.komentārs}
                onChange={onFieldChange}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Attēls
              </Box>
              <FileUploadButton
                multiple={false}
                onFileChange={handleFileChange}
                files={formState.fails || []}
                acceptedUploadFileTypes={ACCEPTED_PARTNER_FILE_TYPES}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel} />
              <Typography
                sx={{
                  ml: 0.5,
                  color: '#999',
                  mt: '-10px !important',
                  maxWidth: '390px !important',
                }}
              >
                {FILE_SIZE_LIMIT_MB_MESSAGE}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
