import React, { useMemo, useState } from 'react'
import { List, ListItem } from '@mui/material'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { ApplicationDocuments } from '../../interfaces/Application/ApplicationDocuments'
import EditIcon from '@mui/icons-material/Edit'
import { ApplicationDocumentUpload } from './ApplicationDocumentUpload'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { FileLink } from '../../elements/FileLink'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../constants/constants'

const columns = [
  {
    field: 'pievienošanas_laiks',
    title: 'Iesniegšanas Datums',
    render: ({ pievienošanas_laiks }: ApplicationDocuments) => {
      if (!pievienošanas_laiks) {
        return undefined
      }

      return format(parseISO(pievienošanas_laiks), DEFAULT_DATETIME_FORMAT)
    },
  },
  {
    field: 'failu_saraksts',
    title: 'Faili',
    render: ({ faili }: ApplicationDocuments) => (
      <List>
        {faili.map((file) => (
          <ListItem key={file.faila_id} disableGutters>
            <FileLink file={file} />
          </ListItem>
        ))}
      </List>
    ),
  },
  {
    field: 'derīguma_termiņš',
    title: 'Derīgs līdz',
    render: ({ derīguma_termiņš }: ApplicationDocuments) => {
      if (!derīguma_termiņš) {
        return undefined
      }

      return format(parseISO(derīguma_termiņš), DEFAULT_DATE_FORMAT)
    },
  },
  {
    field: 'statuss_nosaukums',
    title: 'Statuss',
  },
  {
    field: 'komentārs',
    title: 'Piezīmes',
  },
]

interface Props {
  documents: ApplicationDocuments[]
  documentStatusList: ClassifierStatus[]
  applicationEntryId: number
  type: string
  register_check_id: number
  reload: () => void
}

export const ApplicationRegistersDocumentList = ({
  documents,
  documentStatusList,
  applicationEntryId,
  type,
  register_check_id,
  reload,
}: Props) => {
  const [activeDocument, setActiveDocument] = useState<ApplicationDocuments>()

  const openDocumentDialog = (doc: ApplicationDocuments) => {
    setActiveDocument(doc)
  }

  const closeDocumentDialog = () => {
    setActiveDocument(undefined)
  }

  const actions = useMemo(() => {
    return [
      {
        icon: () => <EditIcon color="info" />,
        onClick: (event: React.MouseEvent, doc: ApplicationDocuments) => openDocumentDialog(doc),
        tooltip: 'Apstrādāt',
      },
    ]
  }, [])

  return (
    <>
      <CustomizedMaterialTable
        columns={columns}
        data={documents}
        actions={actions}
        options={{
          paging: false,
        }}
        rowStyle={{
          height: 50,
        }}
      />
      {activeDocument && (
        <ApplicationDocumentUpload
          open={!!activeDocument}
          onClose={closeDocumentDialog}
          tips_kods={type}
          applicationDocument={activeDocument}
          iesniegums_id={applicationEntryId}
          personas_pārbaude_id={register_check_id}
          documentStatusList={documentStatusList}
          onNewDocument={reload}
        />
      )}
    </>
  )
}
