import React, { useState } from 'react'
import { Box, List, ListItem } from '@mui/material'
import {
  ApplicationEntry,
  ApplicationEntryDocument,
} from '../../interfaces/Application/ApplicationEntry'
import { FileLink } from '../../elements/FileLink'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { FileEntry } from '../../interfaces/File/FileEntry'
import { uploadFiles } from '../../utils/uploadFiles'
import { useMutation } from 'react-query'
import { addInquiryDocument } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { Loader } from '../../elements/Loader'
import { FILE_SIZE_LIMIT_MB_MESSAGE } from '../../../constants/constants'

const sx = {
  formRow: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
  },
}

interface Props {
  applicationEntry: ApplicationEntry
  reload: () => void
}

export function ApplicationDocuments({ applicationEntry, reload }: Props) {
  const { dokuments, id } = applicationEntry
  const { showSnackBar } = useSnackBar()

  const [files, setFiles] = useState<FileListItem[]>([])
  const [isLoading, setIsLoading] = useState<Boolean>(false)

  const [addInquiryDocumentsMutation] = useMutation(
    (document: ApplicationEntryDocument) => addInquiryDocument(document),
    {
      onSuccess: (resp) => {
        setIsLoading(false)
        reload()
        showSnackBar()
      },
      onError: (error: any) => {
        setIsLoading(false)
        setFiles([])
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onFileChange = (files: FileListItem[]) => {
    setFiles(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (response: Response[]) => {
        const faili: FileEntry[] = []

        for (const resp of response) {
          const fileMeta = await resp.json()

          faili.push({
            faila_id: fileMeta.id,
          })
        }

        const dokuments = {
          iesniegums_id: id,
          komentārs: '',
          nosaukums: '',
          termiņš: null,
          faili: faili,
        } as ApplicationEntryDocument

        addInquiryDocumentsMutation(dokuments)
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFiles([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Box sx={{ fontWeight: '500' }}>Iesniegumam pievienotie dokumenti:</Box>

      {dokuments && dokuments.length > 0 && (
        <Box sx={sx.formRow}>
          <List>
            {dokuments.flatMap((dok) =>
              dok.faili.map((file) => (
                <ListItem key={file.faila_id} disableGutters>
                  <FileLink file={file} />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      )}

      <Box>
        <FileUploadButton useLink onFileChange={onFileChange} files={files} />
        <Loader open={isLoading} />
      </Box>
    </>
  )
}
