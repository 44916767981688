export function selectedDateExceedsMinDate(date: Date | null, minimumDate: Date | null) {
  const minDate = minimumDate?.setHours(0, 0, 0, 0)
  const selectedDate = date?.getTime()

  if (minDate && selectedDate && selectedDate < minDate) {
    return null
  } else {
    return date
  }
}
