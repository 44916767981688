import { Box, Theme, useTheme } from '@mui/material'

export function RequiredMarker({ disabled = false }) {
  const theme = useTheme() as Theme

  if (disabled) {
    return <></>
  }

  return (
    <Box component="span" sx={{ color: theme.palette.primary.main }}>
      *
    </Box>
  )
}
