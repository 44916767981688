import React, { SyntheticEvent } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import AddressDropDownList from '../../elements/partners/AddressDropDownList'

const sx = {
  inputLabel: {
    minWidth: '150px',
  },
}

interface Props {
  childrenNames: ApplicationCard[]
  isCardOwnerChild: boolean
  plasticCardCheck: boolean
  showPlasticCardCheckbox?: boolean
  customAddressCheck: boolean
  defaultAddressCheck: boolean
  childrenCheck: { [key: string]: boolean }
  customAddress: AddressResponse
  onPlasticCardCheckChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCustomAddressCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDefaultAddressCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChildCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCustomAddressChange: (event: SyntheticEvent<Element, Event>, newValue: AddressResponse) => void
  helperText?: string
  error?: boolean | string
}

export const RequestNewCardContent = ({
  childrenNames,
  isCardOwnerChild,
  showPlasticCardCheckbox = true,
  plasticCardCheck,
  customAddressCheck,
  defaultAddressCheck,
  childrenCheck,
  customAddress,
  helperText,
  error,
  onPlasticCardCheckChange,
  onCustomAddressCheckChange,
  onDefaultAddressCheckChange,
  onChildCheckChange,
  onCustomAddressChange,
}: Props) => {
  return (
    <>
      <Box>
        {showPlasticCardCheckbox && (
          <>
            <Typography variant="subtitle2" display="block">
              Personai tiks uzģenerēta jauna digitāla apliecība
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={plasticCardCheck}
                  color="primary"
                  onChange={onPlasticCardCheckChange}
                />
              }
              label=""
            />
            <Box component="span" sx={sx.inputLabel}>
              Nepieciešama plastikāta apliecība
            </Box>
          </>
        )}
        {plasticCardCheck && (
          <>
            {!isCardOwnerChild && (
              <>
                <Typography variant="subtitle2" sx={{ paddingTop: '20px' }}>
                  Lūdzu, atzīmējiet, kurām personām nepieciešams izgatavot plastikāta apliecības:
                </Typography>

                {childrenNames.map((child, index) => (
                  <Box key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            childrenCheck[
                              `${child.persona_id}_${child.vārds}_${child.uzvārds}`.replace(
                                / /g,
                                ''
                              )
                            ]
                          }
                          color="primary"
                          onChange={onChildCheckChange}
                          name={`${child.persona_id}_${child.vārds}_${child.uzvārds}`.replace(
                            / /g,
                            ''
                          )}
                        />
                      }
                      label=""
                    />
                    <Box component="span" sx={sx.inputLabel}>
                      {child.vārds} {child.uzvārds}
                    </Box>
                  </Box>
                ))}
              </>
            )}

            <Typography variant="subtitle2" sx={{ paddingTop: '20px' }}>
              Saņemšanas vieta
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={defaultAddressCheck}
                  checked={customAddressCheck}
                  color="primary"
                  onChange={onCustomAddressCheckChange}
                />
              }
              label=""
            />
            <Box component="span" sx={sx.inputLabel}>
              Uz norādīto adresi
            </Box>

            <AddressDropDownList
              value={customAddress}
              onValueChange={(event, newValue) => onCustomAddressChange(event, newValue)}
              helperText={helperText}
              error={Boolean(error)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  disabled={customAddressCheck}
                  checked={defaultAddressCheck}
                  color="primary"
                  onChange={onDefaultAddressCheckChange}
                />
              }
              label=""
            />
            <Box component="span" sx={sx.inputLabel}>
              Sabiedrības integrācijas fonda birojā
            </Box>
          </>
        )}
      </Box>
    </>
  )
}
