import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { reverse } from 'named-urls'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { fetchPartnerData, fetchPartnerStatus } from '../../../api/API'
import { INFO_REQUESTS_PAGE_TAB, PAGE_NOT_FOUND } from '../../../constants/constants'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { NewPartnerResponse } from '../../interfaces/Partners/NewPartnerEntity'
import { NewPartnerStatus } from '../../interfaces/Partners/NewPartnerStatus'
import routes from '../../routes/routes'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import { ApplicationTabPanel, applicationTabA11yProps } from '../Application/ApplicationTabPanel'
import { ExistingPartner } from './ExistingPartner'
import { ExistingPartnerEdit } from './ExistingPartnerEdit'
import NewPartner from './NewPartner'
import { PartnerBackButton } from './PartnerBackButton'
import { PartnerComplaintsTabContent } from './PartnerComplaintsTabContent'
import { PartnerContactPersonTabContent } from './PartnerContactPersonTabContent'
import { PartnerDiscountTabContent } from './PartnerDiscountTabContent'
import { PartnerTasksTabContent } from './PartnerTasksTabContent'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import useRouterQuery from '../../hooks/useRouterQuery'
import { PartnerInfoRequestContent } from './PartnerInfoRequestContent'
import { PartnerLastActionsTabContent } from './PartnerLastActionsTabContent'
import { PartnerLocationTabContent } from './PartnerLocationTabContent'
import { PartnerPromotionsTabContent } from './PartnerPromotionsTabContent'
import { PartnerRatingTabContent } from './PartnerRatingTabContent'
import { PartnerServicesTabContent } from './PartnerServiceTabContnet'

const sx = {
  partner: {
    pl: 4,
    borderLeft: '1px solid #999',
    fontWeight: 700,
    color: '#999',
    fontSize: '1.2rem',
  },
}

const applicationTabNames = [
  'Pamatdati',
  'Lietotāji',
  'Atrašanās vietas',
  'Atlaides',
  'Akcijas',
  'Atsauksmes',
  'Sūdzības',
  'Informācijas pieprasījumi',
  'Uzdevumi',
  'Servisi',
  'Darbību vēsture',
]

const partnerTypesQueryParams = {
  klasifikatora_kods: 'PARTNERA_VEIDS',
}

const partnerGroupQueryParams = {
  klasifikatora_kods: 'PAKALPOJUMU_GRUPA',
}

const partnerStatusQueryParams = {
  klasifikatora_kods: 'PARTNERA_STATUSS',
}

const partnerTagQueryParams = {
  klasifikatora_kods: 'BIRKA',
}

const contractDiscontinueQueryParams = {
  klasifikatora_kods: 'PĀRTRAUKŠANAS_IEMESLS',
}

export default function Partner() {
  const history = useHistory()
  const { partnerId } = useParams<{ partnerId: string }>()
  const query = useRouterQuery()
  const currentTab = query.get('tab')
  const tab: number = currentTab === null ? 0 : parseInt(currentTab)

  const [isEditMode, setIsEditMode] = React.useState(false)
  const [activeTab, setActiveTab] = useState(tab)
  const [isNewPartner, setIsNewPartner] = useState<boolean>(true)
  const [doneInit, setDoneInit] = useState(false)
  const [partnerEntry, setPartnerEntry] = useState<null | NewPartnerResponse>(null)
  const [partnerStatus, setPartnerStatus] = useState<null | NewPartnerStatus>(null)
  const [partnerStatusList, setPartnerStatusList] = useState<ClassifierStatus[]>([])
  const [contractDiscontinueCodes, setContractDiscontinueCodes] = useState<ClassifierStatus[]>([])
  const [partnerTagsList, setPartnerTagsList] = useState<ClassifierStatus[]>([])
  const [partnerTypeList, setPartnerTypeList] = React.useState<ClassifierStatus[]>([])
  const [partnerGroupList, setPartnerGroupList] = useState<ClassifierStatus[]>([])

  useEffect(() => {
    const isNew = partnerId === 'new'

    setIsNewPartner(isNew)

    if (isNew) {
      setDoneInit(true)
    }
  }, [partnerId])

  const { isLoading: isLoadingPartnerTypes } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_types',
    queryParameters: partnerTypesQueryParams,
    querySuccess: (resp) => {
      setPartnerTypeList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerGroups } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_groups',
    queryParameters: partnerGroupQueryParams,
    querySuccess: (resp) => {
      setPartnerGroupList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerStatus } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_status',
    queryParameters: partnerStatusQueryParams,
    querySuccess: (resp) => {
      setPartnerStatusList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerTags } = useFetchClassifiers({
    queryKey: 'classifier_tags',
    queryParameters: partnerTagQueryParams,
    querySuccess: (resp) => {
      setPartnerTagsList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingContractDiscontinueCodes } = useFetchClassifiers({
    queryKey: 'classifier_records',
    queryParameters: contractDiscontinueQueryParams,
    querySuccess: (resp) => {
      setContractDiscontinueCodes(convertToClassifierStatus(resp))
    },
  })

  const { refetch, error } = useQuery(
    ['partner', partnerId],
    () => fetchPartnerData(parseInt(partnerId)),
    {
      enabled: !isNewPartner,
      refetchOnWindowFocus: false,
      retry: (_, errorResponse: AxiosError) => {
        const is404Error = errorResponse?.response?.status === 404
        return !is404Error
      },
      onSuccess: (resp: NewPartnerResponse) => {
        setPartnerEntry(resp)
      },
    }
  )

  const { refetch: refetchStatus } = useQuery(
    ['partnerStatus', partnerId],
    () => fetchPartnerStatus(parseInt(partnerId)),
    {
      enabled: !isNewPartner,
      refetchOnWindowFocus: false,
      retry: (_, errorResponse: AxiosError) => {
        const is404Error = errorResponse?.response?.status === 404
        return !is404Error
      },
      onSuccess: (resp: NewPartnerStatus) => {
        setPartnerStatus(resp)
        setDoneInit(true)
      },
    }
  )

  const onNewPartner = (partnerId: number) => {
    setDoneInit(false)
    history.push(reverse(routes.partners.details, { partnerId }))
    setIsNewPartner(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
    setIsEditMode(false)
  }

  const handleEditMode = () => {
    setIsEditMode(!isEditMode)
  }

  const onSuccess = () => {
    setIsEditMode(false)
    onRefetch()
  }

  const onRefetch = () => {
    refetch()
    refetchStatus()
  }

  if (error?.response?.status === 404) return <h3>{PAGE_NOT_FOUND}</h3>

  return (
    <>
      {doneInit &&
      !isLoadingPartnerTypes &&
      !isLoadingPartnerGroups &&
      !isLoadingPartnerStatus &&
      !isLoadingPartnerTags &&
      !isLoadingContractDiscontinueCodes ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h1" sx={{ mr: 4 }}>
              {isNewPartner ? 'Partnera datu ievade' : 'Aktuālā partnera apskate'}
            </Typography>
            {partnerEntry && <Typography sx={sx.partner}>{`${partnerEntry.nosaukums}`}</Typography>}
          </Box>

          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
                sx={{ mt: 2 }}
                aria-label="Iesnieguma sadaļas"
              >
                {applicationTabNames.map((tabName, index) => {
                  return (
                    <Tab
                      label={tabName}
                      key={index}
                      disabled={isNewPartner && index > 0}
                      onClick={() => history.push(`/partners/${partnerId}?tab=${index}`)}
                      {...applicationTabA11yProps(index)}
                    />
                  )
                })}
              </Tabs>
            </Box>
            <ApplicationTabPanel value={activeTab} index={0}>
              {partnerEntry ? (
                !isEditMode ? (
                  <ExistingPartner
                    onRefetch={onRefetch}
                    partnerEntry={partnerEntry}
                    partnerStatus={partnerStatus}
                  />
                ) : (
                  <ExistingPartnerEdit
                    onSuccess={onSuccess}
                    partnerId={parseInt(partnerId)}
                    partnerEntry={partnerEntry}
                    partnerTagsList={partnerTagsList}
                    partnerStatusList={partnerStatusList}
                    contractDiscontinueCodes={contractDiscontinueCodes}
                    partnerTypeList={partnerTypeList}
                    partnerGroupList={partnerGroupList}
                  />
                )
              ) : (
                <NewPartner
                  onNewPartner={onNewPartner}
                  partnerTypeList={partnerTypeList}
                  partnerGroupList={partnerGroupList}
                  partnerTagsList={partnerTagsList}
                />
              )}
              {partnerEntry && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 2 }}>
                  <Button variant="outlined" color="primary" onClick={handleEditMode}>
                    {isEditMode ? 'Atcelt' : 'Rediģēt'}
                  </Button>
                </Box>
              )}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>

            <ApplicationTabPanel value={activeTab} index={1}>
              {partnerEntry && (
                <PartnerContactPersonTabContent partnerId={partnerEntry.id.toString()} />
              )}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>

            <ApplicationTabPanel value={activeTab} index={2}>
              {partnerEntry && <PartnerLocationTabContent partnerId={partnerEntry.id.toString()} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>

            <ApplicationTabPanel value={activeTab} index={3}>
              {partnerEntry && <PartnerDiscountTabContent partnerId={partnerEntry.id.toString()} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={4}>
              {partnerEntry && <PartnerPromotionsTabContent partnerId={partnerEntry.id} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={5}>
              {partnerEntry && <PartnerRatingTabContent partnerId={partnerEntry.id} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={6}>
              {partnerEntry && (
                <PartnerComplaintsTabContent
                  partnerId={partnerEntry.id}
                  setActiveTab={setActiveTab}
                />
              )}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={INFO_REQUESTS_PAGE_TAB}>
              {partnerEntry && <PartnerInfoRequestContent partnerId={partnerEntry.id} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={8}>
              {partnerEntry && <PartnerTasksTabContent partnerId={partnerEntry.id} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={9}>
              {partnerEntry && <PartnerServicesTabContent partnerId={partnerEntry.id} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
            <ApplicationTabPanel value={activeTab} index={10}>
              {partnerEntry && <PartnerLastActionsTabContent partnerId={partnerEntry.id} />}
              <PartnerBackButton isNewPartner={isNewPartner} />
            </ApplicationTabPanel>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <DelayedLoader />
        </Box>
      )}
    </>
  )
}
