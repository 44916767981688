import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Box } from '@mui/material'
import { hasUserRole } from '../utils/role'
import { fetchCurrentUser } from '../../api/API'
import { Loader } from '../elements/Loader'
import { UserRoles } from '../enums/UserRoles'
import Navigation from '../elements/Navigation'
import Classifiers from './Classifiers/Classifiers'
import History from './History/History'
import Users from './Users/Users'
import Applications from './Applications/Applications'
import Application from './Application/Application'
import Partners from './Partners/Partners'
import Partner from './Partner/Partner'
import Certificates from './Certificates/Certificates'
import routes from '../routes/routes'
import Settings from './Settings/Settings'
import { useCurrentUser } from '../../store/user'
// import useWebsocketConnection from '../hooks/Websocket/useWebsocketConnection'
import Info from './Info'
import Reports from './Reports'
import MassMail from './MassMail'
import HistoryItem from './History/HistoryItem'

export default function Main() {
  const { currentUser, setCurrentUser } = useCurrentUser()

  const [isLoading, setIsLoading] = React.useState(false)

  const { Superadmin, Admin, Program_coordinator, Partner_coordinator } = UserRoles

  React.useEffect(() => {
    setIsLoading(true)
    fetchCurrentUser()
      .then((data) => {
        setCurrentUser(data)
        setIsLoading(false)
      })
      .catch(() => {
        setCurrentUser(null)
        setIsLoading(false)
      })
  }, [setCurrentUser])

  const getRole = (component: JSX.Element, roles: string[]) => {
    if (currentUser && !hasUserRole(currentUser?.lomas, roles)) {
      return <Redirect push to="/login" />
    }
    return component
  }

  // useWebsocketConnection()

  return (
    <Box>
      <Navigation user={currentUser} />
      <main style={{ padding: '0 40px' }}>
        <Switch>
          <Route path={routes.info} component={Info} />
          <Route path={routes.report} component={Reports} />
          <Route
            path={routes.classifiers.root}
            render={() => getRole(<Classifiers />, [Superadmin, Admin])}
          />
          <Route path={routes.users} render={() => getRole(<Users />, [Superadmin, Admin])} />
          <Route path={routes.settings} render={() => getRole(<Settings />, [Superadmin, Admin])} />
          <Route
            path={routes.history.details}
            render={() => getRole(<HistoryItem />, [Superadmin, Admin])}
          />
          <Route
            path={routes.history.list}
            render={() => getRole(<History />, [Superadmin, Admin])}
          />
          <Route
            path={routes.applications.details}
            render={() => getRole(<Application />, [Program_coordinator])}
          />
          <Route
            path={routes.applications.list}
            render={() => getRole(<Applications />, [Program_coordinator])}
          />
          <Route
            path={routes.partners.details}
            render={() => getRole(<Partner />, [Partner_coordinator])}
          />
          <Route
            path={routes.partners.list}
            render={() => getRole(<Partners />, [Partner_coordinator])}
          />
          <Route
            path={routes.certificates}
            render={() => getRole(<Certificates />, [Program_coordinator])}
          />
          <Route path={routes.mail} render={() => getRole(<MassMail />, [Program_coordinator])} />
          <Redirect to={routes.info} />
        </Switch>
      </main>
      <Loader open={isLoading} />
    </Box>
  )
}
