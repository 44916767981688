import React, { useState, useEffect } from 'react'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import CancelIcon from '@mui/icons-material/Cancel'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import GroupsIcon from '@mui/icons-material/Groups'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { useQuery } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { Alert, Box, Typography } from '@mui/material'
import RequestNewCardModal from './RequestNewCardModal'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { getApplication, getApplicationChildren, getApplicationIssuingData } from '../../../api/API'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import ChangeCardStatusModal from './ChangeCardStatusModal'
import BlockCardModal from './BlockCardModal'
import CardActivationCodeModal from './CardActivationCodeModal'
import { ApplicationIssuingData } from '../../interfaces/Application/ApplicationIssuingData'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import * as helpers from './helpers'
import SelectChildrenForParentCardModal from './SelectChildrenForParentCardModal'
import { getApplicationCardsByInquiryId, getApplicationCardsByPersonId } from './ApplicationUtils'

const applicationStatusesQueryParams = {
  klasifikatora_kods: 'APLIECĪBAS_STATUSS',
}

const columns = [
  {
    // custom field created in helpers.reshapeApplicationCardData function for grouping purpose
    field: 'id_full_name',
    title: 'Vārds, uzvārds',
    defaultGroupOrder: 0,
    // a workaround to remove sorting by default for group column
    customSort: () => 0,
    minWidth: 100,
    render: (id_full_name: String) => {
      return <>{id_full_name.replace(/[0-9]/g, '')}</>
    },
  },
  {
    field: 'numurs',
    title: 'Apliecības numurs',
  },
  {
    field: 'derīguma_termiņš',
    title: 'Derīguma termiņš',
    render: ({ derīguma_termiņš }: ApplicationCard) =>
      format(parseISO(derīguma_termiņš), DEFAULT_DATE_FORMAT),
  },
  {
    field: 'tips_nosaukums',
    title: 'Apliecības tips',
  },
  {
    field: 'statuss_nosaukums',
    title: 'Plastikāta apliecība',
  },
  {
    field: 'komentārs',
    title: 'Komentārs',
  },
  {
    field: 'anulēšanas_datums',
    title: 'Anulēta',
    render: ({ anulēšanas_datums }: ApplicationCard) => {
      if (anulēšanas_datums) {
        return <>{format(parseISO(anulēšanas_datums), DEFAULT_DATE_FORMAT)}</>
      }

      return <></>
    },
  },
]

interface Props {
  filterCheck: boolean
  applicationEntry: ApplicationEntry | null
}

interface childrenOnCardProps {
  id: number
  bērni: { vārds: string; uzvārds: string }[]
}

// TODO: API does not return data, need clarification
// TODO: Application interfaces probably could be moved into its own folder
// TODO: what to show when there are no cards?

export const ApplicationCards = ({ filterCheck, applicationEntry }: Props) => {
  const { showSnackBar } = useSnackBar()
  const { applicationId } = useParams<{ applicationId: string }>()

  const [applicationCards, setApplicationCards] = useState<ApplicationCard[]>()
  const [filteredApplicationCards, setFilteredApplicationCards] = useState<ApplicationCard[]>()
  const [statusList, setStatusList] = useState<ClassifierStatus[]>([])
  const [applicationIssuingData, setApplicationIssuingData] =
    useState<ApplicationIssuingData | null>(null)
  const [childrenOnCardData, setChildrenOnCardData] = useState<childrenOnCardProps>()
  const [openModalSelectChildrenForParentCard, setOpenModalSelectChildrenForParentCard] =
    useState(false)
  const [openModalAddEdit, setOpenModalAddEdit] = useState(false)
  const [openModalChangeStatus, setOpenModalChangeStatus] = useState(false)
  const [openModalBlockCard, setOpenModalBlockCard] = useState(false)
  const [openModalActivationCode, setOpenModalActivationCode] = useState(false)
  const [selectedCard, setSelectedCard] = useState<ApplicationCard | null>(null)
  const [parentCard, setParentCard] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)

  const parentAndChildIds = applicationEntry
    ? [
        ...applicationEntry.aizbilstamie.map((child) => child.persona_id),
        applicationEntry.persona_id,
      ]
    : []

  const fetchChildCertificates = () => {
    setIsLoading(true)

    Promise.all([
      ...parentAndChildIds.map((personId) => getApplicationCardsByPersonId(personId)),
      getApplicationCardsByInquiryId(applicationId),
    ])
      .then((resp) => {
        const cards = resp.flatMap((card) => card)
        const cardIds = cards.map((card) => card.id)

        Promise.all(cardIds.map((cardId) => getApplication(cardId)))
          .then((cards) => {
            const cardIds = cards.map((card) => card.id)
            const uniqueCards = cards.filter(({ id }, index) => !cardIds.includes(id, index + 1))

            const reshapedCardData = helpers.reshapeApplicationCardData(uniqueCards)
            const foundParentCard = reshapedCardData.find((card) => card.tips_kods === 'VC')?.id

            setApplicationCards(reshapedCardData)
            setParentCard(foundParentCard)
          })
          .catch((error) =>
            showSnackBar({
              severity: 'error',
              text: handleErrorMessages(error),
            })
          )
      })
      .catch((error) =>
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      )
      .finally(() => setIsLoading(false))
  }

  const { isLoading: isLoadingStatuses } = useFetchClassifiers({
    queryKey: 'classifier_records',
    queryParameters: applicationStatusesQueryParams,
    querySuccess: (resp) => {
      setStatusList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingApplicationIssuingData, refetch: refetchApplicationIssuingData } =
    useQuery(['application_issuing_data'], () => getApplicationIssuingData(applicationEntry!.id), {
      enabled: !!applicationEntry,
      refetchOnWindowFocus: false,
      onSuccess: (resp: any) => {
        setApplicationIssuingData(resp)
      },
    })

  const { isLoading: isLoadingChildrenOnCardData, refetch: refetchChildrenOnCardData } = useQuery(
    ['children_on_card_data'],
    () => getApplicationChildren(parentCard),
    {
      enabled: !!parentCard,
      refetchOnWindowFocus: false,
      onSuccess: (resp: any) => {
        setChildrenOnCardData(resp)
      },
    }
  )

  useEffect(() => {
    fetchChildCertificates()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilteredApplicationCards(
      !filterCheck
        ? applicationCards?.filter((card) => helpers.isApplicationCardValid(card))
        : applicationCards
    )
  }, [filterCheck, applicationCards])

  const onNewCard = (event: React.MouseEvent, card: ApplicationCard) => {
    setSelectedCard(card)
    setOpenModalAddEdit(true)
  }

  const onChangeStatus = (event: React.MouseEvent, card: ApplicationCard) => {
    setSelectedCard(card)
    setOpenModalChangeStatus(true)
  }

  const onSelectChildren = (event: React.MouseEvent, card: ApplicationCard) => {
    setSelectedCard(card)
    setOpenModalSelectChildrenForParentCard(true)
  }

  const closeRequestNewCardModal = () => {
    setOpenModalAddEdit(false)
  }

  const closeChangeStatusModal = () => {
    setOpenModalChangeStatus(false)
  }

  const openSelectChildrenModal = () => {
    setOpenModalSelectChildrenForParentCard(true)
  }

  const closeSelectChildrenModal = () => {
    setOpenModalSelectChildrenForParentCard(false)
  }

  const onBlockCard = (event: React.MouseEvent, card: ApplicationCard) => {
    setSelectedCard(card)
    setOpenModalBlockCard(true)
  }

  const onGetActivationCode = (event: React.MouseEvent, card: ApplicationCard) => {
    setSelectedCard(card)
    setOpenModalActivationCode(true)
  }

  const closeModal = () => {
    setSelectedCard(null)
    setOpenModalAddEdit(false)
    setOpenModalChangeStatus(false)
    setOpenModalBlockCard(false)
    setOpenModalActivationCode(false)
    closeSelectChildrenModal()
    fetchChildCertificates()
    refetchApplicationIssuingData()
    refetchChildrenOnCardData()
  }

  const actions = [
    (rowData: ApplicationCard) => {
      const isBlocked = Boolean(rowData.anulēšanas_datums)

      return {
        hidden: isBlocked,
        icon: () => <CancelIcon fontSize="small" color="error" />,
        onClick: onBlockCard,
        tooltip: 'Anulēt',
      }
    },
    (rowData: ApplicationCard) => {
      const isParent = Boolean(rowData.tips_kods === 'VC')

      return {
        hidden: isParent,
        icon: () => <VpnKeyIcon fontSize="small" color="info" />,
        onClick: onGetActivationCode,
        tooltip: 'Iegūt aktivizācijas kodu',
      }
    },
    (rowData: ApplicationCard) => {
      const isRevoked = Boolean(rowData.anulēšanas_datums)

      return {
        hidden: isRevoked,
        icon: () => <ChangeCircleIcon fontSize="small" color="info" />,
        onClick: onChangeStatus,
        tooltip: 'Statusa maiņa',
      }
    },
    (rowData: ApplicationCard) => {
      const isParent = Boolean(rowData.tips_kods === 'VC')

      return {
        hidden: !isParent,
        icon: () => <InfoOutlinedIcon fontSize="small" />,
        tooltip: (
          <div>
            Uz vecāku apliecības tiks attēloti šie bērni:{' '}
            {childrenOnCardData?.bērni.map((child) => (
              <div>
                {child.vārds} {child.uzvārds}
              </div>
            ))}
          </div>
        ),
      }
    },
    (rowData: ApplicationCard) => {
      const isRevoked = Boolean(rowData.anulēšanas_datums)
      const isCardExpirationDateValid = new Date(rowData.derīguma_termiņš) > new Date()

      return {
        hidden: !isRevoked && isCardExpirationDateValid,
        icon: () => <SettingsBackupRestoreIcon fontSize="small" color="success" />,
        onClick: onNewCard,
        tooltip: 'Atjaunot',
      }
    },
    (rowData: ApplicationCard) => {
      const isParent = Boolean(rowData.tips_kods === 'VC')

      return {
        hidden: !isParent,
        icon: () => <GroupsIcon fontSize="small" color="info" />,
        onClick: onSelectChildren,
        tooltip: 'Norādīt uz plastikāta kartes drukājamos bērnus',
      }
    },
  ]

  return (
    <>
      {openModalAddEdit && (
        <RequestNewCardModal
          card={selectedCard}
          child={applicationEntry?.aizbilstamie.find(
            (child) => child.persona_id === selectedCard?.persona_id
          )}
          applicationCards={applicationCards || []}
          applicationEntry={applicationEntry}
          closeRequestNewCardModal={closeRequestNewCardModal}
          openSelectChildrenModal={openSelectChildrenModal}
          onClose={closeModal}
          refetch={fetchChildCertificates}
        />
      )}
      {openModalChangeStatus && (
        <ChangeCardStatusModal
          card={selectedCard}
          applicationCards={applicationCards || []}
          applicationEntry={applicationEntry}
          applicationIssuingData={applicationIssuingData}
          statusList={statusList}
          isLoadingStatuses={isLoadingStatuses}
          isLoadingApplicationIssuingData={isLoadingApplicationIssuingData}
          onClose={closeModal}
          closeChangeStatusModal={closeChangeStatusModal}
          openSelectChildrenModal={openSelectChildrenModal}
        />
      )}
      {openModalSelectChildrenForParentCard && (
        <SelectChildrenForParentCardModal
          card={selectedCard}
          applicationCards={applicationCards || []}
          applicationEntry={applicationEntry}
          onClose={closeModal}
        />
      )}
      {openModalBlockCard && (
        <BlockCardModal card={selectedCard} onClose={closeModal} refetch={fetchChildCertificates} />
      )}
      {openModalActivationCode && (
        <CardActivationCodeModal card={selectedCard} onClose={closeModal} />
      )}

      {isLoading || isLoadingChildrenOnCardData ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
          <DelayedLoader />
        </Box>
      ) : (
        <>
          {filteredApplicationCards == null ? (
            <></>
          ) : filteredApplicationCards.length > 0 ? (
            <>
              <CustomizedMaterialTable
                columns={columns}
                data={filteredApplicationCards}
                actions={actions}
                options={{
                  defaultExpanded: true,
                  paging: false,
                }}
                rowStyle={(card: ApplicationCard) => ({
                  height: 50,
                  color: helpers.isApplicationCardValid(card) ? '#000' : '#AAA',
                })}
              />
              {applicationIssuingData &&
                (applicationIssuingData.adrese || applicationIssuingData.izsniegt_birojā) && (
                  <Box mt={5}>
                    <Typography variant="subtitle2">Saņemšanas vieta:</Typography>
                    <Box>
                      {helpers.getFullAddress(applicationIssuingData.adrese) ||
                        (applicationIssuingData.izsniegt_birojā ? 'Izsniegt birojā' : '')}
                    </Box>
                  </Box>
                )}
            </>
          ) : (
            <Alert severity="info">Nav neviena aktīva Goda Ģimenes apliecība</Alert>
          )}
        </>
      )}
    </>
  )
}
