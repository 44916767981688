import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'

import { useSnackBar } from '../../providers/SnackBarProvider'
import { Complaint, ComplaintEditRequest } from '../../interfaces/Complaints'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import { editComplaintData } from '../../../api/API'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import RequiredLabel from '../../elements/RequiredLabel'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const complaintDiscountQueryParams = {
  klasifikatora_kods: 'SŪDZĪBAS_STATUSS',
}

interface Props {
  complaint: Complaint
  onClose: () => void
  onSuccess: () => void
}

export default function EditComplaintStatusModal({ complaint, onClose, onSuccess }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isStatusInvalid, setIsStatusInvalid] = useState<boolean>(false)
  const [selectedStatus, setSelectedStatus] = useState<string>(
    complaint.statuss_kods === 'JAUNA' ? '' : complaint.statuss_kods
  )
  const [contractDiscontinueCodes, setContractDiscontinueCodes] = useState<ClassifierStatus[]>([])

  useFetchClassifiers({
    queryKey: 'classifier_records',
    queryParameters: complaintDiscountQueryParams,
    querySuccess: (resp) => {
      setContractDiscontinueCodes(
        convertToClassifierStatus(resp).filter(
          (complaintStatus) => complaintStatus.kods !== 'JAUNA'
        )
      )
    },
  })

  const [complaintMutation] = useMutation((data: ComplaintEditRequest) => editComplaintData(data), {
    onSuccess: () => {
      showSnackBar()
      setIsLoadingSubmit(false)
      onSuccess()
      onClose()
    },
    onError: (error: any) => {
      setIsLoadingSubmit(false)
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const onSubmit = () => {
    if (!selectedStatus) {
      setIsStatusInvalid(true)
      return
    }

    setIsLoadingSubmit(true)

    complaintMutation({
      id: complaint.id,
      statuss_kods: selectedStatus,
      atbilde: complaint.atbilde,
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Labot sūdzības statusu
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Statuss" />
              <FormControl variant="outlined" error={isStatusInvalid} sx={{ minWidth: '300px' }}>
                <Select
                  size="small"
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value)
                    setIsStatusInvalid(false)
                  }}
                >
                  {contractDiscontinueCodes.map((contractDiscontinueCode, index) => (
                    <MenuItem key={index} value={contractDiscontinueCode.kods}>
                      {contractDiscontinueCode.nosaukums}
                    </MenuItem>
                  ))}
                </Select>
                {isStatusInvalid && <FormHelperText>{REQUIRED_ERROR_MESSAGE}</FormHelperText>}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
