import React, { FormEvent } from 'react'
import { Box, Checkbox, FormControlLabel, Typography, Button, InputAdornment } from '@mui/material'
import { Person } from '../../interfaces/Person'
import { TextField } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useMutation } from 'react-query'
import { editPersonData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { EMAIL_LENGTH, INVALID_EMAIL_LENGTH, PHONE_PLACEHOLDER } from '../../../constants/constants'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import ConfirmationStatusRenderer from '../../elements/ConfirmationStatusRenderer'
import { EAddressStatus } from '../../enums/EAddressStatus'

const sx = {
  formRow: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
  },
  label: {
    width: '260px',
    display: 'inline-block',
  },
}

const defaultFormErrors = {
  telefons: '',
  epasts: '',
}

interface Props {
  userInfo: Person
  reload: () => void
}

export function ApplicationContacts({ userInfo, reload }: Props) {
  const { showSnackBar } = useSnackBar()
  const { id, telefoni, epasti, eadrese, saziņa_neelektroniskā_formā } = userInfo
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [userContactInfo, setUserContactInfo] = React.useState({
    id: id,
    telefons: telefoni && telefoni.length > 0 ? telefoni[0].telefons : '',
    epasts: epasti && epasti.length > 0 ? epasti[0].epasts : '',
    eadrese: eadrese === EAddressStatus.izmantot,
    neelektroniska_saziņa: saziņa_neelektroniskā_formā,
  })
  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)

  const [editPersonMutation] = useMutation(
    ({ id, telefons, epasts, eadrese, neelektroniska_saziņa }: any) =>
      editPersonData(
        {
          id: id,
          eadrese: eadrese ? EAddressStatus.izmantot : EAddressStatus.neizmantot,
          saziņa_neelektroniskā_formā: neelektroniska_saziņa,
          telefoni: [
            {
              telefons: telefons,
              piezīmes: '',
              ir_verificēts: false,
            },
          ],
          epasti: [
            {
              epasts: epasts,
              piezīmes: '',
              ir_verificēts: false,
            },
          ],
        },
        id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        handleEditMode()
        reload()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (userContactInfo.epasts && userContactInfo.epasts.length > EMAIL_LENGTH) {
      errors['epasts'] = INVALID_EMAIL_LENGTH
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    editPersonMutation(userContactInfo)
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }

    setUserContactInfo({
      ...userContactInfo,
      [event.target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const handleEditMode = () => {
    setIsEditMode(!isEditMode)
  }

  return (
    <>
      {!isEditMode ? (
        <>
          <Box sx={sx.formRow}>
            <Typography sx={sx.label}>Tālrunis:</Typography>
            <Typography>
              {telefoni
                ?.filter((tel) => tel.telefons)
                .map((tel) => (
                  <Box key={tel.telefons} display="flex" alignItems="center" mb={1}>
                    {tel.telefons}
                    <ConfirmationStatusRenderer sx={{ ml: 1 }} isEnabled={tel.ir_verificēts} />
                  </Box>
                ))}
            </Typography>
          </Box>
          <Box sx={sx.formRow}>
            <Typography sx={sx.label}>E-pasts:</Typography>
            <Typography>
              {epasti
                ?.filter((email) => email.epasts)
                .map((email) => (
                  <Box key={email.epasts} display="flex" alignItems="center" mb={1}>
                    {email.epasts}
                    <ConfirmationStatusRenderer sx={{ ml: 1 }} isEnabled={email.ir_verificēts} />
                  </Box>
                ))}
            </Typography>
          </Box>
          <Box sx={sx.formRow}>
            <Typography sx={sx.label}>E-adrese:</Typography>
            <Typography>
              {eadrese === EAddressStatus.izmantot ? <CheckIcon /> : <CloseIcon />}
            </Typography>
          </Box>
          <Box sx={sx.formRow}>
            <Typography sx={sx.label}>Saziņa veicama neelektroniskā veidā:</Typography>
            <Typography>{saziņa_neelektroniskā_formā ? <CheckIcon /> : <CloseIcon />}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button color="primary" variant="outlined" onClick={handleEditMode}>
              Mainīt
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.label}>
              Tālrunis
            </Box>
            <TextField
              size="small"
              name="telefons"
              onChange={onChange}
              placeholder={PHONE_PLACEHOLDER}
              value={userContactInfo.telefons}
              helperText={formErrors.telefons}
              error={Boolean(formErrors.telefons)}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.label}>
              E-pasts
            </Box>
            <TextField
              size="small"
              name="epasts"
              onChange={onChange}
              value={userContactInfo.epasts}
              helperText={formErrors.epasts}
              error={Boolean(formErrors.epasts)}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box sx={sx.label} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={userContactInfo.eadrese}
                  size="small"
                  onChange={onChange}
                  name="eadrese"
                />
              }
              label={
                <Typography component="span" fontSize="inherit">
                  E-adrese
                </Typography>
              }
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box sx={sx.label} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={userContactInfo.neelektroniska_saziņa}
                  size="small"
                  onChange={onChange}
                  name="neelektroniska_saziņa"
                />
              }
              label={
                <Typography component="span" fontSize="inherit">
                  Saziņa veicama neelektroniskā veidā
                </Typography>
              }
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, letterSpacing: 2 }}>
            <Button color="primary" variant="outlined" sx={{ mr: 2 }} onClick={handleEditMode}>
              Atcelt
            </Button>
            <Button color="primary" variant="outlined" onClick={onSubmit}>
              Saglabāt
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
