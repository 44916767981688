import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { editUserData } from '../../../api/API'
import { addUserData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import UserRolesDropDownList from '../../elements/users/UserRolesDropDownList'
import RequiredLabel from '../../elements/RequiredLabel'
import DatePicker from '../../elements/DatePicker'
import { format, isValid, parseISO } from 'date-fns'
import {
  END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE,
  DEFAULT_API_DATE_FORMAT,
  INVALID_DATE_FORMAT,
  INVALID_PERSON_CODE_FORMAT,
  REGEX_PERSON_CODE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { User } from '../../interfaces/User'
import { UserRole, UserRoleSelect } from '../../interfaces/UserRole'
import { AddEditUser } from '../../interfaces/AddEditUser'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { convertPersonCode } from '../../utils/convertPersonCode'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'baseline',
  },
  inputLabel: {
    minWidth: '160px',
  },
  inputField: {
    paddingLeft: '10px',
  },
  lomasDate: {
    paddingLeft: '10px',
  },
}

interface Props {
  user: User | null
  dropdownRoles: UserRoleSelect[]
  onClose: () => void
}

export default function AddEditUserModal({ user, dropdownRoles, onClose }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const isEditMode = Boolean(user)

  const [separateRoles, setSeparateRoles] = React.useState(true)
  const [generalRoleDateFrom, setGeneralRoleDateFrom] = React.useState<Date | null>(new Date())
  const [generalRoleDateTo, setGeneralRoleDateTo] = React.useState<Date | null>(null)
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>({
    vārds: '',
    uzvārds: '',
    personas_kods: '',
    datums_no: '',
    generalRoleDateFrom: '',
  })

  const [formState, setFormState] = React.useState<AddEditUser>({
    id: user?.id,
    vārds: user?.vārds || '',
    uzvārds: user?.uzvārds || '',
    personas_kods: user?.personas_kods || '',
    active_directory_lietotājvārds: user?.active_directory_lietotājvārds || '',
    ir_aktivs: user?.ir_aktivs || true,
    lomas: user?.lomas
      ? user.lomas.map((role: UserRole) => {
          return {
            ...role,
            datums_no:
              role?.datums_no && typeof role.datums_no === 'string'
                ? parseISO(role.datums_no)
                : null,
            datums_līdz:
              role?.datums_līdz && typeof role.datums_līdz === 'string'
                ? parseISO(role.datums_līdz)
                : null,
          }
        })
      : [],
  })

  const isFormValid = () => {
    const { vārds, uzvārds, personas_kods, lomas } = formState
    const _errors: { [key: string]: string } = {}

    if (!vārds) _errors['vārds'] = REQUIRED_ERROR_MESSAGE
    if (!uzvārds) _errors['uzvārds'] = REQUIRED_ERROR_MESSAGE
    if (!personas_kods.match(REGEX_PERSON_CODE))
      _errors['personas_kods'] = INVALID_PERSON_CODE_FORMAT
    if (!personas_kods) _errors['personas_kods'] = REQUIRED_ERROR_MESSAGE
    if (!separateRoles && !generalRoleDateFrom)
      _errors['generalRoleDateFrom'] = REQUIRED_ERROR_MESSAGE

    if (separateRoles) {
      for (let i = 0; i < lomas.length; i++) {
        const role = lomas[i]

        if (!role.datums_no) {
          _errors['datums_no' + i] = REQUIRED_ERROR_MESSAGE
        }

        if (role.datums_no && !isValid(role.datums_no)) {
          _errors['datums_no' + i] = INVALID_DATE_FORMAT
        }

        if (role.datums_līdz && !isValid(role.datums_līdz)) {
          _errors['datums_līdz' + i] = INVALID_DATE_FORMAT
        }

        if (
          role.datums_no &&
          role.datums_līdz &&
          format(role.datums_no, DEFAULT_API_DATE_FORMAT) >
            format(role.datums_līdz, DEFAULT_API_DATE_FORMAT)
        ) {
          _errors['datums_līdz' + i] = END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE
        }
      }
    } else {
      if (generalRoleDateFrom && !isValid(generalRoleDateFrom)) {
        _errors.generalRoleDateFrom = INVALID_DATE_FORMAT
      }

      if (generalRoleDateTo && !isValid(generalRoleDateTo)) {
        _errors.generalRoleDateTo = INVALID_DATE_FORMAT
      }
      if (
        generalRoleDateFrom &&
        generalRoleDateTo &&
        format(generalRoleDateFrom, DEFAULT_API_DATE_FORMAT) >
          format(generalRoleDateTo, DEFAULT_API_DATE_FORMAT)
      ) {
        _errors.generalRoleDateTo = END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE
      }
    }

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const remapRoles = (roles: UserRole[]) => {
    return roles
      ? roles.map((role: UserRole) => {
          return {
            ...role,
            datums_no: !separateRoles
              ? generalRoleDateFrom
                ? format(generalRoleDateFrom, DEFAULT_API_DATE_FORMAT)
                : null
              : role.datums_no instanceof Date
              ? format(role.datums_no, DEFAULT_API_DATE_FORMAT)
              : null,
            datums_līdz: !separateRoles
              ? generalRoleDateTo
                ? format(generalRoleDateTo, DEFAULT_API_DATE_FORMAT)
                : null
              : role.datums_līdz instanceof Date
              ? format(role.datums_līdz, DEFAULT_API_DATE_FORMAT)
              : null,
          }
        })
      : []
  }

  const [addUserMutation] = useMutation(
    (user: AddEditUser) =>
      addUserData({
        ...user,
        lomas: remapRoles(user.lomas),
        personas_kods: convertPersonCode(user.personas_kods),
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const [editUserMutation] = useMutation(
    (user: AddEditUser) =>
      editUserData(
        {
          ...user,
          lomas: remapRoles(user.lomas),
          personas_kods: convertPersonCode(user.personas_kods),
        },
        user.id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState({ ...formState, [target.name]: target.value })

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    const relatedRoles = formState.lomas.filter((role: UserRole) => value.includes(role.loma))

    setFormState({
      ...formState,
      [event.target.name]:
        typeof value === 'string'
          ? value.split(',').map((role) => {
              return {
                loma: role,
                datums_no: null,
                datums_līdz: null,
              }
            })
          : value.map((role: string, index: number) => {
              return {
                loma: role,
                datums_no: new Date() || null,
                datums_līdz: relatedRoles[index]?.datums_līdz || null,
              }
            }),
    })
  }

  const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: !event.target.checked })
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsLoadingSubmit(true)
    if (isEditMode) {
      editUserMutation(formState)
    } else {
      addUserMutation(formState)
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={4}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Lietotājs
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Vārds" />
              <TextField
                sx={sx.inputField}
                variant="outlined"
                size="small"
                fullWidth
                name="vārds"
                autoComplete="off"
                value={formState.vārds}
                onChange={onFieldChange}
                helperText={formErrors.vārds}
                error={Boolean(formErrors.vārds)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Uzvārds" />
              <TextField
                sx={sx.inputField}
                variant="outlined"
                size="small"
                fullWidth
                name="uzvārds"
                autoComplete="off"
                value={formState.uzvārds}
                onChange={onFieldChange}
                helperText={formErrors.uzvārds}
                error={Boolean(formErrors.uzvārds)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Personas kods" />
              <TextField
                sx={sx.inputField}
                variant="outlined"
                size="small"
                fullWidth
                name="personas_kods"
                autoComplete="off"
                value={formState.personas_kods}
                onChange={onFieldChange}
                helperText={formErrors.personas_kods}
                error={Boolean(formErrors.personas_kods)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Bloķēts
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.ir_aktivs ? false : true}
                    name="ir_aktivs"
                    color="primary"
                    onChange={onCheckChange}
                  />
                }
                label=""
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                AD lietotājvārds
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="active_directory_lietotājvārds"
                autoComplete="off"
                value={formState.active_directory_lietotājvārds}
                onChange={onFieldChange}
              />
            </Box>
            <UserRolesDropDownList
              name="lomas"
              value={formState.lomas.map((role: UserRole) => role.loma)}
              onChange={handleChange}
              data={dropdownRoles}
            />
            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={separateRoles}
                    name="seperate_roles"
                    color="primary"
                    onChange={(event) => setSeparateRoles(event.target.checked)}
                  />
                }
                label={
                  <Typography variant="caption">Datumu atzīmēt par katru lomu atsevišķi</Typography>
                }
              />
            </Box>

            {separateRoles ? (
              formState.lomas.map((role: UserRole, index: number) => (
                <Box key={index} sx={sx.inputWrapper}>
                  <RequiredLabel label={role.loma} />
                  <DatePicker
                    sx={sx.lomasDate}
                    minDate={new Date()}
                    helperText={formErrors['datums_no' + index]}
                    error={Boolean(formErrors['datums_no' + index])}
                    value={formState.lomas[index].datums_no}
                    onChange={(date: Date | null) =>
                      setFormState({
                        ...formState,
                        lomas: formState.lomas.map((role: UserRole, ind: number) => {
                          if (index === ind)
                            return {
                              ...role,
                              datums_no: date,
                            }
                          else return role
                        }),
                      })
                    }
                  />
                  <span style={{ padding: '5px' }}> - </span>
                  <DatePicker
                    value={formState.lomas[index].datums_līdz}
                    minDate={new Date()}
                    helperText={formErrors['datums_līdz' + index]}
                    error={Boolean(formErrors['datums_līdz' + index])}
                    onChange={(date: Date | null) =>
                      setFormState({
                        ...formState,
                        lomas: formState.lomas.map((role: UserRole, ind: number) => {
                          if (index === ind)
                            return {
                              ...role,
                              datums_līdz: date,
                            }
                          else return role
                        }),
                      })
                    }
                  />
                </Box>
              ))
            ) : (
              <div>
                <Box sx={sx.inputWrapper}>
                  <RequiredLabel label="Datums no" />
                  <DatePicker
                    minDate={new Date()}
                    helperText={formErrors.generalRoleDateFrom}
                    error={Boolean(formErrors.generalRoleDateFrom)}
                    value={generalRoleDateFrom}
                    onChange={(date: Date | null) => setGeneralRoleDateFrom(date)}
                  />
                </Box>
                <Box sx={sx.inputWrapper}>
                  <Box component="span" sx={sx.inputLabel}>
                    Datums līdz
                  </Box>
                  <DatePicker
                    minDate={new Date()}
                    helperText={formErrors.generalRoleDateTo}
                    error={Boolean(formErrors.generalRoleDateTo)}
                    value={generalRoleDateTo}
                    onChange={(date: Date | null) => setGeneralRoleDateTo(date)}
                  />
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
