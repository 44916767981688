import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import { Box, Button, FormHelperText, List, ListItem, Typography, useTheme } from '@mui/material'
import React, { useRef } from 'react'
import { ACCEPTED_FILE_TYPES } from '../../constants/constants'
import { FileListItem } from '../interfaces/File/FileListItem'
import { useSnackBar } from '../providers/SnackBarProvider'
import { FileLink } from './FileLink'

const sx = {
  fileItem: {
    mr: 2,
    maxWidth: '320px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
interface Props {
  files: FileListItem[]
  multiple?: boolean
  useLink?: boolean
  onFileChange: (files: FileListItem[]) => void
  acceptedUploadFileTypes?: { [key: string]: string | string[] }
  error?: string
}

export function FileUploadButton({
  multiple = true,
  useLink,
  onFileChange,
  files,
  acceptedUploadFileTypes = ACCEPTED_FILE_TYPES,
  error,
}: Props) {
  const defaultFileTypes = acceptedUploadFileTypes
  const acceptedFileTypes = Object.keys(defaultFileTypes)

  const acceptedTypes = acceptedFileTypes.map((type) => `.${type}`).join(',')
  const lastType = acceptedFileTypes.pop()
  const acceptedTypesText = acceptedFileTypes.join(', ') + ` vai ${lastType}`

  const fileUploadRef = useRef<HTMLInputElement>(null)
  const { showSnackBar } = useSnackBar()
  const theme = useTheme()

  const selectFiles = () => {
    fileUploadRef && fileUploadRef.current?.click()
  }

  const isFileValid = (extension: string, type: string) => {
    if (defaultFileTypes[extension] == null) {
      return false
    }

    if (Array.isArray(defaultFileTypes[extension])) {
      return defaultFileTypes[extension].includes(type)
    }

    return defaultFileTypes[extension] === type
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles: FileListItem[] = []

      for (const file of Array.from(event.target.files)) {
        const extension = file.name.split('.').pop()?.toLowerCase()

        if (!extension) {
          continue
        }

        if (isFileValid(extension, file.type)) {
          newFiles.push({
            file,
            state: 'new',
          })
        } else {
          showSnackBar({
            severity: 'warning',
            text: `${file.name} netika pievienots. Nederīgs faila formāts!`,
          })
        }
      }

      if (multiple) {
        onFileChange([...files, ...newFiles])
      } else {
        onFileChange([...newFiles])
      }
    }
  }

  const removeFile = (index: number) => {
    const filesCopy = [...files]

    if (filesCopy[index].file instanceof File) {
      filesCopy.splice(index, 1)
    } else {
      filesCopy[index] = {
        ...filesCopy[index],
        state: 'delete',
      }
    }

    onFileChange(filesCopy)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <input
          ref={fileUploadRef}
          type="file"
          multiple={multiple}
          accept={acceptedTypes}
          hidden
          // A workaround to fix onChange event not firing upon selecting the same file (GI-511)
          onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            const fileInput = event.target as HTMLInputElement
            fileInput.value = ''
          }}
          onChange={onChange}
        ></input>
        <Button variant="outlined" color="primary" onClick={selectFiles}>
          Pievienot {multiple ? 'failus' : 'failu'} <UploadIcon />
        </Button>
        <Typography sx={{ color: '#999', ml: 2 }}>{acceptedTypesText}</Typography>
      </Box>
      {error && (
        <FormHelperText sx={{ ml: '14px', color: theme.palette.error.main }}>
          {error}
        </FormHelperText>
      )}
      <Box>
        <List>
          {files.map((entry, index) => (
            <Box key={index}>
              {entry.state === 'delete' ? (
                <></>
              ) : (
                <ListItem key={index} disableGutters>
                  <Box component="span" sx={sx.fileItem}>
                    {useLink ? (
                      entry.file instanceof File ? (
                        entry.file.name
                      ) : (
                        <FileLink file={entry.file} />
                      )
                    ) : entry.file instanceof File ? (
                      entry.file.name
                    ) : (
                      entry.file.nosaukums
                    )}
                  </Box>
                  <Button onClick={() => removeFile(index)}>
                    <DeleteIcon />
                  </Button>
                </ListItem>
              )}
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  )
}
