import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { markApplicationsPrinted, markApplicationsPrintedOffice } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { ApplicationStatusHistory } from '../../interfaces/ApplicationStatusHistory'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { getCertificateTableColumns } from './CertificateTableUtils'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
}

interface CertificateModalProps {
  data: ApplicationStatusHistory[]
  displaySmsButton: boolean | null
  onClose: () => void
}

interface CertificateAppIdProps {
  apliecības_id: number[]
}

export default function CertificateModal({
  data,
  displaySmsButton,
  onClose,
}: CertificateModalProps) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<ApplicationStatusHistory[]>([])

  const columns = getCertificateTableColumns()

  const [markApplicationsMutation] = useMutation(
    (applications: CertificateAppIdProps[]) => markApplicationsPrinted(applications),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const [markApplicationsOfficeMutation] = useMutation(
    (applications: CertificateAppIdProps[]) => markApplicationsPrintedOffice(applications),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const onSubmit = (sendMessage: boolean) => {
    setIsLoadingSubmit(true)

    const applicationIds = selectedRows.map((application) => application.id)

    !sendMessage
      ? markApplicationsMutation({
          apliecības_id: applicationIds,
        } as unknown as CertificateAppIdProps[])
      : markApplicationsOfficeMutation({
          apliecības_id: applicationIds,
        } as unknown as CertificateAppIdProps[])
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={4}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Apliecību saraksta apstrāde
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomizedMaterialTable
              selection={true}
              onSelectionChange={(rows: ApplicationStatusHistory[]) => setSelectedRows(rows)}
              columns={columns}
              data={data}
              rowStyle={{
                height: 50,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={() => onSubmit(false)}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  'Uzstādīt statusu izdrukāta'
                )}
              </Button>
              {displaySmsButton && (
                <Button
                  sx={[sx.button, { marginLeft: '20px' }]}
                  variant="contained"
                  onClick={() => onSubmit(true)}
                  color="primary"
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    'Uzstādīt statusu izdrukāta un nosūtīt SMS'
                  )}
                </Button>
              )}
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
