import { format } from 'date-fns'
import queryString from 'query-string'
import { DEFAULT_API_DATE_FORMAT, DEFAULT_API_DATETIME_FORMAT } from '../../constants/constants'

const normalizeQueryObject = <T extends object>(obj: T, dateTime: boolean) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([k, v]) => {
        if (typeof v === 'boolean') {
          return true
        }

        if (typeof v === 'number') {
          return true
        }

        return v
      })
      .map(([k, v]) => {
        if (v instanceof Date) {
          v = format(v, dateTime ? DEFAULT_API_DATETIME_FORMAT : DEFAULT_API_DATE_FORMAT)
        }

        return [k, v]
      })
  )
}

export function paramFetcher<T extends object>(
  queryParams: T,
  endpoint: string,
  countEndpoint: string,
  dateTime = false
) {
  const controller = new AbortController()

  const signal = controller.signal

  const listQueryParams = queryString.stringify(normalizeQueryObject(queryParams, dateTime), {
    arrayFormat: 'none',
    skipNull: true,
  })

  /*
   * count query also respects offset/limit/sort params
   * as we need total count, those need to be omitted
   */
  const countQueryParams = queryString.stringify(
    normalizeQueryObject(
      {
        ...queryParams,
        offset: null,
        limit: null,
        sort: null,
      },
      dateTime
    ),
    {
      arrayFormat: 'none',
      skipNull: true,
    }
  )

  const promise = Promise.all([
    fetch(`${endpoint}?${listQueryParams}`, { signal }),
    fetch(`${countEndpoint}?${countQueryParams}`, { signal }),
  ]).then(async ([listRes, countRes]) => {
    const list = await listRes.json()
    const count = await countRes.text()

    return [list, parseInt(count, 10)]
  })

  // @ts-ignore
  promise.cancel = () => controller.abort()

  return promise
}
