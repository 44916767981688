import axios from 'axios'

export const initInterceptors = (history: any) => {
  // request interceptor
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        history.replace('/login')
      }

      return Promise.reject(error)
    }
  )
}
