import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { Alert, Box, Button, Grid } from '@mui/material'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { editPartnerContactData, fetchPartnerContactData } from '../../../api/API'
import { NewPartnerContactResponse } from '../../interfaces/Partners/NewPartnerContact'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import EditPersonModal from './EditPersonModal'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { convertPersonCode } from '../../utils/convertPersonCode'

const columns = [
  {
    field: 'vārds',
    title: 'Vārds',
  },
  {
    field: 'uzvārds',
    title: 'Uzvārds',
  },
  {
    field: 'loma_nosaukums',
    title: 'Loma',
    width: '40%',
  },
  {
    field: 'telefons',
    title: 'Tālrunis',
  },
  {
    field: 'epasts',
    title: 'E-pasts',
  },
  {
    field: 'ir_bloķēts',
    title: 'Lietotāja statuss',
    width: '8%',
    render: ({ ir_bloķēts }: NewPartnerContactResponse) => (ir_bloķēts ? 'Neaktīvs' : 'Aktīvs'),
  },
]

const rows: NewPartnerContactResponse[] = []

const partnerRoleQueryParams = {
  klasifikatora_kods: 'PARTNERA_PERSONAS_LOMA',
}

interface PartnerContactPersonProps {
  partnerId: string
}

export const PartnerContactPersonTabContent = ({ partnerId }: PartnerContactPersonProps) => {
  const { showSnackBar } = useSnackBar()
  const partnerQueryParams = {
    sadarbības_partneris_id: partnerId,
  }

  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [partnerContactData, setPartnerContactData] = React.useState<NewPartnerContactResponse[]>()
  const [selectedPerson, setSelectedPerson] = React.useState<NewPartnerContactResponse | null>(null)
  const [partnerRoleList, setPartnerRoleList] = React.useState<ClassifierStatus[]>([])

  const { isLoading, refetch } = useQuery(
    ['partnerContacts', partnerQueryParams],
    () => fetchPartnerContactData(partnerQueryParams),
    {
      refetchOnWindowFocus: false,
      onSuccess: (contacts: NewPartnerContactResponse[]) =>
        setPartnerContactData(contacts && contacts.length ? contacts : rows),
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const { isLoading: isLoadingPartnerRoles } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_roles',
    queryParameters: partnerRoleQueryParams,
    querySuccess: (resp) => {
      setPartnerRoleList(convertToClassifierStatus(resp))
    },
  })

  const [editPartnerContactStatusMutation] = useMutation(
    (person: NewPartnerContactResponse) =>
      editPartnerContactData(
        {
          ...person,
          ir_bloķēts: !person.ir_bloķēts,
          personas_kods: convertPersonCode(person.personas_kods),
        },
        person.id
      ),
    {
      onSuccess: () => {
        showSnackBar()
        refetch()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onUserStatusChange = (event: React.MouseEvent, partner: NewPartnerContactResponse) => {
    editPartnerContactStatusMutation(partner)
  }

  const onPersonEdit = (event: React.MouseEvent, person: NewPartnerContactResponse) => {
    setSelectedPerson(person)
    setOpenModalAddEdit(true)
  }

  const closeModal = () => {
    setSelectedPerson(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onPersonEdit,
      tooltip: 'Rediģēt',
    },
    (rowData: NewPartnerContactResponse) => {
      const isBlocked = Boolean(rowData.ir_bloķēts)
      return {
        icon: () =>
          isBlocked ? (
            <CheckCircleOutlineIcon fontSize="small" htmlColor="#00DC25" />
          ) : (
            <RemoveCircleOutlineIcon fontSize="small" htmlColor="#FF0000" />
          ),
        onClick: onUserStatusChange,
        tooltip: isBlocked ? 'Aktivizēt' : 'Bloķēt',
      }
    },
  ]

  return (
    <>
      {openModalAddEdit && (
        <EditPersonModal
          person={selectedPerson}
          partnerId={parseInt(partnerId)}
          partnerRoleList={partnerRoleList}
          partnerContactData={partnerContactData}
          onClose={closeModal}
        />
      )}
      <Grid container sx={{ pb: '20px' }}>
        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end" container>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot kontaktpersonu
          </Button>
        </Grid>
      </Grid>
      {isLoading || isLoadingPartnerRoles ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
          <DelayedLoader />
        </Box>
      ) : (
        <>
          {partnerContactData == null ? (
            <></>
          ) : partnerContactData.length > 0 ? (
            <CustomizedMaterialTable
              columns={columns}
              data={partnerContactData}
              options={{
                paging: false,
              }}
              actions={actions}
              rowStyle={{
                height: 50,
              }}
            />
          ) : (
            <Alert severity="info">Sadarbības personai nav kontaktpersonu</Alert>
          )}
        </>
      )}
    </>
  )
}
