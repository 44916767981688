import React from 'react'
import { Box, Breakpoint, Button, Dialog, DialogContent } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { grey } from '@mui/material/colors'
import { confirmable, createConfirmation } from 'react-confirm'
import ThemeProvider from '../providers/ThemeProvider'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  button: {
    minWidth: 90,
  },
  smallText: {
    fontSize: 13,
    color: grey[700],
  },
  text: {
    fontSize: 18,
  },
}

export interface ConfirmModalProps {
  show: boolean
  text: string
  description: string
  proceed: ({ ok }: { ok: boolean }) => void
  children?: React.ReactNode
  hideOkCancelButtons?: boolean
  maxWidth?: Breakpoint
}

export function ConfirmModal({
  show,
  proceed,
  text,
  description,
  hideOkCancelButtons,
  children,
  maxWidth = 'sm',
}: ConfirmModalProps) {
  return (
    <ThemeProvider>
      <Dialog open={show} maxWidth={maxWidth}>
        <Box pb={2}>
          <Box mt={1} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon
              sx={sx.closeIcon}
              color="primary"
              onClick={() =>
                proceed({
                  ok: false,
                })
              }
            />
          </Box>
          <Box px={2}>
            <DialogContent>
              <Box>
                <Box sx={sx.text}>{text}</Box>
                {description && (
                  <Box sx={sx.smallText} dangerouslySetInnerHTML={{ __html: description }} />
                )}
              </Box>
              {children && <Box mt={1}>{children}</Box>}
              {!hideOkCancelButtons && (
                <Box mt={2} mb={1} display="flex" justifyContent="center">
                  <Button
                    sx={sx.button}
                    variant="contained"
                    onClick={() => {
                      proceed({
                        ok: true,
                      })
                    }}
                    color="primary"
                  >
                    Labi
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    sx={sx.button}
                    variant="contained"
                    onClick={() =>
                      proceed({
                        ok: false,
                      })
                    }
                    color="primary"
                  >
                    Atcelt
                  </Button>
                </Box>
              )}
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  )
}

export const confirmDialog = createConfirmation(
  confirmable<ConfirmModalProps>(ConfirmModal)
) as unknown as (props: any) => Promise<{ ok: boolean }>
