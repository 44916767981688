import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  SelectChangeEvent,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { PartnerContractSignatory } from '../../interfaces/Partners/PartnerContractSignatory'
import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import { RequiredMarker } from '../../elements/RequiredMarker'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
  },
  inputLabel: {
    minWidth: '14rem',
    alignSelf: 'baseline',
    marginTop: '11px',
  },
  informativeLabel: {
    fontSize: '13px',
    fontWeight: '400',
    color: 'grey',
  },
}

const templateTypes = [
  {
    nosaukums: 'Papīra',
    kods: 'papīra',
  },
  {
    nosaukums: 'Elektroniska',
    kods: 'elektroniska',
  },
]

const defaultFormErrors = {
  ir_eparaksts: '',
  amats: '',
  parakstitajs: '',
}

interface Props {
  isLoading: boolean
  onClose: () => void
  onGenerate: (signatory: PartnerContractSignatory) => void
}

export default function GenerateContractModal({ isLoading, onClose, onGenerate }: Props) {
  const [signatory, setSignatory] = useState({
    ir_eparaksts: '',
    amats: '',
    parakstitajs: '',
  })
  const [formErrors, setFormErrors] = useState(defaultFormErrors)

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignatory({
      ...signatory,
      [event.target.name]: event.target.value,
    })

    resetError(event.target.name)
  }

  const handleDropDownChange = (event: SelectChangeEvent) => {
    setSignatory({
      ...signatory,
      [event.target.name]: event.target.value,
    })

    resetError(event.target.name)
  }

  const resetError = (fieldName: string) => {
    setFormErrors({
      ...formErrors,
      [fieldName]: '',
    })
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!signatory.ir_eparaksts) {
      errors['ir_eparaksts'] = REQUIRED_ERROR_MESSAGE
    }

    if (!signatory.amats) {
      errors['amats'] = REQUIRED_ERROR_MESSAGE
    }

    if (!signatory.parakstitajs) {
      errors['parakstitajs'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const generateAgreement = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    onGenerate({
      ir_eparaksts: signatory.ir_eparaksts === 'elektroniska',
      amats: signatory.amats,
      parakstitajs: signatory.parakstitajs,
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Ģenerēt parakstāmo līgumu
            </Box>
            <Box display="flex" justifyContent="center" style={sx.informativeLabel}>
              Laukos jānorāda sadarbības partnera parakstītāja vārds, uzvārds un amats.
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Sagataves versija
                <RequiredMarker />
              </Box>
              <PartnerDropDownList
                name="ir_eparaksts"
                disabled={isLoading}
                value={signatory.ir_eparaksts}
                onChange={handleDropDownChange}
                data={templateTypes}
                helperText={formErrors.ir_eparaksts}
                error={Boolean(formErrors.ir_eparaksts)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Parakstītāja vārds, uzvārds
                <RequiredMarker />
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="parakstitajs"
                autoComplete="off"
                disabled={isLoading}
                value={signatory.parakstitajs}
                onChange={onFieldChange}
                helperText={formErrors.parakstitajs}
                error={Boolean(formErrors.parakstitajs)}
              />
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Parakstītāja amats
                <RequiredMarker />
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="amats"
                autoComplete="off"
                disabled={isLoading}
                value={signatory.amats}
                onChange={onFieldChange}
                helperText={formErrors.amats}
                error={Boolean(formErrors.amats)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={generateAgreement}
                disabled={isLoading}
                color="primary"
              >
                Ģenerēt
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
