import MaterialTable from '@material-table/core'
import { Box } from '@mui/material'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'

const sx = {
  root: {
    minWidth: 650,
    '& .MuiTable-root': {
      margin: 'auto',
      width: '95%',
    },
    '& .MuiPaper-root': {
      border: '1px solid rgb(97, 97, 97, .5)',
      borderRadius: '10px',
      filter:
        'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    '& .MuiTableCell-root': {
      padding: '0px 16px',
    },
    '& thead tr th': {
      position: 'relative',
      letterSpacing: '0.025em',
      lineHeight: '100%',
      fontSize: '0.875rem',
      color: '#616161',
      padding: '12px 16px !important',
    },
  },
}

export const CustomizedMaterialTable = ({
  options,
  components,
  isLoading,
  onOrderChange,
  pageSize,
  rowStyle,
  wrapperBoxStyles = undefined,
  selection = false,
  ...props
}: any) => (
  <Box sx={[sx.root, wrapperBoxStyles]}>
    <MaterialTable
      {...props}
      options={{
        selection: selection,
        filtering: false,
        search: false,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
        actionsColumnIndex: props.columns.length,
        rowStyle: (rowData) => ({
          fontFamily: 'DINRoundPro, sans-serif',
          fontSize: '0.875rem',
          color: rowData.ir_aktivs == null ? 'inherit' : rowData.ir_aktivs ? 'inherit' : '#AAA',
          ...(typeof rowStyle === 'function' ? rowStyle(rowData) : rowStyle),
        }),
        ...options,
      }}
      isLoading={isLoading}
      {...(typeof onOrderChange === 'function' && {
        onOrderChange: (colId, order) => onOrderChange(props.columns[colId]?.field, order),
      })}
      localization={{
        pagination: {
          labelRowsPerPage: ' ',
          labelRowsSelect: 'rindas',
          labelDisplayedRows: `{from}-{to} no {count}`,
          firstTooltip: 'Pirmā lapa',
          nextTooltip: 'Nākamā lapa',
          previousTooltip: 'Iepriekšējā lapa',
          lastTooltip: 'Pēdējā lapa',
        },
        body: {
          emptyDataSourceMessage: 'Nav datu',
        },
        header: {
          actions: '',
        },
      }}
      components={{
        Toolbar: () => null,
        Groupbar: () => null,
        ...components,
      }}
    />
  </Box>
)

export default CustomizedMaterialTable
