import React from 'react'
import { FormControl, MenuItem, Select, FormHelperText, SelectChangeEvent } from '@mui/material'
import { StatusCodeItem } from '../../interfaces/StatusCodeItem'

interface Props {
  data: StatusCodeItem[]
  value: string | undefined
  name: string
  onChange: (event: SelectChangeEvent<string>) => void
  helperText?: string
  disabled?: boolean
  error?: boolean
}

export default function StatusCodeDropDownList({
  data = [],
  helperText,
  error,
  disabled,
  ...props
}: Props) {
  return (
    <FormControl
      variant="outlined"
      error={error}
      sx={{ flexGrow: 1, minWidth: 400 }}
      disabled={disabled}
    >
      <Select size="small" {...props}>
        {data.map((data, index) => (
          <MenuItem key={index} value={data.statusCode}>
            {data.statusName}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
