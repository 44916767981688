enum ChildDisabilityStatus {
  // Atbilst
  Disabled = 'Disabled',
  // Neatbilst
  NotDisabled = 'NotDisabled',
  // Nav pārbaudīts
  NotChecked = 'NotChecked',
  // Nav atļauta (piekrīt_veselības_datu_apstrādei = false)
  NotAllowed = 'NotAllowed',
}

export default ChildDisabilityStatus
