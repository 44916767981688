import { useQuery } from 'react-query'
import { Classifier } from '../components/interfaces/Classifier'
import { useCurrentUser } from '../store/user'
import { fetchClassifierData } from './API'

interface FetchClassifiersProps {
  queryKey: string
  enabled?: boolean
  querySuccess?: (response: Classifier[]) => void
  queryError?: (response: any) => void
}

export default function useFetchClassifierData({
  queryKey,
  enabled = true,
  querySuccess = () => {},
  queryError = () => {},
}: FetchClassifiersProps) {
  const { currentUser } = useCurrentUser()

  const { data, isLoading, error } = useQuery(queryKey, fetchClassifierData, {
    refetchOnWindowFocus: false,
    enabled: enabled && currentUser,
    onSuccess: (data: Classifier[]) => querySuccess(data),
    onError: (error) => queryError(error),
  })

  return { data, isLoading, error }
}
