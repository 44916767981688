import React from 'react'
import FlakyIcon from '@mui/icons-material/Flaky'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { useMutation, useQuery } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { Alert, Box, Button, Grid } from '@mui/material'
import { deletePartnerDiscountData, fetchPartnerDiscountData } from '../../../api/API'
import {
  DiscountStatusCode,
  NewPartnerDiscountResponse,
} from '../../interfaces/Partners/NewPartnerDiscount'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import EditDiscountModal from './EditDiscountModal'
import { confirmDialog } from '../../elements/ConfirmDialog'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import ChangeDiscountStatusModal from './ChangeDiscountStatusModal'
import { getRowColor } from '../../utils/discountsRowColors'
import { getSortedMatchingDiscountArrays } from '../../utils/getSortedMatchingDiscountArrays'
import ViewDiscountModal from './ViewDiscountModal'

const columns = [
  {
    field: 'skaits',
    title: 'Skaits',
  },
  {
    field: 'mērvienība_nosaukums',
    title: 'Mērvienība',
  },
  {
    field: 'apraksts',
    title: 'Apraksts',
    width: '60%',
    cellStyle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100px',
    },
  },
  {
    field: 'statuss_nosaukums',
    title: 'Statuss',
  },
]

const partnerDiscountUnitQueryParams = {
  klasifikatora_kods: 'MĒRVIENĪBA',
}

const partnerDiscountStatusQueryParams = {
  klasifikatora_kods: 'ATLAIDES_STATUSS',
}

interface PartnerDiscountProps {
  partnerId: string
}

export const PartnerDiscountTabContent = ({ partnerId }: PartnerDiscountProps) => {
  const { showSnackBar } = useSnackBar()
  const partnerQueryParams = {
    sadarbības_partneris_id: partnerId,
  }

  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [openStatusChangeModal, setOpenStatusChangeModal] = React.useState(false)
  const [openViewDiscountModal, setOpenViewDiscountModal] = React.useState(false)
  const [partnerDiscountData, setPartnerDiscountData] = React.useState<
    NewPartnerDiscountResponse[]
  >([])
  const [suggestedDiscounts, setSuggestedDiscounts] = React.useState<NewPartnerDiscountResponse[]>(
    []
  )
  const [activeDiscounts, setActiveDiscounts] = React.useState<NewPartnerDiscountResponse[]>([])
  const [selectedDiscount, setSelectedDiscount] = React.useState<NewPartnerDiscountResponse | null>(
    null
  )
  const [partnerDiscountUnitList, setPartnerDiscountUnitList] = React.useState<ClassifierStatus[]>(
    []
  )
  const [partnerDiscountStatusList, setPartnerDiscountStatusList] = React.useState<
    ClassifierStatus[]
  >([])

  const { isLoading, refetch } = useQuery(
    ['partnerDiscounts', partnerQueryParams],
    () => fetchPartnerDiscountData(partnerQueryParams),
    {
      refetchOnWindowFocus: false,
      onSuccess: (discounts: NewPartnerDiscountResponse[]) => {
        const filteredDiscounts = discounts.filter(
          (discount) =>
            discount.statuss_kods === DiscountStatusCode.Aktīva ||
            discount.statuss_kods === DiscountStatusCode.Piedāvāta
        )

        setPartnerDiscountData(filteredDiscounts)

        const { sortedActiveDiscounts, sortedSuggestedDiscounts } =
          getSortedMatchingDiscountArrays(filteredDiscounts)

        setActiveDiscounts(sortedActiveDiscounts)
        setSuggestedDiscounts(sortedSuggestedDiscounts)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const { isLoading: isLoadingPartnerDiscountUnit } = useFetchClassifiers({
    queryKey: 'classifier_records_discount_unit',
    queryParameters: partnerDiscountUnitQueryParams,
    querySuccess: (resp) => {
      setPartnerDiscountUnitList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerDiscountStatus } = useFetchClassifiers({
    queryKey: 'classifier_records_discount_status',
    queryParameters: partnerDiscountStatusQueryParams,
    querySuccess: (resp) => {
      setPartnerDiscountStatusList(convertToClassifierStatus(resp))
    },
  })

  const [deletePartnerDiscountMutation] = useMutation(
    (id: number) => deletePartnerDiscountData(id),
    {
      onSuccess: () => {
        showSnackBar()
        refetch()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onDiscountEdit = (event: React.MouseEvent, discount: NewPartnerDiscountResponse) => {
    setSelectedDiscount(discount)
    setOpenModalAddEdit(true)
  }

  const onDiscountView = (_: React.MouseEvent, discount: NewPartnerDiscountResponse) => {
    if (discount.id) {
      setSelectedDiscount(discount)
      setOpenViewDiscountModal(true)
    }
  }

  const onDiscountStatusChange = (
    event: React.MouseEvent,
    discount: NewPartnerDiscountResponse
  ) => {
    setSelectedDiscount(discount)
    setOpenStatusChangeModal(true)
  }

  const onDiscountDelete = async (
    event: React.MouseEvent,
    { mērvienība_nosaukums, skaits, apraksts, id }: NewPartnerDiscountResponse
  ) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst atlaidi "${skaits} ${mērvienība_nosaukums} - ${apraksts}" no datubāzes?`,
    })

    if (confirm.ok) {
      deletePartnerDiscountMutation(id)
    }
  }

  const closeAddEditModal = () => {
    setSelectedDiscount(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const closeDiscountStatusChangeModal = () => {
    setSelectedDiscount(null)
    setOpenStatusChangeModal(false)
    refetch()
  }

  const closeDiscountViewModal = () => {
    setSelectedDiscount(null)
    setOpenViewDiscountModal(false)
  }

  const actions = [
    (rowData: NewPartnerDiscountResponse) => ({
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onDiscountEdit,
      tooltip: 'Rediģēt',
      hidden: !rowData.id,
    }),
    (rowData: NewPartnerDiscountResponse) => ({
      icon: () => <DeleteIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onDiscountDelete,
      tooltip: 'Dzēst',
      hidden: !rowData.id,
    }),
    (rowData: NewPartnerDiscountResponse) => ({
      icon: () => <FlakyIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onDiscountStatusChange,
      tooltip: 'Statusa maiņa',
      hidden: !rowData.id || rowData.statuss_kods !== 'APIE',
    }),
  ]

  return (
    <>
      {openModalAddEdit && (
        <EditDiscountModal
          discount={selectedDiscount}
          partnerId={parseInt(partnerId)}
          partnerDiscountUnitList={partnerDiscountUnitList}
          partnerDiscountStatusList={partnerDiscountStatusList}
          onClose={closeAddEditModal}
        />
      )}
      {openStatusChangeModal && (
        <ChangeDiscountStatusModal
          offeredDiscount={selectedDiscount}
          discountsData={partnerDiscountData}
          onClose={closeDiscountStatusChangeModal}
        />
      )}
      {openViewDiscountModal && (
        <ViewDiscountModal discountData={selectedDiscount} onClose={closeDiscountViewModal} />
      )}
      <Grid container sx={{ pb: '20px' }}>
        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end" container>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot atlaidi
          </Button>
        </Grid>
      </Grid>
      {isLoading || isLoadingPartnerDiscountUnit || isLoadingPartnerDiscountStatus ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
          <DelayedLoader />
        </Box>
      ) : (
        <>
          {partnerDiscountData == null ? (
            <></>
          ) : partnerDiscountData.length > 0 ? (
            <Box display="flex" width="100%">
              {activeDiscounts.some(({ id }) => id) && (
                <CustomizedMaterialTable
                  columns={columns}
                  data={activeDiscounts}
                  wrapperBoxStyles={{ width: '100%' }}
                  options={{
                    paging: false,
                    sorting: false,
                  }}
                  actions={actions}
                  rowStyle={(rowData: NewPartnerDiscountResponse) => ({
                    height: 50,
                    color: getRowColor(rowData),
                  })}
                  onRowClick={onDiscountView}
                />
              )}
              {suggestedDiscounts.some(({ id }) => id) && (
                <CustomizedMaterialTable
                  columns={columns}
                  data={suggestedDiscounts}
                  wrapperBoxStyles={{ width: '100%', marginLeft: '20px' }}
                  options={{
                    paging: false,
                    sorting: false,
                  }}
                  actions={actions}
                  rowStyle={(rowData: NewPartnerDiscountResponse) => ({
                    height: 50,
                    color: getRowColor(rowData),
                  })}
                  onRowClick={onDiscountView}
                />
              )}
            </Box>
          ) : (
            <Alert severity="info">Sadarbības personai nav atlaides</Alert>
          )}
        </>
      )}
    </>
  )
}
