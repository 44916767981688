import { Box, Button, Divider, SelectChangeEvent, TextField } from '@mui/material'
import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import { RequiredMarker } from '../../elements/RequiredMarker'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { NewPartnerDiscount } from '../../interfaces/Partners/NewPartnerDiscount'

const sx = {
  button: {
    minWidth: 130,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
}

const emptyDiscount = {
  skaits: 0,
  mērvienība_kods: '',
  apraksts: '',
}

interface Props {
  discounts: NewPartnerDiscount[]
  partnerDiscountUnitList: ClassifierStatus[]
  formErrors: { [key: string]: string }
  handleDiscountChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void
  setDiscounts: React.Dispatch<React.SetStateAction<NewPartnerDiscount[]>>
  handleArrayValueChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerDiscount[]>>,
    index: number
  ) => void
  removeAdditionalEntry: (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerDiscount[]>>,
    index: number
  ) => void
  addAdditionalEntry: (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerDiscount[]>>,
    emptyObject: object
  ) => void
}

export default function NewPartnerDiscountData({
  discounts,
  formErrors,
  partnerDiscountUnitList,
  handleDiscountChange,
  setDiscounts,
  handleArrayValueChange,
  addAdditionalEntry,
  removeAdditionalEntry,
}: Props) {
  return (
    <>
      {discounts.map((_discount, index) => (
        <Box key={index}>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Skaits
              <RequiredMarker />
            </Box>
            <TextField
              type="number"
              sx={{ flexGrow: 1 }}
              size="small"
              name="skaits"
              onChange={(e) => handleDiscountChange(e, index)}
              value={discounts[index].skaits}
              helperText={formErrors['skaits' + index]}
              error={Boolean(formErrors['skaits' + index])}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Mērvienības
              <RequiredMarker />
            </Box>
            <PartnerDropDownList
              name="mērvienība_kods"
              value={discounts[index].mērvienība_kods}
              onChange={(e) => handleArrayValueChange(e, discounts, setDiscounts, index)}
              data={partnerDiscountUnitList}
              helperText={formErrors['mērvienība_kods' + index]}
              error={Boolean(formErrors['mērvienība_kods' + index])}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Apraksts
              <RequiredMarker />
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="apraksts"
              onChange={(e) => handleDiscountChange(e, index)}
              value={discounts[index].apraksts}
              helperText={formErrors['apraksts' + index]}
              error={Boolean(formErrors['apraksts' + index])}
            />
          </Box>
          {discounts.length > 0 && (
            <>
              <Box
                mb={2}
                mt={2}
                style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  sx={sx.button}
                  variant="contained"
                  onClick={() => removeAdditionalEntry(discounts, setDiscounts, index)}
                  color="primary"
                >
                  Noņemt atlaidi
                </Button>
              </Box>

              <Divider sx={sx.divider} />
            </>
          )}
        </Box>
      ))}
      <Box mb={2} mt={6} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={sx.button}
          variant="outlined"
          color="primary"
          onClick={() => addAdditionalEntry(discounts, setDiscounts, emptyDiscount)}
        >
          Pievienot atlaidi
        </Button>
      </Box>
    </>
  )
}
