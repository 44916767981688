import InfoIcon from '@mui/icons-material/Info'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import { INFO_REQUEST_LIST_COUNT_ENDPOINT, INFO_REQUEST_LIST_ENDPOINT } from '../../../api/API'
import { DEFAULT_DATETIME_FORMAT, DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { paramFetcher } from '../../elements/ParamFetcher'
import useRouterQuery from '../../hooks/useRouterQuery'
import { ComplaintInfoRequest } from '../../interfaces/ComplaintInfoRequest'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import InfoRequestModal from './InfoRequestModal'
import AskInfoRequestModal from './AskInfoRequestModal'

const columns = [
  {
    field: 'jautājums',
    title: 'Jautājums',
    width: '40%',
  },
  {
    field: 'atbilde',
    title: 'Atbilde',
    width: '40%',
  },
  {
    field: 'pievienošanas_laiks',
    title: 'Pievienošanas laiks',
    render: (rowData: ComplaintInfoRequest) =>
      format(new Date(rowData.pievienošanas_laiks), DEFAULT_DATETIME_FORMAT),
  },
]

interface PartnerTasksProps {
  partnerId: number
}

export const PartnerInfoRequestContent: React.FC<PartnerTasksProps> = ({ partnerId }) => {
  const { showSnackBar } = useSnackBar()
  const query = useRouterQuery()
  const complaintId = query.get('complaintId')
  const [showSpecificInfoRequests, setShowSpecificInfoRequest] = useState<boolean>(false)
  const [complaintsInfoRequestData, setComplaintsInfoRequestData] = useState<
    ComplaintInfoRequest[] | undefined
  >(undefined)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false)
  const [selectedComplaintInfoRequest, setSelectedComplaintInfoRequest] =
    useState<ComplaintInfoRequest | null>(null)
  const [isComplaintInfoRequestModalOpen, setIsComplaintInfoRequestModalOpen] =
    useState<boolean>(false)
  const [queryParams, setQueryParams] = useState<TaskQueryParams>({
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const handleShowAllCheckbox = () => {
    setShowSpecificInfoRequest(!showSpecificInfoRequests)
    setComplaintsInfoRequestData(
      showSpecificInfoRequests
        ? filteredInfoRequestDisplayableData
        : staticComplaintsInfoRequestData
    )
  }

  const { isLoading, refetch, data } = useQuery(
    ['partnerComplaintInfoRequests', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        INFO_REQUEST_LIST_ENDPOINT,
        INFO_REQUEST_LIST_COUNT_ENDPOINT
      ),
    {
      onSuccess: ([data]) => {
        const filteredData = (data as ComplaintInfoRequest[]).filter(
          ({ sadarbības_partnera_sūdzības_id }) =>
            sadarbības_partnera_sūdzības_id === Number(complaintId)
        )
        setComplaintsInfoRequestData(complaintId ? filteredData : data)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      refetchOnWindowFocus: false,
    }
  )

  const [staticComplaintsInfoRequestData] = (data ?? []) as [ComplaintInfoRequest[], number]

  const filteredInfoRequestDisplayableData: ComplaintInfoRequest[] | undefined = useMemo(
    () =>
      staticComplaintsInfoRequestData?.filter(
        ({ sadarbības_partnera_sūdzības_id }) =>
          sadarbības_partnera_sūdzības_id === Number(complaintId)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [staticComplaintsInfoRequestData]
  )

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const handleCloseReplyChangeModal = () => {
    setSelectedComplaintInfoRequest(null)
    setIsInfoModalOpen(false)
  }

  const handleOpenInfoModal = (_: React.MouseEvent, complaintInfoRequest: ComplaintInfoRequest) => {
    setSelectedComplaintInfoRequest(complaintInfoRequest)
    setIsInfoModalOpen(true)
  }

  const actions = [
    {
      icon: () => <InfoIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: handleOpenInfoModal,
      tooltip: 'Apskatīt pilnu sūdzības aprakstu',
    },
  ]

  return (
    <>
      {isInfoModalOpen && selectedComplaintInfoRequest && (
        <InfoRequestModal
          complaintInfoRequest={selectedComplaintInfoRequest}
          onClose={handleCloseReplyChangeModal}
        />
      )}

      {isComplaintInfoRequestModalOpen && (
        <AskInfoRequestModal
          complaint={{ sadarbības_partneris_id: partnerId }}
          onClose={() => setIsComplaintInfoRequestModalOpen(false)}
          onSuccess={refetch}
        />
      )}

      <Box display="flex" flexDirection="column" rowGap="20px">
        <Box display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleShowAllCheckbox}
                checked={showSpecificInfoRequests}
                disabled={!Boolean(complaintId)}
              />
            }
            label="Rādīt visus sūdzību informācijas pieprasījumus"
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsComplaintInfoRequestModalOpen(true)}
          >
            Pievienot informācijas pieprasījumu
          </Button>
        </Box>
        <CustomizedMaterialTable
          columns={columns}
          data={complaintsInfoRequestData}
          isLoading={isLoading}
          page={queryParams.offset! / queryParams.limit!}
          totalCount={complaintsInfoRequestData?.length}
          pageSize={queryParams.limit}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          actions={actions}
          rowStyle={{
            height: 50,
          }}
        />
      </Box>
    </>
  )
}
