import React, { ReactElement, ReactNode } from 'react'
import { InputLabel, Box } from '@mui/material'
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline'

const sx = {
  root: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    marginTop: '22px',
    '& label.MuiFormLabel-root': {
      backgroundColor: '#fff',
      marginLeft: '-5px',
      padding: '0 6px',
    },
  },
  contentWrapper: {
    position: 'relative',
    width: '100%',
  },
  content: {
    padding: 3,
  },
  inputLabel: {
    fontSize: 25,
    position: 'absolute',
    left: 0,
    top: -5,
  },
}

interface Props {
  id: string
  label: ReactNode
  children: ReactNode
}

export default function OutlinedContainer({ id, label, children }: Props): ReactElement {
  return (
    <Box sx={sx.root}>
      <InputLabel htmlFor={id} variant="outlined" sx={sx.inputLabel} shrink>
        {label}
      </InputLabel>
      <Box sx={sx.contentWrapper}>
        <Box id={id} sx={sx.content}>
          {children}
          <NotchedOutline notched label={label} style={{ borderRadius: '4px' }} />
        </Box>
      </Box>
    </Box>
  )
}
