export function convertPersonCode(personCode: string) {
  let modifiedPersonCode = ''

  if (!personCode.includes('-')) {
    const hyphenPosition = 6

    modifiedPersonCode =
      personCode.slice(0, hyphenPosition) + '-' + personCode.slice(hyphenPosition)
  } else {
    modifiedPersonCode = personCode
  }

  return modifiedPersonCode
}
