import React, { SyntheticEvent } from 'react'
import { Autocomplete, TextField, Box, FormControl, FormHelperText } from '@mui/material'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import { fetchAddresses } from '../../../api/API'
import { stringifyGeom } from '../../utils/geoCoordinatesParsers'
import { lks92ToWgs84 } from '../../utils/coordinateUtils'

interface Props {
  value: any
  disabled?: boolean
  onValueChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: AddressResponse,
    index?: number
  ) => void
  index?: number
  helperText?: string
  error?: boolean
}

export default function AddressDropDownList({
  onValueChange,
  disabled = false,
  value,
  helperText,
  error,
  index,
  ...props
}: Props) {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<AddressResponse[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const addressQueryParams = {
    limit: '100',
    search: inputValue,
    lks_koord: 'true',
    atvk: 'true',
    struct: 'true',
  }

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true)

      fetchAddresses(addressQueryParams)
        .then((resp) => {
          setOptions(resp)
        })
        .catch((e) => {
          setOptions([])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    const timeoutId = setTimeout(() => {
      fetchData()
    }, 500)

    return () => {
      clearTimeout(timeoutId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <FormControl
      variant="outlined"
      error={error}
      fullWidth
      sx={{
        flexGrow: 1,
        minWidth: 400,
      }}
      disabled={disabled}
    >
      <Autocomplete
        disabled={disabled}
        value={value}
        onChange={(e, newValue) =>
          onValueChange(
            e,
            {
              ...newValue,
              address: newValue?.address || '',
              geom: stringifyGeom(lks92ToWgs84(newValue?.lksLong || 0, newValue?.lksLat || 0)),
            },
            index
          )
        }
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        loading={isLoading}
        getOptionLabel={(option) => option.address + (option.zipCode ? `, ${option.zipCode}` : '')}
        options={options}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        filterOptions={(options) => options.filter(({ irAdrese }) => irAdrese)}
        sx={{ flexGrow: 1 }}
        noOptionsText={'Adrese netika atrasta'}
        renderOption={(props, jsonResults) => (
          <Box component="li" {...props} key={jsonResults.code}>
            {jsonResults.address}
          </Box>
        )}
        renderInput={(params) => (
          <TextField multiline {...params} sx={{ flexGrow: 1 }} size="small" />
        )}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
