import axios from 'axios'

export async function exportHonoraryFamilyCardsRequest() {
  const response = await axios.get('/data/atskaite_apliecību_saraksts', {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  })

  return response.data
}

export async function exportCurrentHonoraryFamilyCardsRequest() {
  const response = await axios.get('/data/atskaite_aktuālo_goda_ģimeņu_apliecību_saraksts', {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function exportCooperationPartnersByGroupNumberRequest() {
  const response = await axios.get('/data/atskaite_sadarbības_partneri_pa_grupām_skaita_saraksts', {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function exportAuthorizationCountRequest(startDate: string, endDate: string) {
  const response = await axios.get('/data/atskaite_autorizācijas_izsaukumi', {
    params: {
      sākuma_datums: startDate,
      beigu_datums: endDate,
    },
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function exportCooperationPartnersActionsRequest() {
  const response = await axios.get('/data/atskaite_sadarbības_partneru_akcija_saraksts', {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function exportCooperationPartnersDiscountsRequest() {
  const response = await axios.get('/data/atskaite_sadarbības_partneru_atlaižu_saraksts', {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function exportCooperationPartnersRequest() {
  const response = await axios.get('/data/atskaite_sadarbības_partneru_saraksts', {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriodRequest(
  startDate: string,
  endDate: string
) {
  const response = await axios.get(
    '/data/atskaite_aktuālo_goda_ģimeņu_apliecību_skaita_dinamika_saraksts',
    {
      params: {
        sākuma_datums: startDate,
        beigu_datums: endDate,
      },
      responseType: 'blob',
      headers: {
        accept: 'application/vnd.ms-excel',
      },
    }
  )

  return response.data
}

export async function exportIssuedHonoraryCertificatesOfFamiliesInAPeriodRequest(
  startDate: string,
  endDate: string
) {
  const response = await axios.get('/data/atskaite_izsniegtās_goda_ģimeņu_apliecības_saraksts', {
    params: {
      sākuma_datums: startDate,
      beigu_datums: endDate,
    },
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}
export async function exportDeniedCountOfHonoraryFamilyCertificatesRequest(
  startDate: string,
  endDate: string
) {
  const response = await axios.get(
    '/data/atskaite_noraidīto_goda_ģimeņu_apliecību_skaita_saraksts',
    {
      params: {
        sākuma_datums: startDate,
        beigu_datums: endDate,
      },
      responseType: 'blob',
      headers: {
        accept: 'application/vnd.ms-excel',
      },
    }
  )

  return response.data
}

export async function exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriodRequest(
  startDate: string,
  endDate: string
) {
  const response = await axios.get(
    '/data/atskaite_noraidīto_goda_ģimeņu_apliecību_skaita_saraksts',
    {
      params: {
        sākuma_datums: startDate,
        beigu_datums: endDate,
      },
      responseType: 'blob',
      headers: {
        accept: 'application/vnd.ms-excel',
      },
    }
  )

  return response.data
}
