import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
  Button,
  FormControlLabel,
} from '@mui/material'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { PartnerListFilterState } from '../../interfaces/Partners/PartnerListFilterState'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const sx = {
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  formItem: {
    flex: '40%',
    marginRight: '16px',
  },
  filterClear: {
    textDecoration: 'underline',
    maxWidth: '125px',
  },
}

interface Props {
  filter: PartnerListFilterState
  partnerTypeList: ClassifierStatus[]
  partnerServiceGroupList: ClassifierStatus[]
  partnerStatusList: ClassifierStatus[]
  isLoading: boolean
  resetFilterValues: () => void
  onTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (name: string, values: ClassifierStatus[]) => void
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  onSecondaryFilterToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function PartnersFilter({
  filter,
  partnerTypeList,
  partnerServiceGroupList,
  partnerStatusList,
  isLoading,
  onTextFieldChange,
  onCheckboxChange,
  resetFilterValues,
  onSelectChange,
  onSecondaryFilterToggle,
}: Props) {
  const mainPartnerFilters = [
    {
      id: 'partner-type-picker',
      options: partnerTypeList,
      value: filter.veids,
      filterKey: 'veids',
      label: 'Partnera veids',
    },
    {
      id: 'service-group-picker',
      options: partnerServiceGroupList,
      value: filter.grupa,
      filterKey: 'grupa',
      label: 'Preces un pakalpojumu grupas',
    },
    {
      id: 'partner-status-picker',
      options: partnerStatusList,
      value: filter.statuss,
      filterKey: 'statuss',
      label: 'Partnera statuss',
    },
  ]
  const restPartnerFilters = [
    {
      label: 'Neizskatītās sūdzības',
      name: 'ir_sūdzības',
      checked: filter.ir_sūdzības || false,
    },
    {
      label: 'Neizskatītās atbildes uz informācijas pieprasījumiem',
      name: 'ir_pieprasījumi',
      checked: filter.ir_pieprasījumi || false,
    },
    {
      label: 'Nepublicētās akcijas',
      name: 'ir_akcijas',
      checked: filter.ir_akcijas || false,
    },
    {
      label: 'Neizpildītie uzdevumi',
      name: 'ir_uzdevumi',
      checked: filter.ir_uzdevumi || false,
    },
  ]

  return (
    <Box display="flex" alignItems="start" flexDirection="column" height="100%">
      <Box display="flex" alignItems="start" width="100%">
        <Box component="form" sx={sx.formWrapper}>
          <TextField
            size="small"
            label="Atslēgvārdi"
            name="atslēgvārdi"
            disabled={isLoading}
            value={filter.atslēgvārdi}
            onChange={onTextFieldChange}
            variant="outlined"
            sx={sx.formItem}
          />
          {mainPartnerFilters.map((filterItem, index) => (
            <Box key={index} sx={sx.formItem} style={{ marginTop: index > 0 ? '16px' : '' }}>
              <Autocomplete
                multiple
                id={filterItem.id}
                fullWidth
                size="small"
                disabled={isLoading}
                options={filterItem.options}
                disableCloseOnSelect
                getOptionLabel={(option) => option.nosaukums}
                value={filterItem.value}
                onChange={(event, values) => onSelectChange(filterItem.filterKey, values)}
                renderTags={(value: ClassifierStatus[], getTagProps) =>
                  value.map((option: ClassifierStatus, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option.nosaukums}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.nosaukums}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={filterItem.label} name={filterItem.filterKey} />
                )}
              />
            </Box>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          width="20%"
        >
          <Button sx={sx.filterClear} variant="text" color="primary" onClick={resetFilterValues}>
            Notīrīt filtrus
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.secondaryFilters || false}
                size="small"
                onChange={onSecondaryFilterToggle}
                name="secondaryFilters"
              />
            }
            label="Rādīt visus filtrus"
          />
        </Box>
      </Box>

      {filter.secondaryFilters ? (
        <Box display="flex" flexDirection="column" flexWrap="wrap" mt={2}>
          {restPartnerFilters.map(({ label, name, checked }, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox checked={checked} size="small" onChange={onCheckboxChange} name={name} />
              }
              label={label}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  )
}
