import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  deletePartnerProps,
  deleteService,
  editService,
  getPartnerProps,
  SERVICE_LIST_COUNT_ENDPOINT,
  SERVICE_LIST_ENDPOINT,
} from '../../../api/API'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { NewServiceReq, NewServiceForm } from '../../interfaces/NewServiceForm'
import { useSnackBar } from '../../providers/SnackBarProvider'
import NewServiceModal from './NewServiceModal'
import { Alert, Box, Button, Grid, Switch, Typography } from '@mui/material'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { TaskQueryParams } from '../../interfaces/Partners/TaskQueryParams'
import { paramFetcher } from '../../elements/ParamFetcher'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmDialog } from '../../elements/ConfirmDialog'
import NewPartnerPropsModal from './NewPartnerPropsModal'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { partnerPropsColumns, serviceColumns as staleServiceColumns } from './constants'
import { NewPartnerProps } from '../../interfaces/NewPartnerProps'

interface PartnerServicesProps {
  partnerId: number
}

export const PartnerServicesTabContent = ({ partnerId }: PartnerServicesProps) => {
  const { showSnackBar } = useSnackBar()
  const parameters = {
    sadarbības_partneris_id: partnerId,
    sort: 'id',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  }
  const [count, setCount] = useState<number>(0)
  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false)
  const [isPartnerPropsModalOpen, setIsPartnerPropsModalOpen] = useState<boolean>(false)
  const [isToggleDisabled, setToggleDisabled] = useState<boolean>(false)
  const [serviceData, setServiceData] = useState<NewServiceReq[] | null>(null)
  const [partnertPropsData, setPartnerPropsData] = useState<NewPartnerProps[] | null>(null)
  const [selectedService, setSelectedService] = useState<NewServiceForm | undefined>(undefined)
  const [selectedPartnerProps, setSelectedPartnerProps] = useState<NewPartnerProps | undefined>(
    undefined
  )
  const [queryParams, setQueryParams] = useState<TaskQueryParams>(parameters)

  const onCloseServiceModal = () => {
    setIsServiceModalOpen(false)
    refetchServices()
  }

  const onClosePartnerPropsModal = () => {
    setIsPartnerPropsModalOpen(false)
    setSelectedPartnerProps(undefined)
    refetchPartnerProps()
  }

  const { isLoading: isLoadingPartnerProps, refetch: refetchPartnerProps } = useQuery(
    ['partnerProps', parameters],
    () => getPartnerProps(parameters),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPartnerPropsData(data)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const { isLoading: isLoadingServices, refetch: refetchServices } = useQuery(
    ['partnerServices', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        SERVICE_LIST_ENDPOINT,
        SERVICE_LIST_COUNT_ENDPOINT
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([data, count]) => {
        setServiceData(data)
        setCount(count)
        setToggleDisabled(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const [editServiceRecordMutation] = useMutation(
    (service: NewServiceReq) => editService(service),
    {
      onSuccess: (e) => {
        showSnackBar()
        refetchServices()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setToggleDisabled(false)
      },
    }
  )

  const onToggle = (e: NewServiceReq) => {
    setToggleDisabled(true)
    editServiceRecordMutation(e)
  }

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit! !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit!,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const serviceColumns = [
    ...staleServiceColumns,
    {
      title: 'Statuss',
      render: (row: NewServiceReq) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{row.ir_aktīvs ? 'aktīvs' : 'bloķēts'}</Typography>
            <Switch
              key={row.id}
              disabled={isToggleDisabled}
              checked={!!row.ir_aktīvs}
              onChange={() =>
                onToggle({
                  ...row,
                  ir_aktīvs: !row.ir_aktīvs,
                })
              }
            />
          </Box>
        )
      },
      width: '20%',
    },
  ]

  const [deletePartnerServiceMutation] = useMutation((id: number) => deleteService(id), {
    onSuccess: () => {
      showSnackBar()
      refetchServices()
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const [deletePartnerPropsMutation] = useMutation((id: number) => deletePartnerProps(id), {
    onSuccess: () => {
      showSnackBar()
      refetchPartnerProps()
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const onServiceEdit = (_: React.MouseEvent, promotion: NewServiceForm) => {
    setSelectedService(promotion)
    setIsServiceModalOpen(true)
  }

  const onServiceDelete = async (_: React.MouseEvent, { id }: { [key: string]: any }) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst servisu ${id}?`,
    })

    if (confirm.ok) {
      deletePartnerServiceMutation(id)
    }
  }

  const onPartnerPropsEdit = (_: React.MouseEvent, partnerProps: NewPartnerProps) => {
    setSelectedPartnerProps(partnerProps)
    setIsPartnerPropsModalOpen(true)
  }

  const onPartnerPropsDelete = async (_: React.MouseEvent, partnerProps: NewPartnerProps) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst lietotāja "${partnerProps.lietotājvārds}" rekvizītus?`,
    })

    if (confirm.ok) {
      deletePartnerPropsMutation(partnerProps.id)
    }
  }

  const serviceActions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onServiceEdit,
      tooltip: 'Rediģēt',
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onServiceDelete,
      tooltip: 'Dzēst',
    },
  ]

  const partnerPropsActions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onPartnerPropsEdit,
      tooltip: 'Rediģēt',
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor="#FFA64B" />,
      onClick: onPartnerPropsDelete,
      tooltip: 'Dzēst',
    },
  ]

  return (
    <>
      {isServiceModalOpen && (
        <NewServiceModal
          partnerId={partnerId}
          service={selectedService}
          onClose={onCloseServiceModal}
        />
      )}
      <>
        <Grid container sx={{ mb: '20px' }} xs={12} justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedService(undefined)
              setIsServiceModalOpen(true)
            }}
          >
            Pievienot servisu
          </Button>
        </Grid>
        {isLoadingServices ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
            <DelayedLoader />
          </Box>
        ) : serviceData?.length ? (
          <CustomizedMaterialTable
            columns={serviceColumns}
            data={serviceData}
            isLoading={isLoadingServices}
            page={queryParams.offset! / queryParams.limit!}
            totalCount={count}
            pageSize={queryParams.limit}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            actions={serviceActions}
            rowStyle={{
              height: 50,
            }}
          />
        ) : (
          <Alert severity="info">Sadarbības personai nav pievienotu servisu</Alert>
        )}
        {isPartnerPropsModalOpen && (
          <NewPartnerPropsModal
            partnerId={partnerId}
            onClose={onClosePartnerPropsModal}
            partnerProps={selectedPartnerProps}
          />
        )}
        <Grid
          container
          sx={{ mb: '20px', mt: '30px' }}
          xs={12}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsPartnerPropsModalOpen(true)
            }}
            disabled={!!partnertPropsData?.length}
          >
            Pievienot rekvizītus
          </Button>
        </Grid>
        {isLoadingPartnerProps ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
            <DelayedLoader />
          </Box>
        ) : partnertPropsData?.length ? (
          <CustomizedMaterialTable
            columns={partnerPropsColumns}
            data={partnertPropsData}
            options={{ paging: false }}
            actions={partnerPropsActions}
            rowStyle={{
              height: 50,
            }}
          />
        ) : (
          <Alert severity="info">Sadarbības personai nav pievienoti lietotāja rekvizīti</Alert>
        )}
      </>
    </>
  )
}
