import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import lvLocale from 'date-fns/locale/lv'
import { DatePicker as DatePickerControl, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_MASK } from '../../constants/constants'
import { SxProps, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'

interface Props extends Omit<DatePickerProps<Date, Date>, 'renderInput'> {
  helperText?: string
  error?: boolean
  minDate?: Date
  maxDate?: Date
  sx?: SxProps
}

export default function DatePicker(props: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lvLocale}>
      <DatePickerControl
        {...props}
        inputFormat={DEFAULT_DATE_FORMAT}
        mask={DEFAULT_DATE_MASK}
        minDate={props.minDate}
        maxDate={props.maxDate}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              size="small"
              helperText={props.helperText}
              error={props.error}
              sx={props.sx}
              inputProps={{
                ...params.inputProps,
                placeholder: 'dd.mm.gggg',
              }}
            />
          )
        }}
      />
    </LocalizationProvider>
  )
}
