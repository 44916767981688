import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { ApplicationListFilterState } from '../../interfaces/Application/ApplicationListFilterState'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_MASK } from '../../../constants/constants'
import lvLocale from 'date-fns/locale/lv'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const sx = {
  filterControlSmall: {
    width: '240px',
    marginRight: '12px',
  },
  filterControlLarge: {
    width: '550px',
    marginRight: '12px',
  },
  keywordsFilter: {
    width: '492px',
  },
  filterClear: {
    marginLeft: 2,
    textDecoration: 'underline',
  },
}

interface Props {
  filter: ApplicationListFilterState
  statusList: ClassifierStatus[]
  isLoading: boolean
  onTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (event: SelectChangeEvent<string[]>) => void
  onSecondaryFilterToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  resetFilterValues: () => void
  onApplicationDateFromChange: (date: Date | null) => void
  onApplicationDateToChange: (date: Date | null) => void
  onModifiedDateFromChange: (date: Date | null) => void
  onModifiedDateToChange: (date: Date | null) => void
  onStatusChange: (status: ClassifierStatus[]) => void
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

// TODO: generic input validation
// TODO: max length validation

export default function ApplicationsFilter({
  filter,
  onTextFieldChange,
  onSecondaryFilterToggle,
  resetFilterValues,
  onApplicationDateFromChange,
  onApplicationDateToChange,
  onModifiedDateFromChange,
  onModifiedDateToChange,
  onStatusChange,
  onCheckboxChange,
  statusList,
  isLoading,
}: Props) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  return (
    <form>
      <TextField
        size="small"
        sx={sx.keywordsFilter}
        label="Atslēgvārdi"
        name="atslēgvārdi"
        value={filter.atslēgvārdi}
        onChange={onTextFieldChange}
        variant="outlined"
      />
      <Button sx={sx.filterClear} variant="text" color="primary" onClick={resetFilterValues}>
        Notīrīt filtrus
      </Button>
      <Box sx={{ mt: 2, display: 'flex' }}>
        <TextField
          size="small"
          sx={sx.filterControlSmall}
          label="Personas kods"
          name="personas_kods"
          value={filter.personas_kods}
          onChange={onTextFieldChange}
          variant="outlined"
        />
        <Autocomplete
          sx={sx.filterControlLarge}
          multiple
          size="small"
          disabled={isLoading}
          options={statusList}
          disableCloseOnSelect
          getOptionLabel={(option) => option.nosaukums}
          value={filter.statuss}
          onChange={(event, values) => onStatusChange(values)}
          renderTags={(value: ClassifierStatus[], getTagProps) =>
            value.map((option: ClassifierStatus, index: number) => (
              <Chip
                variant="outlined"
                label={option.nosaukums}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.nosaukums}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Statuss" name="statuss" />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filter.secondaryFilters}
              size="small"
              onChange={onSecondaryFilterToggle}
              name="secondaryFilters"
            />
          }
          label="Rādīt visus filtrus"
        />
      </Box>
      {filter.secondaryFilters && (
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.ir_neapstrādāti_dokumenti}
                  size="small"
                  onChange={onCheckboxChange}
                  name="ir_neapstrādāti_dokumenti"
                />
              }
              label="Neapstrādātie dokumenti"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.satur_anulējamas_apliecības}
                  size="small"
                  onChange={onCheckboxChange}
                  name="satur_anulējamas_apliecības"
                />
              }
              label="Anulējamās apliecības"
            />
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lvLocale}>
            <Box mt={2}>
              <DatePicker
                label="Iesniegšanas datums no"
                value={filter.iesniegšanas_datums_no}
                disableFuture={true}
                onChange={onApplicationDateFromChange}
                renderInput={(params) => (
                  <TextField size="small" sx={sx.filterControlSmall} {...params} />
                )}
                inputFormat={DEFAULT_DATE_FORMAT}
                mask={DEFAULT_DATE_MASK}
              />
              <DatePicker
                label="Iesniegšanas datums līdz"
                value={filter.iesniegšanas_datums_līdz}
                disableFuture={true}
                onChange={onApplicationDateToChange}
                renderInput={(params) => (
                  <TextField size="small" sx={sx.filterControlSmall} {...params} />
                )}
                inputFormat={DEFAULT_DATE_FORMAT}
                mask={DEFAULT_DATE_MASK}
              />
            </Box>
            <Box mt={2}>
              <DatePicker
                label="Pēdējās izmaiņas no"
                value={filter.aktualizacijas_datums_no}
                disableFuture={true}
                onChange={onModifiedDateFromChange}
                renderInput={(params) => (
                  <TextField size="small" sx={sx.filterControlSmall} {...params} />
                )}
                inputFormat={DEFAULT_DATE_FORMAT}
                mask={DEFAULT_DATE_MASK}
              />
              <DatePicker
                label="Pēdējās izmaiņas līdz"
                value={filter.aktualizacijas_datums_līdz}
                disableFuture={true}
                onChange={onModifiedDateToChange}
                renderInput={(params) => (
                  <TextField size="small" sx={sx.filterControlSmall} {...params} />
                )}
                inputFormat={DEFAULT_DATE_FORMAT}
                mask={DEFAULT_DATE_MASK}
              />
            </Box>
          </LocalizationProvider>
        </Box>
      )}
    </form>
  )
}
