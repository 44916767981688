import React, { useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { ApplicationTabPanel, applicationTabA11yProps } from './ApplicationTabPanel'
import { ApplicationBackButton } from './ApplicationBackButton'
import { useQuery } from 'react-query'
import { getApplicationCards } from './ApplicationUtils'
import routes from '../../routes/routes'
import { reverse } from 'named-urls'
import { DelayedLoader } from '../../elements/DelayedLoader'
import { fetchPersonData } from '../../../api/API'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { Person } from '../../interfaces/Person'
import { ApplicationCards } from './ApplicationCards'
import { ApplicationTabContent } from './ApplicationTabContent'
import { ApplicationChildrenTabContent } from './ApplicationChildrenTabContent'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import ApplicationNotificationTab from './Notifications/ApplicationNotificationTab'
import { useCurrentUser } from '../../../store/user'
import { hasUserRole } from '../../utils/role'
import { UserRoles } from '../../enums/UserRoles'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { AxiosError } from 'axios'
import { PAGE_NOT_FOUND } from '../../../constants/constants'
import { userDataProps } from '../../interfaces/UserDataProps'
import { ApplicationHistory } from './ApplicationHistory'
import useDocumentProcessing from '../../hooks/useDocumentProcessing'

const applicationStatusesQueryParams = {
  klasifikatora_kods: 'DOKUMENTA_STATUSS',
}

const sx = {
  applicant: {
    pl: 4,
    borderLeft: '1px solid #999',
    fontWeight: 700,
    color: '#999',
    fontSize: '1.2rem',
  },
  collapseContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
    backgroundColor: '#A8A9AD',
    padding: '5px',
    borderRadius: '8px',
    border: '1px solid #000000',
    color: 'white',
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

const defaultApplicationTabNames = ['Iesniegums', 'Bērni', 'Apliecības', 'Vēsture']

export default function Application() {
  const history = useHistory()
  const currentUser = useCurrentUser((state) => state.currentUser)

  const [activeTab, setActiveTab] = useState(0)
  const [isNewApplication, setIsNewApplication] = useState<boolean>(true)
  const [doneInit, setDoneInit] = useState(false)
  const [userInfo, setUserInfo] = React.useState<null | Person>(null)
  const [refetchApplicationEntry, setRefetchApplicationEntry] = useState(false)
  const [applicationCards, setApplicationCards] = useState<ApplicationCard[]>()
  const [documentStatusList, setDocumentStatusList] = useState<ClassifierStatus[]>([])
  const [canManageNotifications, setCanManageNotifications] = useState(false)
  const [applicationTabNames, setApplicationTabNames] = useState(defaultApplicationTabNames)
  const [filterCheck, setFilterCheck] = React.useState(false)

  const { applicationId } = useParams<{ applicationId: string }>()
  const applicationIdParameter = isNewApplication ? '' : applicationId
  const {
    data: applicationEntry,
    isLoading,
    error,
  } = useDocumentProcessing(
    applicationIdParameter,
    refetchApplicationEntry,
    isNewApplication,
    setDoneInit,
    setRefetchApplicationEntry
  )
  const userData = {
    persona_id: applicationEntry?.persona_id,
    personas_vārds: applicationEntry?.personas_vārds,
    personas_uzvārds: applicationEntry?.personas_uzvārds,
    personas_kods: applicationEntry?.personas_kods,
    telefoni: applicationEntry?.telefoni || [],
    epasti: applicationEntry?.epasti || [],
  } as userDataProps

  const addPenultimateElementToArray = (element: string) => {
    const defaultApplicationTabNamesClone = defaultApplicationTabNames.slice(0)
    defaultApplicationTabNamesClone.splice(-1, 0, element)

    return defaultApplicationTabNamesClone
  }

  useEffect(() => {
    const isNew = applicationId === 'new'

    setIsNewApplication(isNew)

    if (isNew) {
      setDoneInit(true)
    }
  }, [applicationId])

  useEffect(() => {
    if (currentUser) {
      const isPartnerCoordinator = hasUserRole(currentUser.lomas, [UserRoles.Partner_coordinator])
      const isProgramCoordinator = hasUserRole(currentUser.lomas, [UserRoles.Program_coordinator])

      setCanManageNotifications(isPartnerCoordinator || isProgramCoordinator)
      setApplicationTabNames(
        isPartnerCoordinator || isProgramCoordinator
          ? addPenultimateElementToArray('Saziņa')
          : defaultApplicationTabNames
      )
    }
  }, [currentUser])

  const { isLoading: isLoadingCardData, refetch: refetchCardData } = useQuery(
    ['applicationCards', applicationIdParameter],
    () => getApplicationCards(applicationEntry?.persona_id || 0, applicationId),
    {
      enabled: !isNewApplication,
      refetchOnWindowFocus: false,
      retry: (_, errorResponse: AxiosError) => {
        const is404Error = errorResponse?.response?.status === 404
        return !is404Error
      },
      onSuccess: (cards: ApplicationCard[]) => {
        setApplicationCards(cards && cards.length ? cards : [])
      },
    }
  )

  const { isLoading: isLoadingUserData, refetch: refetchUserData } = useQuery(
    ['user_info'],
    () => fetchPersonData(applicationEntry!.persona_id),
    {
      enabled: !isNewApplication && !!applicationEntry,
      refetchOnWindowFocus: false,
      onSuccess: (resp: any) => {
        setUserInfo(resp)
      },
    }
  )

  const { isLoading: isLoadingDocumentStatuses } = useFetchClassifiers({
    queryKey: 'document_status_classifier',
    queryParameters: applicationStatusesQueryParams,
    enabled: !isNewApplication,
    querySuccess: (resp) => {
      setDocumentStatusList(convertToClassifierStatus(resp))
    },
  })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const onNewApplication = (applicationId: number) => {
    setDoneInit(false)
    history.push(reverse(routes.applications.details, { applicationId }))
    setIsNewApplication(false)
  }

  const triggerApplicationRefetch = () => {
    setRefetchApplicationEntry(true)
  }

  const reload = () => {
    if (!isNewApplication) {
      setDoneInit(false)
      triggerApplicationRefetch()
      refetchCardData()
      refetchUserData()
    }
  }

  const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCheck(event.target.checked)
  }

  if (error === 404) return <h3>{PAGE_NOT_FOUND}</h3>

  return (
    <>
      {doneInit &&
      !isLoadingDocumentStatuses &&
      !isLoadingUserData &&
      !isLoadingCardData &&
      !isLoading &&
      (!isNewApplication ? userInfo : true) ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h1" sx={{ mr: 4 }}>
              {isNewApplication ? 'Iesnieguma datu ievade' : 'Aktuālās lietas apskate'}
            </Typography>
            {applicationEntry && (
              <Typography sx={sx.applicant}>
                {`${applicationEntry?.personas_vārds} ${applicationEntry?.personas_uzvārds}, ${applicationEntry?.personas_kods}`}
              </Typography>
            )}
          </Box>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
                sx={{ mt: 2 }}
                aria-label="Iesnieguma sadaļas"
              >
                {applicationTabNames.map((tabName, index) => {
                  return (
                    <Tab
                      label={tabName}
                      key={index}
                      disabled={isNewApplication && index > 0}
                      {...applicationTabA11yProps(index)}
                    />
                  )
                })}
              </Tabs>
            </Box>

            <ApplicationTabPanel value={activeTab} index={0}>
              <ApplicationTabContent
                applicationEntry={applicationEntry}
                userInfo={userInfo}
                onNewApplication={onNewApplication}
                documentStatusList={documentStatusList}
                reload={reload}
              />
              <ApplicationBackButton isNewApplication={isNewApplication} />
            </ApplicationTabPanel>

            <ApplicationTabPanel value={activeTab} index={1}>
              <ApplicationChildrenTabContent
                applicationEntry={applicationEntry}
                applicationCards={applicationCards || []}
                onNewApplication={onNewApplication}
                documentStatusList={documentStatusList}
                reload={reload}
              />
              <ApplicationBackButton />
            </ApplicationTabPanel>

            <ApplicationTabPanel value={activeTab} index={2}>
              {applicationEntry && (
                <>
                  <Grid container sx={{ paddingBottom: '20px' }}>
                    <Grid item xs={9}>
                      <Box sx={sx.inputWrapper}>
                        <Box component="span" sx={sx.inputLabel}>
                          Rādīt visas
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterCheck}
                              color="primary"
                              onChange={onCheckChange}
                            />
                          }
                          label=""
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <ApplicationCards filterCheck={filterCheck} applicationEntry={applicationEntry} />
                </>
              )}
              <ApplicationBackButton />
            </ApplicationTabPanel>

            {canManageNotifications && (
              <ApplicationTabPanel value={activeTab} index={3}>
                {applicationEntry && (
                  <ApplicationNotificationTab userData={userData} userInfo={userInfo} />
                )}
                <ApplicationBackButton />
              </ApplicationTabPanel>
            )}

            <ApplicationTabPanel value={activeTab} index={4}>
              <ApplicationHistory />
            </ApplicationTabPanel>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <DelayedLoader />
        </Box>
      )}
    </>
  )
}
