import React, { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import { useQuery, useMutation } from 'react-query'
import {
  FILE_SIZE_LIMIT_MB_MESSAGE,
  INVALID_PERSON_CODE_FORMAT,
  REGEX_NUMERIC_DASH,
  REGEX_PERSON_CODE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { RequiredMarker } from '../../elements/RequiredMarker'
import { createNewApplication, getApplicationEntry } from './ApplicationUtils'
import { NewApplication, NewApplicationResponse } from '../../interfaces/NewApplication'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { Loader } from '../../elements/Loader'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { uploadFiles } from '../../utils/uploadFiles'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { convertPersonCode } from '../../utils/convertPersonCode'
import { getApplicationByPersonCode } from '../../../api/API'
import routes from '../../routes/routes'
import { FileEntry } from '../../interfaces/File/FileEntry'

const sx = {
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  submitWrap: {
    justifyContent: 'flex-end',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
}

const defaultFormErrors = {
  vārds: '',
  personas_kods: '',
  uzvārds: '',
}

interface Props {
  onNewApplication: (applicationId: number) => void
}

export default function NewApplicationTab({ onNewApplication }: Props) {
  const { showSnackBar } = useSnackBar()
  const history = useHistory()
  const [isLoading, setIsLoading] = React.useState(false)
  const [files, setFiles] = useState<FileListItem[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<FileEntry[]>([])
  const [applicationId, setApplicationId] = React.useState<string>('')
  const [newApplication, setNewApplication] = useState<NewApplication>({
    // vārds: '',
    piekrīt_veselības_datu_apstrādei: false,
    personas_kods: '',
    // uzvārds: '',
  })
  const [formErrors, setFormErrors] = useState(defaultFormErrors)

  const [addApplication] = useMutation<NewApplicationResponse, unknown, NewApplication>(
    (newApplicationData) => createNewApplication(newApplicationData),
    {
      onSuccess: (application: NewApplicationResponse) => {
        setApplicationId(application.id.toString())
      },
      onError: async (error: any) => {
        const hasAlreadyApplied = error?.response?.data?.[0].kļūdas?.[0] === 'unique_iesniegums'
        if (hasAlreadyApplied) {
          const applicationByPersonCode = await getApplicationByPersonCode(
            convertPersonCode(newApplication.personas_kods)
          )
          showSnackBar({
            severity: 'warning',
            text: handleErrorMessages(error),
          })
          history.push(
            reverse(routes.applications.details, { applicationId: applicationByPersonCode?.id })
          )
          return
        }
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  useQuery(['application', applicationId], () => getApplicationEntry(parseInt(applicationId)), {
    enabled: !!applicationId,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      showSnackBar()
      setIsLoading(false)
      onNewApplication(parseInt(applicationId))
    },
    onError: (error: any) => {
      setIsLoading(false)
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoading(true)

    if (uploadedFiles.length) {
      Object.assign(newApplication, {
        dokuments: [
          {
            faili: uploadedFiles,
            nosaukums: 'Apliecinājuma dokuments',
            komentārs: '',
            izveidošanas_datums: null,
            termiņš: null,
          },
        ],
      })
    }

    const modifiedNewApplication = {
      ...newApplication,
      personas_kods: convertPersonCode(newApplication.personas_kods),
    }

    addApplication(modifiedNewApplication)
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!newApplication.personas_kods) {
      errors['personas_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (newApplication.personas_kods && !newApplication.personas_kods.match(REGEX_PERSON_CODE)) {
      errors['personas_kods'] = INVALID_PERSON_CODE_FORMAT
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }

    setNewApplication({
      ...newApplication,
      [event.target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onPersonCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value !== '' && !REGEX_NUMERIC_DASH.test(value)) {
      return
    }

    setNewApplication({
      ...newApplication,
      [event.target.name]: value,
    })
  }

  const onFileChange = (files: FileListItem[]) => {
    setFiles(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFiles((prevFormState) => [
            ...prevFormState,
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFiles((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFiles([])
        setUploadedFiles([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <form onSubmit={onSubmit}>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Personas kods
          <RequiredMarker />
        </Box>
        <TextField
          size="small"
          name="personas_kods"
          value={newApplication.personas_kods}
          onChange={onPersonCodeChange}
          helperText={formErrors.personas_kods}
          error={Boolean(formErrors.personas_kods)}
        />
      </Box>
      <Box sx={sx.formRow}>
        <Box sx={sx.labelRow} />
        <FormControlLabel
          control={
            <Checkbox
              checked={newApplication.piekrīt_veselības_datu_apstrādei}
              size="small"
              onChange={onChange}
              name="piekrīt_veselības_datu_apstrādei"
            />
          }
          label={
            <Typography component="span" fontSize="inherit">
              Apliecinājums, ka persona piekrīt Veselības datu apstrādes noteikumiem īpašos
              gadījumos
            </Typography>
          }
        />
      </Box>

      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Apliecinājums
        </Box>
        <FileUploadButton onFileChange={onFileChange} files={files} />
      </Box>

      <Box sx={[sx.formRow, sx.submitWrap]}>
        <Button variant="outlined" color="primary" type="submit">
          Veikt pārbaudi
        </Button>
      </Box>

      <Loader open={isLoading} />
    </form>
  )
}
