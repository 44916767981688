import React from 'react'
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { withStyles } from '@mui/styles'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useHistory, useLocation } from 'react-router-dom'
import LogoIcon from './icons/LogoIcon'
import routes from '../routes/routes'
import { useQuery } from 'react-query'
import { logout } from '../../api/API'
import { useSnackBar } from '../providers/SnackBarProvider'
import { Classifier } from '../interfaces/Classifier'
import { hasUserRole } from '../utils/role'
import { UserRoles } from '../enums/UserRoles'
import { CurrentUser } from '../interfaces/CurrentUser'
import useFetchClassifiersData from '../../api/useFetchClassifierData'
import { handleErrorMessages } from '../utils/handleErrorMessages'
import { useViewport } from '../hooks/useViewport'

const sx = {
  menuButton: {
    padding: '0px 20px',
    alignSelf: 'stretch',
    borderRadius: 0,
    color: 'inherit',
    '&:first-of-type': {
      marginLeft: '0',
    },
  },
  profileButton: {
    marginLeft: 'auto',
  },
  toolbar: {
    fontWeight: 500,
    color: '#FFF',
  },
  logoText: {
    marginLeft: '10px',
    maxWidth: '100px',
    font: 'inherit',
    lineHeight: '100%',
  },
  homeIcon: {
    width: 55,
    minWidth: 55,
    height: 55,
    fill: 'white',
  },
  activeMenuItem: {
    color: 'primary.main',
    backgroundColor: 'secondary.light',
    '&:hover': {
      backgroundColor: 'secondary.light',
    },
  },
}

const StyledMenu = withStyles({
  paper: {
    borderRadius: 6,
    color: '#000000',
    minWidth: 300,
    border: 'solid 1px #A30A32',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px 0',
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme: Theme) => ({
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
    minHeight: '32px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}))(MenuItem)

interface Props {
  user: CurrentUser | null
}

export default function Navigation({ user }: Props) {
  const history = useHistory()
  const location = useLocation()
  const { showSnackBar } = useSnackBar()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { isSmallMonitor } = useViewport()
  const [openClassifierDropdown, setOpenClassifierDropdown] = React.useState<HTMLElement | null>(
    null
  )
  const [classifierData, setClassifierData] = React.useState<Classifier[]>([])

  const { Superadmin, Admin, Partner_coordinator, Program_coordinator } = UserRoles
  const isProgramCoordinator = hasUserRole(user?.lomas, [Program_coordinator])
  const isPartnerCoordinator = hasUserRole(user?.lomas, [Partner_coordinator])
  const isAdminOrSuperAdmin = hasUserRole(user?.lomas, [Superadmin, Admin])
  const menuItems = [
    {
      label: 'Informatīvais panelis',
      route: routes.info,
      isVisible: true,
    },
    {
      label: 'Aktuālās lietas',
      route: routes.applications.list,
      onClick: () => {
        if (location.pathname.startsWith(routes.applications.list)) {
          history.push({
            pathname: routes.applications.list,
            state: {
              keepFilters: true,
            },
          })
        } else {
          history.push(routes.applications.list)
        }
      },
      isVisible: isProgramCoordinator,
    },
    {
      label: 'Apliecību izgatavošana',
      route: routes.certificates,
      isVisible: isProgramCoordinator,
    },
    {
      label: 'Partneru lietas',
      route: routes.partners.list,
      onClick: () => {
        history.push({
          pathname: routes.partners.list,
          state: { prevPathName: location.pathname },
        })
      },
      isVisible: isPartnerCoordinator,
    },
    {
      label: 'Atskaites',
      route: routes.report,
      isVisible: true,
    },
    {
      label: 'Klasifikatori',
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        setOpenClassifierDropdown(event.currentTarget)
        setAnchorEl(null)
      },
      isVisible: isAdminOrSuperAdmin,
    },
    {
      label: 'Parametri',
      route: routes.settings,
      isVisible: isAdminOrSuperAdmin,
    },
    {
      label: 'Lietotāju pārvaldība',
      route: routes.users,
      isVisible: isAdminOrSuperAdmin,
    },
    {
      label: 'Audits',
      route: routes.history.list,
      isVisible: isAdminOrSuperAdmin,
    },
    {
      label: 'Masveida izsūtīšana',
      route: routes.mail,
      isVisible: true,
    },
  ]

  useFetchClassifiersData({
    queryKey: 'classifiers',
    querySuccess: (data) => {
      if (data) setClassifierData(data)
      else setClassifierData([])
    },
  })

  const { refetch } = useQuery('logout', logout, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: () => {
      history.replace('/login')
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const handleLogout = () => {
    refetch()
  }

  const handleCloseClassifierMenu = () => {
    setOpenClassifierDropdown(null)
  }

  const handleMenuItemClick = (
    ev: React.MouseEvent<HTMLElement>,
    menuItem: typeof menuItems[0]
  ) => {
    if (typeof menuItem.onClick === 'function') menuItem.onClick(ev)
    else if (menuItem.route) history.push(menuItem.route)
    setAnchorEl(null)
  }
  const isRouteActive = (route: string) => {
    if (location.pathname.startsWith(route)) {
      return sx.activeMenuItem
    }
    return {}
  }

  return (
    <Box alignItems="center" display="flex" mb={4}>
      <AppBar position="static" color="primary">
        <Toolbar sx={sx.toolbar}>
          <Button sx={sx.menuButton} onClick={() => history.push(routes.info)}>
            <LogoIcon style={sx.homeIcon} />
          </Button>
          {isSmallMonitor ? (
            <Box
              sx={{
                ml: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <IconButton
                onClick={(ev: any) => setAnchorEl(ev.currentTarget)}
                color="primary"
                aria-label="menu"
                component="span"
              >
                <MenuIcon style={{ color: '#FFF' }} fontSize="large" />
                <Typography style={{ color: '#FFF', marginLeft: '8px' }}>
                  Galvenā izvēlne
                </Typography>
              </IconButton>
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                  style: {
                    minWidth: 200,
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button startIcon={<PersonIcon />}>
                    {user?.vārds} {user?.uzvārds}
                  </Button>
                  {menuItems
                    .filter((menuItem) => menuItem.isVisible)
                    .map((menuItem) => (
                      <Button
                        key={menuItem.route}
                        sx={{
                          textTransform: 'unset',
                        }}
                        onClick={(ev) => handleMenuItemClick(ev, menuItem)}
                      >
                        {menuItem.label}
                      </Button>
                    ))}

                  <Button
                    sx={{
                      textTransform: 'unset',
                    }}
                    endIcon={<ExitToAppIcon />}
                    onClick={handleLogout}
                  >
                    Iziet
                  </Button>
                </Box>
              </Menu>
            </Box>
          ) : (
            <>
              {menuItems
                .filter((menuItem) => menuItem.isVisible)
                .map((menuItem) => (
                  <Button
                    key={menuItem.route}
                    sx={[sx.menuButton, menuItem.route ? isRouteActive(menuItem.route) : null]}
                    onClick={(ev) => handleMenuItemClick(ev, menuItem)}
                  >
                    {menuItem.label}
                  </Button>
                ))}
              <Button sx={[sx.menuButton, sx.profileButton]} startIcon={<PersonIcon />}>
                {user?.vārds} {user?.uzvārds}
              </Button>
              <Button sx={sx.menuButton} endIcon={<ExitToAppIcon />} onClick={handleLogout}>
                Iziet
              </Button>
            </>
          )}
          <StyledMenu
            anchorEl={openClassifierDropdown}
            keepMounted
            open={Boolean(openClassifierDropdown)}
            onClose={handleCloseClassifierMenu}
          >
            {classifierData.map((dropDownItem: Classifier, index, items) => (
              <div key={index}>
                <StyledMenuItem
                  onClick={() => {
                    handleCloseClassifierMenu()
                    history.push({
                      pathname: `${routes.classifiers}/${dropDownItem.id}`,
                      state: {
                        data: dropDownItem,
                      },
                    })
                  }}
                  key={index}
                >
                  <ListItemText primary={dropDownItem.nosaukums} />
                </StyledMenuItem>
                {index !== items.length - 1 && (
                  <Divider style={{ margin: 0, background: '#000' }} variant="middle" />
                )}
              </div>
            ))}
          </StyledMenu>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
