import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { editSettingData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { PartnerSetting } from '../../interfaces/Partners/PartnerSetting'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

interface Props {
  setting: PartnerSetting | null
  onClose: () => void
}

export default function EditSettingModal({ setting, onClose }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const [formState, setFormState] = React.useState<PartnerSetting>({
    vērtība: setting?.vērtība || '',
    apraksts: setting?.apraksts || '',
    id: setting?.id,
  })

  const [editSettingMutation] = useMutation(
    ({ id, apraksts, vērtība }: PartnerSetting) =>
      editSettingData(
        {
          id: id,
          apraksts: apraksts,
          vērtības: [
            {
              vērtība: vērtība,
            },
          ],
        },
        id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = target.value

    setFormState({
      ...formState,
      [target.name]: value,
    })
  }

  const onSubmit = () => {
    editSettingMutation(formState)
    setIsLoadingSubmit(true)
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Labot vērtību
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Apraksts
              </Box>
              <Typography sx={{ maxWidth: '16rem' }}>{formState.apraksts}</Typography>
            </Box>

            <Box sx={sx.inputWrapper}>
              <Box component="span" sx={sx.inputLabel}>
                Vērtība
              </Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="vērtība"
                autoComplete="off"
                value={formState.vērtība}
                onChange={onFieldChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
