import { BEST_REGARDS_SIGNATURE_TEXT } from '../../constants/constants'

export default function removeSignatureFromMessage(message: string = '') {
  const bestRegardsStartIndex = message.lastIndexOf(BEST_REGARDS_SIGNATURE_TEXT)
  if (bestRegardsStartIndex >= 0) {
    return message.substring(bestRegardsStartIndex, 0)
  } else {
    return message
  }
}
