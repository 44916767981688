import React from 'react'
import { Box, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import routes from '../../routes/routes'

export function ApplicationBackButton({ isNewApplication = false }) {
  const history = useHistory()

  const onBack = () => {
    isNewApplication
      ? history.push(routes.applications.list)
      : history.push({
          pathname: routes.applications.list,
          state: {
            keepFilters: true,
          },
        })
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 2 }}>
      <Button variant="contained" color="primary" onClick={onBack}>
        {isNewApplication ? 'Atcelt' : 'Atgriezties lietu sarakstā'}
      </Button>
    </Box>
  )
}
