import { Autocomplete, Box, Button, Checkbox, Chip, Divider, TextField } from '@mui/material'
import React, { ChangeEvent, SyntheticEvent } from 'react'
import {
  ACCEPTED_PARTNER_FILE_TYPES,
  ACCEPTED_PARTNER_FILE_TYPES_LOGOTYPE,
  GOODS_SERVICES_LIMIT,
  TAG_LIMIT,
} from '../../../constants/constants'
import { FileUploadButton } from '../../elements/FileUploadButton'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { NewPartnerEntity } from '../../interfaces/Partners/NewPartnerEntity'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { Editor } from '../../elements/Editor/Editor'
import { FileEntry } from '../../interfaces/File/FileEntry'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const sx = {
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
    fontSize: 18,
    flexShrink: 0,
  },
}

interface Props {
  partner: NewPartnerEntity
  filesPhoto: FileListItem[]
  filesLogo: FileListItem[]
  partnerGroupList: ClassifierStatus[]
  partnerTagsList: ClassifierStatus[]
  setPartner: React.Dispatch<React.SetStateAction<NewPartnerEntity>>
  onFileChange: (
    files: FileListItem[],
    setState: React.Dispatch<React.SetStateAction<FileListItem[]>>,
    setUploadedFileState: React.Dispatch<React.SetStateAction<FileEntry[]>>
  ) => void
  setFilesLogo: React.Dispatch<React.SetStateAction<FileListItem[]>>
  setFilesPhoto: React.Dispatch<React.SetStateAction<FileListItem[]>>
  setUploadedFilesPhoto: React.Dispatch<React.SetStateAction<FileEntry[]>>
  setUploadedFilesLogo: React.Dispatch<React.SetStateAction<FileEntry[]>>
  handlePartnerChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onOnlineStoreAdd: () => void
  onOnlineStoreRemove: (index: number) => void
  onOnlineStoreTextFieldChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void
  formErrors: { [key: string]: string }
}

export const NewPartnerAdditionalData = ({
  filesPhoto,
  filesLogo,
  partner,
  partnerGroupList,
  partnerTagsList,
  setPartner,
  onFileChange,
  handlePartnerChange,
  setFilesLogo,
  setFilesPhoto,
  setUploadedFilesPhoto,
  setUploadedFilesLogo,
  onOnlineStoreAdd,
  onOnlineStoreRemove,
  onOnlineStoreTextFieldChange,
  formErrors,
}: Props) => {
  const { mājas_lapa, interneta_veikals, pakalpojumu_grupa, pakalpojumu_birkas } = partner

  const { showSnackBar } = useSnackBar()

  const [serviceGroups, setServiceGroups] = React.useState<ClassifierStatus[]>(
    pakalpojumu_grupa.map((group) => {
      return {
        kods: group.grupa_kods || '',
        nosaukums: group.grupa_nosaukums || '',
      }
    })
  )

  const [tags, setTags] = React.useState<ClassifierStatus[]>(
    pakalpojumu_birkas.map((tag) => {
      return {
        kods: tag.birka_kods || '',
        nosaukums: tag.birka_nosaukums || '',
      }
    })
  )

  const onServiceGroupChange = (
    _event: SyntheticEvent<Element, Event>,
    values: ClassifierStatus[]
  ) => {
    if (values.length > GOODS_SERVICES_LIMIT) {
      showSnackBar({
        severity: 'info',
        text: `Nevar norādīt vairāk kā ${GOODS_SERVICES_LIMIT} preču un pakalpojumu grupas`,
      })

      return
    }

    setServiceGroups(values)

    setPartner({
      ...partner,
      pakalpojumu_grupa: values.map((value) => {
        return {
          grupa_kods: value.kods,
        }
      }),
    })
  }

  const onTagChange = (_event: SyntheticEvent<Element, Event>, values: ClassifierStatus[]) => {
    if (values.length > TAG_LIMIT) {
      showSnackBar({
        severity: 'info',
        text: `Nevar norādīt vairāk kā ${TAG_LIMIT} nozares`,
      })

      return
    }

    setTags(values)

    setPartner({
      ...partner,
      pakalpojumu_birkas: values.map((value) => {
        return {
          birka_kods: value.kods,
        }
      }),
    })
  }

  return (
    <>
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Mājas lapa
        </Box>
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          name="mājas_lapa"
          onChange={handlePartnerChange}
          value={mājas_lapa}
          helperText={formErrors.mājas_lapa}
          error={Boolean(formErrors.mājas_lapa)}
        />
      </Box>
      {interneta_veikals?.map((onlineStore, index) => (
        <Box key={index}>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Interneta veikals
            </Box>
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              URL
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="url"
              onChange={(event) => onOnlineStoreTextFieldChange(event, index)}
              value={onlineStore.url}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Komentārs
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="komentārs"
              onChange={(event) => onOnlineStoreTextFieldChange(event, index)}
              value={onlineStore.komentārs}
            />
          </Box>
          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <Button onClick={() => onOnlineStoreRemove(index)} variant="outlined">
              Noņemt internetveikalu
            </Button>
          </Box>
          <Divider sx={{ mt: 2 }} />
        </Box>
      ))}
      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Interneta veikals
        </Box>
        <Button onClick={onOnlineStoreAdd} variant="contained" sx={{ ml: 'auto' }}>
          Pievienot internetveikalu
        </Button>
      </Box>

      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Preču un pakalpojumu grupas
        </Box>
        <Autocomplete
          multiple
          sx={{ flexGrow: 1 }}
          id="partner-group-picker"
          size="small"
          options={partnerGroupList}
          disableCloseOnSelect
          getOptionLabel={(option) => option.nosaukums}
          value={serviceGroups}
          isOptionEqualToValue={(option, value) => option.kods === value.kods}
          onChange={(event, values) => onServiceGroupChange(event, values)}
          renderTags={(value: ClassifierStatus[], getTagProps) =>
            value.map((option: ClassifierStatus, index: number) => (
              <Chip
                variant="outlined"
                label={option.nosaukums}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.nosaukums}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Grupas" />}
        />
      </Box>

      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Nozares
        </Box>
        <Autocomplete
          multiple
          sx={{ flexGrow: 1 }}
          id="partner-tag-picker"
          size="small"
          options={partnerTagsList}
          disableCloseOnSelect
          getOptionLabel={(option) => option.nosaukums}
          value={tags}
          isOptionEqualToValue={(option, value) => option.kods === value.kods}
          onChange={(event, values) => onTagChange(event, values)}
          renderTags={(value: ClassifierStatus[], getTagProps) =>
            value.map((option: ClassifierStatus, index: number) => (
              <Chip
                variant="outlined"
                label={option.nosaukums}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.nosaukums}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Nozares" />}
        />
      </Box>
      <Box sx={[sx.formRow, { wordBreak: 'break-all' }]}>
        <Box component="label" sx={sx.labelRow}>
          Svarīga informācija
        </Box>
        <Editor
          showMaxLength
          name="apraksts"
          onChange={handlePartnerChange}
          value={partner.apraksts}
        />
      </Box>

      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Publicitātes foto
        </Box>
        <Box>
          <FileUploadButton
            multiple={false}
            onFileChange={(e) => onFileChange(e, setFilesPhoto, setUploadedFilesPhoto)}
            files={filesPhoto}
            acceptedUploadFileTypes={ACCEPTED_PARTNER_FILE_TYPES}
          />
        </Box>
      </Box>

      <Box sx={sx.formRow}>
        <Box component="label" sx={sx.labelRow}>
          Logotips
        </Box>
        <Box>
          <FileUploadButton
            multiple={false}
            onFileChange={(e) => onFileChange(e, setFilesLogo, setUploadedFilesLogo)}
            files={filesLogo}
            acceptedUploadFileTypes={ACCEPTED_PARTNER_FILE_TYPES_LOGOTYPE}
          />
        </Box>
      </Box>
    </>
  )
}
