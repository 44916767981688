import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export const Loader = ({ open }: any) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
