import React, { useState } from 'react'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { Children } from '../../interfaces/Children'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { ApplicationRegistersCheckList } from './ApplicationRegistersCheckList'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import RequestNewCardModal from './RequestNewCardModal'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { sortChildrenByBirthdate } from './helpers'
import SelectChildrenForParentCardModal from './SelectChildrenForParentCardModal'

const sx = {
  formRow: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: 500,
  },
}

interface Props {
  areChildrenEligible?: Boolean
  childrenEntry: Children[] | undefined
  applicationCards: ApplicationCard[]
  applicationEntry: ApplicationEntry
  documentStatusList: ClassifierStatus[]
  reload: () => void
}

export function ChildrenTab({
  areChildrenEligible,
  childrenEntry,
  applicationCards,
  reload,
  documentStatusList,
  applicationEntry,
}: Props) {
  const [selectedChild, setSelectedChild] = useState<Children | null>(null)
  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [openModalSelectChildrenForParentCard, setOpenModalSelectChildrenForParentCard] =
    useState(false)

  const applicationPersonIds = applicationCards.map((card) => card.persona_id)
  const selectedCard =
    applicationCards.find((card) => card.persona_id === selectedChild?.persona_id) || null
  const sortedChildren = sortChildrenByBirthdate(childrenEntry)
  const modifiedChildrenEntry =
    sortedChildren && sortedChildren?.length > 0
      ? sortedChildren.map((child) => {
          return {
            ...child,
            hasCard: applicationPersonIds.includes(child.persona_id),
            isEligible: areChildrenEligible,
          }
        })
      : []

  const openModal = (child: Children) => {
    setSelectedChild(child)
    setOpenModalAddEdit(true)
  }

  const openSelectChildrenModal = () => {
    setOpenModalSelectChildrenForParentCard(true)
  }

  const closeRequestNewCardModal = () => {
    setOpenModalAddEdit(false)
  }

  const closeModal = () => {
    setOpenModalAddEdit(false)
    reload()
  }

  return (
    <>
      {openModalSelectChildrenForParentCard && (
        <SelectChildrenForParentCardModal
          card={selectedCard}
          applicationCards={applicationCards || []}
          applicationEntry={applicationEntry}
          onClose={closeModal}
        />
      )}
      {openModalAddEdit && (
        <RequestNewCardModal
          card={selectedCard}
          child={selectedChild}
          applicationCards={applicationCards || []}
          applicationEntry={applicationEntry}
          onClose={closeModal}
          closeRequestNewCardModal={closeRequestNewCardModal}
          openSelectChildrenModal={openSelectChildrenModal}
          refetch={reload}
        />
      )}
      {modifiedChildrenEntry.map((child, index) => (
        <Box
          key={index}
          sx={{
            border: '2px solid black',
            borderRadius: 2,
            p: 2,
            mt: index === 0 ? 0 : 2,
          }}
        >
          <Box sx={sx.formRow}>
            <Typography
              variant="h6"
              sx={{
                color: '#999',
              }}
            >
              {child.vārds} {child.uzvārds} {child.personas_kods}
            </Typography>
          </Box>
          <Box sx={sx.formRow}>
            <Typography>
              Dzimšanas datums:{' '}
              <Box component="span" sx={sx.label}>
                {child.dzimšanas_datums
                  ? format(parseISO(child.dzimšanas_datums), DEFAULT_DATE_FORMAT)
                  : ''}
              </Box>
            </Typography>
            <Box ml={2}>
              <IconButton
                aria-label="Add application"
                color="success"
                onClick={() => openModal(child)}
              >
                <CreateNewFolderIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider
            sx={{
              mt: 2,
              width: '100%',
            }}
          />
          <ApplicationRegistersCheckList
            applicationEntryId={applicationEntry.id}
            list={child.pārbaudes}
            documentStatusList={documentStatusList}
            reload={reload}
          />
        </Box>
      ))}
    </>
  )
}
