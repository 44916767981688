import React from 'react'
import {
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  SelectChangeEvent,
  Typography,
  Box,
} from '@mui/material'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'

interface TaskDropDownListProps {
  data: ClassifierStatus[]
  value: string | undefined
  name: string
  onChange: (event: SelectChangeEvent<string>) => void
  helperText?: string
  disabled?: boolean
  error?: boolean
}

export default function TaskDropDownList({
  data = [],
  helperText,
  error,
  disabled,
  ...props
}: TaskDropDownListProps) {
  return (
    <FormControl
      variant="outlined"
      error={error}
      sx={{ flexGrow: 1, minWidth: 400 }}
      disabled={disabled}
    >
      <Select size="small" {...props}>
        {data.map((data, index) => (
          <MenuItem key={index} value={data.kods}>
            <Box>
              {data.nosaukums}
              {data.piezīmes && (
                <Box pl={2}>
                  <Typography
                    color="gray"
                    variant="caption"
                    sx={{ display: 'table-caption', whiteSpace: 'pre-line' }}
                  >
                    {data.piezīmes}
                  </Typography>
                </Box>
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
