import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Tooltip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CancelIcon from '@mui/icons-material/Cancel'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  labelRow: {
    width: '150px',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
}

interface Props {
  card: ApplicationCard | null
  onClose: () => void
}

export default function CardActivationCodeModal({ card, onClose }: Props) {
  const { showSnackBar } = useSnackBar()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(card?.aktivizācijas_kods || '')

    showSnackBar({
      severity: 'success',
      text: 'Copied to clipboard!',
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Apliecības aktivizācijas kods
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex' }}>
              <Box component="label" sx={sx.labelRow}>
                Vārds, uzvārds
              </Box>
              <Typography>
                {card?.vārds} {card?.uzvārds}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center', alignItems: 'baseline' }}>
              <Box component="label" sx={sx.labelRow}>
                Aktivizācijas kods
              </Box>
              <TextField
                id="activation-code"
                variant="outlined"
                size="small"
                value={card?.aktivizācijas_kods || ''}
                inputProps={{
                  readOnly: true,
                }}
              />
              <Box>
                <Tooltip title="Kopēt starpliktuvē">
                  <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  )
}
