import { NewApplication, NewApplicationResponse } from '../../interfaces/NewApplication'
import { RegisterCheckDocument } from '../../interfaces/RegisterCheckDocument'
import { ApplicationCheck } from '../../interfaces/Application/ApplicationCheck'
import queryString from 'query-string'
import axios from 'axios'
import { ApplicationAgreement } from '../../interfaces/Application/ApplicationAgreement'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { isApplicationCardValid } from './helpers'

export const getApplicationEntry = async (applicationId: number) => {
  const response = await axios.get(`/data/iesniegums/${applicationId}`)

  return response.data
}

export const createNewApplication = async (
  newApplication: NewApplication
): Promise<NewApplicationResponse> => {
  const response = await axios.post('/data/izveidot_iesniegumu', newApplication)

  return response.data
}

export const updateExistingApplication = async (
  newApplication: NewApplication,
  applicationId: number
): Promise<NewApplicationResponse> => {
  const response = await axios.put(`/data/izveidot_iesniegumu/${applicationId}`, newApplication)

  return response.data
}

export const updateExistingApplicationAgreement = async (
  applicationAgreement: ApplicationAgreement,
  applicationId: number
): Promise<NewApplicationResponse> => {
  const response = await axios.put(
    `/data/iesnieguma_piekrišanas_veselības_datu_apstrādei_maiņa/${applicationId}`,
    applicationAgreement
  )

  return response.data
}

export const createNewCheckApplication = async (newApplication: ApplicationCheck) => {
  const response = await axios.post(`/data/iesnieguma_pārbaude`, newApplication)

  return response.data
}

export const changeRegisterCheckDocument = async (document: RegisterCheckDocument) => {
  const url = document.id
    ? `/data/personas_pārbaude_dokuments/${document.id}`
    : '/data/personas_pārbaude_dokuments'

  const response = document.id ? await axios.put(url, document) : await axios.post(url, document)

  return response.data
}

export const getApplicationCards = async (personId: number, inquiryId: string) => {
  const restParams = queryString.stringify({
    persona_id: personId,
    iesniegums_id: parseInt(inquiryId),
  })

  const response = await axios.get(`/data/apliecību_saraksts?${restParams}`)

  return response.data
}

export const getApplicationCardsByPersonId = async (personId: number) => {
  const restParams = queryString.stringify({
    persona_id: personId,
  })

  const response = await axios.get(`/data/apliecību_saraksts?${restParams}`)

  return response.data
}

export const getApplicationCardsByInquiryId = async (inquiryId: string) => {
  const restParams = queryString.stringify({
    iesniegums_id: parseInt(inquiryId),
  })

  const response = await axios.get(`/data/apliecību_saraksts?${restParams}`)

  return response.data
}

export const getApplicationHistory = async (applicationId: string) => {
  const restParams = queryString.stringify({
    iesniegums_id: applicationId,
  })

  const response = await axios.get(`/data/iesnieguma_aktivitātes?${restParams}`)

  return response.data
}

// TODO: maybe better to rename "Application card(s)" occurences to "Application certificate(s)"?
export const getActiveApplicationCardByPersonId = async (personId: number) => {
  const response = await axios.get(`/data/apliecību_saraksts`, {
    params: {
      persona_id: personId,
    },
  })
  const activeApplicationCards = Array.isArray(response.data)
    ? response.data.filter((cert: ApplicationCard) => isApplicationCardValid(cert))
    : []
  return activeApplicationCards?.[0]
}
