import React, { ChangeEvent, FormEvent, SyntheticEvent, useState } from 'react'
import { Box, Button, Grid, SelectChangeEvent } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { format } from 'date-fns'
import {
  DEFAULT_API_DATE_FORMAT,
  EMAIL_LENGTH,
  EXISTING_PERSON_CODE_IN_REGISTER,
  FILE_SIZE_LIMIT_MB_MESSAGE,
  INVALID_EMAIL_LENGTH,
  INVALID_HOME_PAGE_URL,
  INVALID_PERSON_CODE_FORMAT,
  NEW_ONLINE_STORE,
  PHONE_FORMAT_ERROR_MESSAGE,
  REGEX_HOME_PAGE_FORMAT,
  REGEX_PERSON_CODE,
  REGEX_PHONE_CODE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { Loader } from '../../elements/Loader'
import OutlinedContainer from '../../elements/OutlinedContainer'
import {
  AggregatedNewPartnerContactRequest,
  NewPartnerContact,
} from '../../interfaces/Partners/NewPartnerContact'
import {
  AggregatedNewPartnerDiscountRequest,
  NewPartnerDiscount,
} from '../../interfaces/Partners/NewPartnerDiscount'
import {
  AggregatedNewPartnerLocationRequest,
  NewPartnerLocation,
} from '../../interfaces/Partners/NewPartnerLocation'
import { aggregatedAddPartnerData, fetchRegistryData } from '../../../api/API'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import NewPartnerBaseData from './NewPartnerBaseData'
import NewPartnerAdditionalPropertyData from './NewPartnerAdditionalPropertyData'
import NewPartnerContactData from './NewPartnerContactData'
import { NewPartnerAdditionalData } from './NewPartnerAdditionalData'
import NewPartnerDiscountData from './NewPartnerDiscountData'
import NewPartnerLocationData from './NewPartnerLocationData'
import { uploadFiles } from '../../utils/uploadFiles'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { PartnerContract } from '../../interfaces/Partners/PartnerContract'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import {
  AggregatedNewPartnerEntity,
  AggregatedNewPartnerRequest,
  AggregatedNewPartnerResponse,
} from '../../interfaces/Partners/AggregatedNewPartner'
import { FileEntry } from '../../interfaces/File/FileEntry'

const sx = {
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    minWidth: 130,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  submitWrap: {
    justifyContent: 'flex-end',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
}

const defaultFormErrors = {
  //Partners
  reģistrācijas_numurs: '',
  nosaukums: '',
  pvn_reģistrācijas_numurs: '',
  juridiskā_adrese: '',
  konta_numurs: '',
  banka: '',
  swift: '',
  veids_kods: '',
  mājas_lapa: '',
  //Contacts
  personas_kods: '',
  epasts: '',
  telefons: '',
  loma_kods: '',
  //Discounts
  skaits: '',
}

const emptyContract = {
  komentārs: '',
  nosaukums: '',
  numurs: '',
  termiņš: null,
  izveidošanas_datums: new Date(),
} as PartnerContract

const partnerRoleQueryParams = {
  klasifikatora_kods: 'PARTNERA_PERSONAS_LOMA',
}

const partnerDiscountUnitQueryParams = {
  klasifikatora_kods: 'MĒRVIENĪBA',
}

interface Props {
  onNewPartner: (applicationId: number) => void
  partnerTypeList: ClassifierStatus[]
  partnerGroupList: ClassifierStatus[]
  partnerTagsList: ClassifierStatus[]
}

export default function NewPartner({
  onNewPartner,
  partnerTypeList,
  partnerGroupList,
  partnerTagsList,
}: Props) {
  const { showSnackBar } = useSnackBar()
  const [filesLogo, setFilesLogo] = useState<FileListItem[]>([])
  const [filesPhoto, setFilesPhoto] = useState<FileListItem[]>([])
  const [uploadedFilesPhoto, setUploadedFilesPhoto] = useState<FileEntry[]>([])
  const [uploadedFilesLogo, setUploadedFilesLogo] = useState<FileEntry[]>([])
  const [partnerRoleList, setPartnerRoleList] = useState<ClassifierStatus[]>([])
  const [partnerDiscountUnitList, setPartnerDiscountUnitList] = useState<ClassifierStatus[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const [newPartner, setNewPartner] = useState<AggregatedNewPartnerEntity>({
    reģistrācijas_numurs: '',
    nosaukums: '',
    apraksts: '',
    juridiskais_nosaukums: '',
    veids_kods: '',
    epasts: '',
    pvn_reģistrācijas_numurs: '',
    juridiskā_adrese: {
      address: '',
    },
    konta_numurs: '',
    statuss_kods: '',
    banka: '',
    swift: '',
    mājas_lapa: '',
    pakalpojumu_grupa: [],
    pakalpojumu_birkas: [],
    līgums: emptyContract,
    foto_id: null,
    logotips_id: null,
    atlaide: [],
    vieta: [],
    persona: [],
  })
  const [newContacts, setNewContacts] = useState<NewPartnerContact[]>([])
  const [newDiscounts, setNewDiscounts] = useState<NewPartnerDiscount[]>([])
  const [newLocations, setNewLocations] = useState<NewPartnerLocation[]>([])

  const [formErrors, setFormErrors] = useState(defaultFormErrors)

  const { isLoading: isLoadingPartnerRoles } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_roles',
    queryParameters: partnerRoleQueryParams,
    querySuccess: (resp) => {
      setPartnerRoleList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerDiscountUnit } = useFetchClassifiers({
    queryKey: 'classifier_records_discount_unit',
    queryParameters: partnerDiscountUnitQueryParams,
    querySuccess: (resp) => {
      setPartnerDiscountUnitList(convertToClassifierStatus(resp))
    },
  })

  const { refetch: refetchRegistryData, isFetching: isRegistryDataLoading } = useQuery(
    'registry-data',
    () => fetchRegistryData(newPartner.reģistrācijas_numurs),
    {
      onSuccess: (data) => {
        const { address, legalForm, name } = data

        setNewPartner({
          ...newPartner,
          nosaukums: name,
          juridiskais_nosaukums: name,
          veids_kods: legalForm,
          juridiskā_adrese: {
            address,
          },
        })
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const [aggregatedAddNewPartner] = useMutation<
    AggregatedNewPartnerResponse,
    unknown,
    AggregatedNewPartnerRequest
  >((newPartnerData) => aggregatedAddPartnerData(newPartnerData), {
    onSuccess: async (data) => {
      setIsLoading(false)
      showSnackBar()
      onNewPartner(data.id)
    },
    onError: (err) => {
      setIsLoading(false)
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(err),
      })
    },
  })

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoading(true)

    const payloadLocations: AggregatedNewPartnerLocationRequest[] = []
    const payloadContacts: AggregatedNewPartnerContactRequest[] = newContacts.map((contact) => ({
      ...contact,
      ir_bloķēts: false,
    }))
    const payloadDiscounts: AggregatedNewPartnerDiscountRequest[] = newDiscounts.map(
      (discount) => ({
        ...discount,
      })
    )

    await Promise.all(
      newLocations.map(async (location) => {
        const uploadResponse = await uploadFiles(location.fails || [])
        const faili = []

        for (const resp of uploadResponse) {
          const json = await resp.json()

          faili.push({
            faila_id: json.id,
          })
        }

        payloadLocations.push({
          ...location,
          adrese: {
            geom: location.adrese.geom || null,
            zip: location.adrese.zipCode || '',
            novads_nosaukums: location.adrese.novName || '',
            novads_atvk: location.adrese.novAtvk || '',
            pagasts_nosaukums: location.adrese.pagName || '',
            pilseta_atvk: location.adrese.pilAtvk || '',
            code: location.adrese.code || 0,
            adrese: location.adrese.address || '',
            pilseta_nosaukums: location.adrese.pilName || '',
            pagasts_atvk: location.adrese.pagAtvk || '',
            vzd_adrese: '',
          },
          faila_id: faili[0]?.faila_id,
        })
      })
    )

    aggregatedAddNewPartner({
      ...newPartner,
      juridiskā_adrese: {
        adrese: newPartner.juridiskā_adrese?.address || '',
      },
      foto_id: uploadedFilesPhoto[0]?.faila_id,
      logotips_id: uploadedFilesLogo[0]?.faila_id,
      apraksts: newPartner.apraksts,
      līgums: {
        ...newPartner.līgums,
        termiņš:
          newPartner && newPartner?.līgums?.termiņš
            ? format(newPartner.līgums.termiņš, DEFAULT_API_DATE_FORMAT)
            : null,
        izveidošanas_datums: format(new Date(), DEFAULT_API_DATE_FORMAT),
      },
      atlaide: payloadDiscounts,
      persona: payloadContacts,
      vieta: payloadLocations,
    })
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}
    const personCodes = newContacts.map(({ personas_kods }) => personas_kods)
    const duplicatePersonCodes = personCodes.filter(
      (item, index) => personCodes.indexOf(item) !== index
    )

    if (!newPartner.reģistrācijas_numurs) {
      errors['reģistrācijas_numurs'] = REQUIRED_ERROR_MESSAGE
    }

    if (!newPartner.nosaukums) {
      errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    }

    if (!newPartner.veids_kods) {
      errors['veids_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (newPartner.epasts && newPartner.epasts.length > EMAIL_LENGTH) {
      errors['epasts'] = INVALID_EMAIL_LENGTH
    }

    if (newPartner.mājas_lapa && !newPartner.mājas_lapa.match(REGEX_HOME_PAGE_FORMAT)) {
      errors['mājas_lapa'] = INVALID_HOME_PAGE_URL
    }

    for (let i = 0; i < newContacts.length; i++) {
      const contact = newContacts[i]

      if (!contact.personas_kods) {
        errors['personas_kods' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (contact.personas_kods && !contact.personas_kods.match(REGEX_PERSON_CODE)) {
        errors['personas_kods' + i] = INVALID_PERSON_CODE_FORMAT
      }

      if (contact.personas_kods && duplicatePersonCodes.includes(contact.personas_kods)) {
        const lastDuplicateItemIndex = personCodes.lastIndexOf(contact.personas_kods)
        errors['personas_kods' + lastDuplicateItemIndex] = EXISTING_PERSON_CODE_IN_REGISTER
      }

      if (!contact.epasts) {
        errors['epasts' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (contact.epasts && contact.epasts.length > EMAIL_LENGTH) {
        errors['epasts' + i] = INVALID_EMAIL_LENGTH
      }

      if (!contact.telefons) {
        errors['telefons' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (contact.telefons && !contact.telefons.match(REGEX_PHONE_CODE)) {
        errors['telefons' + i] = PHONE_FORMAT_ERROR_MESSAGE
      }

      if (!contact.loma_kods) {
        errors['loma_kods' + i] = REQUIRED_ERROR_MESSAGE
      }
    }

    for (let i = 0; i < newDiscounts.length; i++) {
      const discount = newDiscounts[i]

      if (typeof discount.skaits !== 'number') {
        errors['skaits'] = REQUIRED_ERROR_MESSAGE
      }

      if (!discount.mērvienība_kods) {
        errors['mērvienība_kods' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (!discount.apraksts) {
        errors['apraksts' + i] = REQUIRED_ERROR_MESSAGE
      }
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const handlePartnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }

    setNewPartner((prevPartner) => ({
      ...prevPartner,
      [event.target.name]: value,
    }))

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPartner((prevPartner) => ({
      ...prevPartner,
      juridiskā_adrese: {
        address: event.target.value,
      },
    }))
  }

  const handleDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let value: string | boolean = event.target.value
    let discounts = [...newDiscounts]
    discounts[index] = {
      ...discounts[index],
      [event.target.name]: parseInt(value) || value === '0' ? parseInt(value) || 0 : value,
    }

    setNewDiscounts(discounts)

    setFormErrors({
      ...formErrors,
      [event.target.name + index]: '',
    })
  }

  const handleLocationChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AddressResponse | null,
    index: number
  ) => {
    if (newValue) {
      let locations = [...newLocations]
      locations[index] = {
        ...locations[index],
        adrese: newValue,
      }
      setNewLocations(locations)
    }
  }

  const addAdditionalEntry = (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<any>>,
    emptyObject: object
  ) => {
    setState([...state, emptyObject])
  }

  const removeAdditionalEntry = (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<any>>,
    index: number
  ) => {
    setState([...state.slice(0, index), ...state.slice(index + 1)])
  }

  const handleDropDownChange = (event: SelectChangeEvent) => {
    let value = event.target.value

    setNewPartner({
      ...newPartner,
      [event.target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const handleArrayValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    state: object[],
    setState: React.Dispatch<React.SetStateAction<any>>,
    index: number
  ) => {
    let value = event.target.value
    let arrayCopy = [...state]
    arrayCopy[index] = { ...arrayCopy[index], [event.target.name]: value }

    setState(arrayCopy)

    setFormErrors({
      ...formErrors,
      [event.target.name + index]: '',
    })
  }

  const onFileChange = (
    files: FileListItem[],
    setState: React.Dispatch<React.SetStateAction<FileListItem[]>>,
    setUploadedFileState: React.Dispatch<React.SetStateAction<FileEntry[]>>
  ) => {
    setState(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFileState(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFileState((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setUploadedFileState([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onOnlineStoreAdd = () => {
    const interneta_veikals = newPartner.interneta_veikals
      ? newPartner.interneta_veikals.slice()
      : []

    interneta_veikals.push(NEW_ONLINE_STORE)

    setNewPartner({
      ...newPartner,
      interneta_veikals,
    })
  }

  const onOnlineStoreRemove = (index: number) => {
    const interneta_veikals = newPartner.interneta_veikals
      ? newPartner.interneta_veikals.slice()
      : []

    interneta_veikals.splice(index, 1)

    setNewPartner({
      ...newPartner,
      interneta_veikals,
    })
  }

  const onOnlineStoreTextFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const interneta_veikals = newPartner.interneta_veikals
      ? newPartner.interneta_veikals.slice()
      : []

    interneta_veikals[index] = {
      ...interneta_veikals[index],
      [event.target.name]: event.target.value,
    }

    setNewPartner({
      ...newPartner,
      interneta_veikals,
    })
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="basicInfo" label={<b>Pamatdati</b>}>
            <NewPartnerBaseData
              handlePartnerChange={handlePartnerChange}
              handleDropDownChange={handleDropDownChange}
              partner={newPartner}
              formErrors={formErrors}
              partnerTypeList={partnerTypeList}
              handleRegistryData={refetchRegistryData}
              isRegistryDataLoading={isRegistryDataLoading}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="propInfo" label={<b>Rekvizīti</b>}>
            <NewPartnerAdditionalPropertyData
              handlePartnerChange={handlePartnerChange}
              handleAddressChange={handleAddressChange}
              partner={newPartner}
              formErrors={formErrors}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="contactInfo" label={<b>Kontaktpersonas</b>}>
            <NewPartnerContactData
              handleArrayValueChange={handleArrayValueChange}
              contacts={newContacts}
              setContacts={setNewContacts}
              removeAdditionalEntry={removeAdditionalEntry}
              addAdditionalEntry={addAdditionalEntry}
              formErrors={formErrors}
              partnerRoleList={partnerRoleList}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="extraInfo" label={<b>Papildus informācija</b>}>
            <NewPartnerAdditionalData
              filesPhoto={filesPhoto}
              filesLogo={filesLogo}
              partner={newPartner}
              formErrors={formErrors}
              partnerGroupList={partnerGroupList}
              partnerTagsList={partnerTagsList}
              setPartner={setNewPartner}
              onFileChange={onFileChange}
              handlePartnerChange={handlePartnerChange}
              setFilesLogo={setFilesLogo}
              setFilesPhoto={setFilesPhoto}
              setUploadedFilesPhoto={setUploadedFilesPhoto}
              setUploadedFilesLogo={setUploadedFilesLogo}
              onOnlineStoreAdd={onOnlineStoreAdd}
              onOnlineStoreRemove={onOnlineStoreRemove}
              onOnlineStoreTextFieldChange={onOnlineStoreTextFieldChange}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="discountInfo" label={<b>Piedāvātās atlaides</b>}>
            <NewPartnerDiscountData
              discounts={newDiscounts}
              formErrors={formErrors}
              partnerDiscountUnitList={partnerDiscountUnitList}
              handleDiscountChange={handleDiscountChange}
              setDiscounts={setNewDiscounts}
              handleArrayValueChange={handleArrayValueChange}
              addAdditionalEntry={addAdditionalEntry}
              removeAdditionalEntry={removeAdditionalEntry}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="locationInfo" label={<b>Atrašanās vieta</b>}>
            <NewPartnerLocationData
              locations={newLocations}
              setLocations={setNewLocations}
              handleArrayValueChange={handleArrayValueChange}
              handleLocationChange={handleLocationChange}
              addAdditionalEntry={addAdditionalEntry}
              removeAdditionalEntry={removeAdditionalEntry}
            />
          </OutlinedContainer>
        </Grid>
      </Grid>

      <Box sx={[sx.formRow, sx.submitWrap]}>
        <Button variant="outlined" color="primary" type="submit">
          Saglabāt
        </Button>
      </Box>

      <Loader open={isLoading || isLoadingPartnerRoles || isLoadingPartnerDiscountUnit} />
    </form>
  )
}
