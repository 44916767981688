import { StatusCodeItem } from '../../interfaces/StatusCodeItem'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import { NewServiceForm } from '../../interfaces/NewServiceForm'

export const partnerPropsColumns = [
  {
    field: 'lietotājvārds',
    title: 'Lietotāja vārds',
  },
]

export const serviceColumns = [
  {
    field: 'tips_nosaukums',
    title: 'Servisa tips',
    width: '40%',
  },
  {
    field: 'aktīvs_no',
    title: 'Pieejams no',
    width: '10%',
    render: (rowData: NewServiceForm) => {
      if (rowData.aktīvs_no) {
        return <>{format(parseISO(rowData.aktīvs_no.toString()), DEFAULT_DATE_FORMAT)}</>
      }

      return <></>
    },
  },
  {
    field: 'aktīvs_līdz',
    title: 'Pieejams līdz',
    width: '10%',
    render: (rowData: NewServiceForm) => {
      if (rowData.aktīvs_līdz) {
        return <>{format(parseISO(rowData.aktīvs_līdz.toString()), DEFAULT_DATE_FORMAT)}</>
      }

      return <></>
    },
  },
  {
    field: 'pēdējā_aktivitāte',
    title: 'Pēdējā aktivitāte',
    width: '10%',
  },
]

export enum StatusCodes {
  PBL = 'PBL',
  NPBL = 'NPBL',
}

export const statusCodes: StatusCodeItem[] = [
  {
    statusCode: StatusCodes.PBL,
    statusName: 'Publicēta',
  },
  {
    statusCode: StatusCodes.NPBL,
    statusName: 'Nepublicēta',
  },
]
