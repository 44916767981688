import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { addNotificationData } from '../../../../api/API'
import { useSnackBar } from '../../../providers/SnackBarProvider'
import RequiredLabel from '../../../elements/RequiredLabel'
import { Notification } from '../../../interfaces/Notification'
import { AddEditNotification } from '../../../interfaces/AddEditNotification'
import { FILE_SIZE_LIMIT_MB_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../../../../constants/constants'
import { FileUploadButton } from '../../../elements/FileUploadButton'
import { FileListItem } from '../../../interfaces/File/FileListItem'
import { uploadFiles } from '../../../utils/uploadFiles'
import stripHtml from '../../../utils/stripHtml'
import { FileLink } from '../../../elements/FileLink'
import { handleErrorMessages } from '../../../utils/handleErrorMessages'
import EmailSignature from '../../../elements/EmailSignature'
import SimpleEditor from '../../../elements/Editor/SimpleEditor'
import { ApplicationNotificationType } from '../../../enums/ApplicationNotificationType'
import replaceHtmlBreakWithNewLine from '../../../utils/replaceHtmlBreakWithNewLine'
import ConfirmationStatusRenderer from '../../../elements/ConfirmationStatusRenderer'
import { userDataProps } from '../../../interfaces/UserDataProps'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    marginTop: '15px',
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
  },
  inputLabel: {
    minWidth: '150px',
  },
  removeMarginTopFirstParagraph: {
    '& p:first-of-type': {
      marginTop: 0,
    },
  },
}

interface Props {
  notification: Notification | null
  userData: userDataProps
  isPhoneActivated: boolean
  isEmailActivated: boolean
  isEAddressActivated: boolean
  closeModal: () => void
  closeModalAndRefetch: () => void
}

export function ApplicationNotificationModal({
  notification,
  userData,
  isPhoneActivated,
  isEmailActivated,
  isEAddressActivated,
  closeModal,
  closeModalAndRefetch,
}: Props) {
  const { persona_id, personas_vārds, personas_uzvārds, personas_kods, telefoni, epasti } = userData
  const { showSnackBar } = useSnackBar()

  const [uploadedFiles, setUploadedFiles] = React.useState<{ faila_id: number; sha256: string }[]>(
    []
  )
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>({
    vārds: '',
    uzvārds: '',
    personas_kods: '',
    datums_no: '',
    generalRoleDateFrom: '',
  })

  const [formState, setFormState] = React.useState<AddEditNotification>(() => {
    const initialSelectedChannel = isEmailActivated
      ? ApplicationNotificationType.Epasts
      : isPhoneActivated
      ? ApplicationNotificationType.Sms
      : isEAddressActivated
      ? ApplicationNotificationType.eAdrese
      : ''
    return {
      id: notification?.id,
      nosaukums: notification?.nosaukums || '',
      saturs:
        notification?.saturs ||
        stripHtml(replaceHtmlBreakWithNewLine(renderToString(<EmailSignature />))),
      persona_id: persona_id,
      veids: initialSelectedChannel,
    }
  })

  const [files, setFiles] = React.useState<FileListItem[]>([])

  const [isLoading, setIsLoading] = React.useState(false)

  const isFormValid = () => {
    const _errors: { [key: string]: string } = {}

    if (!formState.nosaukums) {
      _errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    }

    if (!stripHtml(formState.saturs)) {
      _errors['saturs'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const [addNotificationMutation] = useMutation(
    (notification: AddEditNotification) => addNotificationData(notification),
    {
      onSuccess: () => {
        setIsLoading(false)
        showSnackBar()
        closeModalAndRefetch()
      },
      onError: (error: any) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [target.name]: target.value,
    }))

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const onFileChange = (files: FileListItem[]) => {
    setFiles(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFiles((prevFormState) => [
            ...prevFormState,
            {
              faila_id: json.id,
              sha256: json.sha_256,
            },
          ])
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFiles([])
        setUploadedFiles([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    setIsLoading(true)

    addNotificationMutation({
      ...formState,
      pielikumi: uploadedFiles,
    })
  }

  return (
    <Dialog onClose={closeModal} open fullWidth maxWidth="md">
      {notification ? (
        <Box pb={2}>
          <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon style={sx.closeIcon} color="primary" onClick={closeModal} />
          </Box>
          <Box px={4}>
            <DialogTitle sx={sx.dialogTitle}>
              <Box display="flex" justifyContent="center">
                Paziņojums {notification.id}
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box sx={sx.inputWrapper}>
                <Box component="span" sx={sx.inputLabel}>
                  Saņēmējs
                </Box>
                <span>
                  {personas_vārds} {personas_uzvārds}
                </span>
                <span style={{ marginLeft: 20 }}>{personas_kods}</span>
              </Box>

              <Box sx={sx.inputWrapper}>
                <Box component="span" sx={sx.inputLabel}>
                  Saziņas kanāls
                </Box>
                <span>{notification.veids}</span>
              </Box>

              <Box sx={sx.inputWrapper}>
                <Box component="span" sx={sx.inputLabel}>
                  Nosaukums
                </Box>
                <span>{notification.nosaukums}</span>
              </Box>

              <Box sx={sx.inputWrapper}>
                <Box component="span" sx={sx.inputLabel}>
                  Paziņojuma saturs
                </Box>
                <Box
                  sx={{ ...sx.removeMarginTopFirstParagraph, whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{ __html: notification.saturs }}
                />
              </Box>
              <Box sx={sx.inputWrapper}>
                <Box component="span" sx={sx.inputLabel}>
                  Pielikumi
                </Box>
                <List disablePadding sx={{ mt: -1 }}>
                  {notification.pielikumi?.map((file) => (
                    <ListItem key={file.faila_id} disableGutters>
                      <FileLink file={file} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Button sx={sx.button} variant="contained" onClick={closeModal} color="primary">
                  Aizvērt
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      ) : (
        <Box pb={2}>
          <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon style={sx.closeIcon} color="primary" onClick={closeModal} />
          </Box>
          <Box px={4}>
            <DialogTitle sx={sx.dialogTitle}>
              <Box display="flex" justifyContent="center">
                Jauns paziņojums
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box sx={sx.inputWrapper}>
                <Box component="span" sx={sx.inputLabel}>
                  Saņēmējs
                </Box>
                <span>
                  {personas_vārds} {personas_uzvārds}
                </span>
              </Box>
              <Box sx={sx.inputWrapper}>
                <RequiredLabel label="Nosaukums" />
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="nosaukums"
                  autoComplete="off"
                  value={formState.nosaukums}
                  onChange={onFieldChange}
                  helperText={formErrors.nosaukums}
                  error={Boolean(formErrors.nosaukums)}
                />
              </Box>

              {isEAddressActivated ||
              epasti.some((email) => email.epasts) ||
              telefoni.some((tel) => tel.telefons) ? (
                <Box sx={sx.inputWrapper}>
                  <RequiredLabel label="Saziņas kanāls" />
                  <FormControl sx={{ mt: -1 }}>
                    <RadioGroup onChange={onFieldChange} value={formState.veids} name="veids">
                      {epasti.some((email) => email.epasts) ? (
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            value={ApplicationNotificationType.Epasts}
                            control={<Radio />}
                            label="e-pasts"
                          />
                          <ConfirmationStatusRenderer
                            size="small"
                            sx={{ ml: 1 }}
                            isEnabled={isEmailActivated}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      {telefoni.some((tel) => tel.telefons) ? (
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            value={ApplicationNotificationType.Sms}
                            control={<Radio />}
                            label="sms"
                          />
                          <ConfirmationStatusRenderer
                            size="small"
                            sx={{ ml: 1 }}
                            isEnabled={isPhoneActivated}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      {isEAddressActivated && (
                        <FormControlLabel
                          value={ApplicationNotificationType.eAdrese}
                          control={<Radio />}
                          label="eAdrese"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : (
                <></>
              )}

              <Box sx={sx.inputWrapper}>
                <RequiredLabel label="Paziņojuma saturs" />
                <Box width={1}>
                  <SimpleEditor
                    name="saturs"
                    value={stripHtml(replaceHtmlBreakWithNewLine(formState.saturs))}
                    onChange={onFieldChange}
                    helperText={formErrors.saturs}
                    error={Boolean(formErrors.saturs)}
                    showMaxLength
                    maxLength={10000}
                  />
                  {/* Uncomment the following code when html elements are supported in the backend (email client) */}
                  {/* {formState.veids === ApplicationNotificationType.Sms ? (
                    <SimpleEditor
                      name="saturs"
                      value={stripHtml(replaceHtmlBreakWithNewLine(formState.saturs))}
                      onChange={onFieldChange}
                      helperText={formErrors.saturs}
                      error={Boolean(formErrors.saturs)}
                      showMaxLength
                      maxLength={10000}
                    />
                  ) : (
                    <Editor
                      // TODO: check if the backend accepts large messages
                      showMaxLength
                      maxLength={10000}
                      name="saturs"
                      value={formState.saturs}
                      onChange={onFieldChange}
                      helperText={formErrors.saturs}
                      error={Boolean(formErrors.saturs)}
                      // styles for email signature
                      tableStyle={EMAIL_SIGNATURE_STYLES.tableStyle}
                      cellStyle={EMAIL_SIGNATURE_STYLES.cellStyle}
                    />
                  )} */}
                </Box>
              </Box>
              <Box sx={sx.inputWrapper}>
                <Box component="label" sx={[sx.inputLabel, { mt: '8px' }]}>
                  Pielikumi
                </Box>
                <Box>
                  <FileUploadButton onFileChange={onFileChange} files={files} />
                  <Typography sx={{ ml: 0.5, color: '#999', mt: '-10px !important' }}>
                    {FILE_SIZE_LIMIT_MB_MESSAGE}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Button
                  sx={sx.button}
                  disabled={
                    isLoading || (!isEAddressActivated && !epasti.length && !telefoni.length)
                  }
                  variant="contained"
                  onClick={onSubmit}
                  color="primary"
                  startIcon={isLoading && <CircularProgress size={16} />}
                >
                  NOSŪTĪT
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      )}
    </Dialog>
  )
}
