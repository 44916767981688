import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
} from '@mui/material'
import { useQuery } from 'react-query'

import { PostParams, TypographyParams } from '../../interfaces/CertificatesParams'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import {
  getApplication,
  getApplicationStatusHistory,
  getPostCertificate,
  getTypographyCertificate,
} from '../../../api/API'
import { downloadBlobFile } from './utils'
import DatePicker from '../../elements/DatePicker'
import CertificateModal from './CertificateModal'
import {
  ApplicationStatusHistory,
  ApplicationStatusHistoryResponse,
} from '../../interfaces/ApplicationStatusHistory'
import { ApplicationStatusCodes } from '../../enums/ApplicationStatusCodes'
import { format } from 'date-fns'
import { DEFAULT_API_DATE_FORMAT } from '../../../constants/constants'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'

const sx = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  certificateContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusCheckboxContainer: {
    margin: '10px 0 12px',
  },
  statusCheckboxTextBox: {
    display: 'flex',
  },
  statusCheckboxBoldText: {
    marginLeft: '4px',
  },
  prepareListButton: {
    width: '50%',
    marginLeft: '9px',
  },
  inputWrapper: {
    margin: '40px 0px',
    display: 'flex',
  },
  inputLabel: {
    minWidth: '100px',
  },
}

export default function Certificates() {
  const { showSnackBar } = useSnackBar()
  const date = new Date()
  const [isTypographyChecked, setIsTypographyChecked] = useState<boolean>(false)
  const [isPostChecked, setIsPostChecked] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [dateTo, setDateTo] = useState<Date | null>(new Date())
  const [openApplicationStatusModal, setOpenApplicationStatusModal] = useState(false)
  const [selectedList, setSelectedList] = useState<boolean | null>(null)
  const [data, setData] = useState<ApplicationStatusHistory[]>([])

  const [typographyParams, setTypographyParams] = useState<TypographyParams>({
    atzīmēt_ka_nodotas_drukāšanai: false,
  })
  const [postParams, setPostParams] = useState<PostParams>({ atzīmēt_kā_nosūtītas: '' })

  const handleTypographyCheckbox = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsTypographyChecked(checked)
    setTypographyParams({ atzīmēt_ka_nodotas_drukāšanai: checked })
  }

  const handlePostCheckbox = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsPostChecked(checked)
    setPostParams({ atzīmēt_kā_nosūtītas: checked ? 'atzīmēt_kā_nosūtīta' : '' })
  }

  const { refetch: refetchTypographyCertificate, isFetching: isLoadingTypographyCertificate } =
    useQuery(
      ['typograpgy-certificate', typographyParams],
      () => getTypographyCertificate(typographyParams),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (data) => {
          const fileName = 'apliecibu-saraksts-tipografijai.xls'
          downloadBlobFile(data, fileName)
          setIsPostChecked(false)
          setIsTypographyChecked(false)
          setTypographyParams({ atzīmēt_ka_nodotas_drukāšanai: false })
        },
        onError: (error: any) => {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        },
      }
    )

  const { refetch: refetchPostCertificate, isFetching: isLoadingPostCertificate } = useQuery(
    ['post-certificate', postParams],
    () => getPostCertificate(postParams),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        const fileName = 'apliecibu-saraksts-pastam.xls'
        downloadBlobFile(data, fileName)
        setIsPostChecked(false)
        setIsTypographyChecked(false)
        setPostParams({ atzīmēt_kā_nosūtītas: '' })
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const { isLoading: isLoadingStatusHistory, refetch: refetchStatusHistory } = useQuery(
    ['applicationStatusHistory'],
    () =>
      getApplicationStatusHistory(
        Boolean(selectedList),
        dateFrom instanceof Date ? format(dateFrom, DEFAULT_API_DATE_FORMAT) : null,
        dateTo instanceof Date ? format(dateTo, DEFAULT_API_DATE_FORMAT) : null,
        ApplicationStatusCodes.Nodota_drukāšanai
      ),
    {
      refetchOnWindowFocus: false,
      enabled: typeof selectedList === 'boolean',
      onSuccess: (data: ApplicationStatusHistoryResponse[]) => {
        const applicationIds = data.map((certificate) => certificate.id)

        Promise.all(applicationIds.map((applicationId) => getApplication(applicationId)))
          .then((currentCards: ApplicationCard[]) => {
            const remappedData = data.map((historyCard) => {
              return {
                ...historyCard,
                aktīvais_statuss: currentCards.find(
                  (currentCard) => currentCard.id === historyCard.id
                )?.statuss_nosaukums,
              }
            })

            setData(
              remappedData.map((el) => {
                return {
                  ...el,
                  adrese: el.adrese?.adrese,
                }
              })
            )
          })
          .catch((error) => {
            showSnackBar({
              severity: 'error',
              text: handleErrorMessages(error),
            })
          })

        setOpenApplicationStatusModal(true)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  useEffect(() => {
    if (typeof selectedList === 'boolean') {
      refetchStatusHistory()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList])

  const handleTypographyCertificate = () => {
    refetchTypographyCertificate()
  }

  const handlePostCertificate = () => {
    refetchPostCertificate()
  }

  const handleApplicationStatusHistory = (izsniegt_birojā: boolean) => {
    setSelectedList(izsniegt_birojā)
  }

  const closeModal = () => {
    setSelectedList(null)
    setOpenApplicationStatusModal(false)
  }

  return (
    <>
      {openApplicationStatusModal && (
        <CertificateModal data={data} displaySmsButton={selectedList} onClose={closeModal} />
      )}
      <Grid container paddingBottom={20}>
        <Box sx={sx.mainContainer}>
          <Box sx={sx.certificateContainer} marginTop="16px">
            <Typography marginLeft="9px" variant="h5">
              Apliecību saraksta sagatavošana tipogrāfijai
            </Typography>
            <FormControlLabel
              sx={sx.statusCheckboxContainer}
              control={
                <Checkbox checked={isTypographyChecked} onChange={handleTypographyCheckbox} />
              }
              disabled={isPostChecked || isLoadingTypographyCertificate || isLoadingPostCertificate}
              label={
                <Box sx={sx.statusCheckboxTextBox}>
                  <Typography>Mainīt statusu sarakstā iekļautajām apliecībām uz</Typography>
                  <Typography sx={sx.statusCheckboxBoldText} fontWeight="bold">
                    Nodota drukāšanai
                  </Typography>
                </Box>
              }
            />
            <Button
              sx={sx.prepareListButton}
              variant="outlined"
              onClick={handleTypographyCertificate}
            >
              {isLoadingTypographyCertificate ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                'Sagatavot sarakstu'
              )}
            </Button>
          </Box>
          <Box sx={sx.certificateContainer} marginTop="32px">
            <Typography marginLeft="9px" variant="h5">
              Apliecību atlasīšana apstrādei
            </Typography>

            <Box sx={sx.inputWrapper}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component="span" sx={sx.inputLabel}>
                  Datums no
                </Box>
                <DatePicker value={dateFrom} onChange={(date: Date | null) => setDateFrom(date)} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                <Box component="span" sx={sx.inputLabel}>
                  Datums līdz
                </Box>
                <DatePicker value={dateTo} onChange={(date: Date | null) => setDateTo(date)} />
              </Box>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Button
                sx={sx.prepareListButton}
                variant="outlined"
                onClick={() => handleApplicationStatusHistory(true)}
                disabled={isLoadingStatusHistory}
              >
                {isLoadingStatusHistory ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  'Saraksts ar birojā izsniedzamām apliecībām'
                )}
              </Button>
              <Button
                sx={sx.prepareListButton}
                variant="outlined"
                onClick={() => handleApplicationStatusHistory(false)}
                disabled={isLoadingStatusHistory}
              >
                {isLoadingStatusHistory ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  'Saraksts ar pa pastu sūtāmām apliecībām'
                )}
              </Button>
            </Box>
          </Box>
          <Box sx={sx.certificateContainer} marginTop="60px">
            <Typography marginLeft="9px" variant="h5">
              Apliecību saraksta sagatavošana pastam
            </Typography>
            <FormControlLabel
              sx={sx.statusCheckboxContainer}
              control={<Checkbox checked={isPostChecked} onChange={handlePostCheckbox} />}
              disabled={
                isTypographyChecked || isLoadingPostCertificate || isLoadingTypographyCertificate
              }
              label={
                <Box sx={sx.statusCheckboxTextBox}>
                  <Typography>Mainīt statusu sarakstā iekļautajām apliecībām uz</Typography>
                  <Typography sx={sx.statusCheckboxBoldText} fontWeight="bold">
                    Nosūtīta
                  </Typography>
                </Box>
              }
            />
            <Button sx={sx.prepareListButton} variant="outlined" onClick={handlePostCertificate}>
              {isLoadingPostCertificate ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                'Sagatavot sarakstu'
              )}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}
