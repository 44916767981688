import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, Typography } from '@mui/material'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import AddEditClassifierModal from './AddEditClassifierModal'
import {
  deleteClassifierRecordData,
  CLASSIFIER_RECORD_DATA_ENDPOINT,
  CLASSIFIER_RECORD_DATA_COUNT_ENDPOINT,
} from '../../../api/API'
import { confirmDialog } from '../../elements/ConfirmDialog'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import { Classifier } from '../../interfaces/Classifier'
import { paramFetcher } from '../../elements/ParamFetcher'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const columns = [
  {
    field: 'kods',
    title: 'Kods',
  },
  {
    field: 'virskods',
    title: 'Virskods',
  },
  {
    field: 'nosaukums',
    title: 'Nosaukums',
    // TODO: remove this line when the sorting with this field is supported in the BE
    sorting: false,
  },
  {
    field: 'kods_sort',
    title: 'Meklēšanas frāzes',
    // TODO: remove this line when the sorting with this field is supported in the BE
    sorting: false,
  },
  {
    field: 'datums_no',
    title: 'Derīgs no',
    render: (rowData: Classifier) => {
      if (rowData.datums_no) {
        return <>{format(parseISO(rowData.datums_no), DEFAULT_DATE_FORMAT)}</>
      }

      return <></>
    },
    // TODO: remove this line when the sorting with this field is supported in the BE
    sorting: false,
  },
  {
    field: 'datums_līdz',
    title: 'Derīgs līdz',
    render: (rowData: Classifier) => {
      if (rowData.datums_līdz) {
        return <>{format(parseISO(rowData.datums_līdz), DEFAULT_DATE_FORMAT)}</>
      }

      return <></>
    },
    // TODO: remove this line when the sorting with this field is supported in the BE
    sorting: false,
  },
]

export default function Classifiers() {
  const { state } = useLocation<{ data: Classifier }>()
  const { data: { kods, nosaukums } = { kods: '', nosaukums: '' } } = state || {}

  const { showSnackBar } = useSnackBar()
  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [selectedClassifier, setSelectedClassifier] = React.useState<Classifier | null>(null)
  const [rows, setRows] = React.useState([])
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    setQueryParams({
      ...queryParams,
      klasifikatora_kods: kods,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kods])

  const [queryParams, setQueryParams] = React.useState({
    klasifikatora_kods: kods,
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
    sort: '',
  })

  const { isLoading, refetch } = useQuery(
    ['classifier_records', queryParams],
    () =>
      paramFetcher(
        queryParams,
        CLASSIFIER_RECORD_DATA_ENDPOINT,
        CLASSIFIER_RECORD_DATA_COUNT_ENDPOINT
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        setRows(list)
        setCount(count)
      },
    }
  )

  const [deleteClassifierRecordMutation] = useMutation(
    (id: number) => deleteClassifierRecordData(id),
    {
      onSuccess: () => {
        showSnackBar()
        refetch()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onClassifierEdit = (event: React.MouseEvent, classifier: Classifier) => {
    setSelectedClassifier(classifier)
    setOpenModalAddEdit(true)
  }

  const closeModal = () => {
    setSelectedClassifier(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const onClassifierDelete = async (event: React.MouseEvent, { id, nosaukums }: Classifier) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties dzēst klasifikatora ierakstu "${nosaukums}" no datubāzes?`,
    })

    if (confirm.ok) {
      deleteClassifierRecordMutation(id)
    }
  }

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  const onOrderChange = (field: string, order: string) => {
    let sortParam = ''
    if (order === 'asc') sortParam = field
    else if (order === 'desc') sortParam = `~${field}`
    setQueryParams({
      ...queryParams,
      sort: sortParam,
    })
  }

  if (!state) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <>
      {openModalAddEdit && (
        <AddEditClassifierModal classifier={selectedClassifier} code={kods} onClose={closeModal} />
      )}
      <div>
        <div
          style={{
            paddingBottom: '20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">{nosaukums}</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedClassifier(null)
              setOpenModalAddEdit(true)
            }}
          >
            Pievienot ierakstu
          </Button>
        </div>

        <CustomizedMaterialTable
          columns={columns}
          data={rows}
          isLoading={isLoading}
          page={queryParams.offset / queryParams.limit}
          totalCount={count}
          pageSize={queryParams.limit}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onOrderChange={onOrderChange}
          rowStyle={{
            height: 50,
          }}
          actions={[
            {
              icon: () => <EditIcon fontSize="small" htmlColor="#FFA64B" />,
              onClick: onClassifierEdit,
              tooltip: 'Rediģēt',
            },
            (rowData: Classifier) => {
              const isDeleteRelevant = Boolean(!rowData.nav_labojams)
              return {
                icon: () => (
                  <DeleteOutlineIcon
                    fontSize="small"
                    htmlColor={isDeleteRelevant ? '#FF2D2D' : '#AAA'}
                  />
                ),
                onClick: onClassifierDelete,
                tooltip: 'Dzēst',
                disabled: !isDeleteRelevant,
              }
            },
          ]}
        />
      </div>
    </>
  )
}
