import axios from 'axios'
import queryString from 'query-string'
import { AddEditClassifier } from '../components/interfaces/AddEditClassifier'
import { AddEditNotification } from '../components/interfaces/AddEditNotification'
import { AddEditUserRequest } from '../components/interfaces/AddEditUser'
import { AddMassMail } from '../components/interfaces/AddMassMail'
import { ApplicationEntryDocument } from '../components/interfaces/Application/ApplicationEntry'
import { PostParams, TypographyParams } from '../components/interfaces/CertificatesParams'
import { ComplaintEditRequest } from '../components/interfaces/Complaints'
import { NewPartnerProps } from '../components/interfaces/NewPartnerProps'
import { NewPromotionReq } from '../components/interfaces/NewPromotionForm'
import { NewServiceReq } from '../components/interfaces/NewServiceForm'
import { NewTaskReq } from '../components/interfaces/NewTaskForm'
import { LegalAddress } from '../components/interfaces/Partners/LegalAddress'
import { NewPartnerContact } from '../components/interfaces/Partners/NewPartnerContact'
import {
  NewPartnerDiscount,
  NewPartnerDiscountResponse,
} from '../components/interfaces/Partners/NewPartnerDiscount'
import {
  NewPartnerRequest,
  NewPartnerEntityResponse,
} from '../components/interfaces/Partners/NewPartnerEntity'
import {
  NewPartnerLocationRequest,
  NewPartnerLocationResponse,
} from '../components/interfaces/Partners/NewPartnerLocation'
import { NewPartnerStatus } from '../components/interfaces/Partners/NewPartnerStatus'
import { PartnerContractSignatory } from '../components/interfaces/Partners/PartnerContractSignatory'
import { PartnerDiscountStatus } from '../components/interfaces/Partners/PartnerDiscountStatus'
import { PartnerSettingRequest } from '../components/interfaces/Partners/PartnerSetting'
import { TaskQueryParams } from '../components/interfaces/Partners/TaskQueryParams'
import { RatingEditingRightsPayload } from '../components/interfaces/Raitings'
import { UpdatePerson } from '../components/interfaces/UpdatePerson'
import {
  AggregatedNewPartnerRequest,
  AggregatedNewPartnerResponse,
} from '../components/interfaces/Partners/AggregatedNewPartner'
import { ComplaintAddInfoRequest } from '../components/interfaces/ComplaintInfoRequest'

export const ADDRESS_ENDPOINT = 'https://amk.viss.gov.lv/rest/address'

export const LOGIN_ENDPOINT = '/api/v1/test_login'
export const LOGIN_LDAP_ENDPOINT = '/api/v1/ldap_login'
export const LOGOUT_ENDPOINT = '/api/v1/logout'
export const CURRENT_USER_ENDPOINT = '/api/v1/current_user'
export const PARTNER_CONTRACT_GENERATION_ENDPOINT = '/api/v1/sp_izdruka'
export const PARTNER_CONTRACT_DISCONTINUE_GENERATION_ENDPOINT = '/api/v1/sp_atteikuma_izdruka'
export const PARTNER_REGISTRY_DATA_ENDPOINT = '/api/v1/ur_dati'

export const CLASSIFIER_VALUES_DATA_ENDPOINT = '/data/klasifikatora_vērtību_saraksts'
export const CLASSIFIER_DATA_ENDPOINT = '/data/klasifikators'
export const CLASSIFIER_RECORD_DATA_ENDPOINT = '/data/klasifikatora_ieraksts'
export const CLASSIFIER_RECORD_DATA_COUNT_ENDPOINT = '/data/count/klasifikatora_ieraksts'

export const USER_DATA_ENDPOINT = '/data/lietotājs'
export const USER_DATA_COUNT_ENDPOINT = '/data/count/lietotājs'
export const USER_ROLES_ENDPOINT = '/data/lomu_izvēle'

export const NOTIFICATION_DATA_ENDPOINT = '/data/personas_paziņojums'
export const NOTIFICATION_DATA_COUNT_ENDPOINT = '/data/count/personas_paziņojums'

export const PERSON_DATA_ENDPOINT = '/data/persona'

export const HISTORY_DATA_ENDPOINT = '/data/audita_saraksts'
export const HISTORY_DATA_SINGLE_RECORD_ENDPOINT = '/data/audita_ieraksts'
export const HISTORY_DATA_COUNT_ENDPOINT = '/data/count/audita_saraksts'

export const SETTINGS_DATA_ENDPOINT = '/data/sistēmas_uzstādijumu_saraksts'
export const SETTINGS_DATA_COUNT_ENDPOINT = '/data/count/sistēmas_uzstādijumu_saraksts'
export const SETTING_DATA_ENDPOINT = '/data/sistēmas_uzstādijums'

export const INQUIRY_DATA_ENDPOINT = '/data/iesniegumu_saraksts'
export const INQUIRY_DATA_COUNT_ENDPOINT = '/data/count/iesniegumu_saraksts'

export const INFOPANEL_RAW_SUBMISSIONS_ENDPOINT = '/data/count/neapstrādāto_iesniegumu_infopanelis'
export const INFOPANEL_REEVALUATION_SUBMISSIONS_ENDPOINT =
  '/data/count/pārvērtēšanā_esošo_iesniegumu_infopanelis'
export const INFOPANEL_RAW_DOCUMENTS_ENDPOINT = '/data/count/neapstrādāto_dokumentu_infopanelis'

export const INFOPANEL_PENDING_INQUIRIES_ENDPOINT =
  '/data/count/neizskatīto_sadarbības_partnera_pieteikumu_infopanelis'
export const INFOPANEL_PENDING_COMPLAINTS_ENDPOINT = '/data/count/neizskatīto_sūdzību_infopanelis'
export const INFOPANEL_UNREVIEWED_RESPONSES_ENDPOINT =
  '/data/count/informācijas_pieprasījumu_neizlasīto_atbilžu_infopanelis'
export const INFOPANEL_UNPUBLISHED_DISCOUNTS_ENDPOINT = '/data/count/nepublicēto_akciju_infopanelis'
export const INFOPANEL_UNCOMPLETED_TASKS_ENDPOINT = '/data/count/neizpildīto_uzdevumu_infopanelis'

export const APPLICATION_DOCUMENT = '/data/iesnieguma_dokuments'
export const APPLICATION_STATUS_CHANGE = '/data/apliecības_statusa_maiņa'
export const APPLICATION_ISSUING_DATA_CHANGE = '/data/atjaunot_kartes_izsniegšanas_datus'
export const APPLICATION_APPLY_FOR_PLASTIC_CARD = '/data/pieteikties_plastikāta_kartei'
export const APPLICATION_BLOCK_PLASTIC_CARD = '/data/anulēt_apliecību'
export const APPLICATION_DATA_ENDPOINT = '/data/apliecība'
export const APPLICATION_CHILDREN_CHANGE = '/data/labot_apliecības_bērnus'
export const APPLICATION_STATUS_HISTORY_ENDPOINT = '/data/apliecības_statusu_vēsture'
export const APPLICATION_MARK_PRINTED_ENDPOINT = '/data/atzīmēt_apliecības_kā_izdrukātas'
export const APPLICATION_MARK_PRINTED_OFFICE_ENDPOINT =
  '/data/atzīmēt_apliecības_kā_izdrukātas_saņemt_birojā'

export const PARTNER_RATING_DATA_ENDPOINT = '/data/sadarbības_partnera_vērtējumu_saraksts'
export const PARTNER_RATING_DATA_COUNT_ENDPOINT =
  '/data/count/sadarbības_partnera_vērtējumu_saraksts'
export const PARTNER_RATING_BLOCK_PERSON = '/data/bloķēt_personu'

export const PARTNERS_DATA_ENDPOINT = '/data/sadarbības_partneru_saraksts'
export const PARTNERS_DATA_COUNT_ENDPOINT = '/data/count/sadarbības_partneru_saraksts'
export const PARTNER_DATA_ENDPOINT = '/data/sadarbības_partneris'
export const PARTNER_STATUS_ENDPOINT = '/data/sadarbības_partnera_statusa_maiņa'

export const AGGREGETED_PARTNER_ADD_ENDPOINT = '/data/izveidot_sadarbības_partneri'

export const PARTNER_CONTACT_DATA_ENDPOINT = '/data/sadarbības_partnera_personas'
export const PARTNER_LOCATION_DATA_ENDPOINT = '/data/sadarbības_partnera_vieta'
export const PARTNER_DISCOUNT_DATA_ENDPOINT = '/data/atlaide'

export const PARTNER_DISCOUNT_STATUS_CHANGE_ENDPOINT = '/data/atlaides_statusa_maiņa'

export const TASK_RECORD_ENDPOINT = '/data/sadarbības_partnera_uzdevumi'
export const TASK_LIST_ENDPOINT = '/data/sadarbības_partnera_uzdevumu_saraksts'
export const TASK_LIST_COUNT_ENDPOINT = '/data/count/sadarbības_partnera_uzdevumu_saraksts'

export const COMPLAINTS_LIST_ENDPOINTS = '/data/sadarbības_partnera_sūdzību_saraksts'
export const COMPLAINTS_LIST_COUNT_ENDPOINTS = '/data/count/sadarbības_partnera_sūdzību_saraksts'
export const COMPLAINT_DATA_EDIT_ENDPOINT = '/data/sadarbības_partnera_sūdzību_rediģēšana'

export const INFO_REQUEST = '/data/sadarbības_partnera_informācijas_pieprasījums'
export const INFO_REQUEST_LIST_ENDPOINT =
  '/data/sadarbības_partnera_informācijas_pieprasījumu_saraksts'
export const INFO_REQUEST_LIST_COUNT_ENDPOINT =
  '/data/count/sadarbības_partnera_informācijas_pieprasījumu_saraksts'

export const PROMOTIONS = '/data/sadarbības_partnera_akciju_saraksts'
export const PROMOTIONS_COUNT = '/data/count/sadarbības_partnera_akciju_saraksts'
export const PROMOTION_RECORD_ENDPOINT = '/data/sadarbības_partnera_akcija'

export const SERVICE_LIST_ENDPOINT = '/data/sadarbības_partnera_serviss'
export const SERVICE_LIST_COUNT_ENDPOINT = '/data/count/sadarbības_partnera_serviss'
export const SERVICE_PARTNER_PROPS_ENDPOINT = '/data/sadarbības_partnera_autentifikācijas_rekvizīti'

export const MASS_MAIL_ENDPOINT = '/data/masveida_epastu_izsūtīšana'

export const TYPOGRAPHY_CERTIFICATES = '/data/tipogrāfijas_apliecību_saraksts'
export const POST_CERTIFICATES = 'data/izsūtāmo_apliecību_saraksts'
export const APPLICATION_LIST = 'data/apliecību_saraksts'

export const PARTNER_LAST_ACTIONS = '/data/sadarbības_partnera_aktivitātes'
export const PARTNER_LAST_ACTIONS_COUNT = '/data/count/sadarbības_partnera_aktivitātes'

export const CONFIG_ENDPOINT = '/api/v1/config'

export async function login(pk: string) {
  const response = await axios.post(LOGIN_ENDPOINT, { pk })

  return response.data
}

export async function loginLdap(username: string, password: string) {
  const response = await axios.post(LOGIN_LDAP_ENDPOINT, {
    lietotājvārds: username,
    parole: password,
  })

  return response.data
}

export async function logout() {
  const response = await axios.get(LOGOUT_ENDPOINT)

  return response.data
}

export async function applyForPlasticCard(data: { id: number; iesniegums_id: number }, id: number) {
  const response = await axios.put(APPLICATION_APPLY_FOR_PLASTIC_CARD + `/${id}`, data)

  return response.data
}

export async function blockPlasticCard(data: { id: number }, id: number) {
  const response = await axios.put(APPLICATION_BLOCK_PLASTIC_CARD + `/${id}`, data)

  return response.data
}

export async function changeApplicationStatus(
  status: { id: number; statuss_kods: string; komentārs: string },
  id: number
) {
  const response = await axios.put(APPLICATION_STATUS_CHANGE + `/${id}`, status)

  return response.data
}

export async function getApplicationChildren(id?: number) {
  const response = await axios.get(APPLICATION_CHILDREN_CHANGE + `/${id}`)

  return response.data
}

export async function changeApplicationChildren(
  data: {
    id: number
    bērni: { persona_id: number }[]
  },
  id: number
) {
  const response = await axios.put(APPLICATION_CHILDREN_CHANGE + `/${id}`, data)

  return response.data
}

export async function getApplicationIssuingData(id: number) {
  const response = await axios.get(APPLICATION_ISSUING_DATA_CHANGE + `/${id}`)

  return response.data
}

export async function changeApplicationIssuingData(
  data: {
    id: number
    adrese: LegalAddress | null
    izsniegt_birojā: boolean
  },
  id: number
) {
  const response = await axios.put(APPLICATION_ISSUING_DATA_CHANGE + `/${id}`, data)

  return response.data
}

export async function addApplication(data: {
  persona_id: number
  bērni: { persona_id: number }[]
  iesniegums_id?: number
}) {
  const response = await axios.post(APPLICATION_DATA_ENDPOINT, data)

  return response.data
}

export async function getApplication(id: number) {
  const response = await axios.get(APPLICATION_DATA_ENDPOINT + `/${id}`)

  return response.data
}

export async function generateContract(signatory: PartnerContractSignatory, id: number) {
  const response = await axios.post(PARTNER_CONTRACT_GENERATION_ENDPOINT + `/${id}`, signatory, {
    responseType: 'blob',
  })

  return response.data
}

export async function generateContractDiscontinue(signatory: PartnerContractSignatory, id: number) {
  const response = await axios.post(
    PARTNER_CONTRACT_DISCONTINUE_GENERATION_ENDPOINT + `/${id}`,
    signatory,
    {
      responseType: 'blob',
    }
  )

  return response.data
}

export async function fetchAddresses(parameters: { [key: string]: string }) {
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(`${ADDRESS_ENDPOINT}?${restParameters}`)

  return response.data
}

export async function fetchClassifierData() {
  const response = await axios.get(CLASSIFIER_DATA_ENDPOINT)

  return response.data
}

export async function fetchClassifierRecordValueData(parameters: { [key: string]: string }) {
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(`${CLASSIFIER_VALUES_DATA_ENDPOINT}?${restParameters}`)

  return response.data
}

export async function fetchClassifierRecordData(parameters: { [key: string]: string }) {
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(`${CLASSIFIER_RECORD_DATA_ENDPOINT}?${restParameters}`)

  return response.data
}

export async function addClassifierRecordData(classifier: AddEditClassifier) {
  const response = await axios.post(CLASSIFIER_RECORD_DATA_ENDPOINT, classifier)

  return response.data
}

export async function editClassifierRecordData(classifier: AddEditClassifier, id: number) {
  const response = await axios.put(CLASSIFIER_RECORD_DATA_ENDPOINT + `/${id}`, classifier)

  return response.data
}

export async function deleteClassifierRecordData(id: number) {
  const response = await axios.delete(CLASSIFIER_RECORD_DATA_ENDPOINT + `/${id}`, { data: id })

  return response.data
}

export async function editComplaintData(data: ComplaintEditRequest) {
  const response = await axios.put(COMPLAINT_DATA_EDIT_ENDPOINT + `/${data.id}`, data)

  return response.data
}

export async function fetchPersonData(id: number) {
  const response = await axios.get(PERSON_DATA_ENDPOINT + `/${id}`)

  return response.data
}

export async function editPersonData(person: UpdatePerson, id: number) {
  const response = await axios.put(PERSON_DATA_ENDPOINT + `/${id}`, person)

  return response.data
}

export async function editPersonRatingRights(data: RatingEditingRightsPayload) {
  const response = await axios.put(PARTNER_RATING_BLOCK_PERSON + `/${data.id}`, data)

  return response.data
}

export async function fetchSettingsData() {
  const response = await axios.get(SETTINGS_DATA_ENDPOINT)

  return response.data
}

export async function fetchFullHistoryData(id: string) {
  const response = await axios.get(`${HISTORY_DATA_SINGLE_RECORD_ENDPOINT}/${id}`)

  return response.data
}

export async function editSettingData(setting: PartnerSettingRequest, id: number) {
  const response = await axios.put(SETTING_DATA_ENDPOINT + `/${id}`, setting)

  return response.data
}

export async function addUserData(user: AddEditUserRequest) {
  const response = await axios.post(USER_DATA_ENDPOINT, user)

  return response.data
}

export async function editUserData(user: AddEditUserRequest, id: number) {
  const response = await axios.put(USER_DATA_ENDPOINT + `/${id}`, user)

  return response.data
}

export async function addInfoRequest(complaintInfoRequest: ComplaintAddInfoRequest) {
  const response = await axios.post(INFO_REQUEST, complaintInfoRequest)

  return response.data
}

export async function getComplaint(id: number) {
  if (id) {
    const response = await axios.get(INFO_REQUEST + `/${id}`)

    return response.data
  }
}

export async function addNotificationData(notification: AddEditNotification) {
  const response = await axios.post(NOTIFICATION_DATA_ENDPOINT, notification)

  return response.data
}

export async function fetchUserRoles() {
  const response = await axios.get(USER_ROLES_ENDPOINT)

  return response.data
}

export async function fetchCurrentUser() {
  const response = await axios.get(CURRENT_USER_ENDPOINT)

  return response.data
}

export async function fetchRegistryData(registryNumber: string) {
  const response = await axios.get(`${PARTNER_REGISTRY_DATA_ENDPOINT}/${registryNumber}`)

  return response.data
}

export async function fetchPartnerData(id: number) {
  const response = await axios.get(PARTNER_DATA_ENDPOINT + `/${id}`)

  return response.data
}

export async function fetchPartnerStatus(id: number) {
  const response = await axios.get(PARTNER_STATUS_ENDPOINT + `/${id}`)

  return response.data
}

export async function fetchPartnerContactData(parameters: { [key: string]: string }) {
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(PARTNER_CONTACT_DATA_ENDPOINT + `?${restParameters}`)

  return response.data
}

export async function editPartnerContactData(person: NewPartnerContact, id: number) {
  const response = await axios.put(PARTNER_CONTACT_DATA_ENDPOINT + `/${id}`, person)

  return response.data
}

export async function fetchPartnerDiscountData(parameters: { [key: string]: string }) {
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(PARTNER_DISCOUNT_DATA_ENDPOINT + `?${restParameters}`)

  return response.data
}

export async function editPartnerDiscountData(discount: NewPartnerDiscount, id: number) {
  const response = await axios.put(PARTNER_DISCOUNT_DATA_ENDPOINT + `/${id}`, discount)

  return response.data
}

export async function deletePartnerDiscountData(id: number) {
  const response = await axios.delete(PARTNER_DISCOUNT_DATA_ENDPOINT + `/${id}`, { data: id })

  return response.data
}

export async function editPartnerDiscountStatus(discount: PartnerDiscountStatus) {
  const response = await axios.put(
    PARTNER_DISCOUNT_STATUS_CHANGE_ENDPOINT + `/${discount.id}`,
    discount
  )

  return response.data
}

export async function fetchPartnerLocationData(parameters: { [key: string]: string }) {
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(PARTNER_LOCATION_DATA_ENDPOINT + `?${restParameters}`)

  return response.data
}

export async function editPartnerLocationData(location: NewPartnerLocationRequest, id: number) {
  const response = await axios.put(PARTNER_LOCATION_DATA_ENDPOINT + `/${id}`, location)

  return response.data
}

export async function deletePartnerLocationData(id: number) {
  const response = await axios.delete(PARTNER_LOCATION_DATA_ENDPOINT + `/${id}`, { data: id })

  return response.data
}

export async function addPartnerData(
  newPartner: NewPartnerRequest
): Promise<NewPartnerEntityResponse> {
  const response = await axios.post(PARTNER_DATA_ENDPOINT, newPartner)

  return response.data
}

export async function aggregatedAddPartnerData(
  newPartner: AggregatedNewPartnerRequest
): Promise<AggregatedNewPartnerResponse> {
  const response = await axios.post(AGGREGETED_PARTNER_ADD_ENDPOINT, newPartner)

  return response.data
}

export async function editPartnerData(person: NewPartnerRequest, id: number) {
  const response = await axios.put(PARTNER_DATA_ENDPOINT + `/${id}`, person)

  return response.data
}

export async function addPartnerContact(newPartnerContact: NewPartnerContact) {
  const response = await axios.post(PARTNER_CONTACT_DATA_ENDPOINT, newPartnerContact)

  return response.data
}

export async function addPartnerDiscount(
  newPartnerDiscount: NewPartnerDiscount
): Promise<NewPartnerDiscountResponse> {
  const response = await axios.post(PARTNER_DISCOUNT_DATA_ENDPOINT, newPartnerDiscount)

  return response.data
}

export async function addPartnerLocation(
  newPartnerLocation: NewPartnerLocationRequest
): Promise<NewPartnerLocationResponse> {
  const response = await axios.post(PARTNER_LOCATION_DATA_ENDPOINT, newPartnerLocation)

  return response.data
}

export async function addPartnerStatus(
  newPartnerStatus: NewPartnerStatus
): Promise<NewPartnerStatus> {
  const response = await axios.post(PARTNER_STATUS_ENDPOINT, newPartnerStatus)

  return response.data
}

export async function editPartnerStatus(newPartnerStatus: NewPartnerStatus, id: number) {
  const response = await axios.put(PARTNER_STATUS_ENDPOINT + `/${id}`, newPartnerStatus)

  return response.data
}

export async function addTask(newTask: NewTaskReq): Promise<NewTaskReq> {
  const response = await axios.post(TASK_RECORD_ENDPOINT, newTask)

  return response.data
}

export async function getTasks(parameters: TaskQueryParams) {
  const restParameters = queryString.stringify(parameters)
  const response = await axios.get(`${TASK_LIST_ENDPOINT}?${restParameters}`)

  return response.data
}

export async function editTask(newTask: NewTaskReq): Promise<NewTaskReq> {
  const response = await axios.put(`${TASK_RECORD_ENDPOINT}/${newTask.id}`, newTask)
  return response.data
}

export async function deleteTask(id: string | number) {
  const response = await axios.delete(TASK_RECORD_ENDPOINT + `/${id}`)
  return response.data
}

export async function infopanelRawSubmissions() {
  const response = await axios.get(INFOPANEL_RAW_SUBMISSIONS_ENDPOINT)

  return response.data
}

export async function infopanelReevaluationSubmissions() {
  const response = await axios.get(INFOPANEL_REEVALUATION_SUBMISSIONS_ENDPOINT)

  return response.data
}

export async function infopanelRawDocuments() {
  const response = await axios.get(INFOPANEL_RAW_DOCUMENTS_ENDPOINT)

  return response.data
}

export async function infopanelCertificatesToBeRevoked() {
  const response = await axios.get(INQUIRY_DATA_COUNT_ENDPOINT, {
    params: {
      satur_anulējamas_apliecības: true,
    },
  })
  return response.data
}

export async function infopanelPendingInquiries() {
  const response = await axios.get(INFOPANEL_PENDING_INQUIRIES_ENDPOINT)

  return response.data
}

export async function infopanelPendingComplaints() {
  const response = await axios.get(INFOPANEL_PENDING_COMPLAINTS_ENDPOINT)

  return response.data
}

export async function infopanelUnreviewedResponses() {
  const response = await axios.get(INFOPANEL_UNREVIEWED_RESPONSES_ENDPOINT)

  return response.data
}

export async function infopanelUnpublishedDiscounts() {
  const response = await axios.get(INFOPANEL_UNPUBLISHED_DISCOUNTS_ENDPOINT)

  return response.data
}

export async function getPromotionsList() {
  const response = await axios.get(PROMOTIONS)

  return response.data
}

export async function getPromotion(id: number) {
  const response = await axios.get(`${PROMOTION_RECORD_ENDPOINT}/${id}`)

  return response.data
}

export async function addPromotion(newPromotion: NewPromotionReq): Promise<NewPromotionReq> {
  const response = await axios.post(PROMOTION_RECORD_ENDPOINT, newPromotion)

  return response.data
}

export async function deletePromotion(id: number) {
  const response = await axios.delete(PROMOTION_RECORD_ENDPOINT + `/${id}`, {
    data: id,
  })

  return response.data
}

export async function editPromotion(
  promotion: NewPromotionReq,
  id: number | string
): Promise<NewPromotionReq> {
  const response = await axios.put(PROMOTION_RECORD_ENDPOINT + `/${id}`, promotion)

  return response.data
}

export async function infopanelUncompletedTasks() {
  const response = await axios.get(INFOPANEL_UNCOMPLETED_TASKS_ENDPOINT)

  return response.data
}

export async function getServices(parameters: TaskQueryParams) {
  const restParameters = queryString.stringify(parameters)
  const response = await axios.get(`${SERVICE_LIST_ENDPOINT}?${restParameters}`)

  return response.data
}

export async function addService(newService: NewServiceReq): Promise<NewServiceReq> {
  const response = await axios.post(SERVICE_LIST_ENDPOINT, newService)
  return response.data
}

export async function editService(service: NewServiceReq): Promise<NewServiceReq> {
  const response = await axios.put(SERVICE_LIST_ENDPOINT + `/${service.id}`, service)
  return response.data
}

export async function deleteService(id: number | string) {
  const response = await axios.delete(SERVICE_LIST_ENDPOINT + `/${id}`)
  return response.data
}

export async function getPartnerProps(parameters: TaskQueryParams) {
  const restParameters = queryString.stringify(parameters)
  const response = await axios.get(`${SERVICE_PARTNER_PROPS_ENDPOINT}?${restParameters}`)

  return response.data
}

export async function getPartnerProp(id: number) {
  const response = await axios.get(`${SERVICE_PARTNER_PROPS_ENDPOINT}/${id}`)

  return response.data
}

export async function addPartnerProps(partnerProps: NewPartnerProps): Promise<NewPartnerProps> {
  const response = await axios.post(SERVICE_PARTNER_PROPS_ENDPOINT, partnerProps)

  return response.data
}

export async function editPartnerProps(partnerProps: NewPartnerProps): Promise<NewPartnerProps> {
  const response = await axios.put(
    `${SERVICE_PARTNER_PROPS_ENDPOINT}/${partnerProps.id}`,
    partnerProps
  )

  return response.data
}

export async function deletePartnerProps(id: number) {
  const response = await axios.delete(`${SERVICE_PARTNER_PROPS_ENDPOINT}/${id}`)

  return response.data
}

export async function getTypographyCertificate(parameters: TypographyParams) {
  const restParameters = queryString.stringify(parameters)
  const response = await axios.get(`${TYPOGRAPHY_CERTIFICATES}?${restParameters}`, {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function addInquiryDocument(
  document: ApplicationEntryDocument
): Promise<ApplicationEntryDocument> {
  const response = await axios.post(APPLICATION_DOCUMENT, document)

  return response.data
}

export async function getPostCertificate(parameters: PostParams) {
  const restParameters = queryString.stringify(parameters)
  const includedRestParams = parameters.atzīmēt_kā_nosūtītas !== '' ? `?${restParameters}` : ''
  const response = await axios.get(POST_CERTIFICATES + includedRestParams, {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  })

  return response.data
}

export async function getApplicationByPersonCode(personCode: string) {
  const response = await axios.get(INQUIRY_DATA_ENDPOINT, {
    params: {
      personas_kods: personCode,
      limit: 1,
    },
  })

  return response.data?.[0]
}

export async function getApplicationStatusHistory(
  izsniegt_birojā: boolean,
  nodots_drukāšanai_laiks_no: string | null,
  nodots_drukāšanai_laiks_līdz: string | null,
  statuss_kods: string
) {
  const response = await axios.get(APPLICATION_LIST, {
    params: {
      izsniegt_birojā,
      nodots_drukāšanai_laiks_no,
      nodots_drukāšanai_laiks_līdz,
      statuss_kods,
      limit: 10,
    },
  })

  return response.data
}

export async function markApplicationsPrinted(applications: any) {
  const response = await axios.post(APPLICATION_MARK_PRINTED_ENDPOINT, applications)

  return response.data
}

export async function markApplicationsPrintedOffice(applications: any) {
  const response = await axios.post(APPLICATION_MARK_PRINTED_OFFICE_ENDPOINT, applications)

  return response.data
}

export async function addMassMail(data: AddMassMail) {
  const response = await axios.post(MASS_MAIL_ENDPOINT, data)

  return response.data
}

export async function getConfig() {
  const response = await axios.get(CONFIG_ENDPOINT)
  return response.data
}
