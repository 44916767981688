import React, { SyntheticEvent, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  TextField,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  applyForPlasticCard,
  changeApplicationIssuingData,
  changeApplicationStatus,
} from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { ApplicationStatusCodes } from '../../enums/ApplicationStatusCodes'
import { RequestNewCardContent } from './RequestNewCardContent'
import { ApplicationOwnerTypes } from '../../enums/ApplicationOwnerTypes'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { ApplicationIssuingData } from '../../interfaces/Application/ApplicationIssuingData'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import * as helpers from './helpers'
import { REQUIRED_ADRESS, REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import { getChildAge } from '../../utils/calculateChildAge'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
}

const defaultFormErrors = {
  adrese: '',
}

interface Props {
  card: ApplicationCard | null
  applicationCards: ApplicationCard[]
  applicationEntry: ApplicationEntry | null
  applicationIssuingData: ApplicationIssuingData | null
  statusList: ClassifierStatus[]
  isLoadingStatuses: boolean
  isLoadingApplicationIssuingData: boolean
  onClose: () => void
  closeChangeStatusModal: () => void
  openSelectChildrenModal: () => void
}

export default function ChangeCardStatusModal({
  card,
  statusList,
  applicationCards,
  applicationEntry,
  applicationIssuingData,
  isLoadingStatuses,
  isLoadingApplicationIssuingData,
  onClose,
  closeChangeStatusModal,
  openSelectChildrenModal,
}: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const initialStatus = card?.statuss_kods
  const childrenNames =
    applicationCards.filter(
      (card) =>
        card.dzimšanas_datums &&
        getChildAge(card.dzimšanas_datums) >= 6 &&
        helpers.isApplicationCardValid(card) &&
        card.tips_nosaukums === ApplicationOwnerTypes.Child
    ) || []
  const isCardOwnerChild = card?.tips_nosaukums === ApplicationOwnerTypes.Child
  const {
    adrese,
    code,
    novads_atvk,
    novads_nosaukums,
    pagasts_atvk,
    pagasts_nosaukums,
    pilseta_atvk,
    pilseta_nosaukums,
    zip,
  } = applicationIssuingData?.adrese || {}

  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
  const [status, setStatus] = React.useState<string | string[]>(card ? card.statuss_kods : '')
  const [comment, setComment] = React.useState<string>('')
  const [customAddressCheck, setCustomAddressCheck] = React.useState(Boolean(adrese))
  const [defaultAddressCheck, setDefaultAddressCheck] = React.useState(
    Boolean(applicationIssuingData?.izsniegt_birojā)
  )
  const [childrenCheck, setChildrenCheck] = React.useState(
    Object.assign(
      {},
      ...childrenNames.map((child) => ({
        [`${child.persona_id}_${child.vārds}_${child.uzvārds}`.replace(/ /g, '')]:
          child.statuss_kods === ApplicationStatusCodes.Pieprasīta,
      }))
    )
  )
  const [customAddress, setCustomAddress] = React.useState<AddressResponse>({
    address: adrese || '',
    code: code,
    novAtvk: novads_atvk,
    novName: novads_nosaukums,
    pilAtvk: pilseta_atvk,
    pilName: pilseta_nosaukums,
    pagAtvk: pagasts_atvk,
    pagName: pagasts_nosaukums,
    zipCode: zip,
    geom: null,
  })

  const selectedChildrenPersonIds = isCardOwnerChild
    ? []
    : Object.entries(childrenCheck)
        .filter(([key, value]) => value)
        .map((el) => parseInt(el[0].split('_')[0]))

  const selectedChildrenCardIds = selectedChildrenPersonIds
    .map((personCode) => childrenNames.find((child) => child.persona_id === personCode)?.id)
    .map(Number)

  const onCustomAddressCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAddressCheck(event.target.checked)

    setFormErrors({
      ...formErrors,
      adrese: '',
    })
  }

  const onDefaultAddressCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultAddressCheck(event.target.checked)

    setFormErrors({
      ...formErrors,
      adrese: '',
    })
  }

  const onChildCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChildrenCheck({
      ...childrenCheck,
      [event.target.name]: event.target.checked,
    })
  }

  const onCustomAddressChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AddressResponse
  ) => {
    if (newValue) {
      setCustomAddress(newValue)
    }

    setFormErrors({
      ...formErrors,
      adrese: '',
    })
  }

  const applyForPlasticCardCall = (cardId: number) => {
    if (applicationEntry) {
      return applyForPlasticCard(
        {
          id: cardId,
          iesniegums_id: applicationEntry.id,
        },
        cardId
      )
    } else {
      return Promise.resolve()
    }
  }

  const updateApplicationStatusCall = () => {
    return card && card.id
      ? initialStatus === ApplicationStatusCodes.Nav_pieprasīta &&
        status === ApplicationStatusCodes.Pieprasīta
        ? Promise.all(
            [...selectedChildrenCardIds, card.id].map((childCardId) =>
              applyForPlasticCardCall(childCardId)
            )
          )
        : changeApplicationStatus(
            {
              id: card.id,
              statuss_kods: status.toString(),
              komentārs: comment,
            },
            card.id
          )
      : Promise.resolve()
  }

  const updateApplicationIssuingDataCall = () => {
    return applicationEntry && status === ApplicationStatusCodes.Pieprasīta
      ? changeApplicationIssuingData(
          {
            adrese: customAddressCheck
              ? {
                  geom: customAddress.geom || null,
                  zip: customAddress.zipCode || '',
                  novads_nosaukums: customAddress.novName || '',
                  novads_atvk: customAddress.novAtvk || '',
                  pagasts_nosaukums: customAddress.pagName || '',
                  pilseta_atvk: customAddress.pilAtvk || '',
                  code: customAddress.code || 0,
                  adrese: customAddress.address || '',
                  pilseta_nosaukums: customAddress.pilName || '',
                  pagasts_atvk: customAddress.pagAtvk || '',
                  vzd_adrese: '',
                }
              : null,
            izsniegt_birojā: defaultAddressCheck,
            id: applicationEntry.id,
          },
          applicationEntry.id
        )
      : Promise.resolve()
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (
      status === ApplicationStatusCodes.Pieprasīta &&
      !defaultAddressCheck &&
      !customAddressCheck
    ) {
      errors['adrese'] = REQUIRED_ADRESS
    }

    if (
      status === ApplicationStatusCodes.Pieprasīta &&
      customAddressCheck &&
      !customAddress.address
    ) {
      errors['adrese'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onSubmit = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoadingSubmit(true)

    updateApplicationIssuingDataCall()
      .then(() =>
        updateApplicationStatusCall()
          .then(() => {
            showSnackBar()
            if (status === ApplicationStatusCodes.Pieprasīta && !isCardOwnerChild) {
              closeChangeStatusModal()
              openSelectChildrenModal()
            } else {
              onClose()
            }
          })
          .catch((error) => {
            showSnackBar({
              severity: 'error',
              text: handleErrorMessages(error),
            })
          })
      )
      .catch((error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      })
      .finally(() => setIsLoadingSubmit(false))
  }

  const onFilterSelectChange = (event: SelectChangeEvent<typeof status>) => {
    setStatus(event.target.value)
  }

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    setComment(value)
  }

  return (
    <Box>
      <Dialog open onClose={onClose} maxWidth="xl">
        <Box pb={2}>
          <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
          </Box>
          <Box px={12}>
            <DialogTitle sx={sx.dialogTitle}>
              <Box display="flex" justifyContent="center">
                Plastikāta apliecības statusa maiņa
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box sx={sx.formRow}>
                <Box component="label" sx={sx.labelRow}>
                  Statuss
                </Box>
                <FormControl
                  variant="outlined"
                  size="small"
                  disabled={isLoadingStatuses || isLoadingApplicationIssuingData}
                >
                  <Select
                    fullWidth
                    labelId="list-status-select"
                    name="statuss"
                    value={status}
                    onChange={onFilterSelectChange}
                  >
                    {statusList.map((status) => {
                      return (
                        <MenuItem key={status.kods} value={status.kods}>
                          {status.nosaukums}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={sx.formRow}>
                <Box component="label" sx={sx.labelRow}>
                  Komentārs
                </Box>
                <TextField
                  sx={{ flexGrow: 1 }}
                  size="small"
                  name="komentārs"
                  multiline
                  onChange={onCommentChange}
                  value={comment}
                />
              </Box>
              {status === ApplicationStatusCodes.Pieprasīta && (
                <RequestNewCardContent
                  childrenNames={childrenNames}
                  isCardOwnerChild={isCardOwnerChild}
                  showPlasticCardCheckbox={false}
                  plasticCardCheck={true}
                  customAddressCheck={customAddressCheck}
                  defaultAddressCheck={defaultAddressCheck}
                  childrenCheck={childrenCheck}
                  customAddress={customAddress}
                  onCustomAddressCheckChange={onCustomAddressCheckChange}
                  onDefaultAddressCheckChange={onDefaultAddressCheckChange}
                  onChildCheckChange={onChildCheckChange}
                  onCustomAddressChange={onCustomAddressChange}
                  helperText={formErrors.adrese}
                  error={formErrors.adrese}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Box
                px={2}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  sx={sx.button}
                  variant="contained"
                  onClick={onSubmit}
                  disabled={isLoadingSubmit}
                >
                  {isLoadingSubmit ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    'SAGLABĀT'
                  )}
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}
