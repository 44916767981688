import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'
import { addPartnerDiscount, editPartnerDiscountData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { NewPartnerDiscount } from '../../interfaces/Partners/NewPartnerDiscount'
import PartnerDropDownList from '../../elements/partners/PartnerDropDownList'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import RequiredLabel from '../../elements/RequiredLabel'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

const defaultFormErrors = {
  mērvienība_kods: '',
  apraksts: '',
  skaits: '',
}

interface Props {
  discount: NewPartnerDiscount | null
  partnerId: number
  partnerDiscountUnitList: ClassifierStatus[]
  partnerDiscountStatusList: ClassifierStatus[]
  onClose: () => void
}

export default function EditDiscountModal({
  partnerId,
  discount,
  partnerDiscountUnitList,
  partnerDiscountStatusList,
  onClose,
}: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
  const [formState, setFormState] = React.useState<NewPartnerDiscount>({
    skaits: discount?.skaits || 0,
    mērvienība_kods: discount?.mērvienība_kods || '',
    apraksts: discount?.apraksts || '',
    id: discount?.id,
    aizstājamā_atlaide_id: discount?.aizstājamā_atlaide_id,
  })

  const [addPartnerDiscountMutation] = useMutation(
    ({ skaits, mērvienība_kods, apraksts }: NewPartnerDiscount) =>
      addPartnerDiscount({
        sadarbības_partneris_id: partnerId,
        skaits,
        mērvienība_kods,
        apraksts,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
      onSettled: () => {
        setIsLoadingSubmit(false)
      },
    }
  )

  const [editPartnerDiscountMutation] = useMutation(
    ({ id, skaits, mērvienība_kods, apraksts, aizstājamā_atlaide_id }: NewPartnerDiscount) =>
      editPartnerDiscountData(
        {
          id: id,
          sadarbības_partneris_id: partnerId,
          skaits,
          mērvienība_kods,
          apraksts,
          aizstājamā_atlaide_id,
        },
        id as number
      ),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      onSettled: () => {
        setIsLoadingSubmit(false)
      },
    }
  )

  const onQuantityChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let value = 0

    try {
      value = parseInt(target.value, 10)
    } catch {}

    setFormState({
      ...formState,
      [target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const onTextFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [target.name]: target.value,
    })

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const handleDropDownChange = (event: SelectChangeEvent) => {
    let value = event.target.value

    setFormState({
      ...formState,
      [event.target.name]: value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsLoadingSubmit(true)

    if (discount) {
      editPartnerDiscountMutation(formState)
    } else {
      addPartnerDiscountMutation(formState)
    }
  }

  const isFormValid = () => {
    const errors: { [key: string]: string } = {}

    if (!formState.mērvienība_kods) {
      errors['mērvienība_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (!formState.apraksts) {
      errors['apraksts'] = REQUIRED_ERROR_MESSAGE
    }

    if (isNaN(formState.skaits)) {
      errors['skaits'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length === 0
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {discount ? 'Labot' : 'Pievienot'} atlaidi
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Apjoms" />
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                name="skaits"
                autoComplete="off"
                value={formState.skaits}
                onChange={onQuantityChange}
                helperText={formErrors.skaits}
                error={Boolean(formErrors.skaits)}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Mērvienība" />
              <PartnerDropDownList
                name="mērvienība_kods"
                value={formState.mērvienība_kods}
                onChange={handleDropDownChange}
                data={partnerDiscountUnitList}
                helperText={formErrors.mērvienība_kods}
                error={Boolean(formErrors.mērvienība_kods)}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Apraksts" />
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={5}
                fullWidth
                name="apraksts"
                autoComplete="off"
                value={formState.apraksts}
                onChange={onTextFieldChange}
                helperText={formErrors.apraksts}
                error={Boolean(formErrors.apraksts)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
