import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { paramFetcher } from '../../elements/ParamFetcher'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { getPartnerTableColumns } from './PartnersTableUtils'
import { PartnerListEntry } from '../../interfaces/Partners/PartnerListEntry'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../routes/routes'
import { PARTNERS_DATA_ENDPOINT, PARTNERS_DATA_COUNT_ENDPOINT } from '../../../api/API'
import { reverse } from 'named-urls'
import PartnersFilter from './PartnersFilter'
import { PartnerListFilterState } from '../../interfaces/Partners/PartnerListFilterState'
import equal from 'fast-deep-equal'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { convertToClassifierStatus } from '../../utils/convertToClassifierStatus'
import {
  DEFAULT_PARTNER_LIST_FILTER,
  usePartnersListQueryParams,
} from '../../../store/partnersList'
import { PartnersListQueryParams } from '../../interfaces/Partners/PartnerQueryParams'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordValueData'

const partnerTypesQueryParams = {
  klasifikatora_kods: 'PARTNERA_VEIDS',
}

const partnerServiceGroupsQueryParams = {
  klasifikatora_kods: 'PAKALPOJUMU_GRUPA',
}

const partnerStatusQueryParams = {
  klasifikatora_kods: 'PARTNERA_STATUSS',
}

const mapFilterToQueryParams = (filter: PartnerListFilterState) => {
  return {
    ...filter,
    veids: filter.veids.map((item) => item.kods),
    grupa: filter.grupa.map((item) => item.kods),
    statuss: filter.statuss.map((item) => item.kods),
  }
}

export default function Partners() {
  const history = useHistory()
  const { state: { prevPathName } = {} } = useLocation<{
    prevPathName?: string
  }>()
  const [partners, setPartners] = useState<PartnerListEntry[]>([])
  const [partnersCount, setPartnersCount] = useState(0)
  const [partnerTypeList, setPartnerTypeList] = useState<ClassifierStatus[]>([])
  const [partnerServiceGroupList, setPartnerServiceGroupList] = useState<ClassifierStatus[]>([])
  const [partnerStatusList, setPartnerStatusList] = useState<ClassifierStatus[]>([])

  const firstUpdate = useRef(true)

  const { filter, setFilter } = usePartnersListQueryParams()

  const [queryParams, setQueryParams] = React.useState<PartnersListQueryParams>(
    mapFilterToQueryParams(filter)
  )

  useEffect(() => {
    if (prevPathName && !prevPathName.includes(routes.partners.list)) {
      setFilter(DEFAULT_PARTNER_LIST_FILTER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    const timeoutId = setTimeout(() => {
      const nextQueryParams = mapFilterToQueryParams(filter)

      const isRemovingKeywords = nextQueryParams.atslēgvārdi.length < queryParams.atslēgvārdi.length

      const keywords = isRemovingKeywords
        ? nextQueryParams.atslēgvārdi
        : nextQueryParams.atslēgvārdi.length < 3
        ? queryParams.atslēgvārdi
        : nextQueryParams.atslēgvārdi

      nextQueryParams.atslēgvārdi = keywords

      if (equal(nextQueryParams, queryParams)) {
        return
      }

      setQueryParams(nextQueryParams)
    }, 300)

    return () => {
      clearTimeout(timeoutId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const { isLoading: isLoadingList } = useQuery(
    ['partners_list', queryParams],
    () => paramFetcher(queryParams, PARTNERS_DATA_ENDPOINT, PARTNERS_DATA_COUNT_ENDPOINT),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        setPartners(list)
        setPartnersCount(count)
      },
    }
  )

  const { isLoading: isLoadingPartnerTypes } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_types',
    queryParameters: partnerTypesQueryParams,
    querySuccess: (resp) => {
      setPartnerTypeList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerServiceGroups } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_service_groups',
    queryParameters: partnerServiceGroupsQueryParams,
    querySuccess: (resp) => {
      setPartnerServiceGroupList(convertToClassifierStatus(resp))
    },
  })

  const { isLoading: isLoadingPartnerStatuses } = useFetchClassifiers({
    queryKey: 'classifier_records_partner_statuses',
    queryParameters: partnerStatusQueryParams,
    querySuccess: (resp) => {
      setPartnerStatusList(convertToClassifierStatus(resp))
    },
  })

  const onRowClick = (event: React.MouseEvent, rowData: PartnerListEntry) => {
    onPartnerOpen(rowData.id)
  }

  const onPartnerOpen = (partnerId: number) => {
    window.history.replaceState({}, '')
    history.push(reverse(routes.partners.details, { partnerId }))
  }

  const columns = getPartnerTableColumns()

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * filter.limit !== filter.offset) {
      setFilter({
        ...filter,
        offset: pageNumber * filter.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== filter.limit) {
      setFilter({
        ...filter,
        limit: rowsPerPage,
      })
    }
  }

  const newPartner = () => {
    history.push(reverse(routes.partners.details, { partnerId: 'new' }))
  }

  const onFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    })
  }

  const onFilterSelectChange = (name: string, values: ClassifierStatus[]) => {
    setFilter({
      ...filter,
      [name]: values,
    })
  }

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter({
      ...filter,
      [event.target.name]: checked,
    })
  }

  const onSecondaryFilterToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilter({
        ...filter,
        [event.target.name]: event.target.checked,
      })
    } else {
      setFilter({
        ...filter,
        [event.target.name]: event.target.checked,
        ir_sūdzības: false,
        ir_akcijas: false,
        ir_pieprasījumi: false,
        ir_uzdevumi: false,
      })
    }
  }

  const onOrderChange = (field: string, order: string) => {
    let sortParam = ''
    if (order === 'asc') sortParam = field
    else if (order === 'desc') sortParam = `~${field}`
    setQueryParams({
      ...queryParams,
      sort: sortParam,
    })
  }

  const resetFilterValues = () => {
    setFilter(DEFAULT_PARTNER_LIST_FILTER)
  }

  return (
    <>
      <Grid container sx={{ paddingBottom: '20px' }}>
        <Grid item xs={9}>
          <PartnersFilter
            filter={filter}
            partnerTypeList={partnerTypeList}
            partnerServiceGroupList={partnerServiceGroupList}
            partnerStatusList={partnerStatusList}
            isLoading={
              isLoadingPartnerTypes || isLoadingPartnerServiceGroups || isLoadingPartnerStatuses
            }
            onSelectChange={onFilterSelectChange}
            onTextFieldChange={onFilterTextChange}
            resetFilterValues={resetFilterValues}
            onCheckboxChange={onCheckboxChange}
            onSecondaryFilterToggle={onSecondaryFilterToggle}
          />
        </Grid>
        <Grid item xs={3} justifyContent="flex-end" alignItems="flex-end" container>
          <Button onClick={newPartner} variant="outlined" color="primary">
            Jauns partneris +
          </Button>
        </Grid>
      </Grid>
      <CustomizedMaterialTable
        columns={columns}
        data={partners}
        rowStyle={{
          height: 50,
        }}
        isLoading={isLoadingList}
        page={filter.offset / filter.limit}
        totalCount={partnersCount}
        pageSize={filter.limit}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={onRowClick}
        onOrderChange={onOrderChange}
      />
    </>
  )
}
