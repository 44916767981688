import React from 'react'
import { Box, Button } from '@mui/material'
import { differenceInDays, isValid } from 'date-fns'
import { confirmable, createConfirmation } from 'react-confirm'
import DatePicker from './DatePicker'
import { ConfirmModal, ConfirmModalProps } from './ConfirmDialog'
import {
  INVALID_DATE_FORMAT,
  REQUIRED_ERROR_MESSAGE,
  END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE,
} from '../../constants/constants'

const sx = {
  button: {
    minWidth: 90,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    minWidth: '150px',
  },
}

type ConfirmPeriodModalProps = Omit<ConfirmModalProps, 'proceed'> & {
  proceed: ({
    ok,
    startDate,
    endDate,
  }: {
    ok: boolean
    startDate?: Date | null
    endDate?: Date | null
  }) => void
}
// TODO: use `MonthRangePicker` component when available https://github.com/mui/mui-x/issues/4995
function ConfirmPeriodModal({
  maxWidth = 'xs',
  text = 'Norādiet laika periodu',
  ...props
}: ConfirmPeriodModalProps) {
  const [startDate, setStartDate] = React.useState<Date | null>(new Date())
  const [endDate, setEndDate] = React.useState<Date | null>(new Date())
  const [startDateError, setStartDateError] = React.useState('')
  const [endDateError, setEndDateError] = React.useState('')

  const areDatesValid = () => {
    setStartDateError('')
    setEndDateError('')
    if (!startDate) {
      setStartDateError(REQUIRED_ERROR_MESSAGE)
      return false
    }
    if (!endDate) {
      setEndDateError(REQUIRED_ERROR_MESSAGE)
      return false
    }
    if (!isValid(startDate)) {
      setStartDateError(INVALID_DATE_FORMAT)
      return false
    }
    if (!isValid(endDate)) {
      setEndDateError(INVALID_DATE_FORMAT)
      return false
    }
    if (differenceInDays(startDate, endDate) > 0) {
      setEndDateError(END_DATE_SHOULD_BE_BIGGER_ERROR_MESSAGE)
      return false
    }
    return true
  }
  return (
    <ConfirmModal {...props} hideOkCancelButtons text={text} maxWidth={maxWidth}>
      <Box sx={sx.inputWrapper}>
        <Box component="span" sx={sx.inputLabel}>
          Datums no
        </Box>
        <DatePicker
          value={startDate}
          helperText={startDateError}
          error={Boolean(startDateError)}
          onChange={(date: Date | null) => {
            setStartDate(date)
          }}
        />
      </Box>
      <Box sx={sx.inputWrapper}>
        <Box component="span" sx={sx.inputLabel}>
          Datums līdz
        </Box>
        <DatePicker
          value={endDate}
          helperText={endDateError}
          error={Boolean(endDateError)}
          onChange={(date: Date | null) => {
            setEndDate(date)
          }}
        />
      </Box>
      <Box mt={2} mb={1} display="flex" justifyContent="center">
        <Button
          sx={sx.button}
          variant="contained"
          onClick={() =>
            props.proceed({
              ok: false,
            })
          }
          color="primary"
        >
          Atcelt
        </Button>
        <Button
          sx={sx.button}
          style={{ marginLeft: 20 }}
          variant="contained"
          onClick={() => {
            if (areDatesValid())
              props.proceed({
                ok: true,
                startDate,
                endDate,
              })
          }}
          color="primary"
        >
          Labi
        </Button>
      </Box>
    </ConfirmModal>
  )
}

// TODO: rework typing
export const confirmPeriodDialog = createConfirmation(
  confirmable<ConfirmPeriodModalProps>(ConfirmPeriodModal)
) as unknown as () => Promise<{ ok: boolean; startDate?: Date; endDate?: Date }>
