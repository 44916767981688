import { CSSProperties, useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  useTheme,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'

import {
  NewPartnerDiscount,
  NewPartnerDiscountResponse,
} from '../../interfaces/Partners/NewPartnerDiscount'
import RequiredLabel from '../../elements/RequiredLabel'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import { PartnerDiscountStatus } from '../../interfaces/Partners/PartnerDiscountStatus'
import { editPartnerDiscountStatus } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

interface Props {
  onClose: () => void
  offeredDiscount: NewPartnerDiscount | null
  discountsData: NewPartnerDiscountResponse[] | undefined
}

enum STATUSS_KODS {
  AKTĪVA = 'AAKT',
  NORAIDĪTA = 'ANOR',
}

const sx: { [key: string]: CSSProperties } = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '20px 0',
    borderWidth: '1px',
  },
  button: {
    minWidth: 130,
  },
}

const ChangeDiscountStatusModal: React.FC<Props> = ({
  onClose,
  offeredDiscount,
  discountsData,
}) => {
  const theme = useTheme()
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [formState, setFormState] = useState<PartnerDiscountStatus>({
    statuss_kods: '',
    komentārs: '',
  })
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})

  const currentlyActiveDiscount = discountsData?.find(
    ({ id }) => id === offeredDiscount?.aizstājamā_atlaide_id
  )

  const onTextFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [target.name]: target.value,
    })

    setFormErrors({
      ...formErrors,
      [target.name]: '',
    })
  }

  const isFormValid = () => {
    const errors: { [key: string]: string } = {}

    if (!formState.statuss_kods) {
      errors['statuss_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (formState.statuss_kods === STATUSS_KODS.NORAIDĪTA && !formState.komentārs) {
      errors['komentārs'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length === 0
  }

  const [changeDiscountStatusMutation] = useMutation(
    ({ statuss_kods, komentārs }: PartnerDiscountStatus) =>
      editPartnerDiscountStatus({
        id: offeredDiscount?.id,
        statuss_kods,
        komentārs,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsLoadingSubmit(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsLoadingSubmit(false)
      },
    }
  )

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsLoadingSubmit(true)

    changeDiscountStatusMutation(formState)
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <DialogTitle sx={sx.dialogTitle}>
          <Box display="flex" justifyContent="center" px={12}>
            Atlaides statusa maiņa
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">Sākotnējā atlaide</Typography>
            <Typography>
              <b>Skaits: </b>
              {currentlyActiveDiscount?.skaits}
            </Typography>
            <Typography>
              <b>Mērvienība: </b>
              {currentlyActiveDiscount?.mērvienība_nosaukums}
            </Typography>
            <Typography>
              <b>Apraksts: </b>
              {currentlyActiveDiscount?.apraksts}
            </Typography>
            <Divider sx={sx.divider} />
            <Typography variant="h6">Piedāvātā atlaide</Typography>
            <Typography>
              <b>Skaits: </b>
              {offeredDiscount?.skaits}
            </Typography>
            <Typography>
              <b>Mērvienība: </b>
              {offeredDiscount?.mērvienība_nosaukums}
            </Typography>
            <Typography>
              <b>Apraksts: </b>
              {offeredDiscount?.apraksts}
            </Typography>
            <Divider sx={sx.divider} />
          </Box>
          <Box sx={sx.inputWrapper}>
            <RequiredLabel label="Statuss" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <RadioGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formState.statuss_kods === STATUSS_KODS.AKTĪVA}
                        onChange={onTextFieldChange}
                        value={STATUSS_KODS.AKTĪVA}
                        name="statuss_kods"
                      />
                    }
                    label="Aktīva"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formState.statuss_kods === STATUSS_KODS.NORAIDĪTA}
                        onChange={onTextFieldChange}
                        value={STATUSS_KODS.NORAIDĪTA}
                        name="statuss_kods"
                      />
                    }
                    label="Noraidīta"
                  />
                </RadioGroup>
              </Box>
              {formErrors.statuss_kods && (
                <FormHelperText sx={{ ml: '14px', color: theme.palette.error.main }}>
                  {formErrors.statuss_kods}
                </FormHelperText>
              )}
            </Box>
          </Box>
          {formState.statuss_kods === STATUSS_KODS.NORAIDĪTA ? (
            <Box sx={sx.inputWrapper}>
              <RequiredLabel label="Komentārs" />
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={3}
                fullWidth
                name="komentārs"
                autoComplete="off"
                value={formState.komentārs}
                onChange={onTextFieldChange}
                helperText={formErrors.komentārs}
                error={Boolean(formErrors.komentārs)}
              />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={sx.button}
              variant="contained"
              onClick={onSubmit}
              color="primary"
              disabled={isLoadingSubmit}
            >
              {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ChangeDiscountStatusModal
