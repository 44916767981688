import { List, ListItem, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import { PartnerListEntry } from '../../interfaces/Partners/PartnerListEntry'

export const getPartnerTableColumns = () => {
  return [
    {
      field: 'nosaukums',
      title: 'Nosaukums',
      width: '20%',
    },
    {
      field: 'veids_nosaukums',
      title: 'Veids',
      width: '20%',
      sorting: false,
    },
    {
      field: 'pakalpojumu_grupa',
      title: 'Preču un pakalpojumu grupas',
      width: '20%',
      sorting: false,
      render: (rowData: PartnerListEntry) => {
        return (
          <List dense>
            {rowData &&
              rowData.pakalpojumu_grupa &&
              rowData.pakalpojumu_grupa.map((group, index) => (
                <ListItem key={index} disableGutters>
                  <Typography sx={{ fontSize: 'inherit' }}>{group.grupa_nosaukums}</Typography>
                </ListItem>
              ))}
          </List>
        )
      },
    },
    {
      field: 'statuss_nosaukums',
      title: 'Statuss',
      width: '5%',
      sorting: false,
    },
    {
      field: 'juridiskā_adrese',
      title: 'Adrese',
      width: '20%',
      sorting: false,
      render: (rowData: PartnerListEntry) => {
        return rowData.juridiskā_adrese.map((address, index) => (
          <div key={index}>{address.adrese}</div>
        ))
      },
    },
    {
      field: 'izveidošanas_datums',
      title: 'Izveidošanas datums',
      width: '10%',
      sorting: false,
      render: (rowData: PartnerListEntry) => {
        if (rowData.izveidošanas_datums) {
          return <>{format(parseISO(rowData.izveidošanas_datums), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
  ]
}
