import React from 'react'
import CustomizedMaterialTable from '../../../elements/CustomizedMaterialTable/CustomizedMaterialTable'
import { Box, Button } from '@mui/material'
import { useQuery } from 'react-query'
import { NOTIFICATION_DATA_ENDPOINT, NOTIFICATION_DATA_COUNT_ENDPOINT } from '../../../../api/API'
import { ApplicationNotificationModal } from './ApplicationNotificationModal'
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../../constants/constants'
import { format, parseISO } from 'date-fns'
import { paramFetcher } from '../../../elements/ParamFetcher'
import { NotificationQueryParams } from '../../../interfaces/NotificationQueryParams'
import { Notification } from '../../../interfaces/Notification'
import truncateString from '../../../utils/truncateString'
import stripHtml from '../../../utils/stripHtml'
import removeSignatureFromMessage from '../../../utils/removeSignatureFromMessage'
import { userDataProps } from '../../../interfaces/UserDataProps'
import { Person } from '../../../interfaces/Person'
import { EAddressStatus } from '../../../enums/EAddressStatus'
import { sanitize as sanitizeHtml } from '../../../utils/sanitizeHtml'

const columns = [
  {
    width: '5%',
    field: 'laiks',
    title: 'Datums',
    render: (rowData: Notification) => {
      if (rowData.laiks) {
        return <>{format(parseISO(rowData.laiks), DEFAULT_DATE_FORMAT)}</>
      }

      return <></>
    },
  },
  {
    width: '10%',
    field: 'veids',
    title: 'Nosūtīšanas kanāls',
  },
  {
    width: '30%',
    field: 'nosaukums',
    title: 'Nosaukums',
    render: ({ nosaukums }: Notification) => truncateString(nosaukums, 100),
  },
  {
    field: 'saturs',
    title: 'Saturs',
    render: ({ saturs }: Notification) =>
      truncateString(removeSignatureFromMessage(stripHtml(saturs)), 150),
  },
]

interface Props {
  userData: userDataProps
  userInfo: Person | null
}

export default function ApplicationNotificationTab({ userData, userInfo }: Props) {
  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false)
  const [selectedNotification, setSelectedNotification] = React.useState<Notification | null>(null)

  const [rows, setRows] = React.useState<Notification[]>([])
  const [count, setCount] = React.useState(0)

  const [queryParams, setQueryParams] = React.useState<NotificationQueryParams>({
    persona_id: userData.persona_id,
    sort: '~laiks',
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const isPhoneActivated = Boolean(
    userData.telefoni?.find((tel) => tel.ir_verificēts && tel.telefons)
  )
  const isEmailActivated = Boolean(
    userData.epasti?.find((email) => email.ir_verificēts && email.epasts)
  )
  const isEAddressActivated = userInfo?.eadrese === EAddressStatus.izmantot

  const { isLoading, refetch } = useQuery(
    ['notifications', queryParams],
    () =>
      paramFetcher(
        {
          ...queryParams,
        },
        NOTIFICATION_DATA_ENDPOINT,
        NOTIFICATION_DATA_COUNT_ENDPOINT
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: ([list, count]) => {
        const sanitizedList = list.map((listItem: any) => ({
          ...listItem,
          saturs: sanitizeHtml(listItem.saturs).__html,
        }))
        setRows(sanitizedList)
        setCount(count)
      },
    }
  )

  const closeModalAndRefetch = () => {
    setSelectedNotification(null)
    setOpenModalAddEdit(false)
    refetch()
  }

  const closeModal = () => {
    setOpenModalAddEdit(false)
  }

  const onRowClick = (event: React.MouseEvent | undefined, notification: Notification) => {
    setSelectedNotification(notification)
    setOpenModalAddEdit(true)
  }

  const onPageChange = (pageNumber: number) => {
    if (pageNumber * queryParams.limit !== queryParams.offset) {
      setQueryParams({
        ...queryParams,
        offset: pageNumber * queryParams.limit,
      })
    }
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (rowsPerPage !== queryParams.limit) {
      setQueryParams({
        ...queryParams,
        limit: rowsPerPage,
      })
    }
  }

  return (
    <>
      {openModalAddEdit && (
        <ApplicationNotificationModal
          notification={selectedNotification}
          userData={userData}
          isEmailActivated={isEmailActivated}
          isPhoneActivated={isPhoneActivated}
          isEAddressActivated={isEAddressActivated}
          closeModalAndRefetch={closeModalAndRefetch}
          closeModal={closeModal}
        />
      )}
      <Box sx={{ pb: 2, display: 'flex' }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ ml: 'auto' }}
          onClick={() => {
            setSelectedNotification(null)
            setOpenModalAddEdit(true)
          }}
        >
          Pievienot paziņojumu
        </Button>
      </Box>
      <CustomizedMaterialTable
        columns={columns}
        data={rows}
        isLoading={isLoading}
        page={queryParams.offset / queryParams.limit}
        totalCount={count}
        pageSize={queryParams.limit}
        onRowClick={onRowClick}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        rowStyle={{
          height: 50,
        }}
      />
    </>
  )
}
