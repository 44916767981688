import { isBefore, differenceInYears, parseISO } from 'date-fns'
import ChildDisabilityStatus from '../../enums/ChildDisabilityStatus'
import ChildEducationStatus from '../../enums/ChildEducationStatus'
import DebtorStatus from '../../enums/DebtorStatus'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { Children } from '../../interfaces/Children'
import { LegalAddress } from '../../interfaces/Partners/LegalAddress'

export function getDebtorStatus(applicationEntry: ApplicationEntry): DebtorStatus {
  if (applicationEntry.pārbaudes.length > 0) {
    const newestEntry = applicationEntry.pārbaudes.sort((p1, p2) => {
      if (!p1.aktualizācijas_laiks || !p2.aktualizācijas_laiks) return 0
      return (
        parseISO(p2.aktualizācijas_laiks).getTime() - parseISO(p1.aktualizācijas_laiks).getTime()
      )
    })?.[0]

    const isDebtor = !newestEntry.atbilst
    const isUgfaAvailable = newestEntry.komentārs_kods !== '24'
    const status = isUgfaAvailable
      ? isDebtor
        ? DebtorStatus.Debtor
        : DebtorStatus.NotDebtor
      : DebtorStatus.NotChecked

    return status
  } else {
    return DebtorStatus.NotChecked
  }
}

export function getAgeByDate(date: string | Date) {
  let maybeDateObj = date
  if (typeof maybeDateObj === 'string') maybeDateObj = new Date(maybeDateObj)

  return differenceInYears(new Date(), maybeDateObj)
}

export function isApplicationCardValid(card: ApplicationCard | undefined) {
  const today = new Date()
  if (card) {
    return (
      isBefore(today, new Date(card.derīguma_termiņš)) &&
      (card.anulēšanas_datums === null || isBefore(today, new Date(card.anulēšanas_datums)))
    )
  } else return true
}

// TODO: normally this should be done in the backend side
export function reshapeApplicationCardData(cards: ApplicationCard[] = []) {
  const sortedCardsByChildrenBirthdate = cards.sort((card1, card2) => {
    if (!card1.dzimšanas_datums || !card2.dzimšanas_datums) return 0
    return new Date(card2.dzimšanas_datums).getTime() - new Date(card1.dzimšanas_datums).getTime()
  })
  return sortedCardsByChildrenBirthdate.reduce(
    (acc: Array<ApplicationCard & { id_full_name: string }>, curr) => {
      const isParent = curr.tips_kods === 'VC'
      // parent(s) should be set at the top of the array then the sorted children
      if (isParent)
        acc.unshift({
          ...curr,
          id_full_name: curr.persona_id + ' ' + curr.vārds + ' ' + curr.uzvārds,
        })
      else
        acc.push({
          ...curr,
          id_full_name: curr.persona_id + ' ' + curr.vārds + ' ' + curr.uzvārds,
        })
      return acc
    },
    []
  )
}

export function sortChildrenByBirthdate(children: Children[] = []) {
  return children.sort((child1, child2) => {
    if (!child1.dzimšanas_datums || !child2.dzimšanas_datums) return 0
    return new Date(child2.dzimšanas_datums).getTime() - new Date(child1.dzimšanas_datums).getTime()
  })
}

export function getChildEducationStatus(child: Children): ChildEducationStatus {
  const filteredChecks = child.pārbaudes.filter((p) => p.tips_kods === 'VIIS')
  if (filteredChecks.length === 0) return ChildEducationStatus.NotChecked
  const isStudying = filteredChecks.sort((p1, p2) => {
    if (!p1.aktualizācijas_laiks || !p2.aktualizācijas_laiks) return 0
    return parseISO(p2.aktualizācijas_laiks).getTime() - parseISO(p1.aktualizācijas_laiks).getTime()
  })?.[0].atbilst
  if (isStudying) return ChildEducationStatus.Studying
  else return ChildEducationStatus.NotStudying
}

export function getChildDisabilityStatus(
  child: Children,
  hasAgreedToHealthDataProcessing: boolean
): ChildDisabilityStatus {
  if (!hasAgreedToHealthDataProcessing) return ChildDisabilityStatus.NotAllowed
  const filteredChecks = child.pārbaudes.filter((p) => p.tips_kods === 'VDEĀVK')
  if (filteredChecks.length === 0) return ChildDisabilityStatus.NotChecked
  const isDisabled = filteredChecks.sort((p1, p2) => {
    if (!p1.aktualizācijas_laiks || !p2.aktualizācijas_laiks) return 0
    return parseISO(p2.aktualizācijas_laiks).getTime() - parseISO(p1.aktualizācijas_laiks).getTime()
  })?.[0].atbilst
  if (isDisabled) return ChildDisabilityStatus.Disabled
  else return ChildDisabilityStatus.NotDisabled
}

export function getFullAddress(address: LegalAddress) {
  if (!address?.adrese) return ''

  return address.adrese + (address.zip ? `, ${normalizeZipCode(address.zip)}` : '')
}

export function normalizeZipCode(zipCode: string = '') {
  if (zipCode.startsWith('LV-')) return zipCode
  return `LV-${zipCode}`
}
