import React, { Dispatch, SetStateAction } from 'react'
import { ApplicationEntry } from '../interfaces/Application/ApplicationEntry'
import { useSnackBar } from '../providers/SnackBarProvider'
import { handleErrorMessages } from '../utils/handleErrorMessages'
import { getApplicationEntry } from '../views/Application/ApplicationUtils'

export default function useDocumentProcessing(
  applicationId: string,
  refetchApplicationEntry: boolean,
  isNewApplication: boolean,
  setDoneInit: Dispatch<SetStateAction<boolean>>,
  setRefetchApplicationEntry: Dispatch<SetStateAction<boolean>>
) {
  const { showSnackBar } = useSnackBar()

  const [data, setData] = React.useState<ApplicationEntry | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  const intervalTime = 3000

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!isNewApplication && (!data || refetchApplicationEntry)) {
        setIsLoading(true)
        getApplicationEntry(parseInt(applicationId))
          .then((response: ApplicationEntry) => {
            if (!response.ir_apstrādē) {
              setData(response)
              setRefetchApplicationEntry(false)
              setDoneInit(true)
              setIsLoading(false)
            } else {
              showSnackBar({
                severity: 'warning',
                text: `Datu pārbaude ir procesā`,
              })
            }
          })
          .catch((error) => {
            const errorCode = error.response.status
            setError(errorCode)
            setRefetchApplicationEntry(false)
            if (errorCode === 404) {
              clearInterval(interval)
              showSnackBar({
                severity: 'error',
                text: handleErrorMessages(error),
              })
            }
          })
        setIsLoading(false)
      } else {
        clearInterval(interval)
      }
    }, intervalTime)

    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isNewApplication, applicationId, refetchApplicationEntry])

  return { data, isLoading, error }
}
