import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import DatePicker from '../../elements/DatePicker'
import RequiredLabel from '../../elements/RequiredLabel'
import { addService, editService } from '../../../api/API'
import { useMutation } from 'react-query'
import { Loader } from '../../elements/Loader'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { NewServiceForm } from '../../interfaces/NewServiceForm'
import {
  DEFAULT_API_DATE_FORMAT,
  INVALID_DATE,
  REQUIRED_ERROR_MESSAGE,
} from '../../../constants/constants'
import { format, isValid } from 'date-fns'
import useFetchClassifiers from '../../../api/useFetchClassifierRecordData'
import TaskDropDownList from '../../elements/partners/TaskDropDownList'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    minWidth: '400px',
  },
}

interface NewServiceModalProps {
  partnerId: number
  onClose: () => void
  service?: NewServiceForm
}

export default function NewServiceModal({ partnerId, onClose, service }: NewServiceModalProps) {
  const [services, setServices] = useState([])
  const [formState, setFormState] = useState<NewServiceForm>({
    id: service?.id,
    sadarbības_partneris_id: partnerId,
    ir_aktīvs: service?.ir_aktīvs ?? true,
    tips_kods: service?.tips_kods ?? undefined,
    aktīvs_līdz: service?.aktīvs_līdz ? new Date(service.aktīvs_līdz) : null,
    aktīvs_no: service?.aktīvs_no ? new Date(service.aktīvs_no) : null,
  })
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false)

  const { showSnackBar } = useSnackBar()

  const applicationStatusesQueryParams = {
    klasifikatora_kods: 'PARTNERA_SERVISA_TIPS',
  }

  const { isLoading: isLoadingServices } = useFetchClassifiers({
    queryKey: 'classifier_records',
    queryParameters: applicationStatusesQueryParams,
    querySuccess: (resp) => {
      setServices(resp)
    },
    queryError: (error) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const [addServiceRecordMutation] = useMutation(
    ({ sadarbības_partneris_id, ir_aktīvs, aktīvs_līdz, tips_kods, aktīvs_no }: NewServiceForm) => {
      return addService({
        sadarbības_partneris_id: sadarbības_partneris_id,
        ir_aktīvs: ir_aktīvs,
        aktīvs_līdz: format(aktīvs_līdz!, DEFAULT_API_DATE_FORMAT),
        aktīvs_no: format(aktīvs_no!, DEFAULT_API_DATE_FORMAT),
        tips_kods: tips_kods,
      })
    },
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsDisabledSubmit(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsDisabledSubmit(false)
      },
    }
  )

  const [editServiceRecordMutation] = useMutation(
    ({
      id,
      sadarbības_partneris_id,
      ir_aktīvs,
      aktīvs_līdz,
      tips_kods,
      aktīvs_no,
    }: NewServiceForm) =>
      editService({
        id,
        sadarbības_partneris_id: sadarbības_partneris_id,
        ir_aktīvs: ir_aktīvs,
        aktīvs_līdz: format(aktīvs_līdz!, DEFAULT_API_DATE_FORMAT),
        aktīvs_no: format(aktīvs_no!, DEFAULT_API_DATE_FORMAT),
        tips_kods: tips_kods,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        setIsDisabledSubmit(false)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
        setIsDisabledSubmit(false)
      },
    }
  )
  const isValidDate = (date: null | Date) => date !== null && isValid(date)

  const isFormValid = () => {
    const { aktīvs_līdz, tips_kods, aktīvs_no } = formState
    const _errors: { [key: string]: string } = {}

    if (!tips_kods) _errors['tips_kods'] = REQUIRED_ERROR_MESSAGE
    if (!isValidDate(aktīvs_no)) _errors['aktīvs_no'] = INVALID_DATE
    if (!isValidDate(aktīvs_līdz)) _errors['aktīvs_līdz'] = INVALID_DATE
    if (
      aktīvs_no &&
      format(aktīvs_no!, DEFAULT_API_DATE_FORMAT) < format(new Date(), DEFAULT_API_DATE_FORMAT)
    )
      _errors['aktīvs_no'] = INVALID_DATE
    if (
      aktīvs_līdz &&
      format(aktīvs_līdz!, DEFAULT_API_DATE_FORMAT) < format(new Date(), DEFAULT_API_DATE_FORMAT)
    )
      _errors['aktīvs_līdz'] = INVALID_DATE

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsDisabledSubmit(true)
    if (!service?.id) {
      addServiceRecordMutation(formState)
    } else {
      editServiceRecordMutation(formState)
    }
  }

  const onTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {service?.id ? 'Labot' : 'Pievienot'} servisu
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Servisa veids" />
              <TaskDropDownList
                value={formState.tips_kods ?? ''}
                data={services}
                name="tips_kods"
                onChange={onTextFieldChange}
                error={Boolean(formErrors.tips_kods)}
                helperText={formErrors.tips_kods}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Pieejams no" />
              <DatePicker
                minDate={new Date()}
                value={formState.aktīvs_no}
                onChange={(e) => setFormState({ ...formState, aktīvs_no: e })}
                sx={sx.datePicker}
                error={Boolean(formErrors.aktīvs_no)}
                helperText={formErrors.aktīvs_no}
              />
            </Box>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Pieejams līdz" />
              <DatePicker
                minDate={new Date()}
                value={formState.aktīvs_līdz}
                onChange={(e) => setFormState({ ...formState, aktīvs_līdz: e })}
                sx={sx.datePicker}
                error={Boolean(formErrors.aktīvs_līdz)}
                helperText={formErrors.aktīvs_līdz}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isDisabledSubmit}
              >
                {isDisabledSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
      <Loader open={isLoadingServices} />
    </Dialog>
  )
}
