import React from 'react'
import { createTheme, ThemeProvider as Provider } from '@mui/material'
import { ThemeProviderProps } from '../interfaces/ThemeProvider'

export const theme = createTheme({
  typography: {
    fontFamily: ['DINRoundPro', 'sans-serif'].join(','),
    body1: {
      fontSize: '1rem',
    },
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: '#999',
    },
    h2: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h6: {
      fontWeight: 700,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#FFF',
    },
    primary: {
      main: '#A30A32',
    },
    secondary: {
      main: '#7d1111',
      light: '#FBECEC',
    },
  },
})

export default function ThemeProvider({ children }: ThemeProviderProps) {
  return <Provider theme={theme}>{children}</Provider>
}
