import create from 'zustand'
import { ApplicationListFilterState } from '../components/interfaces/Application/ApplicationListFilterState'
import { DEFAULT_PAGE_SIZE } from '../constants/constants'

export const DEFAULT_APPLICATION_LIST_FILTER: ApplicationListFilterState = {
  personas_kods: '',
  atslēgvārdi: '',
  statuss: [{ nosaukums: 'Izskatīšanā', kods: 'IZSKATĪŠANĀ' }],
  iesniegšanas_datums_no: null,
  iesniegšanas_datums_līdz: null,
  aktualizacijas_datums_no: null,
  aktualizacijas_datums_līdz: null,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  sort: null,
}

interface ApplicationFilterStore {
  filter: ApplicationListFilterState
  setFilter: (nextFilter: ApplicationListFilterState) => void
}

export const useApplicationListQueryParams = create<ApplicationFilterStore>((set) => ({
  filter: DEFAULT_APPLICATION_LIST_FILTER,
  setFilter: (nextFilter: ApplicationListFilterState) => {
    set(() => ({ filter: nextFilter }))
  },
}))
