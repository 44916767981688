import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import Login from './components/views/Login'
import Main from './components/views/Main'
import SnackBarProvider from './components/providers/SnackBarProvider'
import ThemeProvider from './components/providers/ThemeProvider'
import { PrivateRoute } from './components/elements/PrivateRoute'
import { initInterceptors } from './api/interceptor'

export const App = () => {
  const history = useHistory()

  initInterceptors(history)

  return (
    <ThemeProvider>
      <SnackBarProvider>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/">
            <Main />
          </PrivateRoute>
        </Switch>
      </SnackBarProvider>
    </ThemeProvider>
  )
}
