import { useState } from 'react'
import { useMutation } from 'react-query'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import RequiredLabel from '../../elements/RequiredLabel'
import { NewPartnerProps } from '../../interfaces/NewPartnerProps'
import { INVALID_PASSWORD_LENGTH, REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'
import { addPartnerProps, editPartnerProps } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { generateRandomPassword } from '../../utils/generateRandomPassword'

interface Props {
  onClose: () => void
  partnerProps?: NewPartnerProps
  partnerId?: number
}

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const NewPartnerPropsModal: React.FC<Props> = ({ onClose, partnerProps, partnerId }) => {
  const { showSnackBar } = useSnackBar()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [formState, setFormState] = useState<NewPartnerProps>({
    sadarbības_partneris_id: partnerId,
    id: partnerProps?.id,
    lietotājvārds: partnerProps?.lietotājvārds,
    parole: partnerProps?.parole,
  })
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})

  const isFormValid = () => {
    const { lietotājvārds, parole } = formState
    const _errors: { [key: string]: string } = {}

    if (!lietotājvārds) {
      _errors['lietotājvārds'] = REQUIRED_ERROR_MESSAGE
    }

    if (!parole) {
      _errors['parole'] = REQUIRED_ERROR_MESSAGE
    }

    if (parole && parole.length < 16) {
      _errors['parole'] = INVALID_PASSWORD_LENGTH
    }

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const [editPartnerPropsMutation] = useMutation(
    (formState: NewPartnerProps) => editPartnerProps(formState),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      onSettled: () => {
        setIsSubmitting(false)
      },
    }
  )

  const [addPartnerpropsMutation] = useMutation(
    (formState: NewPartnerProps) => addPartnerProps(formState),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      onSettled: () => {
        setIsSubmitting(false)
      },
    }
  )

  const onSubmit = () => {
    if (!isFormValid()) return
    setIsSubmitting(true)
    if (partnerProps?.id) {
      editPartnerPropsMutation(formState)
    } else {
      addPartnerpropsMutation(formState)
    }
  }

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const generatePassword = () => {
    const password = generateRandomPassword(16)
    setFormState({
      ...formState,
      parole: password,
    })
  }

  return (
    <Dialog open onClose={onClose} fullWidth>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <DialogTitle sx={sx.dialogTitle}>
          <Box display="flex" justifyContent="center">
            {formState.id ? 'Labot' : 'Pievienot'} lietotāja rekvizītus
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={sx.inputWrapper}>
            <RequiredLabel sx={sx.inputLabel} label="Lietotāja vārds" />
            <TextField
              variant="outlined"
              size="small"
              rows={1}
              fullWidth
              name="lietotājvārds"
              autoComplete="off"
              value={formState.lietotājvārds ?? ''}
              onChange={onTextFieldChange}
              error={Boolean(formErrors.lietotājvārds)}
              helperText={formErrors.lietotājvārds}
            />
          </Box>
          <Box sx={sx.inputWrapper}>
            <RequiredLabel sx={sx.inputLabel} label="Parole" />
            <TextField
              variant="outlined"
              size="small"
              type={showPassword ? 'text' : 'password'}
              rows={1}
              fullWidth
              name="parole"
              autoComplete="off"
              value={formState.parole ?? ''}
              onChange={onTextFieldChange}
              error={Boolean(formErrors.parole)}
              helperText={formErrors.parole}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{ marginLeft: 2, padding: '6px 40px' }}
              variant="outlined"
              onClick={generatePassword}
            >
              Ģenerēt
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            px={2}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" onClick={onSubmit} color="primary" disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default NewPartnerPropsModal
