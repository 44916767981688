import React from 'react'
import { Box } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../../constants/constants'
import { ApplicationStatusHistory } from '../../interfaces/ApplicationStatusHistory'

export const getCertificateTableColumns = () => {
  return [
    {
      field: 'pievienošanas_laiks',
      title: 'Izmaiņu datums',
      render: ({ nodots_drukāšnai_laiks }: ApplicationStatusHistory) => {
        if (!nodots_drukāšnai_laiks) {
          return undefined
        }

        return format(parseISO(nodots_drukāšnai_laiks), DEFAULT_DATE_FORMAT)
      },
    },
    {
      field: 'vārds',
      title: 'Vārds, uzvārds',
      render: (rowData: ApplicationStatusHistory) => {
        return <Box>{`${rowData.vārds} ${rowData.uzvārds}`}</Box>
      },
    },
    {
      field: 'personas_kods',
      title: 'Personas kods',
    },
    {
      field: 'numurs',
      title: 'Apliecības numurs',
    },
  ]
}
