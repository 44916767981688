import React, { SyntheticEvent, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { addApplication, applyForPlasticCard, changeApplicationIssuingData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { ApplicationOwnerTypes } from '../../enums/ApplicationOwnerTypes'
import { RequestNewCardContent } from './RequestNewCardContent'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { convertToChildrenArray } from '../../utils/convertToChildrenArray'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { Children } from '../../interfaces/Children'
import { getChildAge } from '../../utils/calculateChildAge'
import { REQUIRED_ADRESS } from '../../../constants/constants'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
}

const defaultFormErrors = {
  adrese: '',
}

interface Props {
  card: ApplicationCard | null
  child?: Children | null
  applicationCards: ApplicationCard[]
  applicationEntry: ApplicationEntry | null
  onClose: () => void
  closeRequestNewCardModal: () => void
  openSelectChildrenModal: () => void
  refetch: () => void
}

export default function RequestNewCardModal({
  card,
  child,
  applicationCards,
  applicationEntry,
  onClose,
  closeRequestNewCardModal,
  openSelectChildrenModal,
  refetch,
}: Props) {
  const { showSnackBar } = useSnackBar()

  const childrenNames = applicationCards.filter(
    (card) =>
      card.dzimšanas_datums &&
      !card.anulēšanas_datums &&
      getChildAge(card.dzimšanas_datums) >= 6 &&
      card.tips_nosaukums === ApplicationOwnerTypes.Child
  )

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
  const [plasticCardCheck, setPlasticCardCheck] = React.useState(false)
  const [customAddressCheck, setCustomAddressCheck] = React.useState(false)
  const [defaultAddressCheck, setDefaultAddressCheck] = React.useState(false)
  const [childrenCheck, setChildrenCheck] = React.useState(
    Object.assign(
      {},
      ...childrenNames.map((child) => ({
        [`${child.id}_${child.vārds}_${child.uzvārds}`]: false,
      }))
    )
  )
  const [customAddress, setCustomAddress] = React.useState<AddressResponse>({
    address: '',
    code: undefined,
    geom: null,
    novAtvk: '',
    novName: '',
    pilAtvk: '',
    pilName: '',
    pagAtvk: '',
    pagName: '',
    zipCode: '',
  })

  const isCardOwnerChild = card?.tips_nosaukums === ApplicationOwnerTypes.Child || Boolean(child)

  const selectedChildrenPersonIds = isCardOwnerChild
    ? []
    : Object.entries(childrenCheck)
        .filter(([key, value]) => value)
        .map((el) => parseInt(el[0].split('_')[0]))

  const selectedChildrenCardIds = selectedChildrenPersonIds
    .map((personCode) => childrenNames.find((child) => child.persona_id === personCode)?.id)
    .map(Number)

  const onPlasticCardCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlasticCardCheck(event.target.checked)
  }

  const onCustomAddressCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAddressCheck(event.target.checked)
  }

  const onDefaultAddressCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultAddressCheck(event.target.checked)
  }

  const onChildCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChildrenCheck({
      ...childrenCheck,
      [event.target.name]: event.target.checked,
    })
  }

  const onCustomAddressChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AddressResponse
  ) => {
    if (newValue) {
      setCustomAddress(newValue)
    }
  }

  const updateApplicationIssuingDataCall = () => {
    return applicationEntry
      ? changeApplicationIssuingData(
          {
            adrese: customAddressCheck
              ? {
                  geom: customAddress.geom || null,
                  zip: customAddress.zipCode || '',
                  novads_nosaukums: customAddress.novName || '',
                  novads_atvk: customAddress.novAtvk || '',
                  pagasts_nosaukums: customAddress.pagName || '',
                  pilseta_atvk: customAddress.pilAtvk || '',
                  code: customAddress.code || 0,
                  adrese: customAddress.address || '',
                  pilseta_nosaukums: customAddress.pilName || '',
                  pagasts_atvk: customAddress.pagAtvk || '',
                  vzd_adrese: '',
                }
              : null,
            izsniegt_birojā: defaultAddressCheck,
            id: applicationEntry.id,
          },
          applicationEntry.id
        )
      : Promise.resolve()
  }

  const addApplicationCall = () => {
    if (applicationEntry) {
      const personId = child ? child.persona_id : applicationEntry.persona_id

      const children = !isCardOwnerChild ? convertToChildrenArray(childrenCheck) : []

      return addApplication({
        iesniegums_id: applicationEntry.id,
        persona_id: personId,
        bērni: children,
      })
    } else {
      return Promise.resolve()
    }
  }

  const applyForPlasticCardCall = (cardId: number) => {
    if (applicationEntry) {
      return applyForPlasticCard(
        {
          id: cardId,
          iesniegums_id: applicationEntry.id,
        },
        cardId
      )
    } else {
      return Promise.resolve()
    }
  }

  const updateApplicationStatusCall = (newApplication: ApplicationCard | null | void) => {
    return newApplication && newApplication.id
      ? Promise.all(
          [...selectedChildrenCardIds, newApplication.id].map((childCardId) =>
            applyForPlasticCardCall(childCardId)
          )
        )
      : Promise.resolve()
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (plasticCardCheck && !defaultAddressCheck && !customAddressCheck) {
      errors['adrese'] = REQUIRED_ADRESS
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onSubmit = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoadingSubmit(true)

    if (plasticCardCheck) {
      updateApplicationIssuingDataCall()
        .then(() =>
          addApplicationCall()
            .then((newApplication) =>
              updateApplicationStatusCall(newApplication)
                .then(() => {
                  showSnackBar()
                  if (!isCardOwnerChild) {
                    closeRequestNewCardModal()
                    openSelectChildrenModal()
                  } else {
                    onClose()
                  }
                })
                .catch((error) => {
                  showSnackBar({
                    severity: 'error',
                    text: handleErrorMessages(error),
                  })
                })
            )
            .catch((error) => {
              showSnackBar({
                severity: 'error',
                text: handleErrorMessages(error),
              })
            })
        )
        .catch((error) => {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        })
        .finally(() => setIsLoadingSubmit(false))
    } else {
      if (applicationEntry) {
        const personId = child ? child.persona_id : applicationEntry.persona_id

        const children = !isCardOwnerChild ? convertToChildrenArray(childrenCheck) : []

        addApplication({
          iesniegums_id: applicationEntry.id,
          persona_id: personId,
          bērni: children,
        })
          .then(() => {
            showSnackBar()
            refetch()
            onClose()
          })
          .catch((error) => {
            showSnackBar({
              severity: 'error',
              text: handleErrorMessages(error),
            })
          })
          .finally(() => setIsLoadingSubmit(false))
      }
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Jaunas apliecības pieprasīšana
            </Box>
          </DialogTitle>
          <DialogContent>
            <RequestNewCardContent
              childrenNames={childrenNames}
              isCardOwnerChild={isCardOwnerChild}
              plasticCardCheck={plasticCardCheck}
              customAddressCheck={customAddressCheck}
              defaultAddressCheck={defaultAddressCheck}
              childrenCheck={childrenCheck}
              customAddress={customAddress}
              onPlasticCardCheckChange={onPlasticCardCheckChange}
              onCustomAddressCheckChange={onCustomAddressCheckChange}
              onDefaultAddressCheckChange={onDefaultAddressCheckChange}
              onChildCheckChange={onChildCheckChange}
              onCustomAddressChange={onCustomAddressChange}
              helperText={formErrors.adrese}
              error={formErrors.adrese}
            />
          </DialogContent>
          <DialogActions>
            <Box
              px={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
