import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import { useMutation, useQuery } from 'react-query'
import { getConfig, login, loginLdap } from '../../api/API'
import LogoIcon from '../elements/icons/LogoIcon'
import VpsButton from '../elements/VpsButton/VpsButton'
import { CurrentUser } from '../interfaces/CurrentUser'
import { convertPersonCode } from '../utils/convertPersonCode'
import { useSnackBar } from '../providers/SnackBarProvider'
import { AxiosError } from 'axios'

const sx = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    maxWidth: '150px',
    textAlign: 'center',
    lineHeight: 'initial',
  },
  signIn: {
    marginTop: 1,
    padding: '10px 0',
    minHeight: '45px',
    minWidth: '100px',
  },
}

interface LdapProps {
  username: string
  password: string
}

export default function SignIn() {
  const theme = useTheme() as Theme
  const history = useHistory()
  const { showSnackBar } = useSnackBar()

  const [personCode, setPersonCode] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const { data: configData } = useQuery('config-data', getConfig, {
    refetchOnWindowFocus: false,
  })

  const [loginMutation, { isLoading }] = useMutation((personCode: string) => login(personCode), {
    onError: (error: AxiosError<string>) => {
      if (error.response?.status === 401) {
        showSnackBar({
          severity: 'warning',
          text: `Lietotāja vārds vai parole ievadīti nekorekti vai lietotājs ir bloķēts.`,
        })
      } else {
        showSnackBar({
          severity: 'error',
          text: error.response?.data,
        })
      }
    },
    onSuccess: (resp: CurrentUser) => {
      if (resp) history.push('/')
    },
  })

  const [loginLdapMutation, { isLoading: isLoadingLdap }] = useMutation(
    ({ username, password }: LdapProps) => loginLdap(username, password),
    {
      onError: (error: AxiosError<string>) => {
        if (error.response?.status === 401) {
          showSnackBar({
            severity: 'warning',
            text: `Lietotāja vārds vai parole ievadīti nekorekti vai lietotājs ir bloķēts.`,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: error.response?.data,
          })
        }
      },
      onSuccess: (resp: CurrentUser) => {
        if (resp) history.push('/')
      },
    }
  )

  const onSubmitLdap = () => {
    loginLdapMutation({ username, password } as LdapProps)
  }

  const onSubmitTest = () => {
    loginMutation(convertPersonCode(personCode))
  }

  const handlePersonCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPersonCode(value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setUsername(value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPassword(value)
  }

  return (
    <Grid container spacing={0} direction="column" sx={sx.container}>
      <CssBaseline />
      <Grid item>
        <Box sx={sx.paper}>
          <LogoIcon
            style={{
              width: 155,
              height: 155,
              fill: theme.palette.primary.main,
            }}
          />
          <Typography component="h1" variant="h6" color="primary" sx={sx.title}>
            Latvijas Goda Ģimene
          </Typography>
          <Box sx={{ textAlign: 'center', margin: '40px 0px 10px' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Pieslēgšanās ar aktīvās direktorijas lietotāju
            </Typography>
          </Box>
          <Box sx={{ width: '350px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Lietotājvārds"
              autoFocus
              value={username}
              onChange={handleUsernameChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              required
              fullWidth
              label="Parole"
              value={password}
              onChange={handlePasswordChange}
            />

            <Button
              fullWidth
              disabled={isLoading || isLoadingLdap}
              variant="contained"
              color="primary"
              onClick={onSubmitLdap}
              sx={sx.signIn}
            >
              {isLoadingLdap ? <CircularProgress color="inherit" size={18} /> : 'Pieslēgties'}
            </Button>
          </Box>

          {configData?.test ? (
            <Box sx={{ width: '350px' }}>
              <Box sx={{ textAlign: 'center', margin: '40px 0px 10px' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Testa pieslēgšanās
                </Typography>
              </Box>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Personas kods"
                value={personCode}
                onChange={handlePersonCodeChange}
              />
              <Button
                fullWidth
                disabled={isLoading || isLoadingLdap}
                variant="contained"
                color="primary"
                onClick={onSubmitTest}
                sx={sx.signIn}
              >
                {isLoading ? <CircularProgress color="inherit" size={18} /> : 'Pieslēgties'}
              </Button>
            </Box>
          ) : (
            <VpsButton />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
