import React from 'react'
import { Box, Checkbox, FormControlLabel, FormHelperText, Typography } from '@mui/material'
import { theme } from '../../providers/ThemeProvider'
import { Children } from '../../interfaces/Children'

const sx = {
  inputLabel: {
    minWidth: '150px',
  },
}

interface Props {
  childrenNames: Children[]
  childrenCheck: { [key: string]: boolean }
  formErrors: { [key: string]: string }
  onChildCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const SelectChildrenForParentCardContent = ({
  childrenNames,
  childrenCheck,
  formErrors,
  onChildCheckChange,
}: Props) => {
  return (
    <Box>
      <Typography variant="subtitle2" sx={{ paddingTop: '20px' }}>
        Bērnu atzīmēšana drukāšanai uz plastikāta apliecības:
      </Typography>

      {childrenNames.map((child, index) => (
        <Box key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  childrenCheck[
                    `${child.persona_id}_${child.vārds}_${child.uzvārds}`.replace(/ /g, '')
                  ]
                }
                color="primary"
                onChange={onChildCheckChange}
                name={`${child.persona_id}_${child.vārds}_${child.uzvārds}`.replace(/ /g, '')}
              />
            }
            label=""
          />
          <Box component="span" sx={sx.inputLabel}>
            {child.vārds} {child.uzvārds}
          </Box>
        </Box>
      ))}

      {formErrors && formErrors.selectedChildren && (
        <FormHelperText sx={{ mt: 1, ml: 2, color: theme.palette.error.main }}>
          {formErrors.selectedChildren}
        </FormHelperText>
      )}
    </Box>
  )
}
