import { Box, TextField, TextFieldProps, Typography } from '@mui/material'
import { DEFAULT_MAX_LENGTH } from '../../../constants/constants'

interface Props {
  maxLength?: number
  showMaxLength?: boolean
  value: string
  error?: boolean
  helperText?: string
}

export default function SimpleEditor({
  maxLength = DEFAULT_MAX_LENGTH,
  showMaxLength = false,
  value = '',
  helperText,
  error,
  ...props
}: Props & TextFieldProps) {
  return (
    <Box width={1}>
      <TextField fullWidth multiline minRows={5} {...props} value={value} />
      {helperText && (
        <Typography fontSize="0.75rem" mt={1} ml={2} color={error ? 'error' : 'default'}>
          {helperText}
        </Typography>
      )}
      {showMaxLength && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ mt: 1, ml: 'auto' }}>
            <Typography component="span">
              {value.length} / {maxLength}
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  )
}
