import NewApplicationTab from './NewApplicationTab'
import { CollapsableSection } from '../../elements/CollapsableSection'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { Person } from '../../interfaces/Person'
import { ApplicationContacts } from './ApplicationContacts'
import { ExistingApplicationTab } from './ExistingApplicationTab'
import { ApplicationDocuments } from './ApplicationDocuments'
import ChildItems from './ChildItems'
import { ApplicationRegistersCheckList } from './ApplicationRegistersCheckList'

interface Props {
  applicationEntry: ApplicationEntry | null
  userInfo: Person | null
  onNewApplication: (applicationId: number) => void
  documentStatusList: any
  reload: () => void
}

export function ApplicationTabContent({
  applicationEntry,
  userInfo,
  onNewApplication,
  documentStatusList,
  reload,
}: Props) {
  return (
    <>
      {applicationEntry ? (
        <>
          <CollapsableSection title="Iesniegums">
            <ExistingApplicationTab
              userInfo={userInfo}
              applicationEntry={applicationEntry}
              reload={reload}
            />
          </CollapsableSection>
          {applicationEntry.aizbilstamie.length > 0 && (
            <CollapsableSection title="Bērni">
              <ChildItems
                hasAgreedToHealthDataProcessing={applicationEntry.piekrīt_veselības_datu_apstrādei}
                childrenData={applicationEntry.aizbilstamie}
              />
            </CollapsableSection>
          )}
          {applicationEntry.pārbaudes.length > 0 && (
            <CollapsableSection title="UGFA datu pārbaude">
              <ApplicationRegistersCheckList
                applicationEntryId={applicationEntry.id}
                list={applicationEntry.pārbaudes}
                documentStatusList={documentStatusList}
                reload={reload}
              />
            </CollapsableSection>
          )}
        </>
      ) : (
        <NewApplicationTab onNewApplication={onNewApplication} />
      )}

      {userInfo && (
        <CollapsableSection title="Kontaktinformācija">
          <ApplicationContacts userInfo={userInfo} reload={reload} />
        </CollapsableSection>
      )}

      {applicationEntry ? (
        <CollapsableSection title="Iesnieguma dokumenti">
          <ApplicationDocuments applicationEntry={applicationEntry} reload={reload} />
        </CollapsableSection>
      ) : (
        <></>
      )}
    </>
  )
}
