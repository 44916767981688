import React, { FormEvent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import lvLocale from 'date-fns/locale/lv'
import { FileUploadButton } from '../../elements/FileUploadButton'
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_MASK,
  INVALID_DATE_FORMAT,
  INVALID_DATE,
  REQUIRED_ERROR_MESSAGE,
  FILE_SIZE_LIMIT_MB_MESSAGE,
} from '../../../constants/constants'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { useMutation } from 'react-query'
import { changeRegisterCheckDocument } from './ApplicationUtils'
import { format, isValid } from 'date-fns'
import { RequiredMarker } from '../../elements/RequiredMarker'
import CancelIcon from '@mui/icons-material/Cancel'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { RegisterCheckDocument } from '../../interfaces/RegisterCheckDocument'
import { ApplicationDocuments } from '../../interfaces/Application/ApplicationDocuments'
import { FileEntry } from '../../interfaces/File/FileEntry'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { uploadFiles } from '../../utils/uploadFiles'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { DocumentStatus } from '../../enums/DocumentStatus'

const sx = {
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  submitWrap: {
    justifyContent: 'flex-end',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '190px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
  filterControl: {
    width: '240px',
  },
  submitBtn: {
    minHeight: '36px',
    minWidth: '172px',
  },
}

interface DocumentEntryState {
  iesniegšanas_datums: Date | null
  derīguma_termiņš: Date | null
  statuss_kods: string
  komentārs: string
}

interface Props {
  open: boolean
  onClose?: () => void
  tips_kods: string
  iesniegums_id: number
  personas_pārbaude_id: number
  documentStatusList: ClassifierStatus[]
  onNewDocument: () => void
  applicationDocument?: ApplicationDocuments
}

export function ApplicationDocumentUpload({
  open,
  onClose,
  tips_kods,
  iesniegums_id,
  personas_pārbaude_id,
  documentStatusList,
  onNewDocument,
  applicationDocument,
}: Props) {
  const { showSnackBar } = useSnackBar()

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState<FileListItem[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<FileEntry[]>([])
  const [documentEntry, setDocumentEntry] = useState<DocumentEntryState>({
    iesniegšanas_datums: new Date(),
    derīguma_termiņš: null,
    statuss_kods: DocumentStatus.JAUNS,
    komentārs: '',
  })
  const isDocumentRejected = documentEntry.statuss_kods === DocumentStatus.NORAIDĪTS

  useEffect(() => {
    if (applicationDocument) {
      const existingFiles: FileListItem[] = applicationDocument.faili.map((file) => ({
        file,
        state: 'keep',
      }))

      setFiles(existingFiles)

      setDocumentEntry({
        iesniegšanas_datums: applicationDocument.iesniegšanas_datums
          ? new Date(applicationDocument.iesniegšanas_datums)
          : null,
        derīguma_termiņš: applicationDocument.derīguma_termiņš
          ? new Date(applicationDocument.derīguma_termiņš)
          : null,
        statuss_kods: applicationDocument.statuss_kods || DocumentStatus.JAUNS,
        komentārs: applicationDocument.komentārs || '',
      })
    }
  }, [applicationDocument])

  const handleDialogClose = () => {
    onClose && onClose()
  }

  const onFileChange = (files: FileListItem[]) => {
    setFiles(files)
    setIsUploading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFiles((prevFormState) => [
            ...prevFormState,
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFiles((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFiles([])
        setUploadedFiles([])
      })
      .finally(() => {
        setIsUploading(false)
      })
    setFormErrors({
      ...formErrors,
      dokuments: '',
    })
  }

  const onApplicationDate = (date: Date | null) => {
    onDateChange('iesniegšanas_datums', date)
  }

  const onBestBeforeDate = (date: Date | null) => {
    onDateChange('derīguma_termiņš', date)
  }

  const onDateChange = (name: string, date: Date | null) => {
    setDocumentEntry({
      ...documentEntry,
      [name]: date,
    })

    setFormErrors({
      ...formErrors,
      [name]: '',
    })
  }

  const onSelectChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.name, event.target.value)
  }

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name, event.target.value)
  }

  const onChange = (name: string, value: string | string[]) => {
    setDocumentEntry({
      ...documentEntry,
      [name]: value,
    })
  }

  const [changeDocument] = useMutation<unknown, unknown, RegisterCheckDocument>(
    (document) => changeRegisterCheckDocument(document),
    {
      onSuccess: () => {
        showSnackBar()
        handleDialogClose()
        setIsUploading(false)
        onNewDocument()
      },
      onError: (error: any) => {
        setIsUploading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsUploading(true)

    changeDocument({
      id: applicationDocument?.id,
      faili: uploadedFiles,
      personas_pārbaude_id,
      iesniegums_id,
      tips_kods: tips_kods + '_DOK',
      iesniegšanas_datums: format(
        documentEntry.iesniegšanas_datums as unknown as Date,
        DEFAULT_API_DATE_FORMAT
      ),
      derīguma_termiņš: documentEntry.derīguma_termiņš
        ? format(documentEntry.derīguma_termiņš as unknown as Date, DEFAULT_API_DATE_FORMAT)
        : null,
      statuss_kods: documentEntry.statuss_kods,
      komentārs: documentEntry.komentārs,
    })
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}
    const currentTime = new Date().setHours(0, 0, 0, 0)

    if (
      documentEntry.iesniegšanas_datums &&
      documentEntry.iesniegšanas_datums.setHours(0, 0, 0, 0) > currentTime
    ) {
      errors['iesniegšanas_datums'] = INVALID_DATE
    }

    if (documentEntry.iesniegšanas_datums && !isValid(documentEntry.iesniegšanas_datums)) {
      errors['iesniegšanas_datums'] = INVALID_DATE_FORMAT
    }

    if (!documentEntry.derīguma_termiņš && !isDocumentRejected) {
      errors['derīguma_termiņš'] = REQUIRED_ERROR_MESSAGE
    }

    if (
      documentEntry.derīguma_termiņš &&
      documentEntry.derīguma_termiņš.setHours(0, 0, 0, 0) < currentTime
    ) {
      errors['derīguma_termiņš'] = INVALID_DATE
    }

    const keepFiles = files.filter((file) => file.state === 'keep' || file.state === 'new')

    if (keepFiles.length === 0) {
      errors['dokuments'] = REQUIRED_ERROR_MESSAGE
    }

    if (
      documentEntry.derīguma_termiņš &&
      !isDocumentRejected &&
      !isValid(documentEntry.derīguma_termiņš)
    ) {
      errors['derīguma_termiņš'] = INVALID_DATE_FORMAT
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  return (
    <Dialog open={open} maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <DialogTitle>{tips_kods} dokumentu pievienošana</DialogTitle>
        <CancelIcon style={{ cursor: 'pointer' }} color="primary" onClick={handleDialogClose} />
      </Box>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Dokuments
              <RequiredMarker />
            </Box>
            <Box>
              <FileUploadButton
                useLink
                onFileChange={onFileChange}
                files={files}
                error={formErrors['dokuments']}
              />
            </Box>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lvLocale}>
            <Box sx={sx.formRow}>
              <Box component="label" sx={sx.labelRow}>
                Iesniegšanas datums
                <RequiredMarker />
              </Box>
              <DatePicker
                value={documentEntry.iesniegšanas_datums}
                disableFuture={true}
                onChange={onApplicationDate}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      size="small"
                      helperText={formErrors.iesniegšanas_datums}
                      error={Boolean(formErrors.iesniegšanas_datums)}
                      sx={sx.filterControl}
                    />
                  )
                }}
                inputFormat={DEFAULT_DATE_FORMAT}
                mask={DEFAULT_DATE_MASK}
              />
            </Box>
            {!isDocumentRejected && (
              <Box sx={sx.formRow}>
                <Box component="label" sx={sx.labelRow}>
                  Derīgs līdz
                  <RequiredMarker />
                </Box>
                <DatePicker
                  value={documentEntry.derīguma_termiņš}
                  disablePast={true}
                  onChange={onBestBeforeDate}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        size="small"
                        helperText={formErrors.derīguma_termiņš}
                        error={Boolean(formErrors.derīguma_termiņš)}
                        sx={sx.filterControl}
                      />
                    )
                  }}
                  inputFormat={DEFAULT_DATE_FORMAT}
                  mask={DEFAULT_DATE_MASK}
                />
              </Box>
            )}
          </LocalizationProvider>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Komentārs
            </Box>
            <TextField
              name="komentārs"
              multiline
              rows={4}
              value={documentEntry.komentārs}
              onChange={onTextFieldChange}
              inputProps={{
                maxLength: 200,
              }}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Statuss
            </Box>
            <FormControl variant="outlined" size="small">
              <Select
                labelId="list-status-select"
                sx={sx.filterControl}
                name="statuss_kods"
                value={documentEntry.statuss_kods}
                onChange={onSelectChange}
              >
                {documentStatusList.map((status) => {
                  return (
                    <MenuItem key={status.kods} value={status.kods}>
                      {status.nosaukums}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ pb: 2, pr: 2 }}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            sx={sx.submitBtn}
            disabled={isUploading}
          >
            {isUploading ? <CircularProgress color="inherit" size={18} /> : 'Saglabāt izmaiņas'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
