import { format } from 'date-fns'
import { useMutation, useQuery } from 'react-query'
import {
  exportCooperationPartnersActionsRequest,
  exportCooperationPartnersByGroupNumberRequest,
  exportCooperationPartnersDiscountsRequest,
  exportCooperationPartnersRequest,
  exportCurrentHonoraryFamilyCardsRequest,
  exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriodRequest,
  exportHonoraryFamilyCardsRequest,
  exportIssuedHonoraryCertificatesOfFamiliesInAPeriodRequest,
  exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriodRequest,
  exportAuthorizationCountRequest,
  exportDeniedCountOfHonoraryFamilyCertificatesRequest,
} from '../../../api/reports'
import { DEFAULT_API_DATE_FORMAT } from '../../../constants/constants'
import { confirmPeriodDialog } from '../../elements/ConfirmPeriodDialog'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { downloadBlobFile } from '../Certificates/utils'

export default function useReportsExporter() {
  const { showSnackBar } = useSnackBar()
  // TODO: typing
  const handleError = (error: any) => {
    showSnackBar({
      severity: 'error',
      text: handleErrorMessages(error),
    })
  }
  const { refetch: exportHonoraryFamilyCards, isFetching: isExportingHonoraryFamilyCards } =
    useQuery('honorary-family-cards', exportHonoraryFamilyCardsRequest, {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        if (data) downloadBlobFile(data, 'atskaite_apliecību_saraksts.xls')
      },
      onError: handleError,
    })
  const {
    refetch: exportCurrentHonoraryFamilyCards,
    isFetching: isExportingCurrentHonoraryFamilyCards,
  } = useQuery('current-honorary-family-cards', exportCurrentHonoraryFamilyCardsRequest, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      if (data) downloadBlobFile(data, 'atskaite_aktuālo_goda_ģimeņu_apliecību_saraksts.xls')
    },
    onError: handleError,
  })
  const {
    refetch: exportCooperationPartnersByGroupsNumber,
    isFetching: isExportingCooperationPartnersByGroupsNumber,
  } = useQuery(
    'cooperation-partners-by-group-number',
    exportCooperationPartnersByGroupNumberRequest,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        if (data)
          downloadBlobFile(data, 'atskaite_sadarbības_partneri_pa_grupām_skaita_saraksts.xls')
      },
      onError: handleError,
    }
  )
  const {
    refetch: exportCooperationPartnersActions,
    isFetching: isExportingCooperationPartnersActions,
  } = useQuery('cooperation-partners-actions', exportCooperationPartnersActionsRequest, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      if (data) downloadBlobFile(data, 'atskaite_sadarbības_partneru_akcija_saraksts.xls')
    },
    onError: handleError,
  })
  const {
    refetch: exportCooperationPartnersDiscounts,
    isFetching: isExportCooperationPartnersDiscounts,
  } = useQuery('cooperation-partners-discounts', exportCooperationPartnersDiscountsRequest, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      if (data) downloadBlobFile(data, 'atskaite_sadarbības_partneru_atlaižu_saraksts.xls')
    },
    onError: handleError,
  })
  const { refetch: exportCooperationPartners, isFetching: isExportingCooperationPartners } =
    useQuery('cooperation-partners', exportCooperationPartnersRequest, {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        if (data) downloadBlobFile(data, 'atskaite_sadarbības_partneru_saraksts.xls')
      },
      onError: handleError,
    })
  // TODO: create a `useLazyQuery` hook. Even though `useMutation` is intended to be used for post/put/patch/delete actions I thought that it's better to not create period states for each query for the requests that require them
  const [
    exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod,
    { isLoading: isExportingDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod },
  ] = useMutation(
    ({ startDate, endDate }: { startDate: string; endDate: string }) =>
      exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriodRequest(startDate, endDate),
    {
      onSuccess: (data) => {
        if (data)
          downloadBlobFile(
            data,
            'atskaite_aktuālo_goda_ģimeņu_apliecību_skaita_dinamika_saraksts.xls'
          )
      },
      onError: handleError,
    }
  )
  const [
    exportIssuedHonoraryCertificatesOfFamiliesInAPeriod,
    { isLoading: isExportingIssuedHonoraryCertificatesOfFamiliesInAPeriod },
  ] = useMutation(
    ({ startDate, endDate }: { startDate: string; endDate: string }) =>
      exportIssuedHonoraryCertificatesOfFamiliesInAPeriodRequest(startDate, endDate),
    {
      onSuccess: (data) => {
        if (data) downloadBlobFile(data, 'atskaite_izsniegtās_goda_ģimeņu_apliecības_saraksts.xls')
      },
      onError: handleError,
    }
  )
  const [
    exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod,
    { isLoading: isExportingNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod },
  ] = useMutation(
    ({ startDate, endDate }: { startDate: string; endDate: string }) =>
      exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriodRequest(startDate, endDate),
    {
      onSuccess: (data) => {
        if (data)
          downloadBlobFile(data, 'atskaite_noraidīto_goda_ģimeņu_apliecību_skaita_saraksts.xls')
      },
      onError: handleError,
    }
  )
  const [exportAuthorizationCountMutation, { isLoading: isExportingAuthorizationCount }] =
    useMutation(
      ({ startDate, endDate }: { startDate: string; endDate: string }) =>
        exportAuthorizationCountRequest(startDate, endDate),
      {
        onSuccess: (data) => {
          if (data)
            downloadBlobFile(data, 'atskaite_goda_ģimeņu_apliecību_autorizācijas_izsaukumi.xls')
        },
        onError: handleError,
      }
    )
  const [
    exportDeniedCountOfHonoraryFamilyCertificates,
    { isLoading: isExportingDeniedCountOfHonoraryFamilyCertificates },
  ] = useMutation(
    ({ startDate, endDate }: { startDate: string; endDate: string }) =>
      exportDeniedCountOfHonoraryFamilyCertificatesRequest(startDate, endDate),
    {
      onSuccess: (data) => {
        if (data) downloadBlobFile(data, 'atskaite_noraidīto_goda_ģimeņu_apliecību_skaits.xls')
      },
      onError: handleError,
    }
  )

  return {
    isExportingHonoraryFamilyCards,
    exportHonoraryFamilyCards,
    isExportingCurrentHonoraryFamilyCards,
    exportCurrentHonoraryFamilyCards,
    isExportingCooperationPartnersByGroupsNumber,
    exportCooperationPartnersByGroupsNumber,
    isExportingCooperationPartnersActions,
    exportCooperationPartnersActions,
    isExportCooperationPartnersDiscounts,
    exportCooperationPartnersDiscounts,
    isExportingCooperationPartners,
    exportCooperationPartners,
    isExportingDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod,
    exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod: async () => {
      const confirm = await confirmPeriodDialog()
      if (confirm.ok && confirm.startDate && confirm.endDate) {
        exportDynamicListOfCertificatesNumberOfHonoraryFamiliesInAPeriod({
          startDate: format(confirm.startDate, DEFAULT_API_DATE_FORMAT),
          endDate: format(confirm.endDate, DEFAULT_API_DATE_FORMAT),
        })
      }
    },
    isExportingIssuedHonoraryCertificatesOfFamiliesInAPeriod,
    exportIssuedHonoraryCertificatesOfFamiliesInAPeriod: async () => {
      const confirm = await confirmPeriodDialog()
      if (confirm.ok && confirm.startDate && confirm.endDate) {
        exportIssuedHonoraryCertificatesOfFamiliesInAPeriod({
          startDate: format(confirm.startDate, DEFAULT_API_DATE_FORMAT),
          endDate: format(confirm.endDate, DEFAULT_API_DATE_FORMAT),
        })
      }
    },
    isExportingNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod,
    exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod: async () => {
      const confirm = await confirmPeriodDialog()
      if (confirm.ok && confirm.startDate && confirm.endDate) {
        exportNumberOfCertificatesOfHonoraryFamiliesRejectedInAPeriod({
          startDate: format(confirm.startDate, DEFAULT_API_DATE_FORMAT),
          endDate: format(confirm.endDate, DEFAULT_API_DATE_FORMAT),
        })
      }
    },
    isExportingDeniedCountOfHonoraryFamilyCertificates,
    exportDeniedCountOfHonoraryFamilyCertificates: async () => {
      const confirm = await confirmPeriodDialog()
      if (confirm.ok && confirm.startDate && confirm.endDate) {
        exportDeniedCountOfHonoraryFamilyCertificates({
          startDate: format(confirm.startDate, DEFAULT_API_DATE_FORMAT),
          endDate: format(confirm.endDate, DEFAULT_API_DATE_FORMAT),
        })
      }
    },
    isExportingAuthorizationCount,
    exportAuthorizationCountMutation: async () => {
      const confirm = await confirmPeriodDialog()
      if (confirm.ok && confirm.startDate && confirm.endDate) {
        exportAuthorizationCountMutation({
          startDate: format(confirm.startDate, DEFAULT_API_DATE_FORMAT),
          endDate: format(confirm.endDate, DEFAULT_API_DATE_FORMAT),
        })
      }
    },
  }
}
