import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useMutation } from 'react-query'

import { useSnackBar } from '../../providers/SnackBarProvider'
import { Complaint, ComplaintEditRequest } from '../../interfaces/Complaints'
import { editComplaintData } from '../../../api/API'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import RequiredLabel from '../../elements/RequiredLabel'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants/constants'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 130,
  },
  inputWrapper: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    minWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

interface Props {
  complaint: Complaint
  onClose: () => void
  onSuccess: () => void
}

export default function ReplyComplaintModal({ complaint, onClose, onSuccess }: Props) {
  const { showSnackBar } = useSnackBar()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isReplyInvalid, setIsReplyInvalid] = useState<boolean>(false)
  const [reply, setReply] = useState<string>('')

  const [complaintMutation] = useMutation((data: ComplaintEditRequest) => editComplaintData(data), {
    onSuccess: () => {
      showSnackBar()
      setIsLoadingSubmit(false)
      onSuccess()
      onClose()
    },
    onError: (error: any) => {
      setIsLoadingSubmit(false)
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const onSubmit = () => {
    if (!reply) {
      setIsReplyInvalid(true)
      return
    }

    setIsLoadingSubmit(true)

    complaintMutation({
      statuss_kods: complaint.statuss_kods,
      atbilde: reply,
      id: complaint.id,
    })
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon style={sx.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={12}>
          <DialogTitle sx={sx.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Atbildēt sūdzības iesniedzējam
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={sx.inputWrapper}>
              <RequiredLabel sx={sx.inputLabel} label="Atbilde" />
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={5}
                fullWidth
                autoComplete="off"
                value={reply}
                sx={{ minWidth: '400px' }}
                onChange={(e) => {
                  setReply(e.target.value)
                  setIsReplyInvalid(false)
                }}
                error={isReplyInvalid}
                helperText={isReplyInvalid && REQUIRED_ERROR_MESSAGE}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={sx.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disabled={isLoadingSubmit}
              >
                {isLoadingSubmit ? <CircularProgress color="inherit" size="1.5rem" /> : 'SAGLABĀT'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
