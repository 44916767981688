import { useTheme } from '@mui/material'
import { CollapsableSection } from '../../elements/CollapsableSection'
import { ApplicationCard } from '../../interfaces/Application/ApplicationCard'
import { ApplicationEntry } from '../../interfaces/Application/ApplicationEntry'
import { ChildrenTab } from './ChildrenTab'
import NewApplicationTab from './NewApplicationTab'

interface Props {
  applicationEntry: ApplicationEntry | null
  applicationCards: ApplicationCard[]
  onNewApplication: (applicationId: number) => void
  documentStatusList: any
  reload: () => void
}

export function ApplicationChildrenTabContent({
  applicationEntry,
  applicationCards,
  onNewApplication,
  documentStatusList,
  reload,
}: Props) {
  const theme = useTheme()
  const eligibleChildren = applicationEntry?.aizbilstamie
    .filter((e) => e.pārbaudes.some((r) => r.atbilst))
    .sort((a, b) => a.vārds.localeCompare(b.vārds))

  const ineligibleChildren = applicationEntry?.aizbilstamie
    .filter((e) => !e.pārbaudes.some((r) => r.atbilst))
    .sort((a, b) => a.vārds.localeCompare(b.vārds))

  return (
    <>
      {applicationEntry?.aizbilstamie ? (
        <CollapsableSection
          title="Bērni, kuri atbilst apliecības piešķiršanas kritērijiem"
          style={{ backgroundColor: theme.palette.success.main }}
        >
          <ChildrenTab
            areChildrenEligible={true}
            childrenEntry={eligibleChildren}
            applicationCards={applicationCards}
            applicationEntry={applicationEntry}
            reload={reload}
            documentStatusList={documentStatusList}
          />
        </CollapsableSection>
      ) : (
        <NewApplicationTab onNewApplication={onNewApplication} />
      )}

      {applicationEntry?.aizbilstamie ? (
        <CollapsableSection
          title="Bērni, kuri neatbilst apliecības piešķiršanas kritērijiem"
          style={{ backgroundColor: theme.palette.error.main }}
        >
          <ChildrenTab
            areChildrenEligible={false}
            childrenEntry={ineligibleChildren}
            applicationCards={applicationCards}
            applicationEntry={applicationEntry}
            reload={reload}
            documentStatusList={documentStatusList}
          />
        </CollapsableSection>
      ) : (
        <NewApplicationTab onNewApplication={onNewApplication} />
      )}
    </>
  )
}
