import sanitizeHtml from 'sanitize-html'

const defaultSanitizeOptions = {
  allowedTags: [
    'b',
    'u',
    's',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'strong',
    'i',
    'em',
    'strong',
    'a',
    'img',
    'p',
    'ul',
    'li',
    'ol',
    'br',
  ],
  allowedAttributes: {
    p: ['style'],
    a: ['href', '_target'],
    img: ['src', 'width', 'height', 'style', 'alt'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
}

const replaceNewLineWithHtmlBreak = (string = '') => {
  // Noticed that some texts contain "//n" are returned from backend (endpoint: data/sadarbības_partneru_saraksts)
  return string.replace(/(\n|\\n)/g, '<br />')
}

export const sanitize = (htmlDirty: string, options?: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(replaceNewLineWithHtmlBreak(htmlDirty), {
    ...defaultSanitizeOptions,
    ...options,
  }),
})
