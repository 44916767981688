import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Box, Grid, SelectChangeEvent, Button } from '@mui/material'
import OutlinedContainer from '../../elements/OutlinedContainer'
import { useMutation, useQuery } from 'react-query'
import { NewPartnerEntity, NewPartnerResponse } from '../../interfaces/Partners/NewPartnerEntity'
import { format, isValid } from 'date-fns'
import NewPartnerBaseData from './NewPartnerBaseData'
import { editPartnerData, editPartnerStatus, fetchRegistryData } from '../../../api/API'
import { Loader } from '../../elements/Loader'
import NewPartnerAdditionalPropertyData from './NewPartnerAdditionalPropertyData'
import { NewPartnerAdditionalData } from './NewPartnerAdditionalData'
import { FileListItem } from '../../interfaces/File/FileListItem'
import NewPartnerContractData from './NewPartnerContractData'
import { uploadFiles } from '../../utils/uploadFiles'
import {
  DEFAULT_API_DATE_FORMAT,
  NEW_ONLINE_STORE,
  REQUIRED_ERROR_MESSAGE,
  EMAIL_LENGTH,
  INVALID_EMAIL_LENGTH,
  INVALID_DATE_FORMAT,
  REGEX_HOME_PAGE_FORMAT,
  INVALID_HOME_PAGE_URL,
  FILE_SIZE_LIMIT_MB_MESSAGE,
} from '../../../constants/constants'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { FileEntry } from '../../interfaces/File/FileEntry'
import { NewPartnerStatus } from '../../interfaces/Partners/NewPartnerStatus'
import { ClassifierStatus } from '../../interfaces/ClassifierStatus'
import { mapPartnerAgreementFilesToFileListItems } from '../../utils/partner'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { PartnerContractFiles } from '../../interfaces/Partners/PartnerContract'

const sx = {
  formRow: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  submitWrap: {
    justifyContent: 'flex-end',
  },
  label: {
    marginRight: 2,
    width: '260px',
    display: 'inline-block',
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}

const defaultFormErrors = {
  reģistrācijas_numurs: '',
  nosaukums: '',
  pvn_reģistrācijas_numurs: '',
  juridiskā_adrese: '',
  konta_numurs: '',
  banka: '',
  swift: '',
  veids_kods: '',
  mājas_lapa: '',
}

interface Props {
  partnerEntry: NewPartnerResponse
  partnerId: number
  onSuccess: () => void
  partnerTypeList: ClassifierStatus[]
  partnerGroupList: ClassifierStatus[]
  partnerStatusList: ClassifierStatus[]
  contractDiscontinueCodes: ClassifierStatus[]
  partnerTagsList: ClassifierStatus[]
}

export function ExistingPartnerEdit({
  partnerEntry,
  partnerId,
  partnerTypeList,
  partnerStatusList,
  contractDiscontinueCodes,
  partnerGroupList,
  partnerTagsList,
  onSuccess,
}: Props) {
  const { showSnackBar } = useSnackBar()

  const [filesContract, setFilesContract] = useState<FileListItem[]>(
    mapPartnerAgreementFilesToFileListItems(partnerEntry)
  )

  const [filesLogo, setFilesLogo] = React.useState<FileListItem[]>(
    partnerEntry && partnerEntry.logotips
      ? [
          {
            file: {
              faila_id: partnerEntry.logotips.id,
              nosaukums: partnerEntry.logotips.nosaukums,
              content_type: partnerEntry.logotips.content_type,
              sha256: partnerEntry.logotips.sha256,
            },
            state: 'keep',
          },
        ]
      : []
  )
  const [filesPhoto, setFilesPhoto] = React.useState<FileListItem[]>(
    partnerEntry && partnerEntry.foto
      ? [
          {
            file: {
              faila_id: partnerEntry.foto.id,
              nosaukums: partnerEntry.foto.nosaukums,
              content_type: partnerEntry.foto.content_type,
              sha256: partnerEntry.foto.sha256,
            },
            state: 'keep',
          },
        ]
      : []
  )
  const [uploadedFilesPhoto, setUploadedFilesPhoto] = useState<FileEntry[]>([])
  const [uploadedFilesLogo, setUploadedFilesLogo] = useState<FileEntry[]>([])
  const [uploadedFilesContract, setUploadedFilesContract] = useState<PartnerContractFiles[]>(
    filesContract.map((file) => {
      return {
        faila_id: (file.file as FileEntry).faila_id,
      }
    })
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
  const [formState, setFormState] = React.useState<NewPartnerEntity>({
    reģistrācijas_numurs: partnerEntry.reģistrācijas_numurs,
    nosaukums: partnerEntry.nosaukums,
    juridiskais_nosaukums: partnerEntry.juridiskais_nosaukums,
    veids_kods: partnerEntry.veids_kods,
    epasts: partnerEntry.epasts,
    pvn_reģistrācijas_numurs: partnerEntry.pvn_reģistrācijas_numurs,
    juridiskā_adrese: partnerEntry.juridiskā_adrese?.adrese
      ? {
          address: partnerEntry.juridiskā_adrese?.adrese,
        }
      : { address: '' },
    konta_numurs: partnerEntry.konta_numurs,
    interneta_veikals: partnerEntry.interneta_veikals,
    statuss_kods: partnerEntry.statuss_kods || '',
    banka: partnerEntry.banka,
    swift: partnerEntry.swift,
    mājas_lapa: partnerEntry.mājas_lapa,
    pakalpojumu_grupa: partnerEntry.pakalpojumu_grupa,
    pakalpojumu_birkas: partnerEntry.pakalpojumu_birkas,
    apraksts: partnerEntry.apraksts,
    līgums: {
      izveidošanas_datums: partnerEntry.līgums?.izveidošanas_datums
        ? new Date(partnerEntry.līgums?.izveidošanas_datums)
        : null,
      komentārs: partnerEntry.līgums?.komentārs || '',
      nosaukums: partnerEntry.līgums?.nosaukums || '',
      numurs: partnerEntry.līgums?.numurs || '',
      termiņš: partnerEntry.līgums?.termiņš ? new Date(partnerEntry.līgums?.termiņš) : null,
    },
    foto_id: partnerEntry.foto_id,
    logotips_id: partnerEntry.logotips_id,
    pārtraukšanas_iemesls_kods: partnerEntry.pārtraukšanas_iemesls_kods,
  })
  const isActive = formState.statuss_kods === 'SAKT'

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!formState.veids_kods) {
      errors['veids_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (formState.epasts && formState.epasts.length > EMAIL_LENGTH) {
      errors['epasts'] = INVALID_EMAIL_LENGTH
    }

    if (formState.mājas_lapa && !formState.mājas_lapa.match(REGEX_HOME_PAGE_FORMAT)) {
      errors['mājas_lapa'] = INVALID_HOME_PAGE_URL
    }

    if (formState.līgums && formState.līgums.termiņš && !isValid(formState.līgums.termiņš)) {
      errors['termiņš'] = INVALID_DATE_FORMAT
    }

    if (isActive && filesContract.length < 1) {
      showSnackBar({
        severity: 'error',
        text: 'Obligāti jāpievieno līguma fails',
      })
      return true
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const { refetch: refetchRegistryData, isFetching: isRegistryDataLoading } = useQuery(
    'registry-data',
    () => fetchRegistryData(formState.reģistrācijas_numurs),
    {
      onSuccess: (data) => {
        const { address, legalForm, name } = data

        setFormState({
          ...formState,
          nosaukums: name,
          juridiskais_nosaukums: name,
          veids_kods: legalForm,
          juridiskā_adrese: {
            address,
          },
        })
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const [editPartnerMutation] = useMutation(
    ({ juridiskā_adrese, līgums, ...partnerData }: NewPartnerEntity) =>
      editPartnerData(
        {
          ...partnerData,
          id: partnerId,
          juridiskā_adrese: {
            adrese: juridiskā_adrese?.address,
          },
          līgums: {
            ...līgums,
            termiņš:
              formState && formState?.līgums?.termiņš
                ? format(formState.līgums.termiņš, DEFAULT_API_DATE_FORMAT)
                : null,
            izveidošanas_datums: format(new Date(), DEFAULT_API_DATE_FORMAT),
          },
        },
        partnerId as number
      ),
    {
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const [editPartnerStatusMutation] = useMutation(
    ({ statuss_kods }: NewPartnerStatus) =>
      editPartnerStatus(
        {
          statuss_kods: statuss_kods,
          id: partnerId,
        },
        partnerId as number
      ),
    {
      onError: (error: any) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      onSuccess: () => {
        showSnackBar()
        setIsLoading(false)
        onSuccess()
      },
    }
  )

  const handlePartnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      [event.target.name]: value,
    }))

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      juridiskā_adrese: {
        address: event.target.value,
      },
    }))
  }

  const handleDropDownChange = (event: SelectChangeEvent) => {
    let value = event.target.value

    setFormState((prevFormState) => ({
      ...prevFormState,
      [event.target.name]: value,
    }))

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const handleContractChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value

    setFormState((prevFormState) => ({
      ...prevFormState,
      līgums: {
        ...prevFormState.līgums,
        [event.target.name]: value,
      },
    }))
  }

  const onFileChange = (
    files: FileListItem[],
    setState: React.Dispatch<React.SetStateAction<FileListItem[]>>,
    setUploadedFileState: React.Dispatch<React.SetStateAction<FileEntry[]>>
  ) => {
    setState(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFileState(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFileState((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setUploadedFileState([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onOnlineStoreAdd = () => {
    const interneta_veikals = formState.interneta_veikals ? formState.interneta_veikals.slice() : []

    interneta_veikals.push(NEW_ONLINE_STORE)

    setFormState((prevFormState) => ({
      ...prevFormState,
      interneta_veikals,
    }))
  }

  const onOnlineStoreRemove = (index: number) => {
    const interneta_veikals = formState.interneta_veikals ? formState.interneta_veikals.slice() : []

    interneta_veikals.splice(index, 1)

    setFormState((prevFormState) => ({
      ...prevFormState,
      interneta_veikals,
    }))
  }

  const onOnlineStoreTextFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const interneta_veikals = formState.interneta_veikals ? formState.interneta_veikals.slice() : []

    interneta_veikals[index] = {
      ...interneta_veikals[index],
      [event.target.name]: event.target.value,
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      interneta_veikals,
    }))
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoading(true)
    editPartnerMutation({
      ...formState,
      apraksts: formState.apraksts,
      foto_id: uploadedFilesPhoto[0]
        ? uploadedFilesPhoto[0].faila_id
        : filesPhoto[0] && filesPhoto[0].state !== 'delete'
        ? formState.foto_id
        : null,
      logotips_id: uploadedFilesLogo[0]
        ? uploadedFilesLogo[0].faila_id
        : filesLogo[0] && filesLogo[0].state !== 'delete'
        ? formState.logotips_id
        : null,
      līgums: {
        ...formState.līgums,
        faili: uploadedFilesContract,
      },
    })
      .then(() => {
        if (partnerEntry.statuss_kods !== formState.statuss_kods) {
          editPartnerStatusMutation(formState)
        } else {
          showSnackBar()
          setIsLoading(false)
          onSuccess()
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setFilesContract([])
        setFilesLogo([])
        setFilesPhoto([])
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      })
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="basicInfo" label={<b>Pamatdati</b>}>
            <NewPartnerBaseData
              handlePartnerChange={handlePartnerChange}
              handleDropDownChange={handleDropDownChange}
              partner={formState}
              formErrors={formErrors}
              partnerTypeList={partnerTypeList}
              handleRegistryData={refetchRegistryData}
              isRegistryDataLoading={isRegistryDataLoading}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="propInfo" label={<b>Rekvizīti</b>}>
            <NewPartnerAdditionalPropertyData
              handlePartnerChange={handlePartnerChange}
              handleAddressChange={handleAddressChange}
              partner={formState}
              formErrors={formErrors}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="extraInfo" label={<b>Papildus informācija</b>}>
            <NewPartnerAdditionalData
              filesPhoto={filesPhoto}
              filesLogo={filesLogo}
              partner={formState}
              formErrors={formErrors}
              partnerGroupList={partnerGroupList}
              partnerTagsList={partnerTagsList}
              setPartner={setFormState}
              onFileChange={onFileChange}
              handlePartnerChange={handlePartnerChange}
              setFilesLogo={setFilesLogo}
              setFilesPhoto={setFilesPhoto}
              setUploadedFilesLogo={setUploadedFilesLogo}
              setUploadedFilesPhoto={setUploadedFilesPhoto}
              onOnlineStoreAdd={onOnlineStoreAdd}
              onOnlineStoreRemove={onOnlineStoreRemove}
              onOnlineStoreTextFieldChange={onOnlineStoreTextFieldChange}
            />
          </OutlinedContainer>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <OutlinedContainer id="contractInfo" label={<b>Līgums</b>}>
            <NewPartnerContractData
              partner={formState}
              partnerId={partnerId}
              setPartner={setFormState}
              partnerStatusList={partnerStatusList}
              contractDiscontinueCodes={contractDiscontinueCodes}
              formErrors={formErrors}
              handleDropDownChange={handleDropDownChange}
              handleContractChange={handleContractChange}
              onFileChange={onFileChange}
              filesContract={filesContract}
              setFilesContract={setFilesContract}
              setUploadedFilesContract={setUploadedFilesContract}
            />
          </OutlinedContainer>
        </Grid>
      </Grid>

      <Box sx={[sx.formRow, sx.submitWrap]}>
        <Button variant="outlined" color="primary" type="submit">
          Saglabāt
        </Button>
      </Box>

      <Loader open={isLoading} />
    </form>
  )
}
