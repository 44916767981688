import { Box, Button, Divider, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import {
  ACCEPTED_PARTNER_FILE_TYPES,
  FILE_SIZE_LIMIT_MB_MESSAGE,
} from '../../../constants/constants'
import { FileUploadButton } from '../../elements/FileUploadButton'
import AddressDropDownList from '../../elements/partners/AddressDropDownList'
import { FileListItem } from '../../interfaces/File/FileListItem'
import { AddressResponse } from '../../interfaces/Partners/AddressResponse'
import { NewPartnerLocation } from '../../interfaces/Partners/NewPartnerLocation'

const sx = {
  button: {
    minWidth: 130,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  formRow: {
    mt: 2,
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    mt: '11px',
    mr: 2,
    width: '160px',
    display: 'inline-block',
    alignSelf: 'baseline',
  },
}

const emptyLocationAddress = {
  address: '',
  code: 0,
  ielCode: 0,
  ielName: '',
  irAdrese: false,
  nltCode: 0,
  geom: null,
  nltName: '',
  pilAtvk: '',
  pilCode: 0,
  pilName: '',
  typ: 0,
  zipCode: '',
} as AddressResponse

const emptyLocation = {
  adrese: emptyLocationAddress,
}

interface Props {
  locations: NewPartnerLocation[]
  setLocations: React.Dispatch<React.SetStateAction<NewPartnerLocation[]>>
  handleLocationChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: AddressResponse,
    index: number
  ) => void
  removeAdditionalEntry: (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerLocation[]>>,
    index: number
  ) => void
  addAdditionalEntry: (
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerLocation[]>>,
    emptyObject: object
  ) => void
  handleArrayValueChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    state: object[],
    setState: React.Dispatch<React.SetStateAction<NewPartnerLocation[]>>,
    index: number
  ) => void
}

export default function NewPartnerLocationData({
  locations,
  setLocations,
  handleLocationChange,
  addAdditionalEntry,
  removeAdditionalEntry,
  handleArrayValueChange,
}: Props) {
  const handleFileChange = (files: FileListItem[], index: number) => {
    let locationsCopy = [...locations]
    locationsCopy[index] = {
      ...locations[index],
      fails: files,
    }

    setLocations(locationsCopy)
  }

  return (
    <>
      {locations.map((location, index) => (
        <Box key={index}>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Adrese
            </Box>
            <AddressDropDownList
              value={locations[index].adrese}
              onValueChange={(event: SyntheticEvent<Element, Event>, newValue: AddressResponse) =>
                handleLocationChange(event, newValue, index)
              }
              index={index}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Komentārs
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              name="komentārs"
              onChange={(e) => handleArrayValueChange(e, locations, setLocations, index)}
              value={locations[index].komentārs}
            />
          </Box>
          <Box sx={sx.formRow}>
            <Box component="label" sx={sx.labelRow}>
              Attēls
            </Box>
            <Box>
              <FileUploadButton
                multiple={false}
                onFileChange={(e) => handleFileChange(e, index)}
                files={location.fails || []}
                acceptedUploadFileTypes={ACCEPTED_PARTNER_FILE_TYPES}
              />
              <Typography sx={{ ml: 0.5, color: '#999', mt: '-10px !important' }}>
                {FILE_SIZE_LIMIT_MB_MESSAGE}
              </Typography>
            </Box>
          </Box>
          {locations.length > 0 && (
            <>
              <Box
                mb={2}
                mt={2}
                style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  sx={sx.button}
                  variant="contained"
                  onClick={() => removeAdditionalEntry(locations, setLocations, index)}
                  color="primary"
                >
                  Noņemt vietu
                </Button>
              </Box>

              <Divider sx={sx.divider} />
            </>
          )}
        </Box>
      ))}
      <Box mb={2} mt={6} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={sx.button}
          variant="outlined"
          color="primary"
          onClick={() => addAdditionalEntry(locations, setLocations, emptyLocation)}
        >
          Pievienot vietu
        </Button>
      </Box>
    </>
  )
}
